import React, { useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useReactToPrint } from 'react-to-print'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useLocationsQuery } from 'shared/store/useLocations'
import { Order } from 'shared/store/useOrdersHistory/types'
import CustomDialog from 'shared/components/CustomDialog'
import { useProfileQuery } from 'shared/store/useProfile'

import { PrintType, StoredOrderDetails } from '../../types'
import { getOrderDetailsService } from '../../services'
import ClientTable from './components/ClientTable'
import FullTable from './components/FullTable'

interface Props {
   orderUuids: string[]
   open: boolean
   title: string
   cartDescription?: string
   printType: PrintType
   onClose: () => void
   details: StoredOrderDetails[]
   updateDetailsStore: (newRecord: StoredOrderDetails) => void
   orderType?: PropType<Order, 'orderType'>
   formattedValue?: string
   formattedAdditionalServicesValue?: string
   formattedClientValueWithServices?: string
   formattedClientValue?: string
   netDeliveryCost?: number
   grossDeliveryCost?: number
}

export default function OrderPrint({
   orderUuids,
   open,
   onClose,
   details,
   title,
   printType,
   updateDetailsStore,
   formattedClientValueWithServices,
   cartDescription,
   formattedAdditionalServicesValue,
   orderType,
   formattedClientValue,
   formattedValue,
   netDeliveryCost,
   grossDeliveryCost,
}: Props) {
   const [data, setData] = useState<PropType<StoredOrderDetails, 'data'>>({
      products: [],
      additionalServices: [],
   })
   const componentRef = useRef(null)
   const { t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])
   const { data: locationsData } = useLocationsQuery()
   const { data: profileSettings } = useProfileQuery()
   const isDetailsStored = useRef(false)
   const [isLoading, setIsLoading] = useState(false)

   const activeLocationSettings = locationsData?.locations.find(
      (item) => item.locationSettings.uuid === profileSettings?.locationUuid
   )

   async function init() {
      const storedDetails = details.find((item) => item.id === orderUuids[0])
      if (storedDetails) {
         isDetailsStored.current = true
         setData({ additionalServices: storedDetails.data.additionalServices, products: storedDetails.data.products })
      } else {
         setIsLoading(true)
         const req = await getOrderDetailsService(orderUuids)
         if (req) {
            setData({
               additionalServices: req.additionalServices,
               products: req.products,
            })
         }
      }
      setIsLoading(false)
   }

   useEffect(() => {
      init()
   }, [])

   const handleOnClose = () => {
      if (!isDetailsStored.current && orderType) {
         updateDetailsStore({ orderType, id: orderUuids[0], data })
      }
      onClose()
   }

   function renderTables() {
      if (printType === 'clientPrint') {
         return (
            <ClientTable
               formattedClientValue={formattedClientValue || ''}
               orders={data}
               formattedClientValueWithServices={formattedClientValueWithServices}
               formattedAdditionalServicesValue={formattedAdditionalServicesValue}
               priceType={activeLocationSettings?.markupSettings.priceType}
               orderType={orderType}
            />
         )
      }
      return (
         <FullTable
            formattedClientValue={formattedClientValue}
            formattedValue={formattedValue}
            formattedAdditionalServicesValue={formattedAdditionalServicesValue}
            orders={data}
            priceType={activeLocationSettings?.markupSettings.priceType}
            orderType={orderType}
            netDeliveryCost={netDeliveryCost}
            grossDeliveryCost={grossDeliveryCost}
         />
      )
   }

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   return (
      <CustomDialog
         isLoading={isLoading}
         open={open}
         onClose={handleOnClose}
         fullWidth
         scroll="paper"
         maxWidth="lg"
         content={
            <>
               <DialogContent
                  ref={componentRef}
                  style={{ minHeight: 300, display: 'flex', flexDirection: 'column', padding: 20 }}
               >
                  <Box
                     sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                        mb: { xs: 2 },
                     }}
                  >
                     {profileSettings?.image && (
                        <img
                           style={{ marginLeft: 'auto' }}
                           height="60px"
                           width="auto"
                           alt={activeLocationSettings?.locationSettings.orderReflinkSettings?.companyName || 'Logo'}
                           src={`data:image/png;base64,${profileSettings?.image}`}
                        />
                     )}
                     {activeLocationSettings?.locationSettings.orderReflinkSettings?.companyName && (
                        <Typography variant="body1">
                           {activeLocationSettings.locationSettings.orderReflinkSettings.companyName}
                        </Typography>
                     )}
                     {activeLocationSettings?.locationSettings.orderReflinkSettings?.address && (
                        <Typography variant="body1">
                           {activeLocationSettings.locationSettings.orderReflinkSettings.address}
                        </Typography>
                     )}

                     {activeLocationSettings?.locationSettings.orderReflinkSettings?.phoneNumber && (
                        <Typography variant="body1">{`Tel. ${activeLocationSettings.locationSettings.orderReflinkSettings.phoneNumber}`}</Typography>
                     )}
                  </Box>
                  <Typography variant="h5" color="primary">
                     {title}
                  </Typography>
                  {renderTables()}
                  <TextField
                     label={t('TRANSLATION:columnNames.cartDescription')}
                     rows={5}
                     multiline
                     InputProps={{ readOnly: true }}
                     sx={{ width: { md: 400, xs: 350 } }}
                     value={cartDescription || ''}
                  />
               </DialogContent>
               <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', width: '100%', p: 2 }}>
                  <OutlinedButton variant="outlined" onClick={handleOnClose}>
                     {t('TRANSLATION:cancel')}
                  </OutlinedButton>
                  <ContainedButton variant="contained" onClick={handlePrint}>
                     {t('TRANSLATION:print')}
                  </ContainedButton>
               </Box>
            </>
         }
      />
   )
}
