import React, { useEffect } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useEditServiceMutation } from 'shared/store/useAddtionalServices'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useProfileQuery } from 'shared/store/useProfile'
import InputField from 'shared/components/InputField'

import { DialogActions } from '@mui/material'
import { CreateServiceFormikType, ModalInfoType } from '../../../types'
import { createServiceSchema } from '../../../schema'

interface Props {
   handleClose: () => void
   locationUuid: string
   serviceInfo: PropType<ModalInfoType, 'serviceInfo'>
   serviceUuid: string
}

export default function EditServiceModal({ handleClose, serviceInfo, serviceUuid, locationUuid }: Props) {
   const { mutate, isLoading } = useEditServiceMutation()
   const { t } = useTranslation(['TRANSLATION', 'SERVICES'])
   const { data } = useProfileQuery()

   const formik = useFormik<CreateServiceFormikType>({
      initialValues: {
         name: '',
         defaultGrossPrice: 0,
         defaultNetPrice: 0,
         taxRate: 0,
      },
      validationSchema: createServiceSchema,
      onSubmit: editService,
   })

   const { values, isValid, setFieldValue, setValues } = formik

   function editService() {
      mutate(
         { payload: { ...values }, locationUuid, serviceUuid },
         {
            onSuccess: () => {
               handleClose()
            },
         }
      )
   }

   useEffect(() => {
      if (values.taxRate !== 0) {
         const newValue = Number((values.defaultNetPrice * (1 + values.taxRate / 100)).toFixed(2))
         setFieldValue('defaultGrossPrice', newValue)
      }
   }, [values.taxRate])

   function calculateNewNetPrice() {
      if (values.taxRate !== 0 && values.defaultGrossPrice !== 0) {
         const newNetPrice = Number((values.defaultGrossPrice / (1 + values.taxRate / 100)).toFixed(2))
         setFieldValue('defaultNetPrice', newNetPrice)
      }
   }

   function calculateNewGrossPrice() {
      if (values.taxRate !== 0 && values.defaultNetPrice !== 0) {
         const newGrossPrice = Number((values.defaultNetPrice * (1 + values.taxRate / 100)).toFixed(2))
         setFieldValue('defaultGrossPrice', newGrossPrice)
      }
   }

   useEffect(() => {
      if (serviceInfo) {
         setValues({ ...serviceInfo })
      }
   }, [])

   return (
      <>
         <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
               {t('SERVICES:editServiceLabel', { serviceName: serviceInfo?.name })}
            </Typography>
            <FormikProvider value={formik}>
               <Form>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           gap: 2,
                           justifyContent: 'center',
                           minWidth: 300,
                        }}
                     >
                        <InputField<CreateServiceFormikType>
                           variant="standard"
                           label={t('TRANSLATION:formFields.serviceName')}
                           onFocus={(e) => e.target.select()}
                           name="name"
                           required
                           inputProps={{
                              maxLength: 32,
                           }}
                        />
                        <InputField<CreateServiceFormikType>
                           variant="standard"
                           type="Number"
                           inputProps={{
                              min: 0,
                              step: 0.5,
                           }}
                           onFocus={(e) => e.target.select()}
                           label="VAT"
                           name="taxRate"
                           required
                        />
                        <InputField<CreateServiceFormikType>
                           variant="standard"
                           type="Number"
                           onBlur={calculateNewGrossPrice}
                           onFocus={(e) => e.target.select()}
                           inputProps={{
                              min: 0,
                              step: 0.01,
                           }}
                           label={`${t('TRANSLATION:formFields.defaultNetPrice')} (${data?.formattedCurrency})`}
                           name="defaultNetPrice"
                           required
                        />
                        <InputField<CreateServiceFormikType>
                           variant="standard"
                           onBlur={calculateNewNetPrice}
                           type="Number"
                           onFocus={(e) => e.target.select()}
                           inputProps={{
                              min: 0,
                              step: 0.01,
                           }}
                           label={`${t('TRANSLATION:formFields.defaultGrossPrice')} (${data?.formattedCurrency})`}
                           name="defaultGrossPrice"
                           required
                        />
                     </Box>
                  </Box>
               </Form>
            </FormikProvider>
         </DialogContent>
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton disabled={isLoading || !isValid} variant="contained" onClick={editService} type="submit">
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
