import Select, { SelectChangeEvent } from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { useQueryClient } from 'react-query'
import { DialogActions, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { BaselinkerType } from 'shared/store/useWholesalers/types'
import VivaldiIcon from 'assets/images/browserIcons/vivaldi.png'
import ChromeIcon from 'assets/images/browserIcons/chrome.png'
import BraveIcon from 'assets/images/browserIcons/brave.png'
import OperaIcon from 'assets/images/browserIcons/opera.png'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import EdgeIcon from 'assets/images/browserIcons/edge.png'
import { useLocationsQuery } from 'shared/store/useLocations'
import SelectField from 'shared/components/SelectField'
import CustomDialog from 'shared/components/CustomDialog'

import {
   createBaselinkerConfigService,
   deleteBaselinkerConfigService,
   editBaselinkerConfigService,
   getBaselinkerStatusesService,
   getExternalInformationsService,
} from '../../services'
import {
   ExternalFieldToModify,
   ExternalStatusType,
   FormikType,
   GetExternalFieldsType,
   ModifyPayload,
} from '../../types'

interface Props {
   open: boolean
   data: BaselinkerType
   type: 'new' | 'edit' | 'delete'
   handleClose: () => void
}

export default function BaselinkerModal({ open, type, data, handleClose }: Props) {
   const [token, setToken] = useState('')
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const [baselinkerProblem, setBaselinkerProblem] = useState<boolean>(false)
   const queryClient = useQueryClient()
   const [options, setOptions] = useState<ExternalStatusType>()
   const [externalFields, setExternalFields] = useState<GetExternalFieldsType>()
   const { addNotification } = useNotificationContext()
   const [loading, setLoading] = useState(true)
   const { data: locations } = useLocationsQuery()
   const [sendingRequest, setSendingRequest] = useState(false)
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])

   const formik = useFormik<FormikType>({
      initialValues: {
         token: '',
         locationUuid: '',
         successfull: { id: 0, name: '' },
         failed: { id: 0, name: '' },
         partiallySuccessfull: { id: 0, name: '' },
         orderFields: {
            orderNetCostField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            orderGrossCostField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            orderOrderUuidField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            orderStatusField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            orderNotOrderedField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            orderOrderedField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            notFoundProductsIndexes: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            foundProductsIndexes: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
         },
         productFields: {
            productGrossPriceField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            productIndexField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            productNetPriceField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
            productWholesaleNameField: {
               tag: '',
               isExtra: false,
               text: '',
               type: '',
            },
         },
      },
      onSubmit: () => {},
   })

   const { values, setValues, setFieldValue } = formik

   function handleIntegrationCreate() {
      if (token) {
         createBaselinkerConfigService(token, values.locationUuid).then((res) => {
            if (res.status === 200) {
               queryClient.invalidateQueries('wholesalers')
               handleClose()
            }
         })
      }
   }

   function handleIntegrationDelete() {
      if (data.uuid) {
         deleteBaselinkerConfigService(data.uuid)
            .then((res) => {
               if (res.status === 204) {
                  addNotification(t('WHOLESALERS:externalTool.deleteSuccess'), 'success')
                  queryClient.invalidateQueries('wholesalers')
                  handleClose()
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(
                     error.response?.data.errors[0].code || 'WHOLESALERS:externalTool.deleteFailed',
                     'error'
                  )
               } else {
                  addNotification('WHOLESALERS:externalTool.deleteFailed', 'error')
               }
            })
      }
   }

   function handleIntegrationEdit() {
      setSendingRequest(true)

      const productFieldsMap: ExternalFieldToModify = {}
      const orderFieldsMap: ExternalFieldToModify = {}

      Object.values(values.productFields).forEach((item) => {
         if (item.tag) {
            const typeValue = item.type
            productFieldsMap[typeValue] = { tag: item.tag, text: item.text, isExtra: item.isExtra }
         }
      })

      Object.values(values.orderFields).forEach((item) => {
         if (item.tag) {
            const typeValue = item.type
            orderFieldsMap[typeValue] = { tag: item.tag, text: item.text, isExtra: item.isExtra }
         }
      })

      const payload: ModifyPayload = {
         token: values.token,
         locationUuid: values.locationUuid,
         externalStatusesMap: {
            SUCCESSFUL: {
               ...values.successfull,
            },
            PARTIALLY_SUCCESSFUL: {
               ...values.partiallySuccessfull,
            },
            FAILED: {
               ...values.failed,
            },
         },
         productFieldsMap,
         orderFieldsMap,
      }
      if (data.uuid && payload) {
         editBaselinkerConfigService(data.uuid, { ...payload })
            .then((res) => {
               if (res.status === 200) {
                  addNotification('WHOLESALERS:externalTool.editSuccess', 'success')
                  queryClient.invalidateQueries('wholesalers')
                  handleClose()
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data.errors[0].code || 'WHOLESALERS:externalTool.editFailed', 'error')
               } else {
                  addNotification('WHOLESALERS:externalTool.editFailed', 'error')
               }
            })
            .finally(() => setSendingRequest(false))
      }
   }

   function handleExternalSelect(
      e: SelectChangeEvent<string> | SelectChangeEvent<number>,
      fieldName: string,
      fieldType: 'product' | 'order',
      externalType: string
   ) {
      let foundField
      if (e.target.value === '') {
         setFieldValue(fieldName, { tag: '', isExtra: false, text: '' })
      } else if (externalFields) {
         if (fieldType === 'order') {
            foundField = externalFields.externalOrderFields.find((item) => item.tag === e.target.value)
         } else {
            foundField = externalFields.externalOrderProductFields.find((item) => item.tag === e.target.value)
         }
         if (foundField) {
            setFieldValue(fieldName, { ...foundField, type: externalType })
         }
      }
   }

   function handleSelect(e: SelectChangeEvent<string> | SelectChangeEvent<number>, fieldName: string) {
      if (options) {
         const foundOption = options.find((item) => item.id === e.target.value)
         if (foundOption) {
            setFieldValue(fieldName, { id: e.target.value, name: foundOption.name })
         }
      }
   }

   function renderExtensions() {
      return (
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <Typography sx={{ mt: 2 }} variant="h6" color="primary">
               {t('WHOLESALERS:baselinker.downloadExtension')}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
               <IconButton
                  onClick={() =>
                     window.open(
                        'https://chromewebstore.google.com/detail/motorro-for-baselinker/djfeioefppbglafllnefcmckoaicnofd',
                        '_blank'
                     )
                  }
               >
                  <img height={25} width="auto" src={ChromeIcon} alt="chrome" />
               </IconButton>
               <IconButton
                  onClick={() =>
                     window.open(
                        'https://chromewebstore.google.com/detail/motorro-for-baselinker/djfeioefppbglafllnefcmckoaicnofd',
                        '_blank'
                     )
                  }
               >
                  <img height={25} width="auto" src={OperaIcon} alt="chrome" />
               </IconButton>
               <IconButton
                  onClick={() =>
                     window.open(
                        'https://chromewebstore.google.com/detail/motorro-for-baselinker/djfeioefppbglafllnefcmckoaicnofd',
                        '_blank'
                     )
                  }
               >
                  <img height={25} width="auto" src={EdgeIcon} alt="chrome" />
               </IconButton>
               <IconButton
                  onClick={() =>
                     window.open(
                        'https://chromewebstore.google.com/detail/motorro-for-baselinker/djfeioefppbglafllnefcmckoaicnofd',
                        '_blank'
                     )
                  }
               >
                  <img height={25} width="auto" src={BraveIcon} alt="chrome" />
               </IconButton>
               <IconButton
                  onClick={() =>
                     window.open(
                        'https://chromewebstore.google.com/detail/motorro-for-baselinker/djfeioefppbglafllnefcmckoaicnofd',
                        '_blank'
                     )
                  }
               >
                  <img height={25} width="auto" src={VivaldiIcon} alt="chrome" />
               </IconButton>
            </Box>
         </Box>
      )
   }

   useEffect(() => {
      if (locations) {
         setFieldValue(
            'locationUuid',
            locations.locations.find((location) => location.locationSettings.uuid === locations.mainLocationUuid)!
               .locationSettings.uuid
         )
      }
   }, [locations])

   function renderContent() {
      if (baselinkerProblem) {
         return (
            <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <Typography variant="h5" color="GrayText">
                  {t('WHOLESALERS:baselinker.problemWithBaselinker')}
               </Typography>
            </DialogContent>
         )
      }

      switch (type) {
         case 'new':
            return (
               <>
                  <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'space-beetwen' }}>
                     <Typography sx={{ mb: 1 }} variant="body1">
                        {t('WHOLESALERS:baselinker.addBaselinkerText')}
                     </Typography>
                     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <TextField
                           onChange={(e) => setToken(e.target.value)}
                           variant="standard"
                           type="password"
                           fullWidth
                           label={t('WHOLESALERS:externalTool.tokenLabel')}
                        />
                        <FormControl fullWidth component="fieldset" variant="standard">
                           <InputLabel id="select-label">{t('WHOLESALERS:baselinker.selectLocation')}</InputLabel>
                           <Select value={values.locationUuid} label={t('WHOLESALERS:baselinker.selectLocation')}>
                              {locations?.locations
                                 .filter((location) => !location.locationSettings.disabled)
                                 .map((location) => (
                                    <MenuItem value={location.locationSettings.uuid}>
                                       {location.locationSettings.name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                     {!isMobile && renderExtensions()}
                  </DialogContent>
                  <DialogActions>
                     <OutlinedButton onClick={handleClose} variant="outlined">
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton onClick={handleIntegrationCreate} disabled={!token} variant="contained">
                        {t('TRANSLATION:save')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         case 'edit':
            return (
               <Box sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'space-beetwen' }}>
                  <DialogContent>
                     <Typography variant="body1">{t('WHOLESALERS:baselinker.editStatusesText')}</Typography>
                     {!loading ? (
                        <>
                           <FormikProvider value={formik}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                 <TextField
                                    value={values.token}
                                    onChange={(e) => setFieldValue('token', e.target.value)}
                                    variant="standard"
                                    type="password"
                                    label={t('WHOLESALERS:externalTool.tokenLabel')}
                                 />
                                 <SelectField
                                    name="locationUuid"
                                    label={t('WHOLESALERS:baselinker.selectLocation')}
                                    options={
                                       locations
                                          ? [
                                               ...locations.locations
                                                  .filter((location) => !location.locationSettings.disabled)
                                                  .map((location) => ({
                                                     value: location.locationSettings.uuid,
                                                     name: location.locationSettings.name,
                                                  })),
                                            ]
                                          : []
                                    }
                                 />
                                 <Box
                                    sx={{
                                       display: 'flex',
                                       flexDirection: { md: 'row', xs: 'column' },
                                       mt: 2,
                                       gap: { xs: 1 },
                                    }}
                                 >
                                    <FormControl fullWidth component="fieldset" variant="standard">
                                       <InputLabel id="select-label">{t('WHOLESALERS:baselinker.success')}</InputLabel>
                                       <Select
                                          label={t('WHOLESALERS:baselinker.success')}
                                          labelId="select-label"
                                          value={values.successfull.id}
                                          sx={{ minWidth: 150, height: 30 }}
                                          onChange={(e) => handleSelect(e, 'successfull')}
                                       >
                                          {options
                                             ? options.map((item) => (
                                                  <MenuItem key={item.id} value={item.id}>
                                                     {item.name}
                                                  </MenuItem>
                                               ))
                                             : null}
                                       </Select>
                                    </FormControl>
                                    <FormControl fullWidth component="fieldset" variant="standard">
                                       <InputLabel id="select-label">
                                          {t('WHOLESALERS:baselinker.partiallySuccess')}
                                       </InputLabel>
                                       <Select
                                          label={t('WHOLESALERS:baselinker.partiallySuccess')}
                                          labelId="select-label"
                                          value={values.partiallySuccessfull.id}
                                          sx={{ minWidth: 150, height: 30 }}
                                          onChange={(e) => handleSelect(e, 'partiallySuccessfull')}
                                       >
                                          {options
                                             ? options.map((item) => (
                                                  <MenuItem key={item.id} value={item.id}>
                                                     {item.name}
                                                  </MenuItem>
                                               ))
                                             : null}
                                       </Select>
                                    </FormControl>
                                    <FormControl fullWidth component="fieldset" variant="standard">
                                       <InputLabel id="select-label">{t('WHOLESALERS:baselinker.failed')}</InputLabel>
                                       <Select
                                          labelId="select-label"
                                          label={t('WHOLESALERS:baselinker.failed')}
                                          value={values.failed.id}
                                          sx={{ minWidth: 150, height: 30 }}
                                          onChange={(e) => handleSelect(e, 'failed')}
                                       >
                                          {options
                                             ? options.map((item) => (
                                                  <MenuItem key={item.id} value={item.id}>
                                                     {item.name}
                                                  </MenuItem>
                                               ))
                                             : null}
                                       </Select>
                                    </FormControl>
                                 </Box>
                                 <Typography sx={{ mt: 2 }} variant="h6" color="primary">
                                    {t('WHOLESALERS:baselinker.extraFieldsTitle')}
                                 </Typography>
                                 <Typography sx={{ mt: 2 }} variant="body1" color="primary">
                                    {t('WHOLESALERS:baselinker.extraFieldsOrder')}
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Box
                                       sx={{
                                          gap: 1,
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          flexDirection: { md: 'row', xs: 'column' },
                                       }}
                                    >
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[0].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[0].text}
                                             value={values.orderFields.orderGrossCostField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.orderGrossCostField',
                                                   'order',
                                                   'ORDER_GROSS_COST'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.orderFields.orderGrossCostField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[1].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[1].text}
                                             value={values.orderFields.orderNetCostField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.orderNetCostField',
                                                   'order',
                                                   'ORDER_NET_COST'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !== values.orderFields.orderNetCostField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[2].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[2].text}
                                             value={values.orderFields.orderOrderUuidField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.orderOrderUuidField',
                                                   'order',
                                                   'ORDER_UUID'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.orderFields.orderOrderUuidField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                    </Box>
                                    <Box
                                       sx={{
                                          gap: 1,
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          flexDirection: { md: 'row', xs: 'column' },
                                       }}
                                    >
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[3].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[3].text}
                                             value={values.orderFields.orderStatusField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.orderStatusField',
                                                   'order',
                                                   'STATUS'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !== values.orderFields.orderStatusField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[4].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[4].text}
                                             value={values.orderFields.orderNotOrderedField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.orderNotOrderedField',
                                                   'order',
                                                   'NOT_ORDERED_PRODUCTS_INDEXES'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.orderFields.orderNotOrderedField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[5].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[5].text}
                                             value={values.orderFields.orderOrderedField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.orderOrderedField',
                                                   'order',
                                                   'ORDERED_PRODUCTS_INDEXES'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !== values.orderFields.orderOrderedField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                    </Box>
                                    <Box
                                       sx={{
                                          gap: 1,
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          flexDirection: { md: 'row', xs: 'column' },
                                       }}
                                    >
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[6].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[6].text}
                                             value={values.orderFields.notFoundProductsIndexes.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.notFoundProductsIndexes',
                                                   'order',
                                                   'NOT_FOUND_PRODUCTS_INDEXES'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.orderFields.notFoundProductsIndexes.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>{' '}
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderFields[7].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderFields[7].text}
                                             value={values.orderFields.foundProductsIndexes.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'orderFields.foundProductsIndexes',
                                                   'order',
                                                   'FOUND_PRODUCTS_INDEXES'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.orderFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.orderFields.foundProductsIndexes.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                    </Box>
                                    <Typography sx={{ mt: 2 }} variant="body1" color="primary">
                                       {t('WHOLESALERS:baselinker.extraFieldsProduct')}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 1 }}>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderProductFields[0].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderProductFields[0].text}
                                             value={values.productFields.productWholesaleNameField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'productFields.productWholesaleNameField',
                                                   'product',
                                                   'WHOLESALE_NAME'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderProductFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.productFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.productFields.productWholesaleNameField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderProductFields[1].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderProductFields[1].text}
                                             value={values.productFields.productNetPriceField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'productFields.productNetPriceField',
                                                   'product',
                                                   'NET_PRICE'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderProductFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.productFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.productFields.productNetPriceField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderProductFields[2].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderProductFields[2].text}
                                             value={values.productFields.productGrossPriceField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'productFields.productGrossPriceField',
                                                   'product',
                                                   'GROSS_PRICE'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderProductFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.productFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.productFields.productGrossPriceField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                       <FormControl fullWidth component="fieldset" variant="standard">
                                          <InputLabel id="select-label">
                                             {externalFields?.internalOrderProductFields[3].text}
                                          </InputLabel>
                                          <Select
                                             labelId="select-label"
                                             label={externalFields?.internalOrderProductFields[3].text}
                                             value={values.productFields.productIndexField.tag}
                                             sx={{ minWidth: 150, height: 30 }}
                                             onChange={(e) =>
                                                handleExternalSelect(
                                                   e,
                                                   'productFields.productIndexField',
                                                   'product',
                                                   'PRODUCT_INDEX'
                                                )
                                             }
                                          >
                                             <MenuItem value="">&nbsp;</MenuItem>
                                             {externalFields
                                                ? externalFields.externalOrderProductFields
                                                     .filter(
                                                        (item) =>
                                                           !Object.values(values.productFields).some(
                                                              (value) =>
                                                                 item.tag === value.tag &&
                                                                 value.tag !==
                                                                    values.productFields.productIndexField.tag
                                                           )
                                                     )
                                                     .map((item) => (
                                                        <MenuItem key={item.tag} value={item.tag}>
                                                           {item.text}
                                                        </MenuItem>
                                                     ))
                                                : null}
                                          </Select>
                                       </FormControl>
                                    </Box>
                                 </Box>
                              </Box>
                              {!isMobile && renderExtensions()}
                           </FormikProvider>
                           <DialogActions>
                              <OutlinedButton onClick={handleClose} variant="outlined">
                                 {t('TRANSLATION:cancel')}
                              </OutlinedButton>
                              <ContainedButton
                                 onClick={handleIntegrationEdit}
                                 disabled={!values.token || sendingRequest}
                                 variant="contained"
                              >
                                 {t('TRANSLATION:save')}
                              </ContainedButton>
                           </DialogActions>
                        </>
                     ) : (
                        <CircularProgress sx={{ margin: '0 auto' }} />
                     )}
                  </DialogContent>
               </Box>
            )
         case 'delete':
            return (
               <>
                  <DialogContent />
                  <DialogActions>
                     <OutlinedButton onClick={handleClose} variant="outlined">
                        {t('TRANSLATION:no')}
                     </OutlinedButton>
                     <ContainedButton onClick={handleIntegrationDelete} variant="contained">
                        {t('TRANSLATION:yes')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         default:
            return null
      }
   }

   function renderTitle() {
      switch (type) {
         case 'new':
            return t('WHOLESALERS:baselinker.newTitle')
         case 'delete':
            return t('WHOLESALERS:baselinker.deleteTitle')
         case 'edit':
            return t('WHOLESALERS:baselinker.editTitle')
         default:
            return null
      }
   }

   useEffect(() => {
      setLoading(true)
      if (type === 'edit' && data) {
         setValues({
            locationUuid: data.locationUuid,
            token: data.token,
            successfull: {
               ...data.externalStatusesMap.SUCCESSFUL,
            },
            failed: {
               ...data.externalStatusesMap.FAILED,
            },
            partiallySuccessfull: {
               ...data.externalStatusesMap.PARTIALLY_SUCCESSFUL,
            },
            orderFields: {
               orderNetCostField: {
                  ...data.orderFieldsMap.ORDER_NET_COST,
                  type: 'ORDER_NET_COST',
               },
               orderGrossCostField: {
                  ...data.orderFieldsMap.ORDER_GROSS_COST,
                  type: 'ORDER_GROSS_COST',
               },
               orderOrderUuidField: {
                  ...data.orderFieldsMap.ORDER_UUID,
                  type: 'ORDER_UUID',
               },
               orderStatusField: {
                  ...data.orderFieldsMap.STATUS,
                  type: 'STATUS',
               },
               orderNotOrderedField: {
                  ...data.orderFieldsMap.NOT_ORDERED_PRODUCTS_INDEXES,
                  type: 'NOT_ORDERED_PRODUCTS_INDEXES',
               },
               orderOrderedField: {
                  ...data.orderFieldsMap.ORDERED_PRODUCTS_INDEXES,
                  type: 'ORDERED_PRODUCTS_INDEXES',
               },
               notFoundProductsIndexes: {
                  ...data.orderFieldsMap.NOT_FOUND_PRODUCTS_INDEXES,
                  type: 'NOT_FOUND_PRODUCTS_INDEXES',
               },
               foundProductsIndexes: {
                  ...data.orderFieldsMap.FOUND_PRODUCTS_INDEXES,
                  type: 'FOUND_PRODUCTS_INDEXES',
               },
            },
            productFields: {
               productGrossPriceField: {
                  ...data.productFieldsMap.GROSS_PRICE,
                  type: 'GROSS_PRICE',
               },
               productIndexField: {
                  ...data.productFieldsMap.PRODUCT_INDEX,
                  type: 'PRODUCT_INDEX',
               },
               productNetPriceField: {
                  ...data.productFieldsMap.NET_PRICE,
                  type: 'NET_PRICE',
               },
               productWholesaleNameField: {
                  ...data.productFieldsMap.WHOLESALE_NAME,
                  type: 'WHOLESALE_NAME',
               },
            },
         })
         getBaselinkerStatusesService().then((res) => {
            if (res.status === 200) {
               setOptions(res.data)
            }
         })
         getExternalInformationsService()
            .then((res) => {
               if (res.status === 200) {
                  setExternalFields(res.data)
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  if (error.response?.data.errors[0].code.includes('baselinker')) {
                     setBaselinkerProblem(true)
                  }
               }
            })
            .finally(() => setLoading(false))
      }
   }, [type, data.uuid])

   return (
      <CustomDialog
         isLoading={!open}
         fullWidth={type === 'edit'}
         maxWidth="md"
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>{renderTitle()}</Typography>
               </DialogTitle>
               {renderContent()}
            </>
         }
      />
   )
}
