import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const StyledBox = styled(Box)(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   gap: 3,
   margin: '0 auto',
   paddingTop: 50,
   ...(theme.breakpoints.down('lg') && {
      paddingTop: 0,
   }),
}))

export const PayBox = styled(Box)({
   position: 'relative',
   alignItems: 'center',
   zIndex: 10,
   top: -150,
   display: 'flex',
   margin: '0 auto',
   width: '70%',
   minHeight: 300,
})

export const ButtonWrapper = styled('div')({
   display: 'flex',
   justifyContent: 'flex-end',
   marginTop: 20,
   marginBottom: 20,
})

export const StyledSpan = styled('span')(({ theme }) => ({
   marginLeft: 5,
   color: theme.palette.primary.main,
   fontSize: 17,
   fontWeight: 'bolder',
}))

export const StyledAvailabilityDot = styled('div')(({ color }) => ({
   width: 15,
   margin: '0 auto',
   height: 15,
   borderRadius: 50,
   backgroundColor: color,
}))
