import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import React from 'react'

import CustomDialog from 'shared/components/CustomDialog'

import CooperationRejectedNotification from './components/CooperationRejectedNotification'
import ClientOrderCreatedNotification from './components/ClientOrderCreatedNotification'
import CooperationExpiredNotification from './components/CooperationExpriedNotification'
import ClientCartCreatedNotification from './components/ClientCartCreatedNotification'
import OversizeOrderNotification from './components/OversizeOrderNotification'
import CsvSummaryNotification from './components/CSVSummaryNotification'
import PaymentNotification from './components/PaymentNotification'
import DeleteNotification from './components/DeleteNotification'
import OrderNotification from './components/OrderNotification'
import { FormType, NotificationInfo } from '../../types'

interface Props {
   open: boolean
   type?: FormType
   handleClose: () => void
   modalInfo?: NotificationInfo
}

export default function Modal({ open, type, handleClose, modalInfo }: Props) {
   const { t } = useTranslation()
   const theme = useTheme()

   function renderContent() {
      switch (type) {
         case 'CLIENT_FINISHED_ORDER':
            return <OrderNotification handleClose={handleClose} modalInfo={modalInfo} />
         case 'WAITING_FOR_PAYMENT':
            return <PaymentNotification handleClose={handleClose} modalInfo={modalInfo} />
         case 'DELETE':
            return <DeleteNotification handleClose={handleClose} notificationUuid={modalInfo?.notificationUuid} />
         case 'AUTO_CART_CREATED':
         case 'AUTO_CART_CREATION_FAILED':
            return (
               <CsvSummaryNotification
                  cartUuid={modalInfo?.cartUuid || ''}
                  handleClose={handleClose}
                  type={type || 'AUTO_CART_CREATED'}
                  message={modalInfo?.message || ''}
               />
            )
         case 'ORDER_CREATED':
         case 'ORDER_CREATION_FAILED':
            return (
               <OversizeOrderNotification
                  handleClose={handleClose}
                  orderType={type || 'ORDER_CREATED'}
                  cartUuid={modalInfo?.cartUuid}
               />
            )
         case 'CLIENT_REFLINK_CART_CREATED':
            return (
               <ClientCartCreatedNotification
                  handleClose={handleClose}
                  cartUuid={modalInfo?.cartUuid || ''}
                  cartName={modalInfo?.cartName || ''}
               />
            )
         case 'CLIENT_REFLINK_ORDER_CREATED':
            return <ClientOrderCreatedNotification orderUuid={modalInfo?.orderUuid || ''} handleClose={handleClose} />
         case 'COOPERATION_REJECTED_BY_WHOLESALE':
            return (
               <CooperationRejectedNotification
                  wholesaleName={modalInfo?.wholesaleName || ''}
                  handleClose={handleClose}
               />
            )
         case 'COOPERATION_REFLINK_EXPIRED':
            return (
               <CooperationExpiredNotification
                  wholesaleName={modalInfo?.wholesaleName || ''}
                  handleClose={handleClose}
               />
            )
         default:
            return null
      }
   }
   return (
      <CustomDialog
         isLoading={!open}
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
                     {t(`NOTIFICATIONS:${type}`)}
                  </Typography>
               </DialogTitle>
               <DialogContent>{renderContent()}</DialogContent>
            </>
         }
      />
   )
}
