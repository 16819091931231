import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import React from 'react'

import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import CustomDialog from 'shared/components/CustomDialog'

import { getProductName } from '../../helpers'
import { BoldTypography } from '../../styles'
import { Product } from '../../types'

interface Props {
   handleClose: () => void
   open: boolean
   data?: Product[]
}

export default function ChangedPricesModal({ handleClose, open, data }: Props) {
   const theme = useTheme()
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])

   return (
      <CustomDialog
         isLoading={!open}
         maxWidth="lg"
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
                     {t('ORDER_REFLINK:modals.changedPricesTitle')}
                  </Typography>
               </DialogTitle>
               <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Typography variant="body1" color="grayText">
                     {t('ORDER_REFLINK:modals.changedPricesText')}
                  </Typography>
                  {data &&
                     data.map((product) => (
                        <BoldTypography key={product.uuid} variant="body1">
                           {t('ORDER_REFLINK:modals.productPriceChanged', {
                              productName: getProductName(product),
                              oldPrice: product.cartWholesaleDTO.oldClientPrice.toFixed(2),
                              newPrice: product.cartWholesaleDTO.clientNetPrice.toFixed(2),
                           })}
                        </BoldTypography>
                     ))}
                  <Typography variant="body1" color="grayText">
                     {t('ORDER_REFLINK:modals.contact')}
                  </Typography>
                  <OutlinedButton onClick={handleClose} variant="outlined" sx={{ alignSelf: 'center' }}>
                     {t('TRANSLATION:understand')}
                  </OutlinedButton>
               </DialogContent>
            </>
         }
      />
   )
}
