import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'

import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import GrayButton from 'shared/components/Buttons/GrayButton'

import { useMediaQuery, useTheme } from '@mui/material'
import { ButtonContainer } from './styles'

interface Props {
   status: CooperatingWholesalerStatus
   totalAvailability: Nullable<number>
   errorMessage: Nullable<string>
   isLoading: boolean
   netPrice: Nullable<number>
   onReportMissingProduct: () => void
   onSwapProduct: () => void
}

export default function Actions({
   onReportMissingProduct,
   onSwapProduct,
   status,
   netPrice,
   errorMessage,
   totalAvailability,
   isLoading,
}: Props) {
   const { t } = useTranslation('MAIN_CART')
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function renderConnectionProblemContent() {
      if (errorMessage && !errorMessage.includes('NOT_HANDLED')) {
         return (
            <Typography
               sx={{
                  fontWeight: 'bold',
                  color: 'red',
                  border: '1px solid red',
                  p: 1,
                  borderRadius: '5px',
                  maxWidth: isMobile ? '137px' : 'none',
               }}
               variant="body1"
            >
               {errorMessage}
            </Typography>
         )
      }
      return (
         <Typography
            sx={{
               fontWeight: 'bold',
               color: 'red',
               border: '1px solid red',
               p: 1,
               borderRadius: '5px',
               maxWidth: isMobile ? '137px' : 'none',
            }}
            variant="body1"
         >
            {t('swap.wholesaleReconnectFailed')}
         </Typography>
      )
   }

   if (
      status === 'ONLINE_CONNECTION_PROBLEM' ||
      status === 'PRODUCT_NOT_EXIST_IN_WHOLESALE' ||
      (status === 'OK_ONLINE_AVAILABILITY' && !netPrice)
   ) {
      return <ButtonContainer>{renderConnectionProblemContent()}</ButtonContainer>
   }
   if (!totalAvailability) {
      return (
         <ButtonContainer>
            <Tooltip
               placement="right"
               disableHoverListener={status !== 'OK_ONLINE_AVAILABILITY'}
               title={t('swap.sendReportTooltip') || ''}
            >
               <span>
                  <GrayButton
                     variant="outlined"
                     type="button"
                     onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onReportMissingProduct()
                     }}
                     disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
                  >
                     {t('swap.reportButton')}
                  </GrayButton>
               </span>
            </Tooltip>
         </ButtonContainer>
      )
   }
   return (
      <ButtonContainer>
         <OutlinedButton
            variant="outlined"
            onClick={(e) => {
               e.stopPropagation()
               e.preventDefault()
               onSwapProduct()
            }}
            disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
         >
            {t('swap.swapButton')}
         </OutlinedButton>
      </ButtonContainer>
   )
}
