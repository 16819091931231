import React from 'react'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { DialogActions, useTheme } from '@mui/material'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useRemoveCartMutation } from 'shared/store/useSavedCarts'
import CustomDialog from 'shared/components/CustomDialog'

import { ModalInfoType } from '../../types'

interface Props {
   handleClose: () => void
   modalInfo: ModalInfoType
}

export default function Modal({ handleClose, modalInfo }: Props) {
   const { t } = useTranslation('SAVED_CARTS')
   const theme = useTheme()
   const { mutate, isLoading } = useRemoveCartMutation()

   function handleDelete() {
      if (modalInfo.uuid) {
         mutate(modalInfo.uuid, {
            onSettled: handleClose,
         })
      }
   }

   function renderForm() {
      switch (modalInfo.modalType) {
         case 'alreadyInUse':
            return t('alreadyInUseText', { userName: modalInfo.userName })
         case 'inProcessing':
            return t('inProcessingText')
         case 'delete':
            return t('SAVED_CARTS:deleteText', { cartName: modalInfo.cartName })
         default:
            return null
      }
   }
   return (
      <CustomDialog
         isLoading={!modalInfo}
         open={modalInfo.open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
                     {t(`${modalInfo.modalType}`)}
                  </Typography>
               </DialogTitle>
               <DialogContent>{renderForm()}</DialogContent>
               {modalInfo.modalType === 'delete' && (
                  <DialogActions>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton disabled={isLoading} variant="contained" onClick={() => handleDelete()}>
                        {t('TRANSLATION:delete')}
                     </ContainedButton>
                  </DialogActions>
               )}
            </>
         }
      />
   )
}
