import DialogContent from '@mui/material/DialogContent'
import { DialogActions, useTheme } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import React from 'react'

import { DeleteWholesalerPayload, WholesalerType } from 'shared/store/useWholesalers/types'
import { useDeleteCooperationMutation } from 'shared/store/useWholesalers'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import CustomDialog from 'shared/components/CustomDialog'

import WholesalerInfoModal from './components/WholesalerInfoModal'
import EditTimesModal from './components/EditTimesModal'
import { WholesalerActionType } from '../../types'

interface Props {
   data: {
      data: WholesalerType
      type: WholesalerActionType
   }
   activeLocationUuid?: string
   open: boolean
   handleClose: () => void
}

export default function Modal({ open, data: { data, type }, activeLocationUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const { mutate } = useDeleteCooperationMutation()
   const queryClient = useQueryClient()
   const theme = useTheme()

   function handleDeleteCoop() {
      if (activeLocationUuid) {
         const payload: DeleteWholesalerPayload = {
            cooperationUuid: data.cooperationUuid,
            activeLocationUuid,
         }
         mutate(payload, {
            onSuccess: () => {
               queryClient.invalidateQueries('wholesalers')
               handleClose()
            },
         })
      }
   }

   function renderContent() {
      switch (type) {
         case 'deleteWholesaler':
            return (
               <>
                  <DialogContent>
                     <Typography variant="body1" sx={{ mt: 1, mb: 1, color: 'text.secondary' }}>
                        {t('WHOLESALERS:deleteWholesalerDialog')}
                     </Typography>
                  </DialogContent>
                  <DialogActions>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton variant="contained" onClick={handleDeleteCoop}>
                        {t('TRANSLATION:delete')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         case 'editTimes':
            return (
               <EditTimesModal
                  locationUuid={activeLocationUuid || ''}
                  cooperationUuid={data.cooperationUuid}
                  closeModal={handleClose}
               />
            )
         case 'editWholesaler':
            return (
               <WholesalerInfoModal
                  activeLocationUuid={activeLocationUuid}
                  closeModal={handleClose}
                  wholesalerInfo={data}
               />
            )
         case 'addWholesaler':
            return (
               <WholesalerInfoModal
                  activeLocationUuid={activeLocationUuid}
                  closeModal={handleClose}
                  wholesalerInfo={data}
               />
            )
         default:
            return null
      }
   }

   return (
      <CustomDialog
         fullWidth={type === 'editWholesaler'}
         maxWidth="md"
         isLoading={!data || !activeLocationUuid}
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
                     {type === 'editWholesaler' || type === 'addWholesaler'
                        ? data?.wholesaleName
                        : t(`WHOLESALERS:${type}`)}
                  </Typography>
               </DialogTitle>
               {renderContent()}
            </>
         }
      />
   )
}
