import React, { useEffect, useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useFormik, Form, FormikProvider } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import useNonApiWholesalesQuery from 'shared/store/useNonApiWholesales'
import InputField from 'shared/components/InputField'
import { pythonHttpClient } from 'shared/lib'

import Typography from '@mui/material/Typography'
import CustomDialog from 'shared/components/CustomDialog'
import { EditWholesalerProductType } from './types'
import { EditModal } from '../../types'
import { schema } from './schema'

interface Props {
   modalInfo: EditModal | undefined
   handleClose: () => void
}

export default function EditWholesalerModal({ modalInfo, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [multipleIndexes, setMultipleIndex] = useState<string[]>()
   const [isLoading, setIsLoading] = useState(false)
   const { data } = useNonApiWholesalesQuery()

   const formik = useFormik<EditWholesalerProductType>({
      initialValues: {
         depositIndex: '',
         wholesalerIndex: '',
         times: 1,
         taxRate: 23,
         depositPrice: '',
         priceVariants: null,
         departmentsAvailability: null,
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { setValues, values, setFieldError } = formik

   useEffect(() => {
      setValues({
         depositIndex: '',
         depositPrice: '',
         wholesalerIndex: '',
         taxRate: 23,
         times: 1,
         departmentsAvailability: null,
         priceVariants: null,
      })
      if (modalInfo) {
         setIsLoading(true)
         pythonHttpClient
            .get<EditWholesalerProductType>(
               `products/${modalInfo.productUuid}/wholesales/${modalInfo.data.wholesaleUuid}`
            )
            .then((res) => {
               setValues({ ...res.data, taxRate: res.data.taxRate ?? 23 })
            })
            .finally(() => setIsLoading(false))
      }
   }, [modalInfo])

   function handleSubmit() {
      if (modalInfo) {
         setIsLoading(true)
         pythonHttpClient
            .patch(`products/${modalInfo.productUuid}/wholesales/${modalInfo.data.wholesaleUuid}`, {
               wholesalerIndex: values.wholesalerIndex,
               depositIndex: values.depositIndex || null,
               times: values.times,
               taxRate: values.taxRate.toString(),
               depositPrice: values.depositPrice ? values.depositPrice.toString() : null,
               priceVariants: values.priceVariants || null,
               departmentsAvailability: values.departmentsAvailability || null,
            })
            .then((res) => {
               if (res.status === 204) {
                  addNotification('successSave', 'success')
                  handleClose()
               }
            })
            .catch((error) => {
               if (error.response.status === 424) {
                  setFieldError('wholesaler_index', 'Taki indeks już istnieje')
                  pythonHttpClient
                     .post(`products/wholesales/${modalInfo.data.wholesaleUuid}`, {
                        wholesaler_index: values.wholesalerIndex,
                     })
                     .then((res) => {
                        setMultipleIndex(res.data.products_uuids)
                     })
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   function handleWindowOpen(mainProductUuid?: string) {
      let params = ''
      if (multipleIndexes) {
         multipleIndexes.forEach((item) => {
            params = params.concat(`&productUuid=${item}`)
         })
      }
      if (mainProductUuid && params) {
         window.open(`${window.location.origin}/dashboard/internal-search?mainProductUuid=${mainProductUuid}${params}`)
      }
   }

   return (
      <CustomDialog
         isLoading={!modalInfo}
         open={!!modalInfo}
         onClose={handleClose}
         content={
            <>
               <DialogTitle color="primary">Edytuj produkt w {modalInfo?.data.wholesaleName}</DialogTitle>
               <FormikProvider value={formik}>
                  <Form>
                     <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 3, py: 1 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                           <InputField disabled={isLoading} name="wholesalerIndex" label="Index" />
                           <InputField
                              disabled={isLoading}
                              inputProps={{
                                 min: 1,
                              }}
                              type="Number"
                              label="Krotność"
                              name="times"
                           />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                           <InputField disabled={isLoading} name="depositIndex" label="Indeks kaucji produktu" />
                           <InputField
                              disabled={isLoading}
                              type="Number"
                              name="depositPrice"
                              label="Kwota kaucji produktu"
                           />
                        </Box>
                        <InputField
                           disabled={isLoading}
                           type="Number"
                           inputProps={{ min: 1 }}
                           name="taxRate"
                           label="VAT"
                        />
                        {data?.find((item) => item.uuid === modalInfo?.data.wholesaleUuid) && (
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <Typography variant="body1" color="primary.dark">
                                 Dostępność:
                              </Typography>
                              {data!
                                 .find((item) => item.uuid === modalInfo?.data.wholesaleUuid)
                                 ?.departmentsAvailability!.map((key) => (
                                    <InputField
                                       key={key}
                                       InputLabelProps={{ shrink: true }}
                                       label={key}
                                       disabled={isLoading}
                                       name={`departmentsAvailability[${key}]`}
                                    />
                                 ))}
                           </Box>
                        )}
                        {data?.find((item) => item.uuid === modalInfo?.data.wholesaleUuid) && (
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <Typography variant="body1" color="primary.dark">
                                 Warianty cenowe:
                              </Typography>
                              {data!.find((item) => item.uuid === modalInfo?.data.wholesaleUuid)?.priceVariants &&
                                 Object.entries(
                                    data!.find((item) => item.uuid === modalInfo?.data.wholesaleUuid)!.priceVariants
                                 ).map(([key, value]) => (
                                    <InputField
                                       key={key}
                                       InputLabelProps={{ shrink: true }}
                                       label={value}
                                       name={`priceVariants[${key}]`}
                                    />
                                 ))}
                           </Box>
                        )}
                        {multipleIndexes && (
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                              <Button
                                 color="error"
                                 variant="contained"
                                 onClick={() => handleWindowOpen(modalInfo?.productUuid)}
                              >
                                 Pokaż listę produktów
                              </Button>
                           </Box>
                        )}
                     </DialogContent>
                     <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <OutlinedButton type="button" variant="outlined" onClick={handleClose}>
                           Zamknij
                        </OutlinedButton>
                        <ContainedButton onClick={handleSubmit} disabled={isLoading} type="submit" variant="contained">
                           Zapisz
                        </ContainedButton>
                     </DialogActions>
                  </Form>
               </FormikProvider>
            </>
         }
      />
   )
}
