/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import { FormikProvider, useFormik } from 'formik'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { ModalInfoType } from 'views/Authorized/views/MainCart/types'
import { useNotificationContext } from 'shared/context/Notifications'
import { useLocationsQuery } from 'shared/store/useLocations'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import InputField from 'shared/components/InputField'
import MuiPhone from 'shared/components/PhoneInput'
import { httpClient } from 'shared/lib'
import { useMediaQuery, useTheme } from '@mui/material'

interface Props {
   modalInfo: ModalInfoType
   handleClose: () => void
}

export default function ReflinkModal({ modalInfo, handleClose }: Props) {
   const { t } = useTranslation('MAIN_CART')
   const [templateDetails, setTemplateDetails] = useState<SmsTemplateResponse>()
   const [formikInitialValues, setFormikInitialValues] = useState<{ [key: string]: string }>({})
   const [showTemplate, setShowTemplate] = useState(false)
   const [phoneNumber, setPhoneNumber] = useState('')
   const { data } = useLocationsQuery()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const { data: profile } = useProfileQuery()
   const [templateMessage, setTemplateMessage] = useState('')
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const [showSmsAccess, setShowSmsAccess] = useState(false)
   const { addNotification } = useNotificationContext()
   const formik = useFormik({
      initialValues: formikInitialValues,
      onSubmit: () => {},
   })

   const { setFieldValue, setFieldTouched, values } = formik

   const populateTemplate = (message: string, variables: { [key: string]: string }) => {
      return message.replace(/<(\w+)>/g, (match, variableName) => {
         return variables[variableName] || `<${variableName}>`
      })
   }

   function handlePhoneNumber(value: string, country: string) {
      setPhoneNumber(value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function handleSend() {
      const params = new URL(modalInfo.reflinkURL!).searchParams
      const token = params.get('token')
      if (phoneNumber) {
         httpClient
            .post('/sms/send', {
               phoneNumber,
               category: 'ORDER_REFLINK_NOTIFICATION',
               reflinkUuid: token,
               message: templateMessage !== templateDetails?.templateMessage ? templateMessage : null,
               templateValues: {
                  link: modalInfo.reflinkURL!,
                  ...values,
               },
            })
            .then(() => {
               addNotification('successSend', 'success')
               handleClose()
            })
            .catch((err) => {
               if (isAxiosError<ErrorType>(err)) {
                  addNotification(err.response?.data.errors[0].code || 'failedSend', 'error')
               } else {
                  addNotification('failedSend', 'error')
               }
            })
      }
   }

   useEffect(() => {
      const newFormikInitials = { ...formikInitialValues }
      httpClient
         .get<SmsTemplateResponse>('/sms-api/templates/ORDER_REFLINK_NOTIFICATION')
         .then((res) => {
            setShowSmsAccess(true)
            if (res.data) {
               setTemplateDetails(res.data)
               setTemplateMessage(res.data.templateMessage)
               res.data.templateVariables.forEach((item) => {
                  newFormikInitials[item] = ''
                  if (item === 'link') {
                     newFormikInitials[item] = modalInfo.reflinkURL!
                     setFieldValue('link', modalInfo.reflinkURL!)
                     setFieldTouched('link', true)
                  }
                  if (item === 'adres_odbioru_czesci') {
                     const activeLocation = data?.locations.find(
                        (location) => location.locationSettings.uuid === profile?.locationUuid
                     )
                     if (activeLocation) {
                        newFormikInitials[item] = activeLocation.locationSettings.orderReflinkSettings?.address || ''
                        setFieldValue(
                           'adres_odbioru_czesci',
                           activeLocation.locationSettings.orderReflinkSettings?.address || ''
                        )
                        setFieldTouched('adres_odbioru_czesci', true)
                     }
                  }
               })
            }
            setFormikInitialValues(newFormikInitials)
         })
         .catch(() => {
            setShowSmsAccess(false)
         })
   }, [])

   return (
      <>
         <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
               {isMobile && <Typography sx={{ mb: 1 }}>{t('MAIN_CART:clickToCopy')}</Typography>}
               <CopyToClipboard text={modalInfo?.reflinkURL || ''}>
                  <Tooltip title={t('MAIN_CART:clickToCopy') || ''}>
                     <Box
                        sx={{
                           p: 1,
                           px: 2,
                           width: 'fitContent',
                           height: 'fitContent',
                           backgroundColor: 'rgba(0,255,0,0.2)',
                           border: '1px solid green',
                           borderRadius: 1,
                           cursor: 'pointer',
                           '&:hover': {
                              backgroundColor: 'rgba(0,255,0,0.3)',
                           },
                        }}
                     >
                        {modalInfo?.reflinkURL}
                     </Box>
                  </Tooltip>
               </CopyToClipboard>
               {templateDetails && showSmsAccess && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={showTemplate}
                              onChange={() => setShowTemplate((prevState) => !prevState)}
                           />
                        }
                        label={t('MAIN_CART:modals.reflinkModal.sendSmsToCustomer')}
                     />
                     <Collapse in={showTemplate}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                           {modalInfo.notificationSentDate && (
                              <Typography variant="h6" fontWeight="bold">
                                 {t('MAIN_CART:modals.reflinkModal.alreadySent', {
                                    date: modalInfo.notificationSentDate,
                                 })}
                              </Typography>
                           )}
                           <FormikProvider value={formik}>
                              <MuiPhone
                                 variant="outlined"
                                 value={phoneNumber}
                                 onChange={(value, country) => handlePhoneNumber(value, country)}
                              />
                              {templateDetails.templateVariables && templateDetails.templateVariables.length > 0 && (
                                 <Box>
                                    <Typography variant="body1">{t('AUTHORIZED:sendSmsModal.variables')}</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                       {templateDetails.templateVariables
                                          .filter((item) => item !== 'link')
                                          .map((item) => (
                                             <InputField
                                                InputLabelProps={{
                                                   shrink: !!formik.values[item],
                                                }}
                                                InputProps={{
                                                   readOnly: item === 'adres_odbioru_czesci',
                                                }}
                                                name={item}
                                                label={item}
                                             />
                                          ))}
                                    </Box>
                                 </Box>
                              )}
                              <Typography variant="body1">{t('AUTHORIZED:sendSmsModal.review')} </Typography>
                              <TextField
                                 multiline
                                 inputProps={{
                                    maxLength: 500,
                                 }}
                                 onChange={(e) => setTemplateMessage(e.target.value)}
                                 rows={5}
                                 value={populateTemplate(templateMessage, formik.values)}
                              />
                           </FormikProvider>
                        </Box>
                     </Collapse>
                  </Box>
               )}
            </Box>
         </DialogContent>
         <DialogActions>
            {showTemplate ? (
               <Box sx={{ display: 'flex', gap: 2 }}>
                  <OutlinedButton variant="outlined" onClick={handleClose}>
                     {t('TRANSLATION:cancel')}
                  </OutlinedButton>
                  <ContainedButton variant="contained" disabled={!phoneNumber || phoneNumberError} onClick={handleSend}>
                     {t('TRANSLATION:send')}
                  </ContainedButton>
               </Box>
            ) : (
               <OutlinedButton variant="outlined" onClick={handleClose}>
                  OK
               </OutlinedButton>
            )}
         </DialogActions>
      </>
   )
}
