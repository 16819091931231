import React, { useEffect, useState, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import TableFooter from '@mui/material/TableFooter'
import Typography from '@mui/material/Typography'
import { useReactToPrint } from 'react-to-print'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import { ClientPrintTableHeaders, FullPrintTableHeaders } from 'views/Authorized/views/MainCart/data/TableHeaders'
import { StyledTableCell, StyledTitleTableCell } from 'shared/components/StyledTableComponents'
import { FetchCartSummaryResult } from 'shared/store/useCartSummary/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import { useLocationsQuery } from 'shared/store/useLocations'
import { useProfileQuery } from 'shared/store/useProfile'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import ImageComponent from 'shared/components/ImageComponent'
import getProductName from '../../../../helpers/getProductName'
import { StyledPrintingTableCell } from '../../../../styles'
import { ModalInfoType } from '../../../../types'

interface Props {
   data: FetchCartSummaryResult
   handleClose: () => void
   variant: PropType<ModalInfoType, 'pricingVariant'>
}

export default function PricingPrintModal({ handleClose, data, variant }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const componentRef = useRef(null)
   const [printDate, setPrintDate] = useState('')
   const { data: profileSettings } = useProfileQuery()
   const { data: locationsData } = useLocationsQuery()
   const { user } = useLogin()

   const activeLocationSettings = locationsData?.locations.find(
      (item) => item.locationSettings.uuid === profileSettings?.locationUuid
   )

   const PrintTableHeaders: string[] = variant === 'CLIENT' ? ClientPrintTableHeaders : FullPrintTableHeaders

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   function renderServices(services: PropType<FetchCartSummaryResult, 'additionalServices'>) {
      if (variant === 'FULL') {
         return services.map((service) => (
            <TableRow key={service.uuid}>
               <StyledPrintingTableCell width={100} colSpan={3}>
                  {service.name}
               </StyledPrintingTableCell>
               <StyledPrintingTableCell width={100}>{service.quantity}</StyledPrintingTableCell>
               <StyledPrintingTableCell colSpan={2} />
               <StyledTableCell>
                  {profileSettings?.priceType === 'NET' ? service.formattedNetPrice : service.formattedGrossPrice}
               </StyledTableCell>
               <StyledTableCell />
               <StyledTableCell>
                  {profileSettings?.priceType === 'NET' ? service.formattedNetValue : service.formattedGrossValue}
               </StyledTableCell>
            </TableRow>
         ))
      }
      return services.map((service) => (
         <TableRow key={service.uuid}>
            <StyledPrintingTableCell width={100} colSpan={2}>
               {service.name}
            </StyledPrintingTableCell>
            <StyledPrintingTableCell>{service.quantity}</StyledPrintingTableCell>
            <StyledTableCell>
               {profileSettings?.priceType === 'NET' ? service.formattedNetPrice : service.formattedGrossPrice}
            </StyledTableCell>
            <StyledTableCell>
               {profileSettings?.priceType === 'NET' ? service.formattedNetValue : service.formattedGrossValue}
            </StyledTableCell>
         </TableRow>
      ))
   }

   function renderContent(
      products: PropType<FetchCartSummaryResult, 'products'>,
      availability: 'AVAILABLE' | 'NOT_AVAILABLE'
   ) {
      if (variant === 'FULL') {
         return products
            .filter((item) => item.status === availability)
            .map((product) => (
               <TableRow key={product.uuid}>
                  <StyledPrintingTableCell width={100}>{getProductName(product)}</StyledPrintingTableCell>
                  <StyledPrintingTableCell width={100}>{product.index}</StyledPrintingTableCell>
                  <StyledPrintingTableCell>
                     <ImageComponent
                        filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                        producerName={product.producerName}
                        height="15px"
                        width="90px"
                     />
                  </StyledPrintingTableCell>
                  <StyledPrintingTableCell>{product.cartWholesaleDTO.quantity}</StyledPrintingTableCell>
                  <StyledPrintingTableCell>
                     {user?.roles.includes(UserRoles.SALES_REP) ? (
                        product.cartWholesaleName
                     ) : (
                        <img
                           src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${product.cartWholesaleName}.png`}
                           height="15px"
                           width="90px"
                           alt={product.cartWholesaleName}
                        />
                     )}
                  </StyledPrintingTableCell>
                  <StyledTableCell>
                     {profileSettings?.priceType === 'NET'
                        ? product.cartWholesaleDTO.formattedNetPrice
                        : product.cartWholesaleDTO.formattedGrossPrice}
                  </StyledTableCell>
                  <StyledTableCell>
                     {profileSettings?.priceType === 'NET'
                        ? product.cartWholesaleDTO.formattedClientNetPrice
                        : product.cartWholesaleDTO.formattedClientGrossPrice}
                  </StyledTableCell>
                  <StyledTableCell>
                     {profileSettings?.priceType === 'NET'
                        ? product.cartWholesaleDTO.formattedNetValue
                        : product.cartWholesaleDTO.formattedGrossValue}
                  </StyledTableCell>
                  <StyledTableCell>
                     {profileSettings?.priceType === 'NET'
                        ? product.cartWholesaleDTO.formattedClientNetValue
                        : product.cartWholesaleDTO.formattedClientGrossValue}
                  </StyledTableCell>
               </TableRow>
            ))
      }
      return products
         .filter((item) => item.status === availability)
         .map((product) => (
            <TableRow key={product.uuid}>
               <StyledPrintingTableCell width={100}>{getProductName(product)}</StyledPrintingTableCell>
               <StyledPrintingTableCell>
                  <ImageComponent
                     height="15px"
                     width="90px"
                     filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                     producerName={product.producerName}
                  />
               </StyledPrintingTableCell>
               <StyledPrintingTableCell>{product.cartWholesaleDTO.quantity}</StyledPrintingTableCell>
               <StyledTableCell>
                  {profileSettings?.priceType === 'NET'
                     ? product.cartWholesaleDTO.formattedClientNetPrice
                     : product.cartWholesaleDTO.formattedClientGrossPrice}
               </StyledTableCell>
               <StyledTableCell>
                  {profileSettings?.priceType === 'NET'
                     ? product.cartWholesaleDTO.formattedClientNetValue
                     : product.cartWholesaleDTO.formattedClientGrossValue}
               </StyledTableCell>
            </TableRow>
         ))
   }

   useEffect(() => {
      const date = new Date()
      setPrintDate(date.toLocaleString('pl'))
   }, [])

   return (
      <Box>
         <Box ref={componentRef} sx={{ display: 'flex', flexDirection: 'column', gap: 1, margin: 2, minWidth: 700 }}>
            {profileSettings && activeLocationSettings ? (
               <Box
                  sx={{
                     display: 'flex',
                     gap: 1,
                     flexDirection: 'column',
                     alignSelf: 'flex-end',
                     mb: 2,
                     textAlign: 'right',
                  }}
               >
                  {profileSettings.image && (
                     <img
                        style={{ alignSelf: 'flex-end' }}
                        height="60px"
                        width="auto"
                        alt={activeLocationSettings?.locationSettings?.orderReflinkSettings?.companyName || 'Logo'}
                        src={`data:image/png;base64,${profileSettings.image}`}
                     />
                  )}
                  {activeLocationSettings?.locationSettings?.orderReflinkSettings?.companyName && (
                     <Typography variant="body1">
                        {activeLocationSettings.locationSettings.orderReflinkSettings.companyName}
                     </Typography>
                  )}
                  {activeLocationSettings?.locationSettings?.orderReflinkSettings?.address && (
                     <Typography variant="body1">
                        {activeLocationSettings.locationSettings.orderReflinkSettings.address}
                     </Typography>
                  )}
                  {activeLocationSettings?.locationSettings?.orderReflinkSettings?.phoneNumber && (
                     <Typography variant="body1">{`Tel. ${activeLocationSettings.locationSettings.orderReflinkSettings.phoneNumber}`}</Typography>
                  )}
               </Box>
            ) : (
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress color="primary" />
               </Box>
            )}
            <Typography variant="h6" color="primary">
               {data.cartDetailsDTO.cartName}
            </Typography>
            <Typography variant="h6" color="primary">
               {printDate}
            </Typography>
            <RadiusTableWrapper sx={{ my: 2, width: '100%' }}>
               <Table
                  sx={{
                     minWidth: 'fill-available',
                  }}
               >
                  <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <TableRow>
                        {PrintTableHeaders.map((item) => (
                           <StyledTitleTableCell key={item}>
                              {t(`TRANSLATION:columnNames.${item}`)}
                           </StyledTitleTableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {renderContent(data.products, 'AVAILABLE')}
                     <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                        <StyledTableCell
                           sx={{ fontSize: 15, textAlign: 'right' }}
                           colSpan={variant === 'CLIENT' ? 4 : 7}
                        >
                           {t('TRANSLATION:columnNames.sum')}
                        </StyledTableCell>
                        {variant === 'FULL' && (
                           <StyledTableCell sx={{ fontSize: 15 }}>
                              {profileSettings?.priceType === 'NET' ? data.formattedNetValue : data.formattedGrossValue}
                           </StyledTableCell>
                        )}
                        <StyledTableCell sx={{ fontSize: 15 }}>
                           {profileSettings?.priceType === 'NET'
                              ? data.formattedClientNetValue
                              : data.formattedClientGrossValue}
                        </StyledTableCell>
                     </TableRow>
                     {renderServices(data.additionalServices)}
                     {data.additionalServices.length > 0 && (
                        <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                           <StyledTableCell
                              colSpan={variant === 'FULL' ? 8 : 4}
                              sx={{ fontSize: 15, textAlign: 'right' }}
                           >
                              {t('TRANSLATION:columnNames.servicesSum')}
                           </StyledTableCell>

                           <StyledTableCell sx={{ fontSize: 15 }}>
                              {profileSettings?.priceType === 'NET'
                                 ? data.formattedAdditionalServicesNetValue
                                 : data.formattedAdditionalServicesGrossValue}
                           </StyledTableCell>
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </RadiusTableWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, width: '100%' }}>
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography
                     variant="h6"
                     color="grayText"
                  >{`${t('TRANSLATION:columnNames.formattedValueWithServices')}:`}</Typography>
                  <Typography variant="h6">
                     {profileSettings?.priceType === 'NET'
                        ? data.formattedClientNetValueWithAdditionalServices
                        : data.formattedClientGrossValueWithAdditionalServices}
                  </Typography>
               </Box>
            </Box>
            {data.products.find((item) => item.status !== 'AVAILABLE' && item.status !== 'NOT_INCLUDED_IN_ORDER') && (
               <Box sx={{ my: 1 }}>
                  <Typography color="grayText">{t('MAIN_CART:unavailableProducts')}</Typography>
                  <RadiusTableWrapper>
                     <Table
                        sx={{
                           minWidth: 1200,
                        }}
                     >
                        <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                           <TableRow>
                              {PrintTableHeaders.map((item) => (
                                 <StyledTitleTableCell key={item}>
                                    {t(`TRANSLATION:columnNames.${item}`)}
                                 </StyledTitleTableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>{renderContent(data.products, 'NOT_AVAILABLE')}</TableBody>
                        <TableFooter>
                           <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <StyledTableCell
                                 sx={{ fontSize: 15, textAlign: 'right' }}
                                 colSpan={variant === 'CLIENT' ? 4 : 8}
                              >
                                 {t('TRANSLATION:columnNames.sum')}
                              </StyledTableCell>
                              <StyledTableCell sx={{ fontSize: 15 }}>
                                 {profileSettings?.priceType === 'NET'
                                    ? data.formattedClientNetValue
                                    : data.formattedClientGrossValue}
                              </StyledTableCell>
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </RadiusTableWrapper>
               </Box>
            )}

            <TextField
               multiline
               label={t('MAIN_CART:cartDescription')}
               sx={{ alignSelf: 'flex-start', minWidth: 400 }}
               rows={4}
               inputProps={{ readOnly: true }}
               defaultValue={data.cartDetailsDTO.cartDescription}
            />
         </Box>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 1, mr: 1 }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton variant="contained" onClick={handlePrint}>
               {t('TRANSLATION:print')}
            </ContainedButton>
         </Box>
      </Box>
   )
}
