import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { createSearchParams, useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { useNotificationsQuery, usePagingNotifications, useReadNotification } from 'shared/store/useNotifications'
import { NotificationActionsPayload, NotificationType } from 'shared/store/useNotifications/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import CustomTable from 'shared/components/CustomTable'

import { FormType, NotificationInfo } from './types'
import Modal from './components/Modal'
import { en, pl, de } from './locales'

export default function Notifications() {
   const { mutate } = useReadNotification()
   const navigate = useNavigate()
   const [openModal, setOpenModal] = useState(false)
   const [modalInfo, setModalInfo] = useState<NotificationInfo>()
   const [modalType, setModalType] = useState<FormType>()
   const { mutate: pagingMutate } = usePagingNotifications()
   const [activePage, setActivePage] = useState(0)
   const [itemsPerPage, setItemsPerPage] = useState(10)
   const { data } = useNotificationsQuery(activePage, itemsPerPage)
   const { t, i18n } = useTranslation('NOTIFICATIONS')

   i18n.addResourceBundle('pl-PL', 'NOTIFICATIONS', pl)
   i18n.addResourceBundle('en-EN', 'NOTIFICATIONS', en)
   i18n.addResourceBundle('de-DE', 'NOTIFICATIONS', de)

   function handleOpenModal(
      type: PropType<NotificationInfo, 'type'> | 'DELETE',
      notificationDetails: NotificationInfo,
      isRead: boolean
   ) {
      if (!type) return

      if (!isRead && notificationDetails.notificationUuid) {
         const payload: NotificationActionsPayload = { uuid: notificationDetails.notificationUuid }
         mutate(payload)
      }

      setModalInfo(notificationDetails)
      setModalType(type)
      setOpenModal(true)
   }

   function getNotificationType(notificationType: PropType<NotificationInfo, 'type'>) {
      switch (notificationType) {
         case 'PRODUCT_AVAILABILITY_IN_WHOLESALE':
            return t('watched')
         case 'CLIENT_FINISHED_ORDER':
            return t('pricing')
         case 'WAITING_FOR_PAYMENT':
            return t('payment')
         case 'AUTO_CART_CREATION_FAILED':
         case 'AUTO_CART_CREATED':
            return t('automaticCart')
         case 'ORDER_CREATED':
         case 'ORDER_CREATION_FAILED':
            return t('orderCreation')
         case 'COOPERATION_REJECTED_BY_WHOLESALE':
            return t('coopRejected')
         case 'COOPERATION_REFLINK_EXPIRED':
            return t('reflinkExpired')
         case 'CLIENT_REFLINK_ORDER_CREATED':
         case 'CLIENT_REFLINK_CART_CREATED':
            return t('clientCarts')
         default:
            return null
      }
   }

   function getNotificationTitle(
      notificationType: PropType<NotificationInfo, 'type'>,
      message: string,
      productIndex?: string,
      productName?: string,
      orderUuid?: string,
      cartUuid?: string,
      wholesaleName?: string
   ) {
      if (notificationType === 'ORDER_CREATED' && cartUuid) {
         return t('orderCreatedWithProblemsTitle')
      }
      switch (notificationType) {
         case 'PRODUCT_AVAILABILITY_IN_WHOLESALE':
            return `${productName} ( id: ${productIndex})`
         case 'CLIENT_FINISHED_ORDER':
            return message
         case 'WAITING_FOR_PAYMENT':
            return t('paymentTitle', { orderId: orderUuid })
         case 'AUTO_CART_CREATION_FAILED':
            return t('automaticFailedTitle')
         case 'AUTO_CART_CREATED':
            return t('automaticTitle')
         case 'ORDER_CREATED':
            return t('orderCreatedTitle')
         case 'ORDER_CREATION_FAILED':
            return t('orderCreationFailedTitle')
         case 'COOPERATION_REJECTED_BY_WHOLESALE':
            return t('coopRejectedTitle', { wholesaleName })
         case 'CLIENT_REFLINK_CART_CREATED':
            return t('clientCartCreated')
         case 'CLIENT_REFLINK_ORDER_CREATED':
            return t('clientOrderCreated')
         case 'COOPERATION_REFLINK_EXPIRED':
            return t('coopExpiredTitle', { wholesaleName })
         default:
            return null
      }
   }

   function handleForward(notificationUuid: string, isRead: boolean, productIndex?: string, productUuid?: string) {
      if (!isRead) {
         const payload: NotificationActionsPayload = { uuid: notificationUuid }
         mutate(payload)
      }
      if (productIndex && productUuid) {
         navigate({
            pathname: '/dashboard/product',

            search: `?${createSearchParams({
               uuid: productUuid,
            })}`,
         })
      }
   }

   return (
      <Box sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>
         <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal', mb: 2 }}>
            {t('notifications')}
         </Typography>
         {data ? (
            <CustomTable
               onRowClick={(notification: NotificationType) =>
                  notification.type !== 'PRODUCT_AVAILABILITY_IN_WHOLESALE'
                     ? handleOpenModal(
                          notification.type,
                          {
                             orderUuid: notification.orderUuid,
                             notificationUuid: notification.uuid,
                             emailAddress: notification.email,
                             message: notification.message,
                             cartUuid: notification.cartUuid,
                             phoneNumber: notification.phoneNumber,
                             cartName: notification.cartName,
                             wholesaleName: notification.wholesaleName,
                          },
                          notification.read
                       )
                     : handleForward(
                          notification.uuid,
                          notification.read,
                          notification.productIndex,
                          notification.productUuid
                       )
               }
               customRowStyles={(notification: NotificationType) => ({
                  '>td': {
                     padding: '8px 4px 8px 4px',
                     fontWeight: notification.read ? 'normal' : 'bold',
                  },
               })}
               dataSize={data.totalCount}
               customChangePage={(page: number, rowsPerPage: number) => {
                  setActivePage(page)
                  pagingMutate({ page, pageSize: rowsPerPage })
               }}
               customChangeRowsPerPage={(page: number, rowsPerPage: number) => {
                  setItemsPerPage(rowsPerPage)
                  pagingMutate({ page: 0, pageSize: rowsPerPage })
               }}
               activePage={data.page}
               pagingVariants={[5, 10, 15, 20]}
               rowsPerPage={data.pageSize}
               columns={[
                  { name: 'createdDate', renderCell: (row: NotificationType) => row.date, mainOnMobile: true },
                  {
                     name: 'subject',
                     renderCell: (row: NotificationType) => getNotificationType(row.type),
                     mainOnMobile: true,
                  },
                  {
                     name: 'content',
                     customCellStyles: () => ({ width: '25%', textWrap: 'break-line' }),
                     renderCell: (row: NotificationType) =>
                        getNotificationTitle(
                           row.type,
                           row.message,
                           row.productIndex,
                           row.productName,
                           row.orderUuid,
                           row.cartUuid,
                           row.wholesaleName
                        ),
                  },
                  {
                     name: 'deleteNotification',
                     renderCell: (row: NotificationType) => (
                        <IconButton
                           onClick={(e) => {
                              e.stopPropagation()
                              handleOpenModal(
                                 'DELETE',
                                 {
                                    notificationUuid: row.uuid,
                                 },
                                 row.read
                              )
                           }}
                        >
                           <DeleteOutlineOutlinedIcon />
                        </IconButton>
                     ),
                  },
                  {
                     name: '',
                     renderCell: (row: NotificationType) => (
                        <ContainedButton
                           variant="contained"
                           onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              if (row.type !== 'PRODUCT_AVAILABILITY_IN_WHOLESALE') {
                                 handleOpenModal(
                                    row.type,
                                    {
                                       orderUuid: row.orderUuid,
                                       notificationUuid: row.uuid,
                                       emailAddress: row.email,
                                       message: row.message,
                                       cartUuid: row.cartUuid,
                                       phoneNumber: row.phoneNumber,
                                       cartName: row.cartName,
                                       wholesaleName: row.wholesaleName,
                                    },
                                    row.read
                                 )
                              } else handleForward(row.uuid, row.read, row.productIndex, row.productUuid)
                           }}
                        >
                           {t('TRANSLATION:showButton')}
                        </ContainedButton>
                     ),
                     mainOnMobile: true,
                     onlyOnMobile: true,
                  },
               ]}
               rows={data.notifications}
            />
         ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
               <CircularProgress color="primary" />
            </div>
         )}
         <Modal open={openModal} type={modalType} handleClose={() => setOpenModal(false)} modalInfo={modalInfo} />
      </Box>
   )
}
