import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import { Delete } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import CustomTable from 'shared/components/CustomTable'

import ImageComponent from 'shared/components/ImageComponent'
import { Product, ReflinkDataType } from '../../types'
import DeleteModal from '../DeleteModal'

interface Props {
   products: Product[]
   orderValueNet: string
   orderValueGross: string
   updateData: (data: ReflinkDataType) => void
}

export default function MainTable({ products, orderValueNet, orderValueGross, updateData }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'CLIENT_CART'])
   const [deleteModal, setDeleteModal] = useState({
      productUuid: '',
      wholesaleUuid: '',
   })

   return (
      <>
         <CustomTable
            rows={products}
            dataSize={products.length}
            disablePagination
            customFooter={(columnsCount) => (
               <TableFooter sx={{ fontSize: 12, backgroundColor: '#EBEBEB' }}>
                  <TableRow>
                     <StyledTableCell colSpan={columnsCount}>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: '32px',
                              pr: { lg: '32px', md: '32px' },
                              width: '100%',
                              justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                           }}
                        >
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:columnNames.netSum')}</p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{orderValueNet}</p>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                 {t('TRANSLATION:columnNames.grossSum')}
                              </p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{orderValueGross}</p>
                           </Box>
                        </Box>
                     </StyledTableCell>
                  </TableRow>
               </TableFooter>
            )}
            columns={[
               {
                  name: 'partIndex',
                  mainOnMobile: true,
                  renderCell: (row: Product) => row.productIndex,
               },
               {
                  name: 'producer',
                  mainOnMobile: true,
                  renderCell: (row: Product) => (
                     <Tooltip title={row.producerName}>
                        <ImageComponent filename={row.producerName.toLowerCase()} producerName={row.producerName} />
                     </Tooltip>
                  ),
               },
               {
                  name: 'netPrice',
                  renderCell: (row: Product) => row.formattedNetClientPrice,
               },
               {
                  name: 'grossPrice',
                  renderCell: (row: Product) => row.formattedGrossClientPrice,
               },
               {
                  name: 'quantity',
                  renderCell: (row: Product) => row.quantity,
               },
               {
                  name: 'grossValue',
                  mainOnMobile: true,
                  renderCell: (row: Product) => row.formattedGrossClientValue,
               },
               {
                  name: '',
                  renderCell: (row: Product) => (
                     <IconButton
                        onClick={() =>
                           setDeleteModal({ productUuid: row.productUuid, wholesaleUuid: row.wholesaleUuid })
                        }
                        sx={{ '>*': { '&:hover': { color: 'red' } } }}
                     >
                        <Delete />
                     </IconButton>
                  ),
               },
            ]}
         />
         <DeleteModal
            productUuid={deleteModal.productUuid}
            open={!!deleteModal.productUuid}
            wholesaleUuid={deleteModal.wholesaleUuid}
            updateData={updateData}
            handleClose={() => setDeleteModal({ productUuid: '', wholesaleUuid: '' })}
         />
      </>
   )
}
