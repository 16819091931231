import BottomNavigation from '@mui/material/BottomNavigation'
import React, { useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Outlet, useNavigate } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { History, Save } from '@mui/icons-material'
import Box from '@mui/material/Box'

import Footer from 'views/Authorized/components/Footer'
import background from 'assets/svg/background.svg'

import Header from '../Header'
import Sidebar from '../Sidebar'
import MobileSidebar from '../MobileSidebar'

function Main() {
   const [sidebar, handleSidebar] = useState(true)
   const theme = useTheme()
   const navigate = useNavigate()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function handleSidebarChange(open: boolean) {
      handleSidebar(open)
      if (!isMobile) {
         if (open === true) {
            window.localStorage.setItem('sidebarVariant', 'opened')
         } else window.localStorage.setItem('sidebarVariant', 'closed')
      }
   }

   useEffect(() => {
      if (!isMobile) {
         const shouldCloseTab = window.localStorage.getItem('sidebarVariant') === 'closed'
         if (shouldCloseTab) {
            handleSidebar(false)
         }
      }
   }, [])

   return (
      <>
         <Header openSidebar={sidebar && isMobile} />
         <Box component="main" display="flex" minHeight="calc(100vh - 139px)" mt={8}>
            {isMobile ? (
               <MobileSidebar open={sidebar} handleClose={() => handleSidebarChange(false)} />
            ) : (
               <Sidebar open={sidebar} handleOpen={handleSidebarChange} />
            )}
            <Box
               sx={{
                  px: { xs: 1, md: 5 },
                  py: { lg: 5, md: 5, xs: 2 },
                  flexGrow: 1,
                  overflow: 'scroll',
                  backgroundImage: `url(${background})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
               }}
            >
               <Outlet />
            </Box>
         </Box>
         <Footer open={sidebar} />
         {isMobile && (
            <BottomNavigation
               sx={{
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 100,
                  width: '100%',
               }}
            >
               <BottomNavigationAction onClick={() => handleSidebarChange(true)} icon={<MenuIcon />} />
               <BottomNavigationAction onClick={() => navigate('/dashboard/saved-carts')} icon={<Save />} />
               <BottomNavigationAction onClick={() => navigate('/dashboard/orders-history')} icon={<History />} />
            </BottomNavigation>
         )}
      </>
   )
}

export default Main
