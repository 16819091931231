import { Card, IconButton, useMediaQuery, useTheme } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { useQueryClient } from 'react-query'
import { Close } from '@mui/icons-material'
import Box from '@mui/material/Box'

import { useFilterSavedCarts, useSavedCartsQuery, useSetActiveCartMutation } from 'shared/store/useSavedCarts'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { Order } from 'shared/store/useSavedCarts/types'
import CustomTable from 'shared/components/CustomTable'

import { ModalInfoType, StatusType } from './types'
import Modal from './components/Modal'
import { pl, en, de } from './locales'

export default function SavedCarts() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'SAVED_CARTS'])
   i18n.addResourceBundle('pl-PL', 'SAVED_CARTS', pl)
   i18n.addResourceBundle('en-EN', 'SAVED_CARTS', en)
   i18n.addResourceBundle('de-DE', 'SAVED_CARTS', de)
   const { mutate } = useSetActiveCartMutation()
   const [selectedFilter, setSelectedFilter] = useState<string>('LAST_90_DAYS')
   const [searchPhrase, setSearchPhrase] = useState<string>()
   const { data, isLoading, error, isError } = useSavedCartsQuery()
   const location = useLocation()
   const { mutate: savedCartsMutate, isLoading: isMutateLoading } = useFilterSavedCarts()
   const queryClient = useQueryClient()
   const navigate = useNavigate()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const [modalInfo, setModalInfo] = useState<ModalInfoType>({
      open: false,
      cartName: undefined,
      userName: undefined,
      modalType: undefined,
      uuid: undefined,
   })

   function handleOpen(status: StatusType, userName: string, uuid: string, processingState?: string) {
      if (processingState === 'LOCKED') {
         setModalInfo({ open: true, modalType: 'inProcessing' })
      } else if (status === 'INUSE') {
         setModalInfo({ open: true, modalType: 'alreadyInUse', userName })
      } else if (!status || status === 'FREE' || status === 'MAIN') {
         mutate(uuid, {
            onSuccess: () => {
               navigate('/dashboard/main-cart')
            },
         })
      }
   }

   function returnCartStatus(status: StatusType, processingState: string) {
      if (processingState === 'LOCKED') {
         return 'LOCKED'
      }
      switch (status) {
         case 'MAIN':
         case 'INUSE':
            return status
         default:
            return 'FREE'
      }
   }

   useEffect(() => {
      if (isError) {
         navigate('/dashboard/error-page', {
            state: {
               variant: 'backend',
               path: location.pathname,
               error: {
                  message: error,
                  stack: 'unknown',
               },
            },
         })
      }
   }, [isError, isLoading])

   useEffect(() => {
      queryClient.invalidateQueries('userProfile')
   }, [])

   function handleFilter(filter?: string, resetFilters?: boolean) {
      if (resetFilters) {
         setSearchPhrase('')
         setSelectedFilter('LAST_90_DAYS')
         savedCartsMutate('LAST_90_DAYS')
      }
      if (filter) {
         setSelectedFilter(filter)
         savedCartsMutate(filter)
      }
   }

   return (
      <Box>
         {data && !isLoading && Array.isArray(data.carts) ? (
            <Card>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: { md: 'row', xs: 'column' },

                     gap: 2,
                     my: 2,
                     px: 2,
                  }}
               >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                     <TextField
                        variant="standard"
                        value={searchPhrase}
                        label={t('TRANSLATION:searchAndFilters.searchPhrase')}
                        onChange={(e) => {
                           setSearchPhrase(e.target.value)
                        }}
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={selectedFilter === 'ALL'}
                              onChange={() => {
                                 if (selectedFilter === 'ALL') {
                                    handleFilter('LAST_90_DAYS')
                                 } else {
                                    handleFilter('ALL')
                                 }
                              }}
                           />
                        }
                        label={t('TRANSLATION:searchAndFilters.searchEverywhere')}
                     />
                  </Box>
                  {!isMobile && (
                     <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                        <ContainedButton
                           variant="contained"
                           onClick={() => {
                              handleFilter('LAST_90_DAYS', true)
                           }}
                        >
                           {t('TRANSLATION:searchAndFilters.clearFilters')}
                        </ContainedButton>
                        {data?.years.availableFilters
                           .filter((item) => item !== 'ALL' && item !== 'LAST_7_DAYS')
                           .reverse()
                           .map((item) => (
                              <ContainedButton
                                 sx={{ minWidth: 120 }}
                                 onClick={() => {
                                    handleFilter(item)
                                 }}
                                 disabled={selectedFilter === item || isMutateLoading}
                                 variant="contained"
                                 key={item}
                              >
                                 {t(`TRANSLATION:searchAndFilters.filters.${item}`)}
                              </ContainedButton>
                           ))}
                     </Box>
                  )}
               </Box>
               <CustomTable
                  onChangeRowsPerPage={(rowsPerPage: number) =>
                     window.localStorage.setItem('itemsPerPage', rowsPerPage.toString())
                  }
                  rowsPerPage={
                     window.localStorage.getItem('itemsPerPage')
                        ? Number(window.localStorage.getItem('itemsPerPage'))
                        : 25
                  }
                  onRowClick={(row: Order) => handleOpen(row.status, row.userName, row.uuid, row.processingState)}
                  dataSize={
                     data.carts
                        .filter(
                           (item) =>
                              item.processingState === 'AVAILABLE' ||
                              item.processingState === 'LOCKED' ||
                              !item.processingState
                        )
                        .filter((item) => {
                           // eslint-disable-next-line no-extra-boolean-cast
                           if (!!searchPhrase) {
                              return (
                                 item.name?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.description?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.createdDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.updatedDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.description?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.userName?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.savedDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.notificationSentDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                                 item.phoneNumber?.toLowerCase().includes(searchPhrase.toLowerCase())
                              )
                           }
                           return true
                        }).length
                  }
                  rows={data.carts
                     .filter(
                        (item) =>
                           item.processingState === 'AVAILABLE' ||
                           item.processingState === 'LOCKED' ||
                           !item.processingState
                     )
                     .filter((item) => {
                        // eslint-disable-next-line no-extra-boolean-cast
                        if (!!searchPhrase) {
                           return (
                              item.name?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.description?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.createdDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.updatedDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.description?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.userName?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.savedDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.notificationSentDate?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                              item.phoneNumber?.toLowerCase().includes(searchPhrase.toLowerCase())
                           )
                        }
                        return true
                     })}
                  pagingVariants={[10, 25, 50]}
                  columns={[
                     {
                        name: 'cartName',
                        renderCell: (row: Order) => (
                           <Box
                              sx={{
                                 maxWidth: 200,
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 ...(isMobile && {
                                    maxWidth: 100,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                 }),
                              }}
                           >
                              {row.name}
                           </Box>
                        ),
                        sortable: true,
                        sortLabel: 'name',
                        sortType: 'string',
                        mainOnMobile: true,
                     },
                     {
                        name: 'createdDate',
                        renderCell: (row: Order) => row.createdDate,
                        sortable: true,
                        sortLabel: 'createdDate',
                        sortType: 'date',
                     },
                     {
                        name: 'updatedDate',
                        renderCell: (row: Order) => row.updatedDate,
                        sortable: true,
                        sortLabel: 'updatedDate',
                        sortType: 'date',
                     },
                     {
                        name: 'description',
                        renderCell: (row: Order) => (
                           <Box
                              sx={{
                                 maxWidth: 200,
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 ...(isMobile && {
                                    maxWidth: 100,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                 }),
                              }}
                           >
                              {row.description}
                           </Box>
                        ),
                     },
                     {
                        name: 'orderingPerson',
                        renderCell: (row: Order) => row.userName,
                        sortable: true,
                        mainOnMobile: true,
                        sortLabel: 'userName',
                        sortType: 'string',
                     },
                     {
                        name: 'notificationSentDate',
                        renderCell: (row: Order) => row.notificationSentDate,
                        sortable: true,
                        sortLabel: 'notificationSentDate',
                        sortType: 'date',
                     },
                     {
                        name: 'phoneNumber',
                        renderCell: (row: Order) => row.phoneNumber,
                     },
                     {
                        name: 'grossValue',
                        renderCell: (row: Order) => row.formattedValue,
                        showPriceType: true,
                        sortable: true,
                        mainOnMobile: true,
                        sortLabel: 'value',
                        sortType: 'number',
                     },
                     {
                        name: 'grossClientValue',
                        renderCell: (row: Order) => row.formattedClientValue,
                        showPriceType: true,
                        sortable: true,
                        sortLabel: 'clientValue',
                        sortType: 'number',
                     },
                     {
                        name: 'status',
                        renderCell: (row: Order) =>
                           t(`SAVED_CARTS:status.${returnCartStatus(row.status, row.processingState ?? 'FREE')}`),
                        sortable: true,
                        mainOnMobile: true,
                        sortLabel: 'status',
                        sortType: 'string',
                     },
                     {
                        preventOnClick: true,
                        name: 'deleteCart',
                        renderCell: (row: Order) => (
                           <IconButton
                              onClick={() =>
                                 setModalInfo({
                                    open: true,
                                    cartName: row.name,
                                    userName: row.userName,
                                    modalType: 'delete',
                                    uuid: row.uuid,
                                 })
                              }
                           >
                              <Close />
                           </IconButton>
                        ),
                     },
                     {
                        name: '',
                        renderCell: (row: Order) => (
                           <ContainedButton
                              sx={{ padding: '3px 8px', fontSize: '10px' }}
                              disabled={row.status === 'INUSE' || row.status === 'MAIN'}
                              variant="contained"
                              onClick={() => handleOpen(row.status, row.userName, row.uuid, row.processingState)}
                           >
                              {t('TRANSLATION:columnNames:setAsMain')}
                           </ContainedButton>
                        ),
                        onlyOnMobile: true,
                     },
                  ]}
               />
            </Card>
         ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
               <CircularProgress />
            </Box>
         )}
         <Modal
            modalInfo={modalInfo}
            handleClose={() =>
               setModalInfo({
                  ...modalInfo,
                  open: false,
               })
            }
         />
      </Box>
   )
}
