/* eslint-disable array-callback-return */
import { IconButton, TableFooter, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import { Close } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { CSVLink } from 'react-csv'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { StyledTableCell } from 'shared/components/StyledTableComponents'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import getProductName from 'shared/utils/helpers/getProductName'
import { Order } from 'shared/store/useOrdersHistory/types'
import CustomDialog from 'shared/components/CustomDialog'
import CustomTable from 'shared/components/CustomTable'

import ImageComponent from 'shared/components/ImageComponent'
import { StoredOrderDetails, ReportType, OrderProduct, AdditionalService } from '../../types'
import { getOrderDetailsService, getCsvOrderReportService } from '../../services'

// Everything which is commented in rating will be used in future
interface Props {
   orderUuids: string[]
   open: boolean
   title: string
   onClose: () => void
   details: StoredOrderDetails[]
   updateDetailsStore: (newRecord: StoredOrderDetails) => void
   priceType?: PriceType
   orderType?: PropType<Order, 'orderType'>
   formattedNetValue?: string
   formattedGrossValue?: string
   netDeliveryCost?: number
   grossDeliveryCost?: number
   formattedServicesValue?: string
}

export default function OrderDetails({
   orderUuids,
   open,
   onClose,
   details,
   title,
   updateDetailsStore,
   priceType,
   orderType,
   formattedGrossValue,
   formattedNetValue,
   formattedServicesValue,
   netDeliveryCost,
   grossDeliveryCost,
}: Props) {
   // const [rating, setRating] = useState<RatingType>({ conformRate: 0, productionQualityRate: 0, priceToQualityRate: 0 })
   const [isLoading, setIsLoading] = useState(false)
   const [data, setData] = useState<PropType<StoredOrderDetails, 'data'>>({
      products: [],
      additionalServices: [],
   })
   const [reportData, setReportData] = useState<ReportType>()
   const componentRef = useRef(null)
   const { t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])
   const isDetailsStored = useRef(false)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   // function handleRate(productCartUuid: string, productUuid: string) {
   //    setIsLoading(true)
   //    const payload: RatingPayload = {
   //       conformRate: rating.conformRate,
   //       priceToQualityRate: rating.priceToQualityRate,
   //       productionQualityRate: rating.productionQualityRate,
   //       orderUuid: orderUuids[0],
   //       productCartUuid,
   //       productUuid,
   //    }
   //    addRatingService(payload)
   //       .then(() => updateDetailsStore({ id: orderUuids[0], data }))
   //       .finally(() => setIsLoading(false))
   // }

   async function init() {
      const storedDetails = details.find((item) => item.id === orderUuids[0])
      if (storedDetails) {
         isDetailsStored.current = true
         setData(storedDetails.data)
      } else {
         setIsLoading(true)
         const req = await getOrderDetailsService(orderUuids)
         if (req) {
            setData(req)
         }
      }
      getCsvOrderReportService(orderUuids).then((res) => {
         setReportData(res)
      })
      setIsLoading(false)
   }

   function getHighlightColor(product: OrderProduct) {
      if (product.status === 'AVAILABLE') {
         return 'transparent'
      }
      if (product.amount !== product.requestedAmount && product.amount !== 0) {
         return 'rgb(255, 136, 0,0.7)'
      }
      return 'rgba(255,0,0,0.5)'
   }

   function generateCSV() {
      const CSVData: (string | number)[][] = []
      const titleRow = [
         t('TRANSLATION:columnNames.partIndex'),
         t('TRANSLATION:columnNames.producer'),
         t('TRANSLATION:columnNames.wholesaler'),
         t('TRANSLATION:columnNames.netPrice'),
         t('TRANSLATION:columnNames.netSum'),
         t('TRANSLATION:columnNames.grossPrice'),
         t('TRANSLATION:columnNames.grossSum'),
         'VAT',
         t('TRANSLATION:columnNames.orderedAmount'),
         t('TRANSLATION:columnNames.notOrderedAmount'),
         t('TRANSLATION:columnNames.status'),
         t('TRANSLATION:columnNames.wholesaleErrorMessage'),
      ]
      CSVData.push(titleRow)
      const services = [t('TRANSLATION:columnNames.services')]
      if (reportData) {
         reportData.orders.map((order) => {
            order.wholesales.map((wholesale) => {
               wholesale.orderedProducts.map((product) => {
                  CSVData.push([
                     product.index,
                     product.producerName,
                     wholesale.wholesaleName,
                     product.unitNetPrice,
                     product.netPrice,
                     product.unitGrossPrice,
                     product.grossPrice,
                     product.vat,
                     product.orderedAmount,
                     product.notOrderedAmount,
                     product.orderProductStatus,
                     product.wholesaleErrorMessage || '',
                  ])
               })
            })
            if (order.additionalServices.length > 0) {
               CSVData.push(services)
               order.additionalServices.map((service) => {
                  CSVData.push([
                     service.name,
                     '',
                     '',
                     service.formattedNetPrice,
                     service.formattedNetValue,
                     service.formattedGrossPrice,
                     service.formattedGrossValue,
                     service.taxRate,
                     service.amount,
                     '',
                     '',
                     '',
                  ])
               })
            }
         })
      }
      return (
         <CSVLink style={{ textAlign: 'right' }} filename={title} data={CSVData}>
            <ContainedButton variant="contained">{t('ORDERS_HISTORY:generateCSV')}</ContainedButton>
         </CSVLink>
      )
   }

   function generateImportCSV() {
      const CSVData: (string | number)[][] = []
      const titleRow = [
         t('TRANSLATION:columnNames.requestedIndex'),
         t('TRANSLATION:columnNames.partIndex'),
         t('TRANSLATION:columnNames.producer'),
         t('TRANSLATION:columnNames.wholesaler'),
         t('TRANSLATION:columnNames.netPrice'),
         t('TRANSLATION:columnNames.netSum'),
         t('TRANSLATION:columnNames.grossPrice'),
         t('TRANSLATION:columnNames.grossSum'),
         t('TRANSLATION:columnNames.priceThreshold'),
         'VAT',
         t('TRANSLATION:columnNames.requestedAmount'),
         t('TRANSLATION:columnNames.orderedAmount'),
         t('TRANSLATION:columnNames.notOrderedAmount'),
         t('TRANSLATION:columnNames.status'),
         t('TRANSLATION:columnNames.wholesaleErrorMessage'),
      ]
      CSVData.push(titleRow)
      const notFoundIndexes = [t('TRANSLATION:columnNames.notFoundIndexes')]
      const rejectedIndexes = [t('TRANSLATION:columnNames.rejectedIndexes')]
      const services = [t('TRANSLATION:columnNames.services')]
      if (reportData) {
         reportData.orders.map((order) => {
            order.wholesales.map((wholesale) => {
               wholesale.orderedProducts.map((product) => {
                  CSVData.push([
                     product.requestedIndex,
                     product.index,
                     product.producerName,
                     wholesale.wholesaleName,
                     product.unitNetPrice,
                     product.netPrice,
                     product.unitGrossPrice,
                     product.grossPrice,
                     product.priceThreshold,
                     product.vat,
                     product.requestedAmount,
                     product.orderedAmount,
                     product.notOrderedAmount,
                     product.orderProductStatus,
                     product.wholesaleErrorMessage || '',
                  ])
               })
            })
            if (order.notFoundIndexes.length > 0) {
               CSVData.push(notFoundIndexes)
               order.notFoundIndexes.map((index) => {
                  CSVData.push(['', index])
               })
            }
            if (order.rejectedIndexes.length > 0) {
               CSVData.push(rejectedIndexes)
               order.rejectedIndexes.map((index) => {
                  CSVData.push(['', index])
               })
            }
            if (order.additionalServices.length > 0) {
               CSVData.push(services)
               order.additionalServices.map((service) => {
                  CSVData.push([
                     '',
                     service.name,
                     '',
                     '',
                     service.formattedNetPrice,
                     service.formattedNetValue,
                     service.formattedGrossPrice,
                     service.formattedGrossValue,
                     '',
                     service.taxRate,
                     service.amount,
                     '',
                     '',
                     '',
                  ])
               })
            }
         })
      }

      return (
         <CSVLink style={{ textAlign: 'right' }} filename={title} data={CSVData}>
            <ContainedButton variant="contained">{t('ORDERS_HISTORY:generateCSV')}</ContainedButton>
         </CSVLink>
      )
   }

   useEffect(() => {
      init()
   }, [])

   const handleOnClose = () => {
      if (!isDetailsStored.current && orderType) {
         updateDetailsStore({ orderType, id: orderUuids[0], data })
      }
      onClose()
   }

   return (
      <CustomDialog
         open={open}
         onClose={handleOnClose}
         fullWidth
         scroll="paper"
         maxWidth="xl"
         isLoading={isLoading}
         content={
            <DialogContent ref={componentRef} style={{ minHeight: 300, display: 'flex', flexDirection: 'column' }}>
               <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h4" color="primary">
                     {title}
                  </Typography>
                  <IconButton onClick={handleOnClose}>
                     <Close />
                  </IconButton>
               </Box>
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', my: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                     <Typography variant="body1" color="grayText">
                        {t('ORDERS_HISTORY:productsTable')}
                     </Typography>
                     <CustomTable
                        customRowStyles={(row: OrderProduct) => ({
                           backgroundColor: getHighlightColor(row),
                        })}
                        disablePagination
                        customFooter={(columnsCount) => (
                           <TableFooter sx={{ backgroundColor: '#EBEBEB' }}>
                              {netDeliveryCost && (
                                 <StyledTableCell colSpan={columnsCount}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          gap: '32px',
                                          pr: { lg: '32px', md: '32px' },
                                          width: '100%',
                                          justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                                       }}
                                    >
                                       <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                          <span>{t('TRANSLATION:deliveryCost')}</span>
                                          <span>
                                             {t('TRANSLATION:price', { price: netDeliveryCost.toFixed(2) })}{' '}
                                             {t('TRANSLATION:NET')}
                                          </span>
                                          <span>
                                             {t('TRANSLATION:price', {
                                                price: grossDeliveryCost ? grossDeliveryCost.toFixed(2) : 0,
                                             })}{' '}
                                             {t('TRANSLATION:GROSS')}
                                          </span>
                                       </Box>
                                    </Box>
                                 </StyledTableCell>
                              )}
                              <TableRow>
                                 <StyledTableCell colSpan={columnsCount}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          gap: '32px',
                                          pr: { lg: '32px', md: '32px' },
                                          width: '100%',
                                          justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                                       }}
                                    >
                                       <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                          <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                             {t('TRANSLATION:columnNames.netSum')}
                                          </p>
                                          <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                             {formattedNetValue}
                                          </p>
                                       </Box>
                                       <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                          <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                             {t('TRANSLATION:columnNames.grossSum')}
                                          </p>
                                          <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                             {formattedGrossValue}
                                          </p>
                                       </Box>
                                    </Box>
                                 </StyledTableCell>
                              </TableRow>
                           </TableFooter>
                        )}
                        rows={data.products}
                        dataSize={data.products.length}
                        columns={[
                           {
                              name: 'productName',
                              renderCell: (row: OrderProduct) => getProductName(row),
                              mainOnMobile: true,
                           },
                           {
                              name: 'orderNumber',
                              renderCell: (row: OrderProduct) => row.wholesaleOrderNumber,
                           },
                           {
                              name: 'producer',
                              renderCell: (row: OrderProduct) => (
                                 <Tooltip title={row.producerName}>
                                    <ImageComponent
                                       height={isMobile ? '15px' : '20px'}
                                       filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                                       producerName={row.producerName}
                                    />
                                 </Tooltip>
                              ),
                           },
                           {
                              name: 'partIndex',
                              renderCell: (row: OrderProduct) => row.index,
                           },
                           {
                              name: 'quantity',
                              renderCell: (row: OrderProduct) => (
                                 <Tooltip
                                    disableHoverListener={row.amount === 0}
                                    title={t('TRANSLATION:requestedAmountTooltip', {
                                       amount: row.amount,
                                       requestedAmount: row.requestedAmount,
                                    })}
                                 >
                                    <Box>
                                       {row.amount !== 0 && row.amount !== row.requestedAmount
                                          ? `${row.amount}/${row.requestedAmount}`
                                          : row.amount}
                                    </Box>
                                 </Tooltip>
                              ),
                           },
                           {
                              name: isMobile ? 'wholesalerAndNumber' : 'wholesaler',
                              mainOnMobile: true,
                              renderCell: (row: OrderProduct) =>
                                 isMobile ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                       <HiddenWholesalerLogo
                                          wholesalerName={row.wholesaleName}
                                          imgWidth="auto"
                                          imgHeight="15px"
                                       />
                                       {row.wholesaleOrderNumber}
                                    </Box>
                                 ) : (
                                    <HiddenWholesalerLogo
                                       wholesalerName={row.wholesaleName}
                                       imgWidth="auto"
                                       imgHeight="20px"
                                    />
                                 ),
                           },
                           {
                              name: 'price',
                              mainOnMobile: true,
                              renderCell: (row: OrderProduct) => (
                                 <>
                                    {priceType === 'NET' ? row.formattedPrice : row.formattedGrossPrice}
                                    {typeof row.formattedConvertedPrice !== 'undefined' &&
                                       (priceType === 'NET'
                                          ? ` (${row.formattedConvertedPrice})`
                                          : ` (${row.formattedConvertedGrossPrice})`)}
                                 </>
                              ),
                           },
                           {
                              name: 'clientPrice',
                              hidden: orderType === 'BASELINKER',
                              renderCell: (row: OrderProduct) =>
                                 priceType === 'NET' ? row.formattedClientNetPrice : row.formattedClientGrossPrice,
                           },
                           {
                              name: 'userName',
                              hidden: orderType !== 'GROUPED_BUY_NOW',
                              renderCell: (row: OrderProduct) => row.username || '',
                           },
                           {
                              name: 'orderTime',
                              hidden: orderType !== 'GROUPED_BUY_NOW',
                              renderCell: (row: OrderProduct) => row.orderDate || '',
                           },
                           {
                              name: 'wholesaleErrorMessage',
                              hidden: !data.products.some((product) => product.status !== 'AVAILABLE'),
                              renderCell: (row: OrderProduct) => row.wholesaleErrorMessage ?? '',
                           },
                        ]}
                     />
                  </Box>
                  {data.additionalServices.length > 0 && (
                     <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Typography variant="body1" color="grayText">
                           {t('ORDERS_HISTORY:servicesTable')}
                        </Typography>
                        <CustomTable
                           disablePagination
                           customFooter={(columnsCount) => (
                              <TableFooter sx={{ backgroundColor: '#EBEBEB' }}>
                                 <StyledTableCell colSpan={columnsCount}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          gap: '32px',
                                          pr: { lg: '32px', md: '32px' },
                                          width: '100%',
                                          justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                                       }}
                                    >
                                       <span>
                                          {`${t('TRANSLATION:columnNames.servicesSum')}: ${formattedServicesValue}`}
                                       </span>
                                    </Box>
                                 </StyledTableCell>
                              </TableFooter>
                           )}
                           rows={data.additionalServices}
                           dataSize={data.additionalServices.length}
                           columns={[
                              {
                                 name: 'serviceName',
                                 renderCell: (row: AdditionalService) => row.name,
                                 mainOnMobile: true,
                              },
                              {
                                 name: 'quantity',
                                 renderCell: (row: AdditionalService) => row.amount,
                              },
                              {
                                 name: 'price',
                                 renderCell: (row: AdditionalService) =>
                                    priceType === 'NET' ? row.formattedNetPrice : row.formattedGrossPrice,
                              },
                           ]}
                        />
                     </Box>
                  )}
               </Box>
               {!isMobile && (
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                     {orderType === 'CART_CSV' ? generateImportCSV() : generateCSV()}
                  </Box>
               )}
            </DialogContent>
         }
      />
   )
}
