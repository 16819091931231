import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useSettingsQuery } from 'shared/store/useSettings'
import { useMediaQuery, useTheme } from '@mui/material'

interface Props {
   value: Nullable<string>
   data: CooperatingWholesaler[]
   row: CooperatingWholesaler
   priceType: 'GROSS' | 'NET'
}

export default function PriceComponent({ value, data, row, priceType }: Props) {
   const { t } = useTranslation('PRODUCT_DETAILS')
   const { data: settings } = useSettingsQuery()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function returnDeposit() {
      switch (priceType) {
         case 'GROSS': {
            if (typeof row.convertedFormattedGrossDepositPrice === 'number')
               return row.convertedFormattedGrossDepositPrice
            return row.formattedGrossDepositPrice
         }
         case 'NET': {
            if (typeof row.convertedFormattedNetDepositPrice === 'number') return row.convertedFormattedNetDepositPrice
            return row.formattedNetDepositPrice
         }
         default: {
            return null
         }
      }
   }

   function ReturnMinObject() {
      const wholesaleWithLowestPrice = data.reduce(
         (minObject: CooperatingWholesaler | null, currentObject: CooperatingWholesaler) => {
            if (currentObject.netPrice !== null && currentObject.totalAvailability !== null) {
               if (minObject === null || (!!minObject.netPrice && currentObject.netPrice < minObject.netPrice)) {
                  return currentObject
               }
            }
            return minObject
         },
         null
      )

      return wholesaleWithLowestPrice
   }

   function calculatePriceDifference() {
      const lowestPriceWholesale: any = ReturnMinObject()
      let percentageDifference = 0
      if (lowestPriceWholesale) {
         if (row.convertedNetPrice) {
            const difference = row.convertedNetPrice - lowestPriceWholesale.convertedNetPrice
            percentageDifference = (difference / lowestPriceWholesale.convertedNetPrice) * 100
         } else {
            const difference = row.netPrice! - lowestPriceWholesale.netPrice
            percentageDifference = (difference / lowestPriceWholesale.netPrice) * 100
         }
      }
      if (percentageDifference !== 0 && percentageDifference !== -100 && row.netPrice !== 0) {
         return percentageDifference.toFixed(2)
      }
      return null
   }

   return (
      <Box
         sx={{
            display: 'flex',
            gap: { lg: 2, md: 2, xs: 0.5 },
            justifyContent: 'center',
         }}
      >
         <span
            style={{
               display: 'inline',
               ...(isMobile && {
                  display: 'none',
               }),
               textDecoration: 'none',
               color:
                  row.afterSalesBonus && row.afterSalesBonus > Number(calculatePriceDifference())
                     ? '#00CE42'
                     : 'grayText',
               fontSize: 10,
               fontWeight: 'bold',
            }}
         >
            {settings?.userSettings.preferences.priceDifferences === 'true' &&
               priceType === 'NET' &&
               row.afterSalesBonus !== 0 &&
               row.afterSalesBonus !== null &&
               `+${row.afterSalesBonus}%`}
         </span>
         <Tooltip
            disableHoverListener={!returnDeposit() || !value}
            title={t('tables.depositTooltip', { price: returnDeposit() }) || ''}
         >
            <Typography
               sx={{
                  ...(isMobile &&
                     priceType === 'NET' && {
                        fontWeight: 'bold',
                        fontSize: '11px',
                     }),
                  cursor: returnDeposit() && value && 'pointer',
                  textDecoration: returnDeposit() && value && 'underline dotted',
                  textUnderlineOffset: 4,
               }}
            >
               {value || '0,00'}
            </Typography>
         </Tooltip>
         <span
            style={{
               display: isMobile ? 'none' : 'inline',
               textDecoration: 'none',
               color: 'grayText',
               fontSize: 10,
               fontWeight: 'bold',
            }}
         >
            {settings?.userSettings.preferences.priceDifferences === 'true' &&
               calculatePriceDifference() &&
               priceType === 'NET' &&
               row.totalAvailability !== 0 &&
               row.totalAvailability !== null &&
               `+${calculatePriceDifference()}%`}
         </span>
      </Box>
   )
}
