/* eslint-disable react/function-component-definition */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

type ClientPricesContextType = {
   clientPricesOn: boolean
   setPrices: (enabled: boolean) => void
}

const ClientPricesContext = createContext<ClientPricesContextType | null>(null)

export function useClientPrices() {
   const context = useContext(ClientPricesContext)
   if (!context) {
      throw new Error('useClientPrices() must be used within a ClientPricesContextProvider')
   }
   return context
}

export const ClientPricesContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   const [clientPrices, setClientPrices] = useState<boolean>(false)

   const contextValue: ClientPricesContextType = {
      clientPricesOn: clientPrices,
      setPrices: (enabled) => {
         setClientPrices(enabled)
         //   TO BE REMOVED AFTER RESPONSIVE FRONT DEPLOY
         // if (window.location.pathname.includes('/product')) {
         //    window.location.reload()
         // }
         window.localStorage.setItem('clientPrices', enabled ? 'on' : 'off')
      },
   }

   useEffect(() => {
      if (window.localStorage.getItem('clientPrices') && window.localStorage.getItem('clientPrices') === 'on') {
         setClientPrices(true)
      } else {
         setClientPrices(false)
      }
   }, [window.localStorage])

   const memoizedValue = useMemo(() => {
      return contextValue
   }, [clientPrices])

   return <ClientPricesContext.Provider value={memoizedValue}>{children}</ClientPricesContext.Provider>
}
