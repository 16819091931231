import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import CustomDialog from 'shared/components/CustomDialog'
import { useMediaQuery, useTheme } from '@mui/material'

interface Props {
   open: boolean
}

export default function AppVersionModal({ open }: Props) {
   const { t } = useTranslation('AUTHORIZED')
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   return (
      <CustomDialog
         isLoading={!open}
         fullScreen={isMobile}
         open={open}
         onClose={() => null}
         maxWidth="xl"
         content={
            <>
               <DialogTitle color="error">{t('appVersionDialog.title')}</DialogTitle>
               <DialogContent>
                  <Typography variant="body1" fontWeight="bold">
                     {t('appVersionDialog.text')}
                  </Typography>
               </DialogContent>
            </>
         }
      />
   )
}
