import { TableFooter, TableRow, useMediaQuery, useTheme } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { Close } from '@mui/icons-material'
import Box from '@mui/material/Box'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { StyledTableCell } from 'shared/components/StyledTableComponents'
import { useNotificationContext } from 'shared/context/Notifications'
import { useSettingsQuery } from 'shared/store/useSettings'
import { Product } from 'shared/store/useCartSummary/types'
import CustomTable from 'shared/components/CustomTable'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import { useProfileQuery } from 'shared/store/useProfile'
import ImageComponent from 'shared/components/ImageComponent'
import changeStateClientPrice from '../../helpers/changeClientStatePrice'
import { ClientViewPricesType, ModalInfoType } from '../../types'
import {
   QuantityHolder,
   StyledQuantityButton,
   StyledCopyIcon,
   StyledAvailableParagraph,
   StyledInput,
} from '../../styles'

interface Props {
   products: Product[]
   clientView: boolean
   handleProductUpdate: (
      uuid: string,
      quantity?: number,
      includeProductInOrder?: boolean,
      netClientPrice?: number
   ) => void
   formattedNetValue: string
   formattedGrossValue: string
   formattedClientNetValue: string
   formattedClientGrossValue: string
   isProductUpdating: boolean
   setModalInfo: (modalInfo: ModalInfoType) => void
}

export default function MainTable({
   products,
   clientView,
   formattedNetValue,
   formattedGrossValue,
   formattedClientNetValue,
   formattedClientGrossValue,
   handleProductUpdate,
   isProductUpdating,
   setModalInfo,
}: Props) {
   const { t } = useTranslation('MAIN_CART')
   const theme = useTheme()
   const { data: settings } = useSettingsQuery()
   const { data: profileData } = useProfileQuery()
   const [clientPrices, setClientPrices] = useState<ClientViewPricesType>([])
   const { addNotification } = useNotificationContext()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function changeClientPrice(clientPrice: number, uuid: string, productPrice: number, taxRate: number) {
      const tempClientPrice = clientPrice <= productPrice ? productPrice + 0.01 : clientPrice
      if (clientPrice <= productPrice + 0.01) {
         addNotification(t('MAIN_CART:clientPriceError'), 'error')
         changeStateClientPrice(setClientPrices, productPrice, uuid, taxRate)
      } else {
         handleProductUpdate(uuid, undefined, undefined, tempClientPrice)
      }
   }

   useEffect(() => {
      const tempClientPrices: ClientViewPricesType = []
      products.forEach((product) => {
         tempClientPrices.push({
            uuid: product.uuid,
            clientPriceNet: Number(product.cartWholesaleDTO.clientNetPrice),
            clientPriceGross: Number(product.cartWholesaleDTO.clientGrossPrice),
         })
      })
      setClientPrices([...tempClientPrices])
   }, [products])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px' }}>
         <CustomTable
            disablePagination
            customNoResultsText={t('MAIN_CART:noProducts')}
            customFooter={(columnsCount) => (
               <TableFooter sx={{ fontSize: 12, backgroundColor: '#EBEBEB' }}>
                  <TableRow>
                     <StyledTableCell colSpan={columnsCount}>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: '32px',
                              pr: { lg: '32px', md: '32px' },
                              width: '100%',
                              justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                           }}
                        >
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:columnNames.netSum')}</p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                 {clientView ? formattedClientNetValue : formattedNetValue}
                              </p>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                 {t('TRANSLATION:columnNames.grossSum')}
                              </p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                 {clientView ? formattedClientGrossValue : formattedGrossValue}
                              </p>
                           </Box>
                        </Box>
                     </StyledTableCell>
                  </TableRow>
               </TableFooter>
            )}
            customTableStyles={() => ({
               backgroundColor: 'white',
               boxShadow: 'none',
               borderRadius: '0px !important',
            })}
            customHeadRowStyles={() => ({
               '>*': {
                  backgroundColor: '#EBEBEB',
                  borderRadius: '0px !important',
                  '& > th': {
                     borderRadius: '0px !important',
                  },
               },
            })}
            dataSize={products.length}
            rows={products.filter((product) => product.status !== 'NOT_AVAILABLE')}
            columns={[
               {
                  name: isMobile ? 'producerAndIndex' : 'partIndex',
                  mainOnMobile: true,
                  hidden: clientView,
                  renderCell: (row: Product) =>
                     isMobile ? (
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <ImageComponent
                              height="15px"
                              producerName={row.producerName}
                              filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                           />
                           <span>
                              {row.index.toLowerCase()}
                              {row.requestedIndex && row.requestedIndex.toLowerCase() !== row.index.toLowerCase() && (
                                 <span style={{ color: 'grayText', fontSize: 10 }}>{` (${row.requestedIndex})`}</span>
                              )}
                           </span>
                        </Box>
                     ) : (
                        <Tooltip
                           disableHoverListener={settings?.userSettings.preferences.showProductNamesInCart === 'true'}
                           title={row.cartProductName ? row.cartProductName : row.index}
                        >
                           <Box>
                              {row.index.toLowerCase()}
                              {row.requestedIndex && row.requestedIndex.toLowerCase() !== row.index.toLowerCase() && (
                                 <span style={{ color: 'grayText', fontSize: 10 }}>{` (${row.requestedIndex})`}</span>
                              )}
                              {!isMobile && (
                                 <CopyToClipboard text={row.index}>
                                    <StyledCopyIcon
                                       onClick={(e: any) => {
                                          e.stopPropagation()
                                       }}
                                    />
                                 </CopyToClipboard>
                              )}
                           </Box>
                        </Tooltip>
                     ),
               },
               {
                  name: 'productName',
                  mainOnMobile: clientView,
                  hidden: !clientView && settings?.userSettings.preferences.showProductNamesInCart === 'false',
                  renderCell: (row: Product) => (
                     <Tooltip title={row.cartProductName ?? row.index}>
                        <Box>
                           {row.cartProductName
                              ? `${row.cartProductName.split(' ')[0]} ${row.cartProductName.split(' ')[1]}`
                              : row.index}
                        </Box>
                     </Tooltip>
                  ),
               },
               {
                  name: 'quantityAndValue',
                  onlyOnMobile: true,
                  hidden: !isMobile,
                  mainOnMobile: true,
                  renderCell: (row: Product) => (
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                           alignItems: 'center',
                        }}
                     >
                        <QuantityHolder>
                           <StyledQuantityButton
                              disabled={isProductUpdating || row.cartWholesaleDTO.quantity === 0}
                              onClick={() =>
                                 handleProductUpdate(row.uuid, row.cartWholesaleDTO.quantity - row.quantityInSet)
                              }
                           >
                              -
                           </StyledQuantityButton>
                           <StyledQuantityButton
                              disabled={isProductUpdating}
                              type="button"
                              onClick={() =>
                                 setModalInfo({
                                    modalType: 'QUANTITY',
                                    uuid: row.uuid,
                                    quantity: row.cartWholesaleDTO.quantity,
                                 })
                              }
                              style={{ fontWeight: 'normal' }}
                           >
                              {row.cartWholesaleDTO.quantity}
                           </StyledQuantityButton>
                           <StyledQuantityButton
                              disabled={isProductUpdating || row.cartWholesaleDTO.quantity === MAX_PRODUCT_QUANTITY}
                              onClick={() =>
                                 handleProductUpdate(row.uuid, row.cartWholesaleDTO.quantity + row.quantityInSet)
                              }
                           >
                              +
                           </StyledQuantityButton>
                        </QuantityHolder>
                        {row.cartWholesaleDTO.availableQuantity <= 5 && row.cartWholesaleDTO.availableQuantity > 1 ? (
                           <StyledAvailableParagraph>{`${t('MAIN_CART:available')} ${row.cartWholesaleDTO.availableQuantity}`}</StyledAvailableParagraph>
                        ) : null}
                        {clientView ? (
                           row.cartWholesaleDTO.formattedClientGrossValue
                        ) : (
                           <>
                              {row.cartWholesaleDTO.formattedGrossValue}{' '}
                              {row.cartWholesaleDTO.formattedConvertedGrossValue &&
                                 `(${row.cartWholesaleDTO.formattedConvertedGrossValue})`}
                           </>
                        )}
                     </Box>
                  ),
               },

               {
                  name: 'producer',
                  renderCell: (row: Product) => (
                     <ImageComponent
                        height="15px"
                        filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                        producerName={row.producerName}
                     />
                  ),
               },
               {
                  name: 'wholesaleName',
                  mainOnMobile: true,
                  sortable: true,
                  sortLabel: 'cartWholesaleName',
                  sortType: 'string',
                  showSortOption: true,
                  hidden: clientView,
                  renderCell: (row: Product) => (
                     <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                        <HiddenWholesalerLogo wholesalerName={row.cartWholesaleName} imgHeight="20px" imgWidth="auto" />
                        {row.requirePrepayment && (
                           <Tooltip title={t('TRANSLATION:requirePrepayment')}>
                              <AttachMoneyIcon color="success" />
                           </Tooltip>
                        )}
                     </Box>
                  ),
               },
               {
                  name: 'quantity',
                  customCellStyles: () => ({
                     p: 0,
                  }),
                  renderCell: (row: Product) => (
                     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <QuantityHolder>
                           <StyledQuantityButton
                              disabled={isProductUpdating || row.cartWholesaleDTO.quantity === 0}
                              onClick={() =>
                                 handleProductUpdate(row.uuid, row.cartWholesaleDTO.quantity - row.quantityInSet)
                              }
                           >
                              -
                           </StyledQuantityButton>
                           <StyledQuantityButton
                              disabled={isProductUpdating}
                              type="button"
                              onClick={() =>
                                 setModalInfo({
                                    modalType: 'QUANTITY',
                                    uuid: row.uuid,
                                    quantity: row.cartWholesaleDTO.quantity,
                                 })
                              }
                              style={{ fontWeight: 'normal' }}
                           >
                              {row.cartWholesaleDTO.quantity}
                           </StyledQuantityButton>
                           <StyledQuantityButton
                              disabled={isProductUpdating || row.cartWholesaleDTO.quantity === MAX_PRODUCT_QUANTITY}
                              onClick={() =>
                                 handleProductUpdate(row.uuid, row.cartWholesaleDTO.quantity + row.quantityInSet)
                              }
                           >
                              +
                           </StyledQuantityButton>
                        </QuantityHolder>
                        {row.cartWholesaleDTO.availableQuantity <= 5 && row.cartWholesaleDTO.availableQuantity > 1 ? (
                           <StyledAvailableParagraph>{`${t('MAIN_CART:available')} ${row.cartWholesaleDTO.availableQuantity}`}</StyledAvailableParagraph>
                        ) : null}
                     </Box>
                  ),
               },
               {
                  name: 'netPrice',
                  renderCell: (row: Product) =>
                     clientView ? (
                        <>
                           <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                              <StyledInput
                                 disabled={isProductUpdating}
                                 type="number"
                                 min={row.cartWholesaleDTO.netPrice + 0.01}
                                 value={clientPrices.find((item) => item.uuid === row.uuid)?.clientPriceNet}
                                 onBlur={(e: any) =>
                                    changeClientPrice(
                                       Number(e.target.value),
                                       row.uuid,
                                       row.cartWholesaleDTO.netPrice,
                                       row.cartWholesaleDTO.taxRate
                                    )
                                 }
                                 onFocus={(e: any) => e.target.select()}
                                 onChange={(e: any) =>
                                    changeStateClientPrice(
                                       setClientPrices,
                                       Number(e.target.value),
                                       row.uuid,
                                       row.cartWholesaleDTO.taxRate
                                    )
                                 }
                              />
                           </Tooltip>
                           {row.cartWholesaleDTO.preferredCurrency}
                        </>
                     ) : (
                        <>
                           {row.cartWholesaleDTO.formattedNetPrice}{' '}
                           {row.cartWholesaleDTO.formattedConvertedNetPrice &&
                              `(${row.cartWholesaleDTO.formattedConvertedNetPrice})`}
                        </>
                     ),
               },
               {
                  name: 'VAT',
                  renderCell: (row: Product) => `${row.cartWholesaleDTO.taxRate}%`,
               },
               {
                  name: 'grossPrice',
                  hidden: !clientView,
                  renderCell: (row: Product) => (
                     <>
                        <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                           <StyledInput
                              type="number"
                              disabled={isProductUpdating}
                              value={clientPrices.find((item) => item.uuid === row.uuid)?.clientPriceGross}
                              onFocus={(e: any) => e.target.select()}
                              onBlur={(e: any) =>
                                 changeClientPrice(
                                    Number(e.target.value) / (1 + row.cartWholesaleDTO.taxRate / 100),
                                    row.uuid,
                                    row.cartWholesaleDTO.netPrice,
                                    row.cartWholesaleDTO.taxRate
                                 )
                              }
                              onChange={(e: any) =>
                                 changeStateClientPrice(
                                    setClientPrices,
                                    Number(e.target.value) / (1 + row.cartWholesaleDTO.taxRate / 100),
                                    row.uuid,
                                    row.cartWholesaleDTO.taxRate
                                 )
                              }
                           />
                        </Tooltip>
                        {row.cartWholesaleDTO.preferredCurrency}
                     </>
                  ),
               },
               {
                  name: 'grossValue',
                  mainOnMobile: clientView,
                  renderCell: (row: Product) =>
                     clientView ? (
                        row.cartWholesaleDTO.formattedClientGrossValue
                     ) : (
                        <>
                           {row.cartWholesaleDTO.formattedGrossValue}{' '}
                           {row.cartWholesaleDTO.formattedConvertedGrossValue &&
                              `(${row.cartWholesaleDTO.formattedConvertedGrossValue})`}
                        </>
                     ),
               },
               {
                  name: 'includeInOrder',
                  renderCell: (row: Product) => (
                     <Checkbox
                        disabled={isProductUpdating || row.cartWholesaleDTO.quantity === 0}
                        onClick={() => handleProductUpdate(row.uuid, undefined, !row.includedProductInOrder)}
                        color="primary"
                        checked={row.includedProductInOrder}
                     />
                  ),
               },
               {
                  name: 'deleteProduct',
                  renderCell: (row: Product) => (
                     <IconButton
                        onClick={() =>
                           setModalInfo({
                              modalType: 'DELETE',
                              uuid: row.uuid,
                              removeFromUnavailable: false,
                              index: row.index,
                           })
                        }
                        sx={{ '&:hover': { '>*': { color: 'red' } } }}
                     >
                        <Close />
                     </IconButton>
                  ),
               },
               {
                  name: 'swapProduct',
                  hidden: clientView,
                  renderCell: (row: Product) => (
                     <IconButton
                        sx={{ '&:hover': { '>*': { color: theme.palette.primary.main } } }}
                        onClick={() =>
                           setModalInfo({
                              modalType: 'SWAP',
                              defaultQuantity: row.cartWholesaleDTO.quantity,
                              uuid: row.productEntityUuid,
                              productToRemoveUuid: row.uuid,
                              oldClientPrice:
                                 profileData?.priceType === 'NET'
                                    ? row.cartWholesaleDTO.formattedClientNetPrice
                                    : row.cartWholesaleDTO.formattedClientGrossPrice,
                           })
                        }
                     >
                        <SwapHorizIcon />
                     </IconButton>
                  ),
               },
            ]}
         />
      </Box>
   )
}
