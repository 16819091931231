import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ReactMarkdown from 'react-markdown'
import { useTheme } from '@mui/material'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import React from 'react'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import CustomDialog from 'shared/components/CustomDialog'

interface Props {
   open: boolean
   message?: string
   title?: string
   handleClose: () => void
}

export default function Modal({ open, handleClose, message, title }: Props) {
   const theme = useTheme()

   return (
      <CustomDialog
         isLoading={!open}
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20, textAlign: 'center' }}>
                     {title}
                  </Typography>
                  {title === 'Ankieta' && (
                     <IconButton onClick={handleClose} sx={{ '&:hover': { color: 'red' }, mt: -1 }}>
                        <CloseIcon />
                     </IconButton>
                  )}
               </DialogTitle>
               <DialogContent
                  sx={{
                     textAlign: 'center',
                     minWidth: 300,
                  }}
               >
                  <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                     {message}
                  </ReactMarkdown>
               </DialogContent>
               <DialogActions>
                  {title !== 'Ankieta' && (
                     <ContainedButton variant="contained" sx={{ margin: '0 auto' }} onClick={handleClose}>
                        OK
                     </ContainedButton>
                  )}
               </DialogActions>
            </>
         }
      />
   )
}
