import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Card, FormControl, InputLabel, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material'
import React, { useCallback, useState, useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import CheckIcon from '@mui/icons-material/Check'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { CSVLink } from 'react-csv'

import { useChangeOrderNameMutation, useOrdersHistory, useSearchOrdersHistory } from 'shared/store/useOrdersHistory'
import { useSettingsMutation, useSettingsQuery } from 'shared/store/useSettings'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useLocationsQuery } from 'shared/store/useLocations'
import { Order } from 'shared/store/useOrdersHistory/types'
import { useProfileQuery } from 'shared/store/useProfile'

import CustomTable from 'shared/components/CustomTable'
import { ChangeNameDetailsType, PrintType, StoredOrderDetails } from './types'
import { StyledClientPrintIcon, StyledFullPrintIcon } from './styles'
import OrderDetails from './components/OrderDetails'
import OrderPrint from './components/OrderPrint'
import { pl, en, de } from './locales'

function OrdersHistory() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])
   i18n.addResourceBundle('pl-PL', 'ORDERS_HISTORY', pl)
   i18n.addResourceBundle('en-EN', 'ORDERS_HISTORY', en)
   i18n.addResourceBundle('de-DE', 'ORDERS_HISTORY', de)
   const [searchPhrase, setSearchPhrase] = useState('')
   const [selectedFilter, setSelectedFilter] = useState<string>('LAST_7_DAYS')
   const [filterByStatus, setFilterByStatus] = useState<PropType<OrderType, 'status'>[]>([])
   const [openDetails, handleOpenDetails] = useState<string[]>()
   const [printDetails, handleOpenPrint] = useState<string[]>()
   const [printType, setPrintType] = useState<PrintType>('clientPrint')
   const [savedOrderDetails, handleOrdersDetails] = useState<StoredOrderDetails[]>([])
   const { data, isLoading, isError, error } = useOrdersHistory()
   const [searchParams] = useSearchParams()
   const { data: settingsData } = useSettingsQuery()
   const { mutate: mutateSettings, isLoading: isSettingsMutateLoading } = useSettingsMutation(true)
   const { data: profileSettings } = useProfileQuery()
   const { data: locationsData } = useLocationsQuery()
   const location = useLocation()
   const queryClient = useQueryClient()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const navigate = useNavigate()
   const [changeNameDetails, setChangeNameDetails] = useState<ChangeNameDetailsType>({
      orderName: '',
      orderUuid: '',
   })
   const { mutate, isLoading: isMutateLoading } = useSearchOrdersHistory()
   const { mutate: changeOrderNameMutate, isLoading: changeOrderNameMutateIsLoading } = useChangeOrderNameMutation()

   const activeLocationSettings = locationsData?.locations.find(
      (item) => item.locationSettings.uuid === profileSettings?.locationUuid
   )

   function handleOnSearch(filter?: string, resetFilters?: boolean) {
      if (filter) {
         setSelectedFilter(filter)
      }
      if (!isLoading) {
         if (resetFilters) {
            mutate({
               searchValue: '',
               filter: 'LAST_7_DAYS',
            })
         } else if (filter || selectedFilter) {
            mutate({
               searchValue: searchPhrase,
               filter: filter ?? selectedFilter,
               filterStatus: filterByStatus.length > 0 ? filterByStatus : undefined,
            })
         } else {
            mutate({ searchValue: searchPhrase, filterStatus: filterByStatus.length > 0 ? filterByStatus : undefined })
         }
      }
   }

   function updateDetailsStore(newRecord: StoredOrderDetails) {
      handleOrdersDetails((prevState) => [...prevState, newRecord])
   }

   function handleGroupingChange() {
      if (settingsData) {
         const newBuyNowOrdersGroup = settingsData.userSettings.preferences.buyNowOrdersGroup !== 'true'
         mutateSettings(
            {
               ...settingsData,
               userSettings: {
                  ...settingsData.userSettings,
                  preferences: {
                     ...settingsData.userSettings.preferences,
                     showProductNamesInCart: settingsData.userSettings.preferences.showProductNamesInCart === 'true',
                     buyNowOrdersGroup: newBuyNowOrdersGroup,
                     priceDifferences: settingsData.userSettings.preferences.priceDifferences === 'true',
                     displayMinPopup: settingsData.userSettings.preferences.displayMinPopup === 'true',
                     dontShowLocationDialog: settingsData.userSettings.preferences.dontShowLocationDialog === 'true',
                  },
               },
            },
            {
               onSuccess: () => {
                  handleOrdersDetails(
                     savedOrderDetails.filter(
                        (item) => item.orderType !== 'BUY_NOW' && item.orderType !== 'GROUPED_BUY_NOW'
                     )
                  )
                  handleOnSearch()
               },
            }
         )
      }
   }

   useEffect(() => {
      if (isError) {
         navigate('/dashboard/error-page', {
            state: {
               variant: 'backend',
               path: location.pathname,
               error: {
                  message: error,
                  stack: 'unknown',
               },
            },
         })
      }
   }, [isError, isLoading])

   useEffect(() => {
      queryClient.invalidateQueries('userProfile')
   }, [])

   function getHighlightColor(orderStatus: PropType<OrderType, 'status'>) {
      switch (orderStatus) {
         case 'FAILED':
            return 'rgba(255,0,0,0.5)'
         case 'PARTIALLY_SUCCESSFUL':
            return 'rgb(255, 136, 0,0.7)'
         case 'SUCCESSFUL':
         default:
            return 'transparent'
      }
   }

   function handlePrint(order: Order, event: React.MouseEvent<HTMLButtonElement, MouseEvent>, typeOfPrint: PrintType) {
      event.preventDefault()
      event.stopPropagation()
      handleOpenPrint(order.orderUuids)
      setPrintType(typeOfPrint)
   }

   function renderCartName(orderType?: PropType<Order, 'orderType'>, cartName?: string, orderDate?: string) {
      const reg = /^(?:0?[1-9]|[12][0-9]|3[01])\/(?:0?[1-9]|1[012])\/\d{4}$/
      if (cartName) {
         if (orderType === 'BUY_NOW' && reg.test(cartName)) {
            return i18n.t('ORDERS_HISTORY:buyNowCartName', { date: orderDate })
         }
      }
      return cartName
   }

   function handleChangeOrderName() {
      if (changeNameDetails.orderUuid) {
         changeOrderNameMutate(
            { orderName: changeNameDetails.orderName, orderUuid: changeNameDetails.orderUuid },
            {
               onSuccess: () => {
                  setChangeNameDetails({ orderUuid: '', orderName: '' })
               },
            }
         )
      }
   }

   function generateCSV() {
      const CSVData = []
      if (data) {
         const title: string[] = [
            t('TRANSLATION:columnNames.date'),
            t('TRANSLATION:columnNames.cartName'),
            t('TRANSLATION:columnNames.comment'),
            t('TRANSLATION:columnNames.sum'),
            t('TRANSLATION:columnNames.clientValueWithServices'),
            t('TRANSLATION:columnNames.phoneNumber'),
            t('TRANSLATION:columnNames.userName'),
         ]
         CSVData.push(title)
         data.orders.map((item) => {
            return CSVData.push([
               item.orderDate,
               item.cartName,
               item.cartDescription,
               item.formattedValue,
               item.formattedClientValue,
               item.phoneNumber !== null ? item.phoneNumber : '',
               item.userName,
            ])
         })
      }

      const date = new Date()

      return (
         <CSVLink
            filename={t('ORDERS_HISTORY:csvFileName', { date: date.toLocaleString(i18n.language) })}
            data={CSVData}
         >
            <ContainedButton variant="contained">{t('ORDERS_HISTORY:generateCSV')}</ContainedButton>
         </CSVLink>
      )
   }

   const getTitle = useCallback(() => {
      const orderUuids = openDetails || printDetails
      const target = data?.orders.find((item) => item.orderUuids === orderUuids)
      if (target) {
         if (target.orderType === 'GROUPED_BUY_NOW') {
            return t('ORDERS_HISTORY:buyNowOrderPrintTitle', {
               orderDate: target?.orderDate,
            })
         }
         return t('ORDERS_HISTORY:orderPrintTitle', {
            orderName: target?.cartName,
            orderDate: target?.orderDate,
            userName: target.userName,
         })
      }
      return ''
   }, [openDetails, printDetails])

   useEffect(() => {
      const uuid = searchParams.get('uuid')
      if (data) {
         if (uuid) {
            const tempData = data.orders.find((item) => item.orderUuids.includes(uuid))
            if (tempData) {
               handleOpenDetails(tempData.orderUuids)
            }
         }
      }
   }, [searchParams.get('uuid'), data])

   return (
      <Box>
         {!isLoading ? (
            <>
               <div style={{ width: '100%' }}>
                  {data && Array.isArray(data.orders) && (
                     <Card>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                              alignItems: { md: 'center', sm: 'flex-start', xs: 'normal' },
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                              gap: 2,
                              my: 2,
                              px: 2,
                           }}
                        >
                           <Box sx={{ display: 'flex', maxWidth: '100%', gap: 2, alignItems: 'center' }}>
                              <Box
                                 sx={{
                                    display: 'flex',

                                    flexDirection: 'column',
                                 }}
                              >
                                 <TextField
                                    onKeyDown={(e) => {
                                       if (e.key === 'Enter') handleOnSearch()
                                    }}
                                    label={t('TRANSLATION:searchAndFilters.searchPhrase')}
                                    variant="standard"
                                    onChange={(e) => setSearchPhrase(e.target.value)}
                                    value={searchPhrase}
                                 />
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          checked={selectedFilter === 'ALL'}
                                          onChange={() => {
                                             handleOnSearch('ALL')
                                          }}
                                       />
                                    }
                                    label={t('TRANSLATION:searchAndFilters.searchEverywhere')}
                                 />
                              </Box>
                              <OutlinedButton variant="outlined" onClick={() => handleOnSearch()}>
                                 {t('TRANSLATION:search')} <Box component={SearchIcon} sx={{ width: 20, height: 20 }} />
                              </OutlinedButton>
                           </Box>
                           <Box
                              sx={{
                                 display: 'flex',
                                 gap: 1,
                                 flexWrap: 'wrap',
                              }}
                           >
                              <ContainedButton
                                 variant="contained"
                                 onClick={() => {
                                    setSearchPhrase('')
                                    setFilterByStatus([])
                                    handleOnSearch('LAST_7_DAYS', true)
                                 }}
                              >
                                 {t('TRANSLATION:searchAndFilters.clearFilters')}
                              </ContainedButton>
                              {data?.availableFilters
                                 .filter((item) => item !== 'ALL')
                                 .reverse()
                                 .map((item) => (
                                    <ContainedButton
                                       onClick={() => {
                                          handleOnSearch(item)
                                       }}
                                       disabled={selectedFilter === item || isMutateLoading}
                                       variant="contained"
                                       key={item}
                                    >
                                       {t(`TRANSLATION:searchAndFilters.filters.${item}`)}
                                    </ContainedButton>
                                 ))}
                           </Box>
                           <Box
                              sx={{
                                 display: 'flex',
                                 gap: 1,
                                 maxWidth: '100%',
                                 alignItems: 'center',
                              }}
                           >
                              <FormControl
                                 sx={{
                                    minWidth: { lg: 200, md: 200, xs: 'none' },
                                    flex: { lg: 'none', md: 'none', xs: 0.5 },
                                    alignSelf: 'center',
                                 }}
                                 component="fieldset"
                                 variant="outlined"
                              >
                                 <InputLabel>{t('ORDERS_HISTORY:orderStatusFilter')}</InputLabel>
                                 <Select
                                    sx={{ minWidth: { lg: 200, md: 200, xs: 'none' } }}
                                    multiple
                                    onClose={() => handleOnSearch()}
                                    value={filterByStatus}
                                    onChange={(e) =>
                                       setFilterByStatus(e.target.value as unknown as PropType<OrderType, 'status'>[])
                                    }
                                    label={t('ORDERS_HISTORY:orderStatusFilter')}
                                 >
                                    <MenuItem value="SUCCESSFUL">{t('ORDERS_HISTORY:successStatus')}</MenuItem>
                                    <MenuItem value="PARTIALLY_SUCCESSFUL">
                                       {t('ORDERS_HISTORY:partiallySuccess')}
                                    </MenuItem>
                                    <MenuItem value="FAILED">{t('ORDERS_HISTORY:failedStatus')}</MenuItem>
                                 </Select>
                              </FormControl>
                              <FormControlLabel
                                 sx={{
                                    alignSelf: 'center',
                                    flex: { lg: 'none', md: 'none', xs: 0.5 },
                                    justifySelf: 'flex-end',
                                 }}
                                 control={
                                    <Switch
                                       checked={settingsData?.userSettings.preferences.buyNowOrdersGroup === 'true'}
                                       onChange={handleGroupingChange}
                                       disabled={isSettingsMutateLoading}
                                    />
                                 }
                                 label={t('ORDERS_HISTORY:groupBuyNows')}
                              />
                           </Box>
                        </Box>
                        <CustomTable
                           dataSize={data.orders.length}
                           pagingVariants={[10, 25, 50]}
                           onChangeRowsPerPage={(rowsPerPage) =>
                              window.localStorage.setItem('itemsPerPage', rowsPerPage.toString())
                           }
                           rowsPerPage={
                              window.localStorage.getItem('itemsPerPage')
                                 ? Number(window.localStorage.getItem('itemsPerPage'))
                                 : 25
                           }
                           rows={data.orders}
                           onRowClick={(row: Order) => handleOpenDetails(row.orderUuids)}
                           customRowStyles={(row: Order) => ({
                              ...((row.status === 'FAILED' || row.status === 'PARTIALLY_SUCCESSFUL') && {
                                 backgroundColor: getHighlightColor(row.status ?? 'SUCCESSFUL'),
                                 '&:hover': {
                                    backgroundColor: getHighlightColor(row.status ?? 'SUCCESSFUL'),
                                 },
                                 textAlign: 'center',
                                 cursor: 'pointer',
                              }),
                           })}
                           columns={[
                              {
                                 name: 'date',
                                 renderCell: (row: Order) => row.orderDate,
                                 sortable: true,
                                 sortLabel: 'orderDate',
                                 sortType: 'date',
                                 mainOnMobile: true,
                              },
                              {
                                 name: 'cartName',
                                 hidden: !isMobile,
                                 sortable: true,
                                 sortLabel: 'cartName',
                                 sortType: 'string',
                                 mainOnMobile: true,
                                 renderCell: (row: Order) => (
                                    <Box
                                       sx={{
                                          ...(isMobile && {
                                             maxWidth: 100,
                                             overflow: 'hidden',
                                             textOverflow: 'ellipsis',
                                          }),
                                       }}
                                    >
                                       {renderCartName(row.orderType, row.cartName, row.orderDate)}
                                    </Box>
                                 ),
                              },
                              {
                                 name: 'cartName',
                                 hidden: isMobile,
                                 renderCell: (row: Order) =>
                                    row.orderUuids[0] === changeNameDetails.orderUuid ? (
                                       <Box
                                          onKeyDown={(e) => {
                                             if (e.key === 'Escape') {
                                                setChangeNameDetails({
                                                   orderName: '',
                                                   orderUuid: '',
                                                })
                                             }
                                             if (e.key === 'Enter') {
                                                handleChangeOrderName()
                                             }
                                          }}
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             gap: 1,
                                          }}
                                       >
                                          <TextField
                                             label={t('TRANSLATION:columnNames.cartName')}
                                             defaultValue={changeNameDetails.orderName}
                                             disabled={changeOrderNameMutateIsLoading}
                                             inputProps={{
                                                maxLength: 50,
                                             }}
                                             multiline
                                             onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                             }}
                                             onChange={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setChangeNameDetails({
                                                   ...changeNameDetails,
                                                   orderName: e.target.value,
                                                })
                                             }}
                                          />
                                          <IconButton
                                             disabled={changeOrderNameMutateIsLoading}
                                             onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                if (row.cartName !== changeNameDetails.orderName) {
                                                   handleChangeOrderName()
                                                } else {
                                                   setChangeNameDetails({
                                                      orderUuid: '',
                                                      orderName: '',
                                                   })
                                                }
                                             }}
                                             sx={{ '&:hover': { color: 'green', fontWeight: 'bold' } }}
                                          >
                                             <CheckIcon />
                                          </IconButton>
                                       </Box>
                                    ) : (
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             gap: 1,
                                          }}
                                       >
                                          {renderCartName(row.orderType, row.cartName, row.orderDate)}
                                          {row.orderType !== 'GROUPED_BUY_NOW' && (
                                             <IconButton
                                                onClick={(e) => {
                                                   e.preventDefault()
                                                   e.stopPropagation()
                                                   setChangeNameDetails({
                                                      orderUuid: row.orderUuids[0] || '',
                                                      orderName: row.cartName || '',
                                                   })
                                                }}
                                             >
                                                <EditIcon />
                                             </IconButton>
                                          )}
                                       </Box>
                                    ),
                                 sortable: true,
                                 sortLabel: 'cartName',
                                 sortType: 'string',
                                 mainOnMobile: true,
                              },
                              {
                                 name: 'cartName',
                                 hidden: !isMobile,
                                 onlyOnMobile: true,
                                 renderCell: (row: Order) =>
                                    row.orderUuids[0] === changeNameDetails.orderUuid ? (
                                       <Box
                                          onKeyDown={(e) => {
                                             if (e.key === 'Escape') {
                                                setChangeNameDetails({
                                                   orderName: '',
                                                   orderUuid: '',
                                                })
                                             }
                                             if (e.key === 'Enter') {
                                                handleChangeOrderName()
                                             }
                                          }}
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             gap: 1,
                                          }}
                                       >
                                          <TextField
                                             label={t('TRANSLATION:columnNames.cartName')}
                                             defaultValue={changeNameDetails.orderName}
                                             disabled={changeOrderNameMutateIsLoading}
                                             inputProps={{
                                                maxLength: 50,
                                             }}
                                             multiline
                                             onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                             }}
                                             onChange={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setChangeNameDetails({
                                                   ...changeNameDetails,
                                                   orderName: e.target.value,
                                                })
                                             }}
                                          />
                                          <IconButton
                                             disabled={changeOrderNameMutateIsLoading}
                                             onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                if (row.cartName !== changeNameDetails.orderName) {
                                                   handleChangeOrderName()
                                                } else {
                                                   setChangeNameDetails({
                                                      orderUuid: '',
                                                      orderName: '',
                                                   })
                                                }
                                             }}
                                             sx={{ '&:hover': { color: 'green', fontWeight: 'bold' } }}
                                          >
                                             <CheckIcon />
                                          </IconButton>
                                       </Box>
                                    ) : (
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                             gap: 1,
                                             fontSize: '10.5px',
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                ...(isMobile && {
                                                   maxWidth: 100,
                                                   overflow: 'hidden',
                                                   textOverflow: 'ellipsis',
                                                }),
                                             }}
                                          >
                                             {renderCartName(row.orderType, row.cartName, row.orderDate)}
                                          </Box>
                                          {row.orderType !== 'GROUPED_BUY_NOW' && (
                                             <IconButton
                                                onClick={(e) => {
                                                   e.preventDefault()
                                                   e.stopPropagation()
                                                   setChangeNameDetails({
                                                      orderUuid: row.orderUuids[0] || '',
                                                      orderName: row.cartName || '',
                                                   })
                                                }}
                                             >
                                                <EditIcon />
                                             </IconButton>
                                          )}
                                       </Box>
                                    ),
                              },
                              {
                                 name: 'orderType',
                                 renderCell: (row: Order) => t(`ORDERS_HISTORY:orderType.${row.orderType}`),
                              },
                              {
                                 name: 'comment',
                                 renderCell: (row: Order) => row.cartDescription,
                              },
                              {
                                 name: 'phoneNumber',
                                 renderCell: (row: Order) => row.phoneNumber,
                              },
                              {
                                 name: 'sum',
                                 renderCell: (row: Order) => row.formattedValue,
                                 sortable: true,
                                 sortLabel: 'value',
                                 sortType: 'number',
                                 showPriceType: true,
                                 mainOnMobile: true,
                              },
                              {
                                 name: 'clientValueWithServices',
                                 renderCell: (row: Order) => row.formattedClientValueWithServices,
                                 sortable: true,
                                 sortLabel: 'clientValueWithServices',
                                 sortType: 'number',
                                 showPriceType: true,
                              },
                              {
                                 name: 'orderingPerson',
                                 renderCell: (row: Order) => row.userName,
                                 sortable: true,
                                 sortLabel: 'userName',
                                 sortType: 'string',
                              },
                              {
                                 name: '',
                                 // hidden: isMobile,
                                 renderCell: (row: Order) => (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                                       {row.orderType !== 'BASELINKER' && (
                                          <Tooltip title={t('ORDERS_HISTORY:clientPrint') || ''}>
                                             <IconButton
                                                onClick={(e) => {
                                                   handlePrint(row, e, 'clientPrint')
                                                }}
                                             >
                                                <StyledClientPrintIcon />
                                             </IconButton>
                                          </Tooltip>
                                       )}
                                       <Tooltip title={t('ORDERS_HISTORY:fullPrint') || ''}>
                                          <IconButton
                                             onClick={(e) => {
                                                handlePrint(row, e, 'fullPrint')
                                             }}
                                          >
                                             <StyledFullPrintIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </Box>
                                 ),
                              },
                              {
                                 name: '',
                                 onlyOnMobile: true,
                                 renderCell: (row: Order) => (
                                    <ContainedButton
                                       variant="contained"
                                       onClick={() => handleOpenDetails(row.orderUuids)}
                                    >
                                       {t('TRANSLATION:showProductsButton')}
                                    </ContainedButton>
                                 ),
                              },
                           ]}
                        />
                     </Card>
                  )}
               </div>
               {!isMobile && data && data.orders.length > 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, width: '100%' }}>
                     {generateCSV()}
                  </Box>
               )}
               {!!openDetails && (
                  <OrderDetails
                     orderUuids={openDetails}
                     open={!!openDetails}
                     title={getTitle()}
                     onClose={() => handleOpenDetails(undefined)}
                     priceType={activeLocationSettings?.markupSettings.priceType}
                     details={savedOrderDetails}
                     formattedGrossValue={
                        data?.orders.find((item) => item.orderUuids === openDetails)?.formattedGrossValue
                     }
                     formattedNetValue={data?.orders.find((item) => item.orderUuids === openDetails)?.formattedNetValue}
                     formattedServicesValue={
                        data?.orders.find((item) => item.orderUuids === openDetails)?.formattedAdditionalServicesValue
                     }
                     netDeliveryCost={data?.orders.find((item) => item.orderUuids === openDetails)?.netDeliveryCost}
                     grossDeliveryCost={data?.orders.find((item) => item.orderUuids === openDetails)?.grossDeliveryCost}
                     updateDetailsStore={updateDetailsStore}
                     orderType={data?.orders.find((item) => item.orderUuids === openDetails)?.orderType}
                  />
               )}
               {!!printDetails && (
                  <OrderPrint
                     orderUuids={printDetails}
                     open={!!printDetails}
                     cartDescription={
                        data?.orders.find((product) => product.orderUuids === printDetails)?.cartDescription
                     }
                     printType={printType}
                     netDeliveryCost={data?.orders.find((item) => item.orderUuids === printDetails)?.netDeliveryCost}
                     grossDeliveryCost={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.grossDeliveryCost
                     }
                     formattedAdditionalServicesValue={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.formattedAdditionalServicesValue
                     }
                     formattedClientValueWithServices={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.formattedClientValueWithServices
                     }
                     formattedClientValue={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.formattedClientValue
                     }
                     formattedValue={data?.orders.find((item) => item.orderUuids === printDetails)?.formattedValue}
                     title={getTitle()}
                     onClose={() => handleOpenPrint(undefined)}
                     details={savedOrderDetails}
                     updateDetailsStore={updateDetailsStore}
                     orderType={data?.orders.find((item) => item.orderUuids === printDetails)?.orderType}
                  />
               )}
            </>
         ) : (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   )
}

export default OrdersHistory
