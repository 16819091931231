import React, { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { BuySubscriptionPayload, PricesResultType, SubscriptionDurationType } from '../../types'
import buySubscriptionService from '../../services/buySubscriptionService'
import { RootStyle } from '../../styles'

interface Props {
   card: {
      subscription: string
      labelAction: string
      lastPrice: string
      lists: {
         text: string
      }[]
   }
   rebate: number
   prices: PricesResultType
   durationTime: SubscriptionDurationType
   coupon?: string
}

export default function PricingPlanCard({ prices, card, coupon, rebate, durationTime }: Props) {
   const { subscription, lists, labelAction } = card
   const [isLoading, setIsLoading] = useState(false)
   const { addNotification } = useNotificationContext()
   const { t } = useTranslation('PRICING')
   const theme = useTheme()

   function handleBuySubscription(subscriptionType: string) {
      setIsLoading(true)
      const payload: BuySubscriptionPayload = {
         subscriptionType,
         subscriptionDuration: durationTime,
         couponCode: coupon,
      }
      buySubscriptionService(payload)
         .then((res) => {
            window.location.href = res.redirectUrl
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('apiErrors.500', 'error')
            }
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   function getPriceIndex() {
      switch (durationTime) {
         case 'YEAR':
            return 1
         case 'TWO_YEARS':
            return 2
         default:
            return 0
      }
   }

   function returnPriceBeforeDurationTimeRebate() {
      switch (subscription) {
         case 'BASIC': {
            return prices.pricesForBasic[0].toFixed(2)
         }
         case 'PRO': {
            return prices.pricesForPro[0].toFixed(2)
         }
         case 'ULTRA': {
            return prices.pricesForUltra[0].toFixed(2)
         }
         default:
            return null
      }
   }

   function returnPrice() {
      if (prices) {
         switch (subscription) {
            case 'BASIC':
               return (prices.pricesForBasic[getPriceIndex()] * rebate).toFixed(2)
            case 'PRO':
               return (prices.pricesForPro[getPriceIndex()] * rebate).toFixed(2)
            case 'ULTRA':
               return (prices.pricesForUltra[getPriceIndex()] * rebate).toFixed(2)
            default:
               return 0
         }
      }
      return 0
   }

   function returnMonthlyLabel() {
      if (prices) {
         switch (durationTime) {
            case 'YEAR':
               return t('monthlyLabelYear')
            case 'TWO_YEARS':
               return t('monthlyLabelTwoYears')
            default:
               return null
         }
      }
      return null
   }

   return (
      <RootStyle>
         <Typography variant="h3">{subscription}</Typography>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 1 }}>
               <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: 'line-through', color: 'text.secondary' }}
               >{`${returnPriceBeforeDurationTimeRebate()} ${t('currency')}`}</Typography>
               <Box
                  sx={{
                     display: 'flex',
                     padding: '3px 6px',
                     backgroundColor: theme.palette.primary.light,
                     borderRadius: '6px',
                     color: 'white',
                  }}
               >
                  <Typography
                     variant="h2"
                     sx={{
                        mx: 1,
                     }}
                  >
                     {returnPrice() || '-'}
                  </Typography>
                  <Typography
                     gutterBottom
                     component="span"
                     variant="subtitle1"
                     sx={{
                        alignSelf: 'flex-end',
                        fontWeight: 'bold',
                        mb: 1,
                     }}
                  >
                     {t('currency')}
                  </Typography>
               </Box>
            </Box>
         </Box>

         <Typography
            variant="body2"
            sx={{
               color: 'primary.dark',
               textTransform: 'capitalize',
            }}
         >
            {returnMonthlyLabel()}
         </Typography>

         <Stack component="ul" spacing={2} sx={{ my: 3, height: '330px' }}>
            {lists.map((item) => (
               <Stack key={item.text} component="li" direction="row" spacing={1.5} sx={{ typography: 'body2' }}>
                  <Typography variant="body1">{t(`${item.text}`)}</Typography>
               </Stack>
            ))}
         </Stack>
         <ContainedButton
            onClick={() => handleBuySubscription(subscription)}
            disabled={isLoading}
            sx={{
               display: 'flex',
               justifyContent: 'space-between',
               mt: 'auto',
               width: '45%',
            }}
            variant="contained"
         >
            {t(`${labelAction}`)}
            <ArrowForwardIosIcon sx={{ fontSize: 20 }} />
         </ContainedButton>
      </RootStyle>
   )
}
