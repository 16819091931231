import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import Drawer from '@mui/material/Drawer'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import routes from 'config/routes'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { useProfileQuery } from 'shared/store/useProfile'
import { useEffect } from 'react'

// const openedMixin = (theme: Theme): CSSObject => ({
//    width: theme.sizes.sidebarWidth,
//    transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//    }),
//    overflowX: 'hidden',
// })

// const Drawer = styled(MuiDrawer, {
//    shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//    width: theme.sizes.sidebarWidth,
//    flexShrink: 0,
//    whiteSpace: 'nowrap',
//    boxSizing: 'border-box',
//    ...(open && {
//       ...openedMixin(theme),
//       '& .MuiDrawer-paper': openedMixin(theme),
//    }),
// }))

const MENU_GROUPS = [
   {
      name: 'cart',
      label: 'asideLabels.cart',
   },
   // {
   //    name: 'shopping',
   //    label: 'asideLabels.shopping',
   // },
   {
      name: 'settings',
      label: 'asideLabels.settings',
   },
]

interface Props {
   open: boolean
   handleClose: () => void
}

export default function MobileSidebar({ handleClose, open }: Props) {
   const { t } = useTranslation('AUTHORIZED')
   const navigate = useNavigate()
   const location = useLocation()
   const { data: userData } = useProfileQuery()
   const { user } = useLogin()
   const theme = useTheme()

   // useEffect to rerender function when active location changes
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   useEffect(() => {}, [userData?.locationUuid])

   useEffect(() => {
      handleClose()
   }, [location.pathname])

   return (
      <Box
         component="nav"
         sx={{
            width: 0,
            flexShrink: 0,
         }}
      >
         <Drawer
            onClose={handleClose}
            variant="temporary"
            open={open}
            sx={{
               '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: theme.sizes.sidebarWidth,
                  // px: '16px',
                  py: '24px',
               },
            }}
         >
            {MENU_GROUPS.map((group) => (
               <React.Fragment key={group.name}>
                  <Typography
                     variant="body1"
                     sx={{
                        color: theme.palette.primary.dark,
                        transition: '300ms',
                        opacity: `${!open ? '0' : '1'}`,
                        ml: 1,
                        fontSize: 15,
                        fontWeight: 'bold',
                     }}
                  >
                     {t(group.label)}
                  </Typography>

                  <Divider />

                  <List>
                     {routes
                        .filter(
                           (route) =>
                              route.group === group.name &&
                              route.inSidebar &&
                              route.authority.some((item) => user.roles.includes(item)) &&
                              !(route.needSub && !user.roles.includes(UserRoles.SUBSCRIPTION))
                        )
                        .map((route) => {
                           const isActive = location.pathname.includes(route.path)
                           return (
                              <ListItem
                                 key={route.name}
                                 onClick={() => navigate({ pathname: route.path })}
                                 sx={{
                                    maxHeight: 35,
                                    color: isActive ? theme.palette.primary.dark : null,
                                    borderRight: isActive ? '3px solid ' : null,
                                    borderRightColor: theme.palette.primary.dark,
                                    '&:hover': {
                                       cursor: 'pointer',
                                       color: 'white',
                                       backgroundColor: theme.palette.primary.light,
                                    },
                                 }}
                              >
                                 <ListItemIcon
                                    sx={{
                                       marginRight: open ? -3 : 0,
                                       transition: '400ms',
                                    }}
                                 >
                                    {route.icon}
                                 </ListItemIcon>
                                 <ListItemText primary={t(route.name)} />
                              </ListItem>
                           )
                        })}
                  </List>
               </React.Fragment>
            ))}
         </Drawer>
      </Box>
   )
}
