import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import React from 'react'

import { StyledContentTableCell, StyledTableRow } from 'shared/components/StyledTableComponents'
import { StyledTableHeadCell } from 'views/Authorized/views/ErpProductCard/styles'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import CustomDialog from 'shared/components/CustomDialog'

import { Product } from '../../types'

interface Props {
   scoreComponents: PropType<Product, 'scoreComponents'>
   handleClose: () => void
}
export default function ScoreDetailsModal({ scoreComponents, handleClose }: Props) {
   return (
      <CustomDialog
         isLoading={!scoreComponents}
         maxWidth="md"
         onClose={handleClose}
         open={!!scoreComponents}
         content={
            <>
               <DialogTitle color="primary"> Szczegóły dotyczące score</DialogTitle>
               <DialogContent>
                  <Table>
                     <TableHead>
                        <TableRow sx={{ backgroundColor: 'rgba(33,147,224,0.1)' }}>
                           <StyledTableHeadCell>Typ</StyledTableHeadCell>
                           <StyledTableHeadCell>Score</StyledTableHeadCell>
                           <StyledTableHeadCell>Wartośc</StyledTableHeadCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {scoreComponents?.map((item) => (
                           <StyledTableRow key={item.type}>
                              <StyledContentTableCell sx={{ fontWeight: 'bold' }}>{item.type}</StyledContentTableCell>
                              <StyledContentTableCell>{item.score}</StyledContentTableCell>
                              <StyledContentTableCell>{item.value}</StyledContentTableCell>
                           </StyledTableRow>
                        ))}
                     </TableBody>
                  </Table>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                     <Typography variant="h6" color="primary">
                        Bazowe (w każdym wyszukaniu):
                     </Typography>
                     <span>
                        <b>BASIC_OE_PRODUCT</b> - true jeśli producent produktu jest producentem OE
                     </span>
                     <span>
                        <b>BASIC_WHOLESALE_COUNT</b> - liczba hurtowni podpiętych do produktu
                     </span>
                     <span>
                        <b>BASIC_AVAILABILITY</b> - aktualna dostępność produktu liczona w statystykach.
                     </span>
                     <span>
                        <b>BASIC_SEARCH_COUNT</b> - liczba wejść na kartę produktu z ostatnich 30 dni
                     </span>
                     <span>
                        <b>BASIC_ORDER_COUNT</b> - liczba zamówień z ostatnich 30 dni, zawierających produkt
                     </span>
                     <span>
                        <b>BASIC_PRODUCER</b> - producent produktu, wartość to uuid producenta, punkty będą dodatnie
                        jeśli dany producent ma boosta
                     </span>
                     <Typography variant="h6" color="primary">
                        Główna wyszukiwarka (tyczy się też wtyczki i każdego miejsca, które z niej korzysta):
                     </Typography>
                     <span>
                        <b>FO</b> - fraza oryginalna wpisana w wyszukiwarkę
                     </span>
                     <span>
                        <b>FS - FO</b> sformatowana bez znaków specjalnych
                     </span>
                     <span>
                        <b>MAIN_INDEX</b> - informacja o dopasowaniu indeksu produktu do FO
                     </span>
                     <span>są 4 opcje:</span>
                     <span>
                        <b>EXACT</b> (indeks 1:1 z frazą),
                     </span>
                     <span>
                        <b>STARTS_WITH</b> (indeks zaczyna się od frazy)
                     </span>
                     <span>
                        <b>CONTAINS</b> (indeks zawiera w sobie frazę, ale się od niej nie zaczyna)
                     </span>
                     <span>
                        <b>NO_MATCH</b> (indeks zupełnie nie zawiera w sobie frazy)
                     </span>
                     <span>
                        <b>MAIN_INDEX_WITHOUT_SPECIAL_CHARS</b> - identyczna informacja jak wyżej jednak zestawiająca
                        indeks bez znaków specjalnych vs FS
                     </span>
                     <span>
                        <b>MAIN_ALL_PRODUCT_INDEXES</b> - informacja czy FS znajduje się w polu allProductIndexes lub
                        searchIndexes - czyli czy mamy z dowolnego źródła mapowanie FS na ten produkt
                     </span>
                     <span>
                        <b>MAIN_EAN</b> - czy FS jest eanem produktu MAIN_OEM - czy FS jest oemem produktu bez znaków
                        specjalnych
                     </span>
                     <span>
                        <b>MAIN_TEC_NUM</b> - czy FS jest numerem tec doc produktu (rzadko mamy tę informację)
                     </span>
                     <span>
                        <b>MAIN_WHOLESALE_INDEX_MATCH_COUNT</b> - liczba hurtowni, które mają FS jako swój indeks bez
                        znaków specjalnych
                     </span>
                     <Typography variant="h6" color="primary">
                        Zamienniki:
                     </Typography>
                     <span>
                        <b>REPLACEMENTS_OEMS_MATCH_COUNT</b> - liczba wspólnych oemów między głównym produktem a
                        zamiennikiem
                     </span>
                     <span>
                        <b>REPLACEMENTS_DEPTH</b> - poziom głębokości zamiennika, począwszy od 1
                     </span>
                     <Typography variant="h6" color="primary">
                        Zamienniki oraz wyszukiwanie po uuid (to, z którego korzysta Łukasz ze zgłoszeń):
                     </Typography>
                     <span>
                        <b>MAIN_PRODUCT</b> - true, jeśli produkt jest produktem głównym. Domyślnie dodaje to do wyniku
                        10000 żeby produkt był pierwszym wynikiem
                     </span>
                  </Box>
               </DialogContent>
               <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ContainedButton variant="outlined" onClick={handleClose}>
                     Zamknij
                  </ContainedButton>
               </DialogActions>
            </>
         }
      />
   )
}
