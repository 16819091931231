import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import React, { useState } from 'react'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import CustomDialog from 'shared/components/CustomDialog'
import { pythonHttpClient } from 'shared/lib'

interface Props {
   productUuid: string
   handleClose: () => void
}
export default function DeleteModal({ productUuid, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [isDeleting, setIsDeleting] = useState<boolean>(false)

   function deleteProduct() {
      setIsDeleting(true)
      if (productUuid) {
         pythonHttpClient
            .delete(`products/${productUuid}`)
            .then((res) => {
               if (res.status === 204) {
                  addNotification('Usunięto pomyślnie', 'success')
                  handleClose()
               } else {
                  addNotification('Nie udało się usunąć produktu', 'error')
               }
            })
            .catch((error) => {
               if (error) {
                  if (error.response.status === 404) {
                     addNotification('Nie znaleziono produktu', 'error')
                  } else {
                     addNotification('Nie udało się usunąć produktu', 'error')
                  }
               }
            })
            .finally(() => setIsDeleting(false))
      }
   }

   return (
      <CustomDialog
         isLoading={!productUuid}
         onClose={handleClose}
         open={!!productUuid}
         content={
            <>
               <DialogTitle color="primary"> Czy na pewno chcesz usunąć ten produkt?</DialogTitle>
               <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <OutlinedButton variant="outlined" onClick={handleClose}>
                     Anuluj
                  </OutlinedButton>
                  <ContainedButton disabled={isDeleting} variant="contained" onClick={deleteProduct}>
                     Zapisz
                  </ContainedButton>
               </DialogActions>
            </>
         }
      />
   )
}
