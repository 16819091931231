import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import Hidden from 'shared/components/Hidden'
import BannersSlider from 'shared/components/BannersSlider'
import logo from 'assets/images/logo-small.png'

import RemindPasswordForm from './components/RemindPasswordForm'
import LoginForm from './components/LoginForm'
import ProducersSlider from './components/ProducersSlider'
import { en, pl, de } from './locales'
import { ContentStyle, StyledLink } from './styles'

export default function Homepage() {
   const [remind, setRemind] = useState(false)
   const { t, i18n } = useTranslation('HOMEPAGE')
   i18n.addResourceBundle('pl-PL', 'HOMEPAGE', pl)
   i18n.addResourceBundle('en-EN', 'HOMEPAGE', en)
   i18n.addResourceBundle('de-DE', 'HOMEPAGE', de)

   function renderFormContent() {
      return remind ? (
         <div>
            <RemindPasswordForm />
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
               {t('HOMEPAGE:remindForm.haveAccount')}
               <StyledLink onClick={() => setRemind(!remind)}>{t('HOMEPAGE:remindForm.login')}</StyledLink>
            </Typography>
         </div>
      ) : (
         <div>
            <LoginForm />
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
               {t('HOMEPAGE:loginForm.forgotPassword')}
               <StyledLink onClick={() => setRemind(!remind)}>{t('HOMEPAGE:remindForm.remind')}</StyledLink>
            </Typography>
         </div>
      )
   }

   return (
      <>
         <Box>
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} maxWidth="xl">
               <Hidden width="lgDown">
                  <Box height={350} width={{ md: '860px' }}>
                     <BannersSlider variant="LOGIN" />
                  </Box>
               </Hidden>
               <ContentStyle>
                  <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                     <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h4">
                           {remind ? t('remindForm.remindPassword') : t('signIn.title')}
                        </Typography>
                        {!remind && <Typography sx={{ color: 'text.secondary' }}>{t('signIn.subTitle')}</Typography>}
                     </Box>
                     <Box component="img" src={logo} sx={{ width: 'auto', height: { lg: 32, md: 32, xs: 24 } }} />
                  </Stack>
                  {renderFormContent()}
               </ContentStyle>
            </Container>
         </Box>
         <ProducersSlider />
      </>
   )
}
