import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import { TableFooter } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import CustomTable from 'shared/components/CustomTable'

import ImageComponent from 'shared/components/ImageComponent'
import { QuantityHolder, StyledQuantityButton } from '../../styles'
import { AdditionalService, Product } from '../../types'
import { getProductName } from '../../helpers'

interface Props {
   products: Product[]
   services: AdditionalService[]
   formattedAdditionalServicesGrossValue: string
   formattedAdditionalServicesNetValue: string
   formattedClientGrossValueWithAdditionalServices: string
   formattedClientNetValueWithAdditionalServices: string
   formattedGrossValue: string
   formattedNetValue: string
   handleIncludeInOrder: (cartProductUuid: string, includeProductInOrder: boolean) => void
   handleChangeQuantity: (quantity: number, uuid: string) => void
   disableButtons: boolean
}

export default function DesktopTable({
   products,
   handleChangeQuantity,
   handleIncludeInOrder,
   disableButtons,
   formattedGrossValue,
   formattedNetValue,
   services,
   formattedAdditionalServicesGrossValue,
   formattedAdditionalServicesNetValue,
   formattedClientGrossValueWithAdditionalServices,
   formattedClientNetValueWithAdditionalServices,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])
   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
         <Typography variant="body1" color="grayText" sx={{ mt: '8px' }}>
            {t('ORDER_REFLINK:table.availableProducts')}
         </Typography>
         <CustomTable
            rows={products}
            dataSize={products.length}
            disablePagination
            customFooter={(columnsCount) => (
               <TableFooter sx={{ backgroundColor: '#EBEBEB' }}>
                  <TableRow>
                     <StyledTableCell colSpan={columnsCount}>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: '32px',
                              pr: { lg: '32px', md: '32px' },
                              width: '100%',
                              justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                           }}
                        >
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:columnNames.netSum')}</p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{formattedNetValue}</p>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                 {t('TRANSLATION:columnNames.grossSum')}
                              </p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{formattedGrossValue}</p>
                           </Box>
                        </Box>
                     </StyledTableCell>
                  </TableRow>
               </TableFooter>
            )}
            columns={[
               {
                  name: 'productName',
                  mainOnMobile: true,
                  renderCell: (row: Product) => <Box title={row.cartProductName}>{getProductName(row)}</Box>,
               },
               {
                  name: 'producer',
                  mainOnMobile: true,
                  renderCell: (row: Product) => (
                     <ImageComponent
                        filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                        producerName={row.producerName}
                     />
                  ),
               },
               {
                  name: 'quantity',
                  renderCell: (row: Product) => (
                     <QuantityHolder>
                        <StyledQuantityButton
                           disabled={row.cartWholesaleDTO.quantity === 0 || disableButtons}
                           onClick={() =>
                              handleChangeQuantity(row.cartWholesaleDTO.quantity - row.quantityInSet, row.uuid)
                           }
                        >
                           -
                        </StyledQuantityButton>
                        <StyledQuantityButton type="button" style={{ fontWeight: 'normal' }}>
                           {row.cartWholesaleDTO.quantity}
                        </StyledQuantityButton>
                        <StyledQuantityButton
                           disabled={
                              row.cartWholesaleDTO.quantity === row.cartWholesaleDTO.initialQuantity || disableButtons
                           }
                           onClick={() =>
                              handleChangeQuantity(row.cartWholesaleDTO.quantity + row.quantityInSet, row.uuid)
                           }
                        >
                           +
                        </StyledQuantityButton>
                     </QuantityHolder>
                  ),
               },
               {
                  name: 'netPrice',
                  renderCell: (row: Product) => row.cartWholesaleDTO.formattedClientNetPrice,
               },
               {
                  name: 'grossPrice',
                  renderCell: (row: Product) => row.cartWholesaleDTO.formattedClientGrossPrice,
               },
               {
                  name: 'VAT',
                  renderCell: (row: Product) => `${row.cartWholesaleDTO.taxRate}%`,
               },
               {
                  name: 'grossValue',
                  mainOnMobile: true,
                  renderCell: (row: Product) => row.cartWholesaleDTO.formattedClientGrossValue,
               },
               {
                  name: 'includeInOrder',
                  renderCell: (row: Product) => (
                     <Checkbox
                        disabled={disableButtons || row.cartWholesaleDTO.quantity === 0}
                        color="primary"
                        onClick={() => handleIncludeInOrder(row.uuid, !row.includedProductInOrder)}
                        checked={row.includedProductInOrder}
                     />
                  ),
               },
            ]}
         />
         {services.length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
               <Typography variant="body1" color="grayText" sx={{ mt: '8px' }}>
                  {t('ORDER_REFLINK:table.services')}
               </Typography>
               <CustomTable
                  rows={services}
                  dataSize={services.length}
                  disablePagination
                  customFooter={(columnsCount) => (
                     <TableFooter sx={{ backgroundColor: '#EBEBEB' }}>
                        <TableRow>
                           <StyledTableCell colSpan={columnsCount}>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    gap: '32px',
                                    pr: { lg: '32px', md: '32px' },
                                    width: '100%',
                                    justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                                 }}
                              >
                                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.netSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {formattedAdditionalServicesNetValue}
                                    </p>
                                 </Box>
                                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.grossSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {formattedAdditionalServicesGrossValue}
                                    </p>
                                 </Box>
                              </Box>
                           </StyledTableCell>
                        </TableRow>
                     </TableFooter>
                  )}
                  columns={[
                     {
                        name: 'serviceName',
                        mainOnMobile: true,
                        renderCell: (row: AdditionalService) => row.name,
                     },
                     {
                        name: 'quantity',
                        mainOnMobile: true,
                        renderCell: (row: AdditionalService) => row.quantity,
                     },
                     {
                        name: 'netPrice',
                        renderCell: (row: AdditionalService) => row.formattedNetPrice,
                     },
                     {
                        name: 'grossPrice',
                        renderCell: (row: AdditionalService) => row.formattedGrossPrice,
                     },
                     {
                        name: 'VAT',
                        renderCell: (row: AdditionalService) => `${row.taxRate}%`,
                     },
                     {
                        name: 'grossValue',
                        mainOnMobile: true,
                        renderCell: (row: AdditionalService) => row.formattedGrossValue,
                     },
                  ]}
               />
            </Box>
         )}
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 3, mt: '8px' }}>
            <Typography variant="h5">{`${t('TRANSLATION:columnNames.netSum')}: ${formattedClientNetValueWithAdditionalServices}`}</Typography>
            <Typography variant="h5">{`${t('TRANSLATION:columnNames.grossSum')}: ${formattedClientGrossValueWithAdditionalServices}`}</Typography>
         </Box>
      </Box>
   )
}
