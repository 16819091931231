export const FullPrintTableHeaders: string[] = [
   'productName',
   'partIndex',
   'producer',
   'quantity',
   'wholesaler',
   'price',
   'clientPrice',
   'value',
   'clientValue',
]

export const ClientPrintTableHeaders: string[] = ['productName', 'producer', 'quantity', 'price', 'value']
