import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

import { AskCooperationPayload, WholesalerType } from 'shared/store/useWholesalers/types'
import { useAskForCoopMutation } from 'shared/store/useWholesalers'

import { WholesalerActionType } from '../../types'
import { StyledCard, StyledImg } from './styles'

type Props = {
   activeWholesaler: WholesalerType
   activeLocationUuid?: string
   handleWholesaler: (wholesalerId: string, type: WholesalerActionType) => void
}
export default function NonCoopWholesalerCard({ activeWholesaler, handleWholesaler, activeLocationUuid }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const { mutate } = useAskForCoopMutation()
   const [handlingAction, setHandlingAction] = useState(false)

   function handleAskForCooperation(wholesaleUuid: string) {
      if (activeLocationUuid) {
         setHandlingAction(true)
         const payload: AskCooperationPayload = {
            wholesaleUuid,
         }
         if (!handlingAction) {
            mutate(
               { locationUuid: activeLocationUuid, payload },
               {
                  onSettled: () => setHandlingAction(false),
               }
            )
         }
      }
   }

   return (
      <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
         <Tooltip
            title={
               activeWholesaler.cooperationType === 'COOPERATION_EMAIL'
                  ? t('WHOLESALERS:sendingMailTooltip') || ''
                  : t('WHOLESALERS:addingCoopTooltip') || ''
            }
            onClick={
               activeWholesaler.cooperationType === 'COOPERATION_EMAIL'
                  ? () => handleAskForCooperation(activeWholesaler.wholesaleUuid)
                  : () => handleWholesaler(activeWholesaler.wholesaleUuid, 'addWholesaler')
            }
         >
            <StyledCard>
               <StyledImg
                  src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${activeWholesaler.wholesaleName}.png`}
                  alt={activeWholesaler.wholesaleName}
               />
            </StyledCard>
         </Tooltip>
      </Grid>
   )
}
