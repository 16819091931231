import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/material'

interface Props {
   value: Nullable<number>
   row: CooperatingWholesaler
   departmentName: string
}

export default function DepartmentAvailability({ value, row, departmentName }: Props) {
   const { wholesaleInfiniteAvailabilityThreshold: availabilityThreshold } = row

   function getValue() {
      if (value) {
         if (value > availabilityThreshold) {
            return `> ${availabilityThreshold}`
         }
         if (value > 0) {
            return value
         }
         if (value < 0) {
            return 0
         }
         return 0
      }
      return 0
   }

   function getFormattedValue() {
      if (departmentName !== '') {
         return (
            <Tooltip title={departmentName}>
               <div>{getValue()}</div>
            </Tooltip>
         )
      }
      return <div>-</div>
   }

   return <Box>{getFormattedValue()}</Box>
}
