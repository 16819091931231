import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import React from 'react'

import MuiPhone from 'shared/components/PhoneInput'
import InputField from 'shared/components/InputField'

interface Props {
   handlePhoneNumber: (value: string, country: string) => void
}

export function FirstStep({ handlePhoneNumber }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const { values } = useFormikContext<RegisterInitialValues>()

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { lg: '24px', md: '24px', xs: '12px' } }}>
         <InputField label={t('formFields.name')} name="firstName" />
         <InputField label={t('formFields.surname')} name="lastName" />
         <InputField label={t('formFields.email')} name="email" />
         <MuiPhone
            variant="outlined"
            value={values.telephoneNumber}
            onChange={(value, country) => handlePhoneNumber(value, country)}
         />
      </Box>
   )
}
