import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import Close from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import React from 'react'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { Product } from 'shared/store/useCartSummary/types'
import { useSettingsQuery } from 'shared/store/useSettings'
import CustomTable from 'shared/components/CustomTable'

import CopyToClipboard from 'react-copy-to-clipboard'
import ImageComponent from 'shared/components/ImageComponent'
import { ModalInfoType } from '../../types'
import { StyledCopyIcon } from '../../styles'

interface Props {
   products: Product[]
   setModalInfo: (modalInfo: ModalInfoType) => void
}

export default function UnavailableProductTable({ products, setModalInfo }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { data: settings } = useSettingsQuery()

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px' }}>
         <Box sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ color: 'red' }}>
               {t('MAIN_CART:unavailableProducts')}
            </Typography>
         </Box>
         <CustomTable
            rows={products}
            disablePagination
            dataSize={products.length}
            columns={[
               {
                  name: 'partIndex',
                  renderCell: (row: Product) => (
                     <Tooltip
                        disableHoverListener={settings?.userSettings.preferences.showProductNamesInCart === 'true'}
                        title={row.cartProductName}
                     >
                        <>
                           <span>{row.index}</span>
                           {row.requestedIndex && row.requestedIndex.toLowerCase() !== row.index.toLowerCase() && (
                              <span style={{ color: 'grayText', fontSize: 10 }}>{` (${row.requestedIndex})`}</span>
                           )}
                           <CopyToClipboard text={row.index}>
                              <StyledCopyIcon
                                 onClick={(e) => {
                                    e.stopPropagation()
                                 }}
                              />
                           </CopyToClipboard>
                        </>
                     </Tooltip>
                  ),
                  mainOnMobile: true,
               },
               {
                  name: 'productName',
                  hidden: settings?.userSettings.preferences.showProductNamesInCart === 'false',
                  renderCell: (row: Product) =>
                     row.cartProductName
                        ? `${row.cartProductName.split(' ')[0]} ${row.cartProductName.split(' ')[1]}`
                        : row.index,
               },
               {
                  name: 'producer',
                  renderCell: (row: Product) => (
                     <ImageComponent
                        filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                        producerName={row.producerName}
                     />
                  ),
                  mainOnMobile: true,
               },
               {
                  name: 'wholesaleName',
                  renderCell: (row: Product) => (
                     <Box sx={{ display: 'flex', gap: 1 }}>
                        <HiddenWholesalerLogo wholesalerName={row.cartWholesaleName} imgHeight="20px" imgWidth="auto" />
                        {row.requirePrepayment && (
                           <Tooltip title={t('TRANSLATION:requirePrepayment')}>
                              <AttachMoneyIcon color="success" />
                           </Tooltip>
                        )}
                     </Box>
                  ),
                  mainOnMobile: true,
               },
               {
                  name: 'quantity',
                  renderCell: (row: Product) => row.cartWholesaleDTO.quantity,
               },
               {
                  name: 'netPrice',
                  renderCell: (row: Product) => row.cartWholesaleDTO.formattedClientNetPrice,
               },
               {
                  name: 'VAT',
                  renderCell: (row: Product) => `${row.cartWholesaleDTO.taxRate}%`,
               },
               {
                  name: 'grossValue',
                  renderCell: (row: Product) => row.cartWholesaleDTO.formattedClientGrossValue,
               },
               {
                  name: 'deleteProduct',
                  renderCell: (row: Product) => (
                     <IconButton
                        sx={{
                           '&:hover': {
                              '>*': {
                                 color: 'red',
                              },
                           },
                        }}
                        onClick={() =>
                           setModalInfo({
                              modalType: 'DELETE',
                              uuid: row.uuid,
                              removeFromUnavailable: true,
                              index: row.index,
                           })
                        }
                     >
                        <Close />
                     </IconButton>
                  ),
               },
               {
                  name: 'swapProduct',
                  renderCell: (row: Product) => (
                     <IconButton
                        sx={(theme) => ({
                           '&:hover': {
                              '>*': {
                                 color: theme.palette.primary.main,
                              },
                           },
                        })}
                        onClick={() =>
                           setModalInfo({
                              modalType: 'SWAP',
                              defaultQuantity: row.cartWholesaleDTO.quantity,
                              uuid: row.productEntityUuid,
                              productToRemoveUuid: row.uuid,
                              removeFromUnavailable: true,
                           })
                        }
                     >
                        <SwapHorizIcon />
                     </IconButton>
                  ),
               },
            ]}
         />
      </Box>
   )
}
