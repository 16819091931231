import React from 'react'

import getFormattedDeliveryTime from './getFormattedDeliveryTime'
import { GreenHour, RedHour, YellowHour } from '../styles'

export default function returnMinimumDeliveryTableCell(minutesToDeliveryStart: number) {
   switch (true) {
      case minutesToDeliveryStart === -1:
         return null
      case minutesToDeliveryStart < 5:
         return <RedHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</RedHour>
      case minutesToDeliveryStart > 60:
         return <GreenHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</GreenHour>
      default:
         return <YellowHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</YellowHour>
   }
}
