import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useFormik, FormikProvider, Form } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import HelpIcon from '@mui/icons-material/Help'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import useOilParamsQuery from 'shared/store/useOilParams'
import CustomDialog from 'shared/components/CustomDialog'
import SelectField from 'shared/components/SelectField'

import { OilPayload, OilSearchParamsType } from '../types'
import schema from '../schema'

interface Props {
   open: boolean
   handleClose: () => void
}

export default function OilModal({ open, handleClose }: Props) {
   const [oilParams, setOilParams] = useState<OilSearchParamsType>({
      producer: [],
      sae: [],
      capacity: [],
      standard: [],
   })
   const { data } = useOilParamsQuery()
   const navigate = useNavigate()
   const { t } = useTranslation('TRANSLATION')
   const theme = useTheme()
   const formik = useFormik<OilPayload>({
      initialValues: {
         producer: '',
         sae: '',
         capacity: '',
         standard: '',
      },
      validationSchema: schema,
      onSubmit: handleOnSearch,
   })

   function handleOnSearch() {
      navigate({
         pathname: '/dashboard/search',
         search: `?${createSearchParams({
            searchType: 'OIL',
            producer: values.producer,
            producerName: data!.producers.find((producer) => values.producer === producer.uuid)?.name || '',
            sae: values.sae,
            capacity: values.capacity,
            standard: values.standard,
         })}`,
      })
      handleClose()
   }

   const { values } = formik

   useEffect(() => {
      if (data) {
         setOilParams({
            producer: [...data.producers],
            sae: [''].concat(data.sae),
            capacity: [''].concat(data.capacities),
            standard: [''].concat(data.standards),
         })
      }
   }, [data])

   return (
      <CustomDialog
         isLoading={!open}
         maxWidth="xl"
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle color="primary">
                  {t('components.oilModal.oilSearcher')}
                  <Tooltip title={t('components.oilModal.oilTooltip') || ''} placement="top">
                     <HelpIcon
                        sx={{
                           fontSize: 15,
                           ml: 0.5,
                           '&:hover': { color: theme.palette.primary.dark, cursor: 'pointer' },
                        }}
                     />
                  </Tooltip>
               </DialogTitle>
               <DialogContent>
                  <FormikProvider value={formik}>
                     <Form style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' },
                              gap: { md: 3, xs: 1 },
                           }}
                        >
                           <SelectField
                              searcher
                              sx={{ width: 100 }}
                              name="producer"
                              label={t('components.oilModal.producer')}
                              options={[
                                 { name: '', value: '' },
                                 ...oilParams.producer.map((producer) => ({
                                    name: producer.name,
                                    value: producer.uuid,
                                 })),
                              ]}
                           />
                           <SelectField
                              searcher
                              sx={{ width: 100 }}
                              name="sae"
                              label="SAE"
                              options={oilParams.sae.map((item) => ({ name: item, value: item }))}
                           />
                           <SelectField
                              searcher
                              sx={{ width: 100 }}
                              name="capacity"
                              label={t('components.oilModal.capacity')}
                              options={oilParams.capacity.map((item) => ({ name: item, value: item }))}
                           />
                           <SelectField
                              searcher
                              sx={{ width: 100 }}
                              name="standard"
                              label="Standard"
                              options={oilParams.standard.map((item) => ({ name: item, value: item }))}
                           />
                        </Box>
                        <ContainedButton
                           type="submit"
                           disabled={Object.values(values).filter((value) => value).length < 2}
                           variant="contained"
                           sx={{ alignSelf: 'center', mt: 2 }}
                        >
                           {t('search')}
                        </ContainedButton>
                     </Form>
                  </FormikProvider>
               </DialogContent>
            </>
         }
      />
   )
}
