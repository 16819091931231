import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'

export default function Informations() {
   const theme = useTheme()
   const { t } = useTranslation('UNAUTHORIZED')

   return (
      <Box sx={{ textAlign: 'center' }}>
         <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
            <InfoIcon sx={{ color: theme.palette.primary.main, mt: 0.5 }} />
            <Typography variant="h4">{t('demo.informations')}</Typography>
         </Box>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               gap: 2,
               mt: 2,
               paddingX: { md: 7, xs: 2 },
               py: { xs: 2 },
               textAlign: 'justify',
            }}
         >
            <Typography variant="h6">{t('demo.demoRegisterTooltip')}</Typography>
            <Typography variant="h6">{t('demo.demoWarning1')}</Typography>
            <Typography variant="h6">{t('demo.demoWarning2')}</Typography>
         </Box>
      </Box>
   )
}
