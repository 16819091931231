import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

import { fetchNotCooperatingWholesalersData } from 'shared/services/fetchNotCooperationWholesalers'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import SalerLogo from 'shared/components/SalerLogo'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import CustomTable from 'shared/components/CustomTable'
import { establishCooperationService } from '../../services'

interface Props {
   productID: Nullable<string>
   locationID?: string
}

export default function NoCooperationTable({ productID, locationID }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { addNotification } = useNotificationContext()
   const { user: userData } = useLogin()
   const [data, setData] = useState<NoCooperatingWholesaler[]>([])

   async function onEstablishCoop(wholesaleUUID: string) {
      if (userData.roles.includes(UserRoles.DEMO)) {
         addNotification('PRODUCT_DETAILS:tables.featureNotForDemo', 'error')
      } else if (productID && locationID) {
         await establishCooperationService(locationID, {
            productUuid: productID,
            wholesaleUuid: wholesaleUUID,
         })
            .then(({ status }) => {
               if (status === 204) {
                  addNotification('establishCoopSuccess', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
      }
   }

   async function init(product: string) {
      await fetchNotCooperatingWholesalersData(product).then((res) => {
         setData(res.notCooperatingWholesalers)
      })
   }

   useEffect(() => {
      if (productID) {
         init(productID)
      }
   }, [productID])

   return data.length ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mt: '8px', justifyContent: 'flex-start' }}>
         <Typography variant="body1" sx={{ ml: 1, opacity: 0.5 }}>
            {t('PRODUCT_DETAILS:tables.noCooperationHeading')}
         </Typography>
         <CustomTable
            customTableStyles={() => ({
               width: { lg: '50%', md: '50%', xs: '100%' },
               ml: 0,
            })}
            rows={data}
            dataSize={data.length}
            disablePagination
            columns={[
               {
                  name: 'wholesaleName',
                  mainOnMobile: true,
                  renderCell: (row: NoCooperatingWholesaler) => <SalerLogo value={row.wholesaleName} row={row} />,
               },
               {
                  name: 'totalAvailability',
                  mainOnMobile: true,
                  renderCell: (row: NoCooperatingWholesaler) =>
                     row.totalAvailability && row.totalAvailability <= row.wholesaleInfiniteAvailabilityThreshold
                        ? row.totalAvailability
                        : `> ${row.wholesaleInfiniteAvailabilityThreshold}`,
               },
               {
                  name: '',
                  mainOnMobile: true,
                  renderCell: (row: NoCooperatingWholesaler) =>
                     row.cooperationStatus === 'PENDING' ? (
                        <Typography color="primary" sx={{ fontSize: 16 }}>
                           {t('PRODUCT_DETAILS:tables.establishingCoop')}
                        </Typography>
                     ) : (
                        <Tooltip
                           title={t('PRODUCT_DETAILS:tables.cantEstablishCoop') || ''}
                           disableHoverListener={row.cooperationType === 'COOPERATION_EMAIL'}
                        >
                           <div>
                              <ContainedButton
                                 onClick={() => onEstablishCoop(row.wholesaleUuid)}
                                 variant="contained"
                                 disabled={
                                    !data ||
                                    row.cooperationType !== 'COOPERATION_EMAIL' ||
                                    (!userData.roles.includes(UserRoles.LOCALIZATION_ADMIN) &&
                                       !userData.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN))
                                 }
                              >
                                 {t('PRODUCT_DETAILS:tables.addCooperationButton')}
                              </ContainedButton>
                           </div>
                        </Tooltip>
                     ),
               },
            ]}
         />
      </Box>
   ) : null
}
