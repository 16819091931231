import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { FormHelperText, StandardTextFieldProps, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { isString } from 'lodash'

interface Props extends StandardTextFieldProps {
   name: string
   label: string
   fullWidth?: boolean
   searcher?: boolean
   inputDisabled?: boolean
   multiple?: boolean
   noMinWidth?: boolean
   inputVariant?: 'standard' | 'outlined' | 'filled'
   options: OptionType[]
}

function SelectField({
   name,
   label,
   searcher,
   options,
   inputVariant,
   inputDisabled,
   fullWidth,
   noMinWidth,
   multiple,
}: Props) {
   const { t } = useTranslation('SETTINGS')
   const [searchPhrase, setSearchPhrase] = useState('')
   const [fieldProps, meta] = useField(name)

   return (
      <FormControl error={!!meta.error} component="fieldset" fullWidth={fullWidth} variant={inputVariant || 'standard'}>
         <InputLabel>{label}</InputLabel>
         <Select
            label={label}
            error={Boolean(meta.error) && Boolean(meta.touched)}
            value={fieldProps.value}
            multiple={multiple}
            disabled={inputDisabled}
            MenuProps={{
               PaperProps: {
                  style: { maxHeight: '350px' },
               },
            }}
            sx={{
               minWidth: noMinWidth ? 'auto' : '260px',
               '.MuiOutlinedInput-input.MuiSelect-select': {
                  whiteSpace: 'break-spaces',
               },
            }}
            onChange={fieldProps.onChange}
            name={name}
         >
            {searcher && (
               <TextField
                  variant="standard"
                  sx={{ width: '80%', ml: 3 }}
                  defaultValue={searchPhrase}
                  placeholder="Search a phrase"
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                     e.stopPropagation()
                     e.preventDefault()
                  }}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                     setSearchPhrase(e.target.value)
                  }}
               />
            )}
            {options
               .filter(
                  (item) =>
                     (isString(item.name) && item.name.toLowerCase().includes(searchPhrase.toLowerCase())) ||
                     item.value === ''
               )
               .map((option) => (
                  <MenuItem
                     defaultValue={searchPhrase}
                     onKeyDown={(e) => e.stopPropagation()}
                     sx={{ height: 35, whiteSpace: 'text-wrap' }}
                     key={option.value}
                     value={option.value}
                  >
                     {option.name}
                  </MenuItem>
               ))}
         </Select>
         <FormHelperText sx={{ color: 'red' }}>{meta.error && t(meta.error)}</FormHelperText>
      </FormControl>
   )
}
export default SelectField
