import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Form, FormikProvider, useFormik } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import ErrorIcon from '@mui/icons-material/Error'
import Stack from '@mui/material/Stack'

import { ModalFormDefaults, ModalProductDetails } from 'views/Authorized/views/ProductDetails/types'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { Modal } from 'views/Authorized/views/MainCart/types'
import { useProfileQuery } from 'shared/store/useProfile'
import InputField from 'shared/components/InputField'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'
import { Yup } from 'shared/lib'
import { Box, DialogActions, IconButton, TextField } from '@mui/material'
import CustomDialog from 'shared/components/CustomDialog'

const schema = Yup.object().shape({
   cartName: Yup.string().required(),
   clientPrice: Yup.number()
      .required()
      .test({
         name: 'is-proper-value',
         test(value, ctx) {
            const salerPrice = ctx.parent.convertedNetPrice
               ? (ctx.parent.convertedNetPrice as number)
               : (ctx.parent.salerPrice as number)
            if (typeof value === 'number' && value + 0.01 > salerPrice) {
               return true
            }
            return ctx.createError({ message: 'TRANSLATION:minimumValue', params: { value: salerPrice + 0.01 } })
         },
      }),
   salerPrice: Yup.number().required(),
   quantity: Yup.number()
      .required()
      .test({
         name: 'quantity-in-set',
         test(value, ctx) {
            const quantityInSet = ctx.parent.quantityInSet as number
            if (value === quantityInSet || (typeof value === 'number' && value % quantityInSet === 0)) {
               return true
            }
            return ctx.createError({ message: 'TRANSLATION:multipleValue', params: { value: quantityInSet } })
         },
      }),
})

interface Props {
   onClose: () => void
   modalData: Modal
   onSwapProduct: (payload: AddToCartPayload) => Promise<{ status: number }>
   isLoading: boolean
}

export default function ModalComponent({ onClose, modalData, onSwapProduct, isLoading }: Props) {
   const { data: profileData } = useProfileQuery()
   const { t } = useTranslation(['TRANSLATIONS', 'PRODUCT_DETAILS'])

   const priceType = profileData?.priceType || 'GROSS'
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))
   const productDetails: ModalProductDetails = {
      index: '',
      producer: '',
      wholesaleName: modalData?.data.wholesaleName || '',
   }

   function handleOnAddToCart(values: ModalFormDefaults) {
      const payload: AddToCartPayload = {
         netClientPrice:
            priceType === 'NET'
               ? values.clientPrice.toString()
               : (values.clientPrice / (1 + values.taxRate / 100)).toFixed(2).toString(),
         netPrice: values.netPrice.toString(),
         grossPrice: values.grossPrice.toString(),
         currency: values.currency,
         taxRate: values.taxRate,
         quantity: values.quantity,
         wholesaleUuid: modalData?.data.wholesaleUuid as string,
      }
      onSwapProduct(payload).then((res) => {
         if (res.status === 200) {
            onClose()
         }
      })
   }
   const taxRate = modalData.data.taxRate || 23
   const netPrice = modalData.data.netPrice || 0
   const convertedNetPrice = modalData.data.convertedNetPrice || 0
   const totalAvailability = modalData.data.totalAvailability || 0
   const quantityInSet = modalData.data.quantityInSet || 1
   const grossPrice = modalData.data.grossPrice || 0
   const infinityThreshold = modalData.data.wholesaleInfiniteAvailabilityThreshold || MAX_PRODUCT_QUANTITY
   const salerPrice = priceType === 'NET' ? netPrice.toString() : grossPrice.toString()
   const currency = modalData.data.currency || 'PLN'
   const clientPrice = priceType === 'NET' ? modalData.data.clientNetPrice : modalData.data.clientGrossPrice

   const formik = useFormik<ModalFormDefaults & { cartName: string }>({
      initialValues: {
         clientPrice: clientPrice || 0,
         grossPrice,
         currency,
         taxRate,
         salerPrice,
         convertedNetPrice,
         netPrice,
         quantityInSet,
         quantity: quantityInSet || 1,
         cartName: dayjs().format('DD-MM-YYYY HH:mm:ss'),
      },
      validationSchema: schema,
      onSubmit: handleOnAddToCart,
   })

   const { values: formikValues, setFieldValue } = formik

   const renderForm = useCallback(() => {
      if (!modalData) {
         return null
      }

      return (
         <Form>
            <Stack direction="row" spacing={2} justifyContent="space-between">
               <div
                  style={{
                     flex: 1,
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <HiddenWholesalerLogo
                     wholesalerName={productDetails.wholesaleName}
                     imgHeight="auto"
                     imgWidth="160px"
                  />
               </div>
               <div style={{ flex: 1 }}>
                  {!fullScreen ? (
                     <Box sx={{ marginBottom: 3, marginTop: 2 }}>
                        <InputField
                           fullWidth
                           variant="standard"
                           type="Number"
                           name="quantity"
                           label={t('TRANSLATION:columnNames.pickQuantity')}
                           InputProps={{
                              inputProps: {
                                 min: quantityInSet,
                                 max: totalAvailability < infinityThreshold ? totalAvailability : MAX_PRODUCT_QUANTITY,
                                 step: quantityInSet,
                              },
                           }}
                        />
                     </Box>
                  ) : (
                     <Box sx={{ marginBottom: 3, marginTop: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <IconButton
                           disabled={formikValues.quantity === 1}
                           onClick={() => setFieldValue('quantity', formikValues.quantity - formikValues.quantityInSet)}
                        >
                           -
                        </IconButton>
                        <InputField
                           fullWidth
                           variant="standard"
                           type="Number"
                           name="quantity"
                           label={t('TRANSLATION:columnNames.pickQuantity')}
                           InputProps={{
                              inputProps: {
                                 min: quantityInSet,
                                 max: totalAvailability < infinityThreshold ? totalAvailability : MAX_PRODUCT_QUANTITY,
                                 step: quantityInSet,
                              },
                           }}
                        />
                        <IconButton
                           onClick={() => setFieldValue('quantity', formikValues.quantity + formikValues.quantityInSet)}
                        >
                           +
                        </IconButton>
                     </Box>
                  )}
                  <InputField
                     sx={{ marginBottom: 3 }}
                     fullWidth
                     variant="standard"
                     name={convertedNetPrice ? 'convertedNetPrice' : 'salerPrice'}
                     label={`${t('TRANSLATION:columnNames.price')} (${convertedNetPrice ? 'EUR' : currency})`}
                     disabled
                  />
                  {modalData.oldClientPrice && (
                     <TextField
                        value={modalData.oldClientPrice}
                        sx={{ mb: 3 }}
                        fullWidth
                        variant="standard"
                        label={
                           profileData?.priceType === 'NET'
                              ? t('TRANSLATION:columnNames.oldClientPriceNet')
                              : t('TRANSLATION:columnNames.oldClientPriceGross')
                        }
                        disabled
                     />
                  )}
                  <InputField
                     inputProps={{
                        step: 0.01,
                     }}
                     onFocus={(e) => e.target.select()}
                     fullWidth
                     variant="standard"
                     type="Number"
                     name="clientPrice"
                     label={`${t('MAIN_CART:swap.setClientPrice')} (${convertedNetPrice ? 'EUR' : currency})`}
                  />
               </div>
            </Stack>
         </Form>
      )
   }, [modalData, isLoading, formikValues])

   return (
      <CustomDialog
         isLoading={!modalData}
         fullScreen={fullScreen}
         open={!!modalData}
         onClose={onClose}
         content={
            <FormikProvider value={formik}>
               <DialogTitle id="responsive-dialog-title">
                  <Typography sx={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: 20 }}>
                     {t('MAIN_CART:swap.swapTitle')}
                     {modalData?.data.quantityInSet !== 1 && (
                        <Tooltip
                           title={t('MAIN_CART:swap.multipleMustBeMore', {
                              quantity: modalData?.data.quantityInSet,
                           })}
                        >
                           <ErrorIcon sx={{ mb: -0.7, color: 'red' }} />
                        </Tooltip>
                     )}
                  </Typography>
               </DialogTitle>
               <DialogContent>{renderForm()}</DialogContent>
               <DialogActions>
                  <Stack direction="row" spacing={2}>
                     <OutlinedButton variant="outlined" onClick={onClose} disabled={isLoading}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton
                        type="submit"
                        onClick={() => handleOnAddToCart(formik.values)}
                        variant="contained"
                        disabled={isLoading}
                     >
                        {t('TRANSLATION:addToCart')}
                     </ContainedButton>
                  </Stack>
               </DialogActions>
            </FormikProvider>
         }
      />
   )
}
