import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import React from 'react'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import Tooltip from '@mui/material/Tooltip'
import CustomTable from 'shared/components/CustomTable'
import { TableFooter } from '@mui/material'

interface Props {
   data: OrderProductType[]
   netDeliveryCost?: number
   grossDeliveryCost?: number
   formattedGrossValue: string
   formattedNetValue: string
}

export default function OrderedTable({
   data,
   formattedGrossValue,
   formattedNetValue,
   netDeliveryCost,
   grossDeliveryCost,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDER_SUMMARY'])

   function returnComment(name: PropType<OrderProductType, 'status'>) {
      switch (name) {
         case 'NOT_AVAILABLE':
            return t('ORDER_SUMMARY:tables.notAvailable')
         case 'PRICE_HAS_CHANGED':
            return t('ORDER_SUMMARY:tables.priceHasChanged')
         case 'WHOLESALE_CONNECTION_PROBLEM':
            return t('ORDER_SUMMARY:tables.wholesaleConnectionProblem')
         case 'TOO_LOW_AMOUNT':
            return t('ORDER_SUMMARY:tables.tooLowAmount')
         case 'DELIVERY_PROBLEM':
            return t('ORDER_SUMMARY:tables.deliveryProblem')
         case 'INDEX_PROBLEM':
            return t('ORDER_SUMMARY:tables.indexProblem')
         case 'PAYMENTS_PROBLEM':
            return t('ORDER_SUMMARY:tables.paymentsProblem')
         case 'WHOLESALE_ERROR':
            return t('ORDER_SUMMARY:tables.wholesaleError')
         case 'PARTIALLY_AVAILABLE':
            return t('ORDER_SUMMARY:tables.partiallyAvailable')
         default:
            return null
      }
   }

   function returnWholesaleErrorMessage(
      status: PropType<OrderProductType, 'status'>,
      wholesaleErrorMessage?: Nullable<string>
   ) {
      if (status !== 'AVAILABLE' && status !== 'PARTIALLY_AVAILABLE') {
         if (!!wholesaleErrorMessage && !wholesaleErrorMessage?.includes('NOT_HANDLED')) {
            return wholesaleErrorMessage
         }
         if (status === 'PRICE_HAS_CHANGED') {
            return t('ORDER_SUMMARY:tables.priceHasChanges')
         }
         if (status === 'TOO_LOW_AMOUNT') {
            return t('ORDER_SUMMARY:tables.tooLowAmount')
         }
         return t('ORDER_SUMMARY:tables.wholesaleNoResponse')
      }
      return ''
   }

   function getHighlightColor(product: OrderProductType) {
      if (product.status === 'AVAILABLE') {
         return 'transparent'
      }
      if (product.amount !== product.requestedAmount && product.amount !== 0) {
         return 'rgb(255, 136, 0,0.7)'
      }
      return 'rgba(255,0,0,0.5)'
   }

   const notOrderedProducts = data.filter((item) => item.status !== 'AVAILABLE').length
   return (
      <Box>
         <Typography variant="body1" color="text.secondary">
            {t('ORDER_SUMMARY:tables.orderedProducts')}
         </Typography>
         <CustomTable
            customFooter={(columnsCount) => (
               <TableFooter sx={{ backgroundColor: '#EBEBEB' }}>
                  <StyledTableCell sx={{ p: 0 }} colSpan={columnsCount}>
                     <Box
                        sx={{
                           display: 'flex',
                           gap: '32px',
                           pr: { lg: '32px', md: '32px' },
                           width: '100%',
                           justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                        }}
                     >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                           <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:columnNames.netSum')}</p>
                           <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{formattedNetValue}</p>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                           <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:columnNames.grossSum')}</p>
                           <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{formattedGrossValue}</p>
                        </Box>
                        {netDeliveryCost && (
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                 {t('TRANSLATION:columnNames.grossSum')}
                              </p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>{formattedGrossValue}</p>
                           </Box>
                        )}
                     </Box>
                     {grossDeliveryCost && netDeliveryCost && (
                        <Box
                           sx={{
                              display: 'flex',
                              gap: '32px',
                              pr: { lg: '32px', md: '32px' },
                              width: '100%',
                              justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                           }}
                        >
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:deliveryCost')}</p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                 {t('TRANSLATION:price', { price: netDeliveryCost.toFixed(2) })} {t('TRANSLATION:NET')}
                              </p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                 {t('TRANSLATION:price', {
                                    price: grossDeliveryCost ? grossDeliveryCost.toFixed(2) : 0,
                                 })}{' '}
                                 {t('TRANSLATION:GROSS')}
                              </p>
                           </Box>
                        </Box>
                     )}
                  </StyledTableCell>
               </TableFooter>
            )}
            dataSize={data.length}
            rows={data}
            disablePagination
            customRowStyles={(row: OrderProductType) => ({
               backgroundColor: getHighlightColor(row),
               '&:hover': {
                  backgroundColor: getHighlightColor(row),
               },
            })}
            columns={[
               {
                  name: 'partIndex',
                  renderCell: (row: OrderProductType) => row.index,
                  mainOnMobile: true,
               },
               {
                  name: 'orderNumber',
                  mainOnMobile: true,
                  renderCell: (row: OrderProductType) => row.wholesaleOrderNumber,
               },
               {
                  name: 'wholesaler',
                  renderCell: (row: OrderProductType) => (
                     <img
                        height="20px"
                        width="auto"
                        src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${row.wholesaleName}.png`}
                        alt={row.wholesaleName}
                     />
                  ),
               },
               {
                  name: 'quantity',
                  renderCell: (row: OrderProductType) => (
                     <Tooltip
                        disableHoverListener={row.amount === 0}
                        title={t('TRANSLATION:requestedAmountTooltip', {
                           amount: row.amount,
                           requestedAmount: row.requestedAmount,
                        })}
                     >
                        <Box>
                           {row.amount !== 0 && row.amount !== row.requestedAmount
                              ? `${row.amount}/${row.requestedAmount}`
                              : row.amount}
                        </Box>
                     </Tooltip>
                  ),
               },
               {
                  name: 'netPrice',
                  renderCell: (row: OrderProductType) => (
                     <>
                        {row.formattedPrice} {row.formattedConvertedPrice && `(${row.formattedConvertedPrice})`}
                     </>
                  ),
               },
               {
                  name: 'VAT',
                  renderCell: (row: OrderProductType) => `${row.taxRate}%`,
               },
               {
                  name: 'grossValue',
                  mainOnMobile: true,
                  renderCell: (row: OrderProductType) => (
                     <>
                        {row.formattedGrossValue}{' '}
                        {row.formattedConvertedGrossValue && `(${row.formattedConvertedGrossValue})`}
                     </>
                  ),
               },
               {
                  name: 'status',
                  hidden: !notOrderedProducts,
                  renderCell: (row: OrderProductType) => row.status !== 'AVAILABLE' && returnComment(row.status),
               },
               {
                  name: 'wholesaleInfo',
                  hidden: !notOrderedProducts,
                  renderCell: (row: OrderProductType) =>
                     row.status !== 'AVAILABLE' && returnWholesaleErrorMessage(row.status, row.wholesaleErrorMessage),
               },
            ]}
         />
         {!!data.filter((item) => item.status !== 'AVAILABLE').length && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
               <Typography variant="body1">{t('ORDER_SUMMARY:notOrderedProducts')}</Typography>
               <Typography variant="body1">{t('ORDER_SUMMARY:notOrderedProductsBack')}</Typography>
            </Box>
         )}
      </Box>
   )
}
