import { Box, Breakpoint, CircularProgress, Dialog, useMediaQuery, useTheme } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
   content: ReactNode
   isLoading: boolean
   fullWidth?: boolean
   fullScreen?: boolean
   maxWidth?: false | Breakpoint | undefined
   scroll?: 'body' | 'paper' | undefined
   open: boolean
   onClose: () => void
}

export default function CustomDialog({
   content,
   scroll,
   fullScreen,
   open,
   fullWidth,
   maxWidth,
   isLoading,
   onClose,
}: Props) {
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   return (
      <Dialog
         fullWidth={fullWidth}
         fullScreen={fullScreen || isMobile}
         maxWidth={maxWidth}
         scroll={scroll}
         open={open}
         onClose={onClose}
      >
         {!isLoading ? (
            content
         ) : (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 75,
                  width: fullWidth ? '100%' : 75,
               }}
            >
               <CircularProgress />
            </Box>
         )}
      </Dialog>
   )
}
