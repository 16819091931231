import ContainedButton from 'shared/components/Buttons/ContainedButton'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { t } from 'i18next'

import { StyledDeleteIcon, StyledEditIcon } from 'shared/components/TableIcons'
import useAdditionalServicesQuery from 'shared/store/useAddtionalServices'
import { LocationType } from 'shared/store/useLocations/types'

import CustomTable from 'shared/components/CustomTable'
import { AdditionalServiceType } from 'shared/store/useAddtionalServices/types'
import { ModalInfoType } from '../../types'
import Modal from '../../Modal'

interface Props {
   activeLocation: LocationType
}
export default function ServicesList({ activeLocation }: Props) {
   const { data, isFetching } = useAdditionalServicesQuery(activeLocation.locationSettings.uuid)
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()

   function renderContent() {
      if (isFetching) {
         return (
            <Box sx={{ textAlign: 'center' }}>
               <CircularProgress color="primary" />
            </Box>
         )
      }
      if (data && data.additionalServices.length === 0) {
         return (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
               <Typography variant="h5" color="primary">
                  {t('SERVICES:noServices')}
               </Typography>
               <ContainedButton
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={() =>
                     setModalInfo({ locationUuid: activeLocation.locationSettings.uuid, modalType: 'CREATE' })
                  }
               >
                  {t('SERVICES:addServiceButtonLabel')}
               </ContainedButton>
            </Box>
         )
      }
      if (data) {
         return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
               <CustomTable
                  rows={data.additionalServices}
                  dataSize={data.additionalServices.length}
                  disablePagination
                  columns={[
                     {
                        name: 'serviceName',
                        renderCell: (row: AdditionalServiceType) => row.name,
                        mainOnMobile: true,
                     },
                     {
                        name: 'defaultNetPrice',
                        renderCell: (row: AdditionalServiceType) => row.formattedDefaultNetPrice,
                     },
                     {
                        name: 'VAT',
                        renderCell: (row: AdditionalServiceType) => row.taxRate,
                     },
                     {
                        name: 'defaultGrossPrice',
                        renderCell: (row: AdditionalServiceType) => row.formattedDefaultGrossPrice,
                        mainOnMobile: true,
                     },
                     {
                        name: '',
                        renderCell: (row: AdditionalServiceType) => (
                           <Box
                              sx={{ display: 'flex', gap: { lg: '8px', md: '12px', xs: '16px' }, alignItems: 'center' }}
                           >
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       locationUuid: activeLocation.locationSettings.uuid,
                                       modalType: 'EDIT',
                                       serviceUuid: row.uuid,
                                       serviceInfo: {
                                          ...row,
                                       },
                                    })
                                 }
                              >
                                 <StyledEditIcon />
                              </IconButton>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       locationUuid: activeLocation.locationSettings.uuid,
                                       serviceUuid: row.uuid,
                                       serviceName: row.name,
                                       modalType: 'DELETE',
                                    })
                                 }
                              >
                                 <StyledDeleteIcon />
                              </IconButton>
                           </Box>
                        ),
                     },
                  ]}
               />
               <ContainedButton
                  onClick={() =>
                     setModalInfo({ locationUuid: activeLocation.locationSettings.uuid, modalType: 'CREATE' })
                  }
                  variant="contained"
                  sx={{ mt: 1 }}
               >
                  {t('SERVICES:addServiceButtonLabel')}
               </ContainedButton>
            </Box>
         )
      }
      return null
   }

   return (
      <Grid item xs={12} md={10} sx={{ px: { lg: 3, md: 3, xs: 1 }, py: 1 }}>
         {renderContent()}
         {modalInfo && <Modal modalInfo={modalInfo} handleClose={() => setModalInfo(undefined)} />}
      </Grid>
   )
}
