/* eslint-disable no-nested-ternary */
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableFooter from '@mui/material/TableFooter'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import ImageComponent from 'shared/components/ImageComponent'
import {
   StyledTable,
   StyledSpan,
   StyledTableCell,
   ProductTableRow,
   ButtonWrapper,
   StyledBox,
   PayBox,
   StyledContentTableCell,
   StyledAvailabilityDot,
} from './styles'
import { DuplicatedProduct, FetchProductsResult, sortDirectionType, Product, SendDuplicatesPayload } from './types'
import { pl, en, de } from './locales'
import { fetchProducts, sendDuplicates } from './services'
import MergeProductsModal from './components/MergeProductsModal'
import DeleteModal from './components/DeleteModal'

const columnNames = [
   { name: 'index', translateLabel: 'partIndex' },
   { name: 'name', translateLabel: 'productName' },
   { name: 'producer', translateLabel: 'producer' },
   { name: 'meanPrice', translateLabel: 'meanPrice' },
   { name: 'availability', translateLabel: 'availability' },
]

export default function InternalProductsList() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'PRODUCTS_LIST'])
   i18n.addResourceBundle('pl-PL', 'PRODUCTS_LIST', pl)
   i18n.addResourceBundle('en-EN', 'PRODUCTS_LIST', en)
   i18n.addResourceBundle('de-DE', 'PRODUCTS_LIST', de)
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   const [deleteModal, setDeleteModal] = useState<string>()
   const searchValue = searchParams.get('searchType') !== 'SUBSTITUTES' ? searchParams.get('value') || '' : ''
   const { user } = useLogin()
   const [isLoading, setIsLoading] = useState(false)
   const [data, setData] = useState<FetchProductsResult>()
   const { addNotification } = useNotificationContext()
   const [activePage, setActivePage] = useState(0)
   const [openModal, setOpenModal] = useState(false)
   const [searchError, setSearchError] = useState<string>()
   const [isSendingDuplicates, setIsSendingDuplicates] = useState(false)
   const [duplicatedProducts, setDuplicatedProducts] = useState<DuplicatedProduct[]>([])
   const [itemsPerPage, setItemsPerPage] = useState(25)
   const [sortBy, setSortBy] = useState<Exclude<keyof Product, 'wholesaleName'>>()
   const [sortDirection, setSortDirection] = useState<sortDirectionType>('asc')

   function handleItemsPerPage(event: SelectChangeEvent<number>) {
      setActivePage(0)
      window.localStorage.setItem('itemsPerPage', event.target.value.toString())
      setItemsPerPage(event.target.value as number)
   }

   function foundResultsString(resultsCount: number) {
      if (resultsCount === 0) return 'notFound'
      return ''
   }

   function returnSearchPhrase() {
      if (searchParams.get('searchType')) {
         switch (searchParams.get('searchType')) {
            case 'OIL':
               return `${searchParams.get('producer') || []} ${searchParams.get('sae') || ''} ${
                  searchParams.get('capacity') || ''
               }${searchParams.get('standard') || ''}`
            case 'INDEX':
            case 'OE':
            case 'EXTENSION':
               return searchParams.get('value')
            case 'SUBSTITUTES':
               return `${searchParams.get('index')} - ${t('PRODUCTS_LIST:substitutes')}`
            default:
               return ''
         }
      }
      return ''
   }

   function handleOnClick(productUuid: string) {
      navigate({
         pathname: '/dashboard/product',
         search: createSearchParams({
            uuid: productUuid,
         }).toString(),
      })
   }

   function handleScrollClick(productUuid: string) {
      const urlStart = window.location.href.split('/')[0]
      const url = `${urlStart}/dashboard/product?uuid=${productUuid}`
      return window.open(url, '_blank')
   }

   function handleDuplicates(productUuid: string, productIndex: string, producerName: string, wholesaleCount: number) {
      if (duplicatedProducts.some((item) => item.uuid === productUuid)) {
         const newDuplicatedProducts: DuplicatedProduct[] = duplicatedProducts.filter(
            (item) => item.uuid !== productUuid
         )
         setDuplicatedProducts(newDuplicatedProducts)
      } else {
         const newDuplicatedProducts: DuplicatedProduct[] = [
            ...duplicatedProducts,
            { index: productIndex, uuid: productUuid, producer: producerName, wholesaleCount },
         ]

         setDuplicatedProducts(newDuplicatedProducts)
      }
   }

   function handleSortingChange(sortName: Exclude<keyof Product, 'wholesaleName'>) {
      setSortBy(sortName)
      if (sortDirection === 'asc') {
         setSortDirection('desc')
      } else {
         setSortDirection('asc')
      }
   }

   function sortingType(a: string | number | boolean, b: string | number | boolean) {
      switch (sortDirection) {
         case 'desc':
            return a > b ? -1 : 1
         case 'asc':
            return a > b ? 1 : -1
         default:
            return 0
      }
   }
   function returnProductName(productName: string, productIndex: string) {
      if (productName) {
         if (productName.length > 100) {
            return `${productName.slice(0, 100)}...`
         }
         return productName
      }
      return productIndex
   }

   function handleDuplicatesSend() {
      setIsSendingDuplicates(true)
      const payload: SendDuplicatesPayload = {
         products: duplicatedProducts,
      }
      sendDuplicates(payload)
         .then(() => {
            addNotification('duplicatesSend', 'success')
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => {
            setDuplicatedProducts([])
            setIsSendingDuplicates(false)
         })
   }

   function returnAvailabilityDot(availability: number) {
      switch (true) {
         case availability === 0:
            return <StyledAvailabilityDot color="red" />
         case availability === 1:
            return <StyledAvailabilityDot color="orange" />
         case availability === -1:
            return <StyledAvailabilityDot color="gray" />
         case availability > 1:
            return <StyledAvailabilityDot color="green" />
         default:
            return null
      }
   }

   useEffect(() => {
      setIsLoading(true)
      let isFetching = true
      setSortBy(undefined)
      setDuplicatedProducts([])
      let params = ''
      if (searchParams.get('mainProductUuid')) {
         params = params.concat(`?mainProductUuid=${searchParams.get('mainProductUuid')}`)
      }
      if (searchParams.getAll('productUuid')) {
         searchParams.getAll('productUuid').forEach((item) => {
            params = params.concat(`&productUuid=${item}`)
         })
      }
      if (params) {
         fetchProducts(params)
            .then((res) => {
               if (isFetching) {
                  setData(res)
                  setSearchError(undefined)
                  setIsLoading(false)
                  if (res.products.length === 1) {
                     navigate({
                        pathname: '/dashboard/product',
                        search: createSearchParams({
                           uuid: res.products[0].uuid,
                        }).toString(),
                     })
                  }
               }
            })
            .finally(() => {
               if (isFetching) {
                  setIsLoading(false)
               }
            })
      }

      setActivePage(0)
      if (window.localStorage.getItem('itemsPerPage')) {
         setItemsPerPage(Number(window.localStorage.getItem('itemsPerPage')))
      } else {
         setItemsPerPage(25)
      }

      return () => {
         isFetching = false
      }
   }, [searchValue, searchParams, navigate])

   return (
      <StyledBox>
         {data && !isLoading ? (
            user.roles.includes(UserRoles.SUBSCRIPTION) || user.roles.includes(UserRoles.DEMO) ? (
               <div>
                  <div
                     style={{
                        width: '80%',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: data.resultsCount >= 250 || data.resultsCount === 0 ? 'column' : 'row',
                        gap: 5,
                     }}
                  >
                     <Typography variant="body1" color="text.secondary">
                        {t('PRODUCTS_LIST:searchedPhrase')}
                        <StyledSpan>{returnSearchPhrase()}</StyledSpan>
                     </Typography>
                     {data.resultsCount === 0 || data.resultsCount === 250 ? (
                        <Typography variant="h6" sx={{ color: 'red', textTransform: 'upperCase', margin: '0 auto' }}>
                           {t(`PRODUCTS_LIST:${foundResultsString(data.resultsCount)}`)}
                        </Typography>
                     ) : (
                        <Typography variant="body1" sx={{ color: 'grayText' }}>
                           {t('PRODUCTS_LIST:found')} <StyledSpan> {data ? data.resultsCount : 0} </StyledSpan>
                        </Typography>
                     )}
                     {/* to remove, temp for Kuba */}
                     {searchError && (
                        <Typography variant="h4" color="red" sx={{ margin: '0 auto' }}>
                           500
                        </Typography>
                     )}
                  </div>
                  {data.products.length > 0 && (
                     <>
                        <StyledTable>
                           <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <TableRow>
                                 {columnNames.map((column) => (
                                    <StyledTableCell key={column.name}>
                                       <TableSortLabel
                                          onClick={() =>
                                             handleSortingChange(column.name as Exclude<keyof Product, 'wholesaleName'>)
                                          }
                                          active={sortBy === column.name}
                                          direction={sortDirection}
                                       >
                                          {t(`TRANSLATION:columnNames.${column.translateLabel}`)}
                                       </TableSortLabel>
                                    </StyledTableCell>
                                 ))}
                                 <StyledTableCell>
                                    <Tooltip title={t('PRODUCTS_LIST:duplicatedTooltip') || ''}>
                                       <span>{t('PRODUCTS_LIST:duplicated')}</span>
                                    </Tooltip>
                                 </StyledTableCell>
                                 <StyledTableCell />
                                 <StyledTableCell>Podpięta hurtownia </StyledTableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {data.products
                                 .sort((a, b) => (sortBy ? sortingType(a[sortBy], b[sortBy]) : 0))
                                 .slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)
                                 .map((product) => (
                                    <ProductTableRow
                                       sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
                                       onClick={() => handleOnClick(product.uuid)}
                                       onMouseDown={(e: any) => {
                                          if (e.button === 1) handleScrollClick(product.uuid)
                                       }}
                                       key={product.uuid}
                                    >
                                       <StyledContentTableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                          {product.index.length > 30
                                             ? `${product.index.slice(0, 30)}...`
                                             : product.index}
                                          {product.wholesaleCount === 1 ? (
                                             <Tooltip
                                                sx={{ color: 'red' }}
                                                title={t('PRODUCTS_LIST:unmappedTooltip') || ''}
                                             >
                                                <PriorityHighRoundedIcon style={{ marginBottom: -5, fontSize: 20 }} />
                                             </Tooltip>
                                          ) : null}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell
                                          sx={{ textAlign: 'center', color: 'grayText', width: '50%' }}
                                       >
                                          {returnProductName(product.name, product.index)}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          <Tooltip title={product.producer}>
                                             <ImageComponent
                                                producerName={product.producer}
                                                filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                                             />
                                          </Tooltip>
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          {product.meanPrice !== 0 ? product.formattedMeanPrice : '-'}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          {returnAvailabilityDot(product.availability)}
                                       </StyledContentTableCell>
                                       <StyledContentTableCell onClick={(e: any) => e.stopPropagation()}>
                                          <Checkbox
                                             color="primary"
                                             checked={!!duplicatedProducts.find((item) => item.uuid === product.uuid)}
                                             onChange={() =>
                                                handleDuplicates(
                                                   product.uuid,
                                                   product.index,
                                                   product.producer,
                                                   product.wholesaleCount
                                                )
                                             }
                                          />
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          <Button
                                             variant="outlined"
                                             color="error"
                                             onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setDeleteModal(product.uuid)
                                             }}
                                          >
                                             Usuń
                                          </Button>
                                       </StyledContentTableCell>
                                       <StyledContentTableCell>
                                          <Typography variant="body2" fontWeight="bold">
                                             {product.wholesaleCount < 2 && (product.wholesaleName || 'BRAK')}
                                          </Typography>
                                       </StyledContentTableCell>
                                    </ProductTableRow>
                                 ))}
                           </TableBody>
                           <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <TableRow>
                                 <StyledContentTableCell sx={{ textAlign: 'right', paddingRight: 7 }} colSpan={8}>
                                    {t('PRODUCTS_LIST:itemsPerPage')}
                                    <Select
                                       sx={{ mx: 1, color: 'grayText', fontSize: 14 }}
                                       variant="standard"
                                       disabled={data.products.length === 0}
                                       value={itemsPerPage}
                                       onChange={(e) => handleItemsPerPage(e)}
                                    >
                                       <MenuItem value={10}> 10 </MenuItem>
                                       <MenuItem value={25}> 25 </MenuItem>
                                       <MenuItem value={50}> 50 </MenuItem>
                                    </Select>
                                    {t('PRODUCTS_LIST:displayed', {
                                       displayed: `${itemsPerPage * activePage + 1} - ${
                                          itemsPerPage * activePage + itemsPerPage > data.products.length
                                             ? data.products.length
                                             : itemsPerPage * activePage + itemsPerPage
                                       }`,
                                       all: data.products.length,
                                    })}
                                    <IconButton
                                       disabled={data.products.length === 0 || activePage === 0}
                                       onClick={() => setActivePage((page) => page - 1)}
                                    >
                                       <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton
                                       disabled={
                                          data
                                             ? data.products.length === 0 ||
                                               activePage === Math.ceil(data.products.length / itemsPerPage) - 1
                                             : false
                                       }
                                       onClick={() => setActivePage((page) => page + 1)}
                                    >
                                       <ChevronRightIcon />
                                    </IconButton>
                                 </StyledContentTableCell>
                              </TableRow>
                           </TableFooter>
                        </StyledTable>
                        <ButtonWrapper>
                           <Button
                              variant="contained"
                              color="error"
                              disabled={duplicatedProducts.length < 2}
                              onClick={() => setOpenModal(true)}
                           >
                              Połącz produkty
                           </Button>
                           <ContainedButton
                              variant="contained"
                              disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                              onClick={handleDuplicatesSend}
                           >
                              {t('PRODUCTS_LIST:sendDuplicates')}
                           </ContainedButton>
                        </ButtonWrapper>
                     </>
                  )}
               </div>
            ) : (
               <PayBox>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 20 }}>
                     <Typography variant="h6" sx={{ mt: 1, color: 'red', textTransform: 'uppercase' }}>
                        {t('PRODUCTS_LIST:buySubscription')}
                     </Typography>
                     <ContainedButton onClick={() => navigate('/dashboard/payments')} variant="contained">
                        {t('PRODUCTS_LIST:payments')}
                     </ContainedButton>
                  </div>
               </PayBox>
            )
         ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
               <CircularProgress color="primary" />
            </div>
         )}
         <MergeProductsModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            duplicatedProducts={duplicatedProducts}
         />
         <DeleteModal productUuid={deleteModal || ''} handleClose={() => setDeleteModal(undefined)} />
      </StyledBox>
   )
}
