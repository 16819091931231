/* eslint-disable no-nested-ternary */
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

import { StyledDeleteIcon, StyledEditIcon, StyledLockIcon } from 'shared/components/TableIcons'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import getCompanyUsersService from 'shared/services/getCompanyUsers'
import { getRefreshToken } from 'shared/utils/helpers/refreshToken'
import { useLocationsQuery } from 'shared/store/useLocations'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import CustomTable from 'shared/components/CustomTable'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'

import { GetSubscriptionInfoResult, ModalInfoType } from './types'
import { getSubscriptionEndDateService } from './services'
import Modal from './components/Modal'
import { en, pl, de } from './locales'

export default function UsersAndSub() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'USERS_AND_SUBSCRIPTIONS'])
   i18n.addResourceBundle('pl-PL', 'USERS_AND_SUBSCRIPTIONS', pl)
   i18n.addResourceBundle('en-EN', 'USERS_AND_SUBSCRIPTIONS', en)
   i18n.addResourceBundle('de-DE', 'USERS_AND_SUBSCRIPTIONS', de)
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()
   const [isLoading, setIsLoading] = useState(false)
   const [refetch, setRefetch] = useState(false)
   const queryClient = useQueryClient()
   const { data } = useLocationsQuery()
   const [users, setUsers] = useState<UsersType>()
   const [subscriptionDetails, setSubscriptionDetails] = useState<GetSubscriptionInfoResult>()
   const { addNotification } = useNotificationContext()
   const { user } = useLogin()

   const noUsers = users?.length === 0
   const isAdmin = user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)
   const isLocationAdmin = user.roles.includes(UserRoles.LOCALIZATION_ADMIN)
   const salesRepOn = user.roles.includes(UserRoles.ULTRA)

   function handleSalesRepChange(remove: boolean, userUuid: string) {
      setIsLoading(true)
      httpClient
         .post(`companies/users/${userUuid}/change-sales-rep-role?remove=${remove}`)
         .then(() => {
            getRefreshToken()
            queryClient.invalidateQueries('user')
            addNotification('successSave', 'success')
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => setIsLoading(false))
   }

   useEffect(() => {
      setIsLoading(true)
      getCompanyUsersService(true)
         .then((res) => {
            setUsers(res.data)
         })
         .finally(() => {
            setIsLoading(false)
            setRefetch(false)
         })
      if (user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)) {
         getSubscriptionEndDateService().then((res) => {
            if (res) {
               setSubscriptionDetails({ ...res })
            }
         })
      }
   }, [refetch])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
         <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal' }}>
            {t('USERS_AND_SUBSCRIPTIONS:usersAndSubTitle')}
         </Typography>
         <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {!noUsers ? (
               users && !isLoading ? (
                  <CustomTable
                     dataSize={users.length}
                     disablePagination
                     rows={users}
                     columns={[
                        {
                           name: 'nameUser',
                           renderCell: (row: UserType) => row.name,
                           mainOnMobile: true,
                        },
                        {
                           name: 'surname',
                           renderCell: (row: UserType) => row.surname,
                           mainOnMobile: true,
                        },
                        {
                           name: 'email',
                           renderCell: (row: UserType) => row.email,
                        },
                        {
                           name: 'phoneNumber',
                           renderCell: (row: UserType) => row.phoneNumber,
                        },
                        {
                           name: 'lastLoginDate',
                           renderCell: (row: UserType) => row.lastLogin,
                        },
                        {
                           name: 'searchCount',
                           mainOnMobile: true,
                           renderCell: (row: UserType) => row.searchCount,
                        },
                        {
                           name: 'ordersValue',
                           renderCell: (row: UserType) => row.formattedOrdersValue,
                        },
                        {
                           name: 'salesRep',
                           hidden: !isAdmin,
                           renderCell: (row: UserType) => (
                              <Tooltip
                                 title={t('USERS_AND_SUBSCRIPTIONS:onlyInPro') || ''}
                                 disableHoverListener={salesRepOn}
                              >
                                 <div>
                                    <Checkbox
                                       checked={row.roles.includes(UserRoles.SALES_REP)}
                                       disabled={isLoading || !salesRepOn}
                                       onClick={() => {
                                          handleSalesRepChange(row.roles.includes(UserRoles.SALES_REP), row.uuid)
                                          setRefetch(true)
                                       }}
                                    />
                                 </div>
                              </Tooltip>
                           ),
                        },
                        {
                           name: '',
                           hidden: !isAdmin,
                           renderCell: (row: UserType) => (
                              <Box
                                 sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: { lg: 0, md: '16px', xs: '16px' },
                                 }}
                              >
                                 <IconButton
                                    onClick={() =>
                                       setModalInfo({
                                          userInfo: {
                                             ...row,
                                          },
                                          modalType: 'EDIT',
                                       })
                                    }
                                 >
                                    <StyledEditIcon />
                                 </IconButton>
                                 <IconButton
                                    disabled={!isAdmin}
                                    onClick={() =>
                                       setModalInfo({
                                          userInfo: {
                                             ...row,
                                          },
                                          modalType: 'CHANGEPASSWORD',
                                       })
                                    }
                                 >
                                    <StyledLockIcon />
                                 </IconButton>
                                 {isAdmin && !row.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && (
                                    <IconButton
                                       onClick={() =>
                                          setModalInfo({
                                             userInfo: {
                                                ...row,
                                             },
                                             modalType: 'DELETE',
                                          })
                                       }
                                    >
                                       <StyledDeleteIcon />
                                    </IconButton>
                                 )}
                              </Box>
                           ),
                        },
                     ]}
                  />
               ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress color="primary" />
                  </Box>
               )
            ) : (
               <Box sx={{ display: 'flex', textAlign: 'center', color: 'grayText' }}>
                  {t('USERS_AND_SUBSCRIPTIONS:noUsers')}
               </Box>
            )}
            {(isAdmin || isLocationAdmin) && data && (
               <OutlinedButton
                  onClick={() =>
                     setModalInfo({
                        userInfo: {
                           uuid: '',
                           name: '',
                           surname: '',
                           phoneNumber: '',
                           email: '',
                           locationUuids: [],
                        },
                        modalType: 'CREATEUSER',
                     })
                  }
                  sx={{ alignSelf: 'center', mt: 4, fontSize: 11 }}
                  variant="outlined"
               >
                  {t('USERS_AND_SUBSCRIPTIONS:addUserButton')}
               </OutlinedButton>
            )}
         </Box>
         <Modal
            handleRefetch={() => setRefetch(true)}
            modalInfo={modalInfo}
            handleClose={() => setModalInfo(undefined)}
         />
         {!isLoading && (
            <Typography variant="body1" sx={{ color: 'text.secondary', alignSelf: 'center' }}>
               {isAdmin &&
                  subscriptionDetails &&
                  t('USERS_AND_SUBSCRIPTIONS:expiration', {
                     expirationDate: subscriptionDetails.expirationDate,
                     subscriptionType: subscriptionDetails.subscriptionType,
                  })}
            </Typography>
         )}
      </Box>
   )
}
