import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import React from 'react'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import getProductName from 'shared/utils/helpers/getProductName'
import { Order } from 'shared/store/useOrdersHistory/types'

import ImageComponent from 'shared/components/ImageComponent'
import { StoredOrderDetails } from '../../../../types'

const columnNames = ['productName', 'producer', 'quantity', 'clientPrice', 'clientPriceSum']

interface Props {
   orders: PropType<StoredOrderDetails, 'data'>
   priceType?: PriceType
   orderType?: PropType<Order, 'orderType'>
   formattedAdditionalServicesValue?: string
   formattedClientValueWithServices?: string
   formattedClientValue?: string
}

export default function ClientTable({
   orders,
   priceType,
   formattedClientValueWithServices,
   orderType,
   formattedClientValue,
   formattedAdditionalServicesValue,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])

   return (
      <>
         <Table sx={{ width: '100%', marginY: 3 }}>
            <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
               <TableRow>
                  {columnNames.map((column) => (
                     <StyledTableCell sx={{ textAlign: 'center' }} key={column} width={250}>
                        <h3>
                           {t(`TRANSLATION:columnNames.${column}`)}{' '}
                           {(column === 'clientPriceSum' || column === 'clientPrice') && t(`TRANSLATION:${priceType}`)}
                        </h3>
                     </StyledTableCell>
                  ))}
                  {orderType === 'GROUPED_BUY_NOW' && (
                     <StyledTableCell sx={{ textAlign: 'center' }} width={250}>
                        {t('TRANSLATION:columnNames.orderTime')}
                     </StyledTableCell>
                  )}
               </TableRow>
            </TableHead>
            <TableBody>
               {orders.products.map((product) => (
                  <TableRow key={product.uuid}>
                     <StyledTableCell width={250}>{getProductName(product)}</StyledTableCell>
                     <StyledTableCell>
                        <ImageComponent
                           filename={product.producerNameWithoutSpecialChars?.toLowerCase()}
                           producerName={product.producerName}
                        />
                     </StyledTableCell>
                     <StyledTableCell>{product.amount}</StyledTableCell>
                     <StyledTableCell>
                        {priceType === 'NET' ? product.formattedClientNetPrice : product.formattedClientGrossPrice}
                     </StyledTableCell>
                     <StyledTableCell>
                        {priceType === 'NET' ? product.formattedClientNetValue : product.formattedClientGrossValue}
                     </StyledTableCell>
                     {orderType === 'GROUPED_BUY_NOW' && <StyledTableCell>{product.orderDate || ''}</StyledTableCell>}
                  </TableRow>
               ))}
               <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left' }} colSpan={4}>
                     {t('TRANSLATION:columnNames.grossValueSum')}:
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>{formattedClientValue}</StyledTableCell>
                  {orderType === 'GROUPED_BUY_NOW' && <StyledTableCell />}
               </TableRow>
               {orders.additionalServices.length > 0 &&
                  orders.additionalServices.map((service) => (
                     <TableRow key={service.uuid}>
                        <StyledTableCell colSpan={2}>{service.name}</StyledTableCell>
                        <StyledTableCell>{service.amount}</StyledTableCell>
                        <StyledTableCell>
                           {priceType === 'NET' ? service.formattedNetPrice : service.formattedGrossPrice}
                        </StyledTableCell>
                        <StyledTableCell>
                           {priceType === 'NET' ? service.formattedNetValue : service.formattedGrossValue}
                        </StyledTableCell>
                     </TableRow>
                  ))}
               {orders.additionalServices.length > 0 && (
                  <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <TableCell colSpan={3} />
                     <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'right', pl: 2 }}>
                        {`${t('TRANSLATION:columnNames.servicesSum')} ${t(`${priceType}`)}: `}
                     </StyledTableCell>
                     <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                        {formattedAdditionalServicesValue}
                     </StyledTableCell>
                  </TableRow>
               )}
            </TableBody>
         </Table>
         <Box sx={{ width: '100%', textAlign: 'right', pr: 3 }}>
            <Typography
               color="grayText"
               variant="h5"
            >{`${t('TRANSLATION:columnNames.sum')}: ${orders.additionalServices.length > 0 ? formattedClientValueWithServices : formattedClientValue}`}</Typography>
         </Box>
      </>
   )
}
