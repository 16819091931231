import { styled, TableBody } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
   textAlign: 'center',
   cursor: 'pointer',
   ...(theme.breakpoints.down('lg') && {
      padding: '8px',
   }),
}))

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
   textAlign: 'center',
   cursor: 'pointer',
   color: 'grayText',
   ...(theme.breakpoints.down('lg') && {
      padding: '8px',
   }),
}))

export const StyledCollapseTableCell = styled(TableCell)(({ theme }) => ({
   textAlign: 'center',
   cursor: 'pointer',
   fontWeight: 'bold',
   color: 'grayText',
   ...(theme.breakpoints.down('lg') && {
      padding: '8px',
   }),
}))

export const StyledTable = styled(Table)({
   backgroundColor: 'white',
   margin: '0 auto',
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px !important',
   '>*': {
      color: 'grayText',
   },
})

export const StyledTableHead = styled(TableHead)({
   backgroundColor: 'rgba(33,147,224, 0.1)',
   borderTopLeftRadius: '12px !important',
   borderTopRightRadius: '12px !important',
   '& > th:first-child': {
      borderTopLeftRadius: '10px',
   },
   '& > th:last-child': {
      borderTopRightRadius: '10px',
   },
})

export const StyledTableBody = styled(TableBody)<{ noFooter?: boolean }>(({ noFooter }) => ({
   ...(!!noFooter && {
      '& > tr:last-child': {
         borderBottomLeftRadius: '10px !important',
         borderBottomRightRadius: '10px !important',
         '& > td:first-child': {
            borderBottomLeftRadius: '10px !important',
         },
         '& > td:last-child': {
            borderBottomRightRadius: '10px !important',
         },
      },
   }),
}))

export const StyledTableRow = styled(TableRow)({
   backgroundColor: 'white',
   cursor: 'pointer',
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.03)',
   },
})

export const StyledTitleTableCell = styled(TableCell)({
   textAlign: 'center',
   color: '#637381',
   fontWeight: 'bold',
})

export const StyledContentTableCell = styled(TableCell)({
   padding: '6px 4px 6px 4px',
   textAlign: 'center',
})
