import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DoneIcon from '@mui/icons-material/Done'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import React from 'react'

import { useAddServiceMutation, useCartSummaryQuery } from 'shared/store/useCartSummary'
import { AdditionalServiceType } from 'shared/store/useAddtionalServices/types'
import useAdditionalServicesQuery from 'shared/store/useAddtionalServices'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useProfileQuery } from 'shared/store/useProfile'
import CustomTable from 'shared/components/CustomTable'

interface Props {
   handleClose: () => void
   cartUuid: string
}

export default function AddServiceModal({ handleClose, cartUuid }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { data: userData } = useProfileQuery()
   const { mutate, isLoading: isMutateLoading } = useAddServiceMutation()
   const { data: cart } = useCartSummaryQuery()
   const navigate = useNavigate()
   const { addNotification } = useNotificationContext()
   const { data } = useAdditionalServicesQuery(userData?.locationUuid)

   function addToCart(serviceUuid: string) {
      mutate(
         { cartUuid, serviceUuid },
         {
            onSuccess: () => addNotification('successSave', 'success'),
            onError: () => addNotification('failedSave', 'error'),
         }
      )
   }

   return (
      <>
         <DialogContent sx={{ minWidth: { lg: 400, md: 400, xs: 'auto' } }}>
            {!data ? (
               <Box sx={{ textAlign: 'center' }}>
                  <CircularProgress color="primary" />
               </Box>
            ) : (
               <Box>
                  <CustomTable
                     customNoResultsText={t('MAIN_CART:services.noServices')}
                     dataSize={data.additionalServices.length}
                     rows={data.additionalServices}
                     disablePagination
                     columns={[
                        {
                           name: 'serviceName',
                           renderCell: (row: AdditionalServiceType) => row.name,
                           mainOnMobile: true,
                        },
                        {
                           name: 'netPrice',
                           renderCell: (row: AdditionalServiceType) => row.formattedDefaultNetPrice,
                        },
                        {
                           name: 'VAT',
                           renderCell: (row: AdditionalServiceType) => `${row.taxRate}%`,
                           mainOnMobile: true,
                        },
                        {
                           name: 'grossPrice',
                           renderCell: (row: AdditionalServiceType) => row.formattedDefaultGrossPrice,
                           mainOnMobile: true,
                        },
                        {
                           name: '',
                           renderCell: (row: AdditionalServiceType) => (
                              <Button
                                 color={
                                    cart?.additionalServices.find((service) => row.uuid === service.uuid)
                                       ? 'success'
                                       : 'primary'
                                 }
                                 disabled={isMutateLoading}
                                 onClick={() => addToCart(row.uuid)}
                                 variant="outlined"
                              >
                                 {cart?.additionalServices.find((service) => row.uuid === service.uuid) ? (
                                    <Box sx={{ fontSize: 12, display: 'flex', justifyItems: 'center', gap: 1 }}>
                                       {t('MAIN_CART:services.addedServiceButton')} <DoneIcon color="success" />
                                    </Box>
                                 ) : (
                                    t('MAIN_CART:services.addServiceButton')
                                 )}
                              </Button>
                           ),
                        },
                     ]}
                  />
               </Box>
            )}
         </DialogContent>
         <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:close')}
            </OutlinedButton>
            <ContainedButton variant="contained" onClick={() => navigate('/dashboard/additional-services')}>
               {t('MAIN_CART:services.addNewServices')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
