import { Button } from '@mui/material'
import React, { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTranslation } from 'react-i18next'

import { useProfileQuery } from 'shared/store/useProfile'

interface Props {
   updateDirection: (columnIndex: number) => void
   value: string
   index: number
   label?: string
   showSorting?: boolean
   sortName: string
   sortDirection: Nullable<string>
   shouldShowPriceType?: boolean
}

export default function HeadLabel({
   updateDirection,
   value,
   showSorting,
   label,
   sortDirection,
   index,
   sortName,
   shouldShowPriceType,
}: Props) {
   const { t } = useTranslation('TRANSLATION')
   const { data } = useProfileQuery()
   const isFocused = (sortName === value && sortDirection !== 'null') || showSorting
   const [hovered, setHovered] = useState(false)

   const priceType = data?.priceType

   return (
      <Button
         onClick={() => updateDirection(index)}
         onMouseOver={() => setHovered(true)}
         onMouseOut={() => setHovered(false)}
         style={{
            color: isFocused ? 'black' : 'GrayText',
            ...(showSorting && {
               color: 'GrayText',
            }),
            cursor: 'pointer',
            // fontSize: 13,
            textAlign: 'center',
         }}
      >
         {`${label ?? t(`columnNames.${value}`)} ${priceType && shouldShowPriceType ? t(priceType) : ''}`}
         <ArrowDownwardIcon
            sx={{
               fontSize: 13,
               ml: 0.5,
               opacity: 0,
               ...(showSorting && {
                  color: 'GrayText',
               }),
               ...(isFocused && {
                  opacity: 1,
               }),
               ...(hovered && {
                  opacity: 0.7,
               }),
               transition: '300ms',
               transform: sortDirection === 'asc' ? 'rotate(180deg)' : 'none',
            }}
         />
      </Button>
   )
}
