import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import { useMediaQuery, useTheme } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Tooltip from '@mui/material/Tooltip'
import Input from '@mui/material/Input'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import CustomDialog from 'shared/components/CustomDialog'
import CustomTable from 'shared/components/CustomTable'

import ImageComponent from 'shared/components/ImageComponent'
import { SearchResponseType, ReflinkDataType, SearchProductType, AddProductPayload } from '../../types'
import { addProductService } from '../../services/addProductService'
import { StyledAvailabilityDot, StyledSpan } from '../../styles'

interface Props {
   searchPhrase: string
   data?: SearchResponseType
   open: boolean
   updateData: (data: ReflinkDataType) => void
   handleClose: () => void
}
export default function SearchModal({ searchPhrase, data, open, handleClose, updateData }: Props) {
   const { addNotification } = useNotificationContext()
   const [addToCartDetails, setAddToCartDetails] = useState<AddProductPayload>({
      quantity: 1,
      uuid: '',
      index: '',
   })
   const { t } = useTranslation(['CLIENT_CART', 'TRANSLATION'])
   const [isAdding, setIsAdding] = useState(false)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function foundResultsString(resultsCount: number) {
      if (resultsCount === 0) return 'notFound'
      return ''
   }

   function returnProductName(productName: string, productIndex: string) {
      if (productName) {
         if (productName.length > 100) {
            return `${productName.slice(0, 100)}...`
         }
         return productName
      }
      return productIndex
   }

   function returnAvailabilityDot(availability: number) {
      switch (true) {
         case availability === 0:
            return <StyledAvailabilityDot color="red" />
         case availability === 1:
            return <StyledAvailabilityDot color="orange" />
         case availability === -1:
            return <StyledAvailabilityDot color="gray" />
         case availability > 1:
            return <StyledAvailabilityDot color="green" />
         default:
            return null
      }
   }

   function handleAddProduct() {
      const uuid = window.localStorage.getItem('uuid')
      const token = window.localStorage.getItem('reflink-verify-token')
      if (uuid && token) {
         setIsAdding(true)
         addProductService(uuid, token, addToCartDetails.uuid, addToCartDetails.quantity, addToCartDetails.index)
            .then((res) => {
               addNotification(t('CLIENT_CART:searchModal.productAdded'), 'success')
               if (res.data) {
                  updateData(res.data)
               }
               handleClose()
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  if (error.response?.data.errors[0].code === 'errors.authentication.reflink-code-expired') {
                     addNotification(t('CLIENT_CART:expired'), 'error')
                     window.localStorage.removeItem('reflink-verify-token')
                  } else {
                     addNotification(
                        error.response?.data?.errors[0].code || t('CLIENT_CART:searchModal.productAddFailed'),
                        'error'
                     )
                  }
               } else {
                  addNotification(t('CLIENT_CART:searchModal.productAddFailed'), 'error')
               }
            })
            .finally(() => setIsAdding(false))
      }
   }

   function renderContent() {
      if (addToCartDetails.uuid) {
         return (
            <Box>
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                  <Typography variant="h6" color="primary">
                     {t('TRANSLATION:columnNames.pickQuantity')}
                  </Typography>
                  <Input
                     disabled={isAdding}
                     fullWidth
                     type="Number"
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           handleAddProduct()
                        }
                     }}
                     value={addToCartDetails.quantity}
                     onChange={(e) => setAddToCartDetails({ ...addToCartDetails, quantity: Number(e.target.value) })}
                     inputProps={{ min: 1, max: 600, sx: { textAlign: 'center' } }}
                  />
               </Box>
            </Box>
         )
      }
      if (data) {
         return (
            <Box>
               <div
                  style={{
                     margin: '0 auto',
                     display: 'flex',
                     flexDirection: data.resultsCount >= 250 || data.resultsCount === 0 ? 'column' : 'row',
                     gap: 5,
                  }}
               >
                  <Typography variant="body1" color="text.secondary">
                     {t('CLIENT_CART:searchedPhrase')}
                     <StyledSpan>{searchPhrase}</StyledSpan>
                  </Typography>
                  {data.resultsCount === 0 || data.resultsCount === 250 ? (
                     <Typography variant="h6" sx={{ color: 'red', textTransform: 'upperCase', margin: '0 auto' }}>
                        {t(`CLIENT_CART:${foundResultsString(data.resultsCount)}`)}
                     </Typography>
                  ) : (
                     <Typography variant="body1" sx={{ color: 'grayText' }}>
                        {t('CLIENT_CART:found')} <StyledSpan> {data ? data.resultsCount : 0} </StyledSpan>
                     </Typography>
                  )}
               </div>
               {data.products.length > 0 && (
                  <CustomTable
                     rows={data.products}
                     onRowClick={(row: SearchProductType) =>
                        setAddToCartDetails({ quantity: 1, index: row.index, uuid: row.uuid })
                     }
                     dataSize={data.products.length}
                     pagingVariants={[10, 25, 50]}
                     columns={[
                        {
                           name: isMobile ? 'indexAndName' : 'partIndex',
                           sortable: true,
                           mainOnMobile: true,
                           sortLabel: 'index',
                           sortType: 'string',
                           renderCell: (row: SearchProductType) =>
                              isMobile ? (
                                 <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{row.index.length > 30 ? `${row.index.slice(0, 30)}...` : row.index}</span>
                                    <span>{row.name}</span>
                                 </Box>
                              ) : (
                                 <Box>{row.index.length > 30 ? `${row.index.slice(0, 30)}...` : row.index}</Box>
                              ),
                        },
                        {
                           name: 'productName',
                           sortable: true,
                           sortLabel: 'name',
                           sortType: 'string',
                           renderCell: (row: SearchProductType) => returnProductName(row.name, row.index),
                        },
                        {
                           name: 'producer',
                           mainOnMobile: true,
                           sortable: true,
                           sortLabel: 'producer',
                           sortType: 'string',
                           renderCell: (row: SearchProductType) => (
                              <Tooltip title={row.producer}>
                                 <ImageComponent
                                    height={isMobile ? '15px' : '20px'}
                                    filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                                    producerName={row.producer}
                                 />
                              </Tooltip>
                           ),
                        },
                        {
                           name: 'availability',
                           sortable: true,
                           mainOnMobile: true,
                           sortLabel: 'availability',
                           sortType: 'number',
                           renderCell: (row: SearchProductType) => returnAvailabilityDot(row.availability),
                        },
                        {
                           name: '',
                           onlyOnMobile: true,
                           renderCell: (row: SearchProductType) => (
                              <ContainedButton
                                 variant="contained"
                                 onClick={() =>
                                    setAddToCartDetails({
                                       uuid: row.uuid,
                                       index: row.index,
                                       quantity: 1,
                                    })
                                 }
                              >
                                 {t('TRANSLATION:add')}
                              </ContainedButton>
                           ),
                        },
                     ]}
                  />
               )}
            </Box>
         )
      }
      return (
         <Box sx={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress />
         </Box>
      )
   }

   useEffect(() => {
      setAddToCartDetails({
         quantity: 1,
         uuid: '',
         index: '',
      })
   }, [open])

   return (
      <CustomDialog
         isLoading={!open || !addToCartDetails}
         maxWidth="xl"
         fullScreen={isMobile}
         fullWidth={!addToCartDetails.uuid}
         open={open}
         onClose={handleClose}
         content={
            <>
               <DialogTitle>
                  <Typography variant="h4" color="primary" textAlign="center">
                     {t('CLIENT_CART:searchModal.searchResults')}
                  </Typography>
               </DialogTitle>
               <DialogContent sx={{ p: { xs: 1 } }}>{renderContent()}</DialogContent>
               <DialogActions>
                  {addToCartDetails.uuid ? (
                     <Box sx={{ display: 'flex', gap: 1, margin: '0 auto' }}>
                        <OutlinedButton
                           disabled={isAdding}
                           variant="outlined"
                           onClick={() => setAddToCartDetails({ quantity: 1, uuid: '', index: '' })}
                        >
                           {t('TRANSLATION:cancel')}
                        </OutlinedButton>
                        <ContainedButton disabled={isAdding} variant="contained" onClick={handleAddProduct}>
                           {t('TRANSLATION:save')}
                        </ContainedButton>
                     </Box>
                  ) : (
                     <ContainedButton
                        disabled={isAdding}
                        sx={{ margin: '0 auto' }}
                        variant="contained"
                        onClick={handleClose}
                     >
                        {t('CLIENT_CART:close')}
                     </ContainedButton>
                  )}
               </DialogActions>
            </>
         }
      />
   )
}
