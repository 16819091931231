import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { useMessagesQuery, useChangeMessageStatusMutation, usePagingMessages } from 'shared/store/useMessages'

import { MessageType } from 'shared/store/useMessages/types'
import CustomTable from 'shared/components/CustomTable'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { pl, en, de } from './locales'
import Modal from './components/Modal'

export default function Messages() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'MESSAGES'])
   const [activeMessage, setActiveMessage] = useState<MessageType>()
   const [itemsPerPage, setItemsPerPage] = useState(10)
   const { mutate } = useChangeMessageStatusMutation()
   const [activePage, setActivePage] = useState(0)
   const { data } = useMessagesQuery(activePage, itemsPerPage)
   const { mutate: pagingMutate } = usePagingMessages()
   i18n.addResourceBundle('pl-PL', 'MESSAGES', pl)
   i18n.addResourceBundle('en-EN', 'MESSAGES', en)
   i18n.addResourceBundle('de-DE', 'MESSAGES', de)

   function handleModalOpen(messageUuid: string) {
      const tempActiveMessage = data?.messages.find((item) => item.messageUuid === messageUuid)

      if (tempActiveMessage) {
         if (!tempActiveMessage.read) {
            const payload = {
               messageUuid,
            }
            mutate(payload, {
               onSettled: () => setActiveMessage(tempActiveMessage),
            })
         } else {
            setActiveMessage(tempActiveMessage)
         }
      }
      setActiveMessage(tempActiveMessage)
   }

   function renderMessage(messageText: Nullable<string>) {
      if (messageText) {
         return messageText.length > 40
            ? `${messageText.replaceAll('#', '').split('.')[0]}...`
            : messageText.replaceAll('#', '')
      }
      return ''
   }

   return (
      <>
         <Box sx={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}>
            <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal', mb: 2 }}>
               {t('MESSAGES:messages')}
            </Typography>
            {data ? (
               <CustomTable
                  onRowClick={(row: any) => handleModalOpen(row.messageUuid)}
                  customRowStyles={(row: any) => ({
                     '>td': {
                        padding: '8px 4px 8px 4px',
                        fontWeight: row.read ? 'normal' : 'bold',
                     },
                  })}
                  customChangePage={(page: number, rowsPerPage: number) => {
                     setActivePage(page)
                     pagingMutate({ page, pageSize: rowsPerPage })
                  }}
                  customChangeRowsPerPage={(page: number, rowsPerPage: number) => {
                     setItemsPerPage(rowsPerPage)
                     pagingMutate({ page: 0, pageSize: rowsPerPage })
                  }}
                  dataSize={data.totalCount}
                  activePage={data.page}
                  pagingVariants={[5, 10, 15, 20]}
                  rowsPerPage={data.pageSize}
                  columns={[
                     {
                        name: 'createdDate',
                        renderCell: (row: MessageType) => row.createdDate,
                        mainOnMobile: true,
                     },
                     {
                        name: 'subject',
                        renderCell: (row: MessageType) => row.title,
                        mainOnMobile: true,
                     },
                     {
                        name: 'content',
                        customCellStyles: () => ({
                           maxWidth: 250,
                        }),
                        renderCell: (row: MessageType) => renderMessage(row.text),
                     },
                     {
                        name: '',
                        renderCell: (row: MessageType) => (
                           <ContainedButton
                              variant="contained"
                              onClick={(e) => {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 handleModalOpen(row.messageUuid)
                              }}
                           >
                              {t('TRANSLATION:showButton')}
                           </ContainedButton>
                        ),
                        mainOnMobile: true,
                        onlyOnMobile: true,
                     },
                  ]}
                  rows={data.messages}
               />
            ) : (
               <div style={{ width: '100%', textAlign: 'center' }}>
                  <CircularProgress color="primary" />
               </div>
            )}
         </Box>
         <Modal
            open={!!activeMessage}
            message={activeMessage?.text}
            title={activeMessage?.title}
            handleClose={() => setActiveMessage(undefined)}
         />
      </>
   )
}
