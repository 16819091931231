import React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

import CustomDialog from 'shared/components/CustomDialog'

import DeleteLocationModal from './components/DeleteLocationModal'
import NewLocationModal from './components/NewLocationModal'
import { FormType } from '../../types'

interface Props {
   open: boolean
   type?: FormType
   handleClose: () => void
   country?: string
   locationUuid?: string
}

export default function Modal({ open, type, handleClose, locationUuid, country }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const theme = useTheme()

   function renderForm() {
      switch (type) {
         case 'newLocation':
            return <NewLocationModal country={country} handleClose={handleClose} />
         case 'deleteLocation':
            return <DeleteLocationModal locationUuid={locationUuid} handleClose={handleClose} />
         default:
            return null
      }
   }
   return (
      <CustomDialog
         open={open}
         onClose={handleClose}
         isLoading={!open}
         content={
            <>
               <DialogTitle>
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
                     {t(`LOCATIONS:${type}`)}
                  </Typography>
               </DialogTitle>
               <DialogContent>{renderForm()}</DialogContent>
            </>
         }
      />
   )
}
