import { Box, Card, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Print } from '@mui/icons-material'

import { useLogisticMinimumsQuery } from 'shared/store/useLogisticMinimums'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { MinimumType } from 'shared/store/useLogisticMinimums/types'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { useClientPrices } from 'shared/hooks/ClientPriceHook'
import { useProfileQuery } from 'shared/store/useProfile'
import CustomTable from 'shared/components/CustomTable'
import InputField from 'shared/components/InputField'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'
import {
   FetchCartSummaryResult,
   Product,
   RemoveProductPayload,
   UpdateCartInfoPayload,
   UpdateProductType,
} from 'shared/store/useCartSummary/types'

import {
   useCartSummaryQuery,
   useNewCartMutation,
   useRemoveProductMutation,
   useUpdateCartInfoMutation,
   useUpdatePricesMutation,
   useUpdateProductMutation,
} from 'shared/store/useCartSummary'

import returnMinimumDeliveryTableCell from './helpers/returnMinimumDeliveryTableCell'
import { CartDetailsType, ModalInfoType, UpdatePricesDiffType } from './types'
import AdditionalServicesTable from './components/AdditionalServicesTable'
import UnavailableProductTable from './components/UnavailableProductTable'
import generateReflink from './helpers/generateReflink'
import MainTable from './components/MainTable'
import Modal from './components/Modal'
import { pl, en, de } from './locales'
import schema from './schema'

export default function MainCart() {
   const { addNotification } = useNotificationContext()
   const [isOrdering, setIsOrdering] = useState(false)
   const { i18n } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const navigate = useNavigate()
   const queryClient = useQueryClient()
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()
   const { clientPricesOn } = useClientPrices()
   const [clientView, setClientView] = useState(!!clientPricesOn)
   const [oldData, setOldData] = useState<FetchCartSummaryResult>()
   const { data: summaryData, isFetched, isFetching, isError, error, isLoading } = useCartSummaryQuery()
   const { data: logisticMinimums, isFetched: isLogisticMinimumsFetched } = useLogisticMinimumsQuery(false)
   const { mutate: createNewCartMutate, isLoading: createCartIsLoading } = useNewCartMutation()
   const { mutate: updatePricesMutate, isLoading: updatePriceLoading } = useUpdatePricesMutation()
   const { mutate: updateCartInfoMutate, isLoading: updateCartIsLoading } = useUpdateCartInfoMutation()
   const { mutate: removeProductFromCartMutate } = useRemoveProductMutation()
   const { mutate, isLoading: isProductUpdating } = useUpdateProductMutation()
   const { user } = useLogin()
   const location = useLocation()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const { data: profileData } = useProfileQuery()

   async function onOrderSubmit() {
      setIsOrdering(true)
      await httpClient
         .post('/orders/')
         .then((res) => {
            if (res.status === 200) {
               navigate('/dashboard/order-summary', { state: res.data })
            } else if (res.status === 204) {
               addNotification('processingOrder', 'success')
            }
         })
         .catch(() => {
            addNotification('failedOrder', 'error')
         })
         .finally(() => {
            invalidateCarts(queryClient)
            setIsOrdering(false)
         })
   }

   function updateProduct(uuid: string, quantity?: number, includeProductInOrder?: boolean, netClientPrice?: number) {
      if (summaryData) {
         const mutatePayload: UpdateProductType = {
            payload: {
               quantity,
               netClientPrice,
               includeProductInOrder,
            },
            cartUuid: summaryData.cartDetailsDTO.cartUuid,
            cartProductUuid: uuid,
         }
         mutate(mutatePayload, {
            onSuccess: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         })
      }
   }

   const formik = useFormik<CartDetailsType>({
      initialValues: {
         cartDescription: '',
         cartName: '',
         cartUuid: '',
      },
      validationSchema: schema,
      onSubmit: () => {},
   })

   const { setValues, values } = formik

   function changeCartName() {
      if (values.cartName === summaryData?.cartDetailsDTO.cartName) {
         addNotification('TRANSLATION:apiErrors.nothingChanged', 'error')
      } else if (values.cartUuid) {
         const payload: UpdateCartInfoPayload = {
            payload: {
               cartName: values.cartName,
            },
            cartUuid: values.cartUuid,
         }
         updateCartInfoMutate(payload)
      }
   }

   function changeCartDescription() {
      if (values.cartDescription === summaryData?.cartDetailsDTO.cartDescription) {
         addNotification('TRANSLATION:apiErrors.nothingChanged', 'error')
      } else if (values.cartUuid) {
         const payload: UpdateCartInfoPayload = {
            payload: {
               cartDescription: values.cartDescription,
            },
            cartUuid: values.cartUuid,
         }
         updateCartInfoMutate(payload)
      }
   }

   function handleRemoveProduct(uuid: string, removeFromUnavailable?: boolean) {
      if (values.cartUuid) {
         const payload: RemoveProductPayload = {
            cartUuid: values.cartUuid,
            uuid,
            removeFromUnavailable,
         }
         removeProductFromCartMutate(payload, {
            onSettled: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         })
      }
   }

   function handleUpdateCart(cartUuid: string) {
      setOldData(summaryData)
      updatePricesMutate(cartUuid, {
         onSuccess: (data) => checkDiffs(data.products),
      })
   }

   function checkDiffs(data: Product[]) {
      const updatePriceDiffs: UpdatePricesDiffType = []
      if (oldData && data) {
         oldData.products
            .filter((item) => item.status === 'AVAILABLE')
            .forEach((item) => {
               const newProduct: Product | undefined = data
                  .filter((newItem) => newItem.status === 'AVAILABLE')
                  .find((newItem) => item.uuid === newItem.uuid)
               if (newProduct) {
                  if (newProduct.cartWholesaleDTO.netPrice !== item.cartWholesaleDTO.netPrice) {
                     updatePriceDiffs.push({
                        wholesaleName: newProduct.cartWholesaleName,
                        productIndex: newProduct.index,
                        reason: 'PRICE',
                        priceBefore:
                           profileData?.priceType === 'NET'
                              ? item.cartWholesaleDTO.formattedNetPrice
                              : item.cartWholesaleDTO.formattedGrossPrice,
                        priceAfter:
                           profileData?.priceType === 'NET'
                              ? newProduct.cartWholesaleDTO.formattedNetPrice
                              : newProduct.cartWholesaleDTO.formattedGrossPrice,
                     })
                  } else if (newProduct.cartWholesaleDTO.quantity !== item.cartWholesaleDTO.quantity) {
                     updatePriceDiffs.push({
                        wholesaleName: newProduct.cartWholesaleName,
                        productIndex: newProduct.index,
                        reason: 'AVAILABILITY',
                     })
                  }
               }
            })
      }
      if (updatePriceDiffs.length > 0) {
         setModalInfo({
            modalType: 'AFTER_UPDATE',
            updatePricesDiffs: updatePriceDiffs,
         })
      }
   }

   function returnWholesaleMinimumSum(wholesalerUuid: string) {
      return `${summaryData?.products
         .filter(
            (product) =>
               product.cartWholesaleDTO.wholesaleUuid === wholesalerUuid &&
               product.status === 'AVAILABLE' &&
               product.includedProductInOrder
         )
         .reduce((acc, current) => acc + current.cartWholesaleDTO.netPrice * current.cartWholesaleDTO.quantity, 0)
         .toFixed(2)} zł`
   }

   i18n.addResourceBundle('pl-PL', 'MAIN_CART', pl)
   i18n.addResourceBundle('en-EN', 'MAIN_CART', en)
   i18n.addResourceBundle('de-DE', 'MAIN_CART', de)

   useEffect(() => {
      if (summaryData) {
         setOldData(summaryData)
         setValues({
            ...summaryData.cartDetailsDTO,
         })
      }
   }, [summaryData, isFetching])

   useEffect(() => {
      setModalInfo(undefined)
   }, [profileData?.locationUuid])

   useEffect(() => {
      if (isError) {
         navigate('/dashboard/error-page', {
            state: {
               variant: 'backend',
               path: location.pathname,
               error: {
                  message: error,
                  stack: 'unknown',
               },
            },
         })
      }
   }, [isError, isLoading])

   useEffect(() => {
      queryClient.invalidateQueries('userProfile')
   }, [])

   useEffect(() => {
      if (clientPricesOn) {
         setClientView(true)
      }
   }, [clientPricesOn])

   return (
      <Box>
         <FormikProvider value={formik}>
            <Form>
               {isFetched && summaryData ? (
                  <>
                     <Typography variant="body1" color="primary" sx={{ mb: '8px' }}>
                        {t('MAIN_CART:availableProducts')}
                     </Typography>
                     <Card sx={{ backgroundColor: 'rgba(33, 147, 224, 0.1)' }}>
                        <Box
                           sx={{
                              display: 'flex',
                              m: '16px 16px 0px 16px',
                              flexDirection: { lg: 'row', md: 'row', xs: 'column' },
                              gap: '12px',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                           }}
                        >
                           <InputField<CartDetailsType>
                              name="cartName"
                              onBlur={changeCartName}
                              disabled={updateCartIsLoading}
                              fullWidth={false}
                              inputProps={{
                                 maxLength: 50,
                              }}
                              variant="standard"
                              onFocus={(e) => e.target.select()}
                              label={t('MAIN_CART:cartName')}
                           />
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              {!clientPricesOn && (
                                 <Box sx={{ display: 'flex', gap: 1 }}>
                                    {clientView ? (
                                       <ContainedButton
                                          type="button"
                                          variant="contained"
                                          onClick={() => setClientView((prevState) => !prevState)}
                                       >
                                          {t('MAIN_CART:clientView')}
                                       </ContainedButton>
                                    ) : (
                                       <OutlinedButton
                                          type="button"
                                          variant="outlined"
                                          onClick={() => setClientView((prevState) => !prevState)}
                                       >
                                          {t('MAIN_CART:clientView')}
                                       </OutlinedButton>
                                    )}
                                 </Box>
                              )}
                              <ContainedButton
                                 type="button"
                                 disabled={createCartIsLoading}
                                 onClick={createNewCartMutate}
                                 variant="contained"
                                 color="primary"
                              >
                                 {t('MAIN_CART:createCart')}
                              </ContainedButton>
                           </Box>
                        </Box>
                        <MainTable
                           formattedClientGrossValue={summaryData.formattedClientGrossValue}
                           formattedClientNetValue={summaryData.formattedClientNetValue}
                           formattedGrossValue={summaryData.formattedGrossValue}
                           formattedNetValue={summaryData.formattedNetValue}
                           clientView={clientView}
                           setModalInfo={setModalInfo}
                           products={summaryData.products}
                           handleProductUpdate={updateProduct}
                           isProductUpdating={isProductUpdating}
                        />
                     </Card>
                     {summaryData.additionalServices.length > 0 && (
                        <AdditionalServicesTable
                           formattedAdditionalServicesGrossValue={summaryData.formattedAdditionalServicesGrossValue}
                           formattedAdditionalServicesNetValue={summaryData.formattedAdditionalServicesNetValue}
                           additionalServices={summaryData.additionalServices}
                           cartUuid={summaryData.cartDetailsDTO.cartUuid}
                           setModalInfo={setModalInfo}
                           clientView={clientView}
                        />
                     )}
                     {!clientView &&
                        summaryData.products.filter((item) => item.status === 'NOT_AVAILABLE').length > 0 && (
                           <UnavailableProductTable
                              products={summaryData.products.filter((item) => item.status === 'NOT_AVAILABLE')}
                              setModalInfo={setModalInfo}
                           />
                        )}
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'flex-end',
                           gap: 5,
                           margin: '0 auto',
                           paddingTop: 2,
                        }}
                     >
                        <Box sx={{ display: 'flex', gap: 1 }}>
                           <Typography
                              color="grayText"
                              variant="h6"
                           >{`${t('TRANSLATION:columnNames.netSum')}:`}</Typography>
                           <Typography variant="h6">
                              {summaryData.formattedClientNetValueWithAdditionalServices}
                           </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                           <Typography
                              variant="h6"
                              color="grayText"
                           >{`${t('TRANSLATION:columnNames.grossSum')}:`}</Typography>
                           <Typography variant="h6">
                              {summaryData.formattedClientGrossValueWithAdditionalServices}
                           </Typography>
                        </Box>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'flex-end',
                           flexWrap: 'wrap',
                           gap: 2,
                           margin: '0 auto',
                           paddingTop: 2,
                        }}
                     >
                        <OutlinedButton
                           sx={{ alignSelf: 'flex-start' }}
                           onClick={() =>
                              setModalInfo({
                                 modalType: 'SERVICES',
                                 uuid: summaryData.cartDetailsDTO.cartUuid,
                              })
                           }
                           variant="outlined"
                        >
                           {t('MAIN_CART:services.addService')}
                        </OutlinedButton>
                        <OutlinedButton
                           disabled={summaryData?.products.length === 0}
                           onClick={() =>
                              generateReflink({
                                 summaryData,
                                 setModalInfo,
                                 onFailed: () => addNotification(t('MAIN_CART:generateFailed'), 'error'),
                              })
                           }
                           variant="outlined"
                        >
                           <LinkIcon />
                        </OutlinedButton>
                        {!isMobile && (
                           <OutlinedButton
                              disabled={summaryData?.products.length === 0}
                              onClick={() =>
                                 setModalInfo({
                                    modalType: 'PRINT',
                                    pricingVariant: clientView ? 'CLIENT' : 'FULL',
                                 })
                              }
                              variant="outlined"
                           >
                              <Print color={summaryData?.products.length === 0 ? 'disabled' : 'primary'} />
                           </OutlinedButton>
                        )}
                        {!user.roles.includes(UserRoles.DEMO) ? (
                           <OutlinedButton
                              disabled={updatePriceLoading || summaryData?.products.length === 0}
                              onClick={() => {
                                 if (summaryData) {
                                    handleUpdateCart(summaryData.cartDetailsDTO.cartUuid)
                                 }
                              }}
                              variant="outlined"
                           >
                              {t('MAIN_CART:updatePrices')}
                           </OutlinedButton>
                        ) : (
                           <OutlinedButton variant="outlined">{t('MAIN_CART:updatePrices')}</OutlinedButton>
                        )}
                        <ContainedButton
                           onClick={onOrderSubmit}
                           disabled={
                              isProductUpdating ||
                              summaryData?.products.length === 0 ||
                              !summaryData?.products.find((item) => item.includedProductInOrder) ||
                              isOrdering ||
                              user?.roles.includes(UserRoles.SALES_REP)
                           }
                           variant="contained"
                        >
                           {t('MAIN_CART:order')}
                        </ContainedButton>
                     </Box>
                     {!clientView && (
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: { lg: 'row', md: 'row', xs: 'column' },
                              gap: 3,
                              margin: '0 auto',
                              marginTop: 6,
                              minHeight: 200,
                           }}
                        >
                           {logisticMinimums?.logisticMinimums && logisticMinimums.logisticMinimums.length > 0 && (
                              <Box
                                 sx={{
                                    flex: { lg: 0.7, md: 0.7, xs: 1 },
                                 }}
                              >
                                 {isLogisticMinimumsFetched && logisticMinimums ? (
                                    <CustomTable
                                       rows={logisticMinimums.logisticMinimums}
                                       disablePagination
                                       dataSize={logisticMinimums.logisticMinimums.length}
                                       columns={[
                                          {
                                             name: 'wholesaler',
                                             renderCell: (row: MinimumType) => (
                                                <HiddenWholesalerLogo
                                                   wholesalerName={row.wholesaleName}
                                                   imgHeight={isMobile ? '15px' : '20px'}
                                                   imgWidth="auto"
                                                />
                                             ),
                                             mainOnMobile: true,
                                          },
                                          {
                                             name: 'wholesaleProductsSumNet',
                                             renderCell: (row: MinimumType) =>
                                                returnWholesaleMinimumSum(row.wholesaleUuid),
                                          },
                                          {
                                             name: 'remainLogisticMinimumsNet',
                                             renderCell: (row: MinimumType) => row.formattedRemainingAmount,
                                             mainOnMobile: true,
                                          },
                                          {
                                             name: 'timeUntilDeliveryStart',
                                             renderCell: (row: MinimumType) =>
                                                returnMinimumDeliveryTableCell(row.minutesToDeliveryStart),
                                             mainOnMobile: true,
                                          },
                                       ]}
                                    />
                                 ) : (
                                    <CircularProgress sx={{ m: '0 auto' }} color="primary" />
                                 )}
                              </Box>
                           )}
                           <InputField<CartDetailsType>
                              name="cartDescription"
                              onBlur={changeCartDescription}
                              disabled={updateCartIsLoading}
                              sx={{ minHeight: 200, ml: 'auto', flex: { lg: 0.3, md: 0.3, xs: 1 } }}
                              multiline
                              rows={7}
                              label={t('MAIN_CART:cartDescription')}
                           />
                        </Box>
                     )}
                     <Modal
                        modalInfo={modalInfo}
                        data={summaryData}
                        handleClose={() => setModalInfo(undefined)}
                        handleProductUpdate={updateProduct}
                        handleRemoveProductFromCart={handleRemoveProduct}
                     />
                  </>
               ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress />
                  </Box>
               )}
            </Form>
         </FormikProvider>
      </Box>
   )
}
