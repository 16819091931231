import { TableRow, useTheme } from '@mui/material'
import TableFooter from '@mui/material/TableFooter'
import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import { Close } from '@mui/icons-material'
import Box from '@mui/material/Box'

import { AdditionalServiceCartType, EditServicePayload } from 'shared/store/useCartSummary/types'
import { StyledTableCell } from 'shared/components/StyledTableComponents'
import { useUpdateServiceMutation } from 'shared/store/useCartSummary'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import CustomTable from 'shared/components/CustomTable'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import { QuantityHolder, StyledQuantityButton, StyledInput } from '../../styles'
import { ClientViewPricesType, ModalInfoType } from '../../types'

interface Props {
   setModalInfo: (modalInfo: ModalInfoType | undefined) => void
   additionalServices: AdditionalServiceCartType[]
   formattedAdditionalServicesNetValue: string
   formattedAdditionalServicesGrossValue: string
   cartUuid: string
   clientView: boolean
}

export default function AdditionalServicesTable({
   setModalInfo,
   formattedAdditionalServicesGrossValue,
   formattedAdditionalServicesNetValue,
   additionalServices,
   clientView,
   cartUuid,
}: Props) {
   const [clientPrices, setClientPrices] = useState<ClientViewPricesType>([])
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { mutate, isLoading } = useUpdateServiceMutation()
   const theme = useTheme()
   const queryClient = useQueryClient()

   function changeClientPrice(uuid: string, clientPrice: number, taxRate: number, type: 'NET' | 'GROSS') {
      let tempClientPrice
      if (type === 'NET') {
         tempClientPrice = (clientPrice * (1 + taxRate / 100)).toFixed(2)
         updateProduct(uuid, undefined, clientPrice.toFixed(2), tempClientPrice)
      } else {
         tempClientPrice = (clientPrice / (1 + taxRate / 100)).toFixed(2)
         updateProduct(uuid, undefined, tempClientPrice, clientPrice.toFixed(2))
      }
   }

   function updateProduct(uuid: string, quantity?: number, netPrice?: string, grossPrice?: string) {
      const mutatePayload: EditServicePayload = {
         netPrice,
         grossPrice,
         quantity,
      }
      mutate(
         { payload: mutatePayload, cartUuid, serviceUuid: uuid },
         {
            onSuccess: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         }
      )
   }

   function changeStateClientPrice(newClientPrice: number, uuid: string, taxRate: number) {
      setClientPrices((prevState) => {
         const newState = prevState.map((product) => {
            if (product.uuid === uuid) {
               return {
                  ...product,
                  clientPriceNet: Number(newClientPrice.toFixed(2)),
                  clientPriceGross: Number((newClientPrice * (1 + taxRate / 100)).toFixed(2)),
               }
            }
            return product
         })

         return newState
      })
   }

   useEffect(() => {
      const tempClientPrices: ClientViewPricesType = []
      additionalServices.forEach((product) => {
         tempClientPrices.push({
            uuid: product.uuid,
            clientPriceNet: Number(product.netPrice),
            clientPriceGross: Number(product.grossPrice),
         })
      })
      setClientPrices([...tempClientPrices])
   }, [additionalServices])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '16px', gap: '8px' }}>
         <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
            {t('MAIN_CART:additionalServices')}
         </Typography>
         <CustomTable
            rows={additionalServices}
            dataSize={additionalServices.length}
            customFooter={(columnsCount) => (
               <TableFooter sx={{ backgroundColor: '#EBEBEB' }}>
                  <TableRow>
                     <StyledTableCell colSpan={columnsCount}>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: '32px',
                              pr: { lg: '32px', md: '32px' },
                              width: '100%',
                              justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'space-evenly' },
                           }}
                        >
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>{t('TRANSLATION:columnNames.netSum')}</p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                 {formattedAdditionalServicesNetValue}
                              </p>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                              <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                 {t('TRANSLATION:columnNames.grossSum')}
                              </p>
                              <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                 {formattedAdditionalServicesGrossValue}
                              </p>
                           </Box>
                        </Box>
                     </StyledTableCell>
                  </TableRow>
               </TableFooter>
            )}
            disablePagination
            columns={[
               {
                  name: 'serviceName',
                  renderCell: (row: AdditionalServiceCartType) => row.name,
                  mainOnMobile: true,
               },
               {
                  name: 'quantity',
                  preventOnClick: true,
                  renderCell: (row: AdditionalServiceCartType) => (
                     <QuantityHolder>
                        <StyledQuantityButton
                           type="button"
                           disabled={row.quantity === 1 || isLoading}
                           onClick={() => updateProduct(row.uuid, row.quantity - 1)}
                        >
                           -
                        </StyledQuantityButton>
                        <StyledQuantityButton
                           type="button"
                           onClick={() =>
                              setModalInfo({
                                 modalType: 'QUANTITY',
                                 serviceUuid: row.uuid,
                                 changeServiceQuantity: true,
                                 uuid: cartUuid,
                                 quantity: row.quantity,
                              })
                           }
                           style={{ fontWeight: 'normal' }}
                        >
                           {row.quantity}
                        </StyledQuantityButton>
                        <StyledQuantityButton
                           type="button"
                           disabled={row.quantity === MAX_PRODUCT_QUANTITY || isLoading}
                           onClick={() => updateProduct(row.uuid, row.quantity + 1)}
                        >
                           +
                        </StyledQuantityButton>
                     </QuantityHolder>
                  ),
                  mainOnMobile: true,
               },
               {
                  name: 'netPrice',
                  renderCell: (row: AdditionalServiceCartType) =>
                     clientView ? (
                        <>
                           <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                              <StyledInput
                                 onBlur={(e: any) =>
                                    changeClientPrice(row.uuid, Number(e.target.value), row.taxRate, 'NET')
                                 }
                                 onFocus={(e: any) => e.target.select()}
                                 type="Number"
                                 onChange={(e: any) =>
                                    changeStateClientPrice(Number(e.target.value), row.uuid, row.taxRate)
                                 }
                                 value={clientPrices.find((item) => item.uuid === row.uuid)?.clientPriceNet}
                              />
                           </Tooltip>
                           {row.preferredCurrency}
                        </>
                     ) : (
                        row.formattedNetPrice
                     ),
               },
               {
                  name: 'VAT',
                  renderCell: (row: AdditionalServiceCartType) => `${row.taxRate}%`,
               },
               {
                  name: 'grossPrice',
                  hidden: !clientView,
                  renderCell: (row: AdditionalServiceCartType) => (
                     <>
                        <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                           <StyledInput
                              onBlur={(e: any) =>
                                 changeClientPrice(row.uuid, Number(e.target.value), row.taxRate, 'GROSS')
                              }
                              type="Number"
                              onFocus={(e: any) => e.target.select()}
                              onChange={(e: any) =>
                                 changeStateClientPrice(
                                    Number(e.target.value) / (1 + row.taxRate / 100),
                                    row.uuid,
                                    row.taxRate
                                 )
                              }
                              value={clientPrices.find((item) => item.uuid === row.uuid)?.clientPriceGross}
                           />
                        </Tooltip>
                        {row.preferredCurrency}
                     </>
                  ),
               },
               {
                  name: 'grossValue',
                  renderCell: (row: AdditionalServiceCartType) => row.formattedGrossValue,
                  mainOnMobile: true,
               },
               {
                  name: 'deleteService',
                  renderCell: (row: AdditionalServiceCartType) => (
                     <IconButton
                        onClick={() =>
                           setModalInfo({
                              modalType: 'DELETE_SERVICE',
                              serviceUuid: row.uuid,
                              uuid: cartUuid,
                              index: row.name,
                              deleteService: true,
                           })
                        }
                        sx={{ '&:hover': { '>*': { color: 'red' } } }}
                     >
                        <Close />
                     </IconButton>
                  ),
               },
            ]}
         />
      </Box>
   )
}
