import { useTranslation } from 'react-i18next'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import React from 'react'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import getProductName from 'shared/utils/helpers/getProductName'
import { Order } from 'shared/store/useOrdersHistory/types'

import { StoredOrderDetails } from '../../../../types'

const columnNames = [
   'productName',
   'partIndex',
   'producer',
   'quantity',
   'wholesaler',
   'orderNumber',
   'price',
   'grossValueSum',
   'clientPrice',
   'clientPriceSum',
]

const baselinkerColumnNames = [
   'productName',
   'partIndex',
   'producer',
   'quantity',
   'wholesaler',
   'orderNumber',
   'price',
   'grossValueSum',
]

const valueColumnsNames = ['price', 'grossValueSum', 'clientPrice', 'clientPriceSum']

interface Props {
   orders: PropType<StoredOrderDetails, 'data'>
   priceType?: PriceType
   orderType?: PropType<Order, 'orderType'>
   formattedClientValue?: string
   formattedValue?: string
   netDeliveryCost?: number
   formattedAdditionalServicesValue?: string
   grossDeliveryCost?: number
}

export default function FullTable({
   orders,
   priceType,
   orderType,
   formattedClientValue,
   formattedAdditionalServicesValue,
   formattedValue,
   netDeliveryCost,
   grossDeliveryCost,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])

   function returnTableSummaryRow() {
      switch (orderType) {
         case 'GROUPED_BUY_NOW':
            return (
               <>
                  <StyledTableCell colSpan={2} sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', pl: 2 }}>
                     {`${t('TRANSLATION:columnNames.sum')} ${t(`${priceType}`)}:`}
                  </StyledTableCell>
                  <TableCell colSpan={5} />
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>{formattedValue}</StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>{formattedClientValue}</StyledTableCell>
                  <StyledTableCell />
               </>
            )
         case 'BASELINKER':
            return (
               <>
                  <StyledTableCell colSpan={2} sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', pl: 2 }}>
                     {`${t('TRANSLATION:columnNames.sum')} ${t(`${priceType}`)}:`}
                  </StyledTableCell>
                  <TableCell colSpan={5} />
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>{formattedValue}</StyledTableCell>
               </>
            )
         default:
            return (
               <>
                  <StyledTableCell colSpan={2} sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', pl: 2 }}>
                     {`${t('TRANSLATION:columnNames.sum')} ${t(`${priceType}`)}:`}
                  </StyledTableCell>
                  <TableCell colSpan={5} />
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>{formattedValue}</StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>{formattedClientValue}</StyledTableCell>
                  {/* <StyledTableCell /> */}
               </>
            )
      }
   }

   return (
      <Table sx={{ width: '100%', marginY: 3 }}>
         <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
            <TableRow>
               {orderType !== 'BASELINKER' &&
                  columnNames.map((column) => (
                     <StyledTableCell sx={{ textAlign: 'center' }} key={column} width={250}>
                        <h3>
                           {t(`TRANSLATION:columnNames.${column}`)}
                           {valueColumnsNames.includes(column) && t(`TRANSLATION:${priceType}`)}
                        </h3>
                     </StyledTableCell>
                  ))}
               {orderType === 'BASELINKER' &&
                  baselinkerColumnNames.map((column) => (
                     <StyledTableCell sx={{ textAlign: 'center' }} key={column} width={250}>
                        <h3>
                           {t(`TRANSLATION:columnNames.${column}`)}
                           {valueColumnsNames.includes(column) && t(`TRANSLATION:${priceType}`)}
                        </h3>
                     </StyledTableCell>
                  ))}
               {orderType === 'GROUPED_BUY_NOW' && (
                  <StyledTableCell sx={{ textAlign: 'center' }} width={250}>
                     {t('TRANSLATION:columnNames.orderTime')}
                  </StyledTableCell>
               )}
            </TableRow>
         </TableHead>
         <TableBody>
            {orders.products.map((product) => (
               <TableRow key={product.uuid}>
                  <StyledTableCell width={250}>{getProductName(product)}</StyledTableCell>
                  <StyledTableCell>{product.index}</StyledTableCell>
                  <StyledTableCell>{product.producerName}</StyledTableCell>
                  <StyledTableCell>{product.amount}</StyledTableCell>
                  <StyledTableCell>{product.wholesaleName}</StyledTableCell>
                  <StyledTableCell>{product.wholesaleOrderNumber}</StyledTableCell>
                  <StyledTableCell>
                     {priceType === 'NET' ? product.formattedPrice : product.formattedGrossPrice}
                  </StyledTableCell>
                  <StyledTableCell>
                     {priceType === 'NET' ? product.formattedNetValue : product.formattedGrossValue}
                  </StyledTableCell>
                  {orderType !== 'BASELINKER' && (
                     <>
                        <StyledTableCell>
                           {priceType === 'NET' ? product.formattedClientNetPrice : product.formattedClientGrossPrice}
                        </StyledTableCell>
                        <StyledTableCell>
                           {priceType === 'NET' ? product.formattedClientNetValue : product.formattedClientGrossValue}
                        </StyledTableCell>
                     </>
                  )}
                  {orderType === 'GROUPED_BUY_NOW' && <StyledTableCell>{product.orderDate || ''}</StyledTableCell>}
               </TableRow>
            ))}
            {netDeliveryCost && (
               <TableRow>
                  <StyledTableCell colSpan={6} />
                  <StyledTableCell>{t('TRANSLATION:deliveryCost')}</StyledTableCell>
                  <StyledTableCell>
                     {t('TRANSLATION:price', { price: netDeliveryCost.toFixed(2) })} {t('TRANSLATION:NET')}
                  </StyledTableCell>
                  <StyledTableCell>
                     {t('TRANSLATION:price', { price: grossDeliveryCost ? grossDeliveryCost.toFixed(2) : 0 })}{' '}
                     {t('TRANSLATION:GROSS')}
                  </StyledTableCell>
               </TableRow>
            )}
            <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>{returnTableSummaryRow()}</TableRow>
            {orders.additionalServices.length > 0 &&
               orders.additionalServices.map((service) => (
                  <TableRow key={service.uuid}>
                     <StyledTableCell colSpan={3}>{service.name}</StyledTableCell>
                     <StyledTableCell>{service.amount}</StyledTableCell>
                     <StyledTableCell />
                     <StyledTableCell />
                     <StyledTableCell />
                     <StyledTableCell />
                     <StyledTableCell>
                        {priceType === 'NET' ? service.formattedNetPrice : service.formattedGrossPrice}
                     </StyledTableCell>
                     <StyledTableCell>
                        {priceType === 'NET' ? service.formattedNetValue : service.formattedGrossValue}
                     </StyledTableCell>
                  </TableRow>
               ))}
            {orders.additionalServices.length > 0 && (
               <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <TableCell colSpan={7} />
                  <StyledTableCell colSpan={2} sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'right', pl: 2 }}>
                     {`${t('TRANSLATION:columnNames.servicesSum')} ${t(`${priceType}`)}: `}
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                     {formattedAdditionalServicesValue}
                  </StyledTableCell>
               </TableRow>
            )}
         </TableBody>
      </Table>
   )
}
