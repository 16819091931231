import React, { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormik, Form, FormikProvider } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { useQueryClient } from 'react-query'
import Box from '@mui/material/Box'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { useLocationsQuery } from 'shared/store/useLocations'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import MuiPhone from 'shared/components/PhoneInput'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { DialogActions } from '@mui/material'
import { handleLocationAllowedChange } from '../../../../helpers/handleAllowedLocations'
import { CreateUserFormikType, CreateUserPayload } from '../../../../types'
import { addUserService } from '../../../../services'
import { createUserSchema } from '../../../../schema'

interface Props {
   handleClose: () => void
   handleRefetch: () => void
}

export default function CreateUserModal({ handleRefetch, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const queryClient = useQueryClient()
   const { data } = useLocationsQuery()
   const { user } = useLogin()
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const [allowedLocations, setAllowedLocations] = useState<string[]>([])
   const [locationsError, setLocationError] = useState<boolean>(false)
   const [isLoading, setIsLoading] = useState(false)
   const { t } = useTranslation(['TRANSLATION', 'USERS_AND_SUBSCRIPTIONS'])

   const formik = useFormik<CreateUserFormikType>({
      initialValues: {
         name: '',
         phoneNumber: '',
         surname: '',
         email: '',
      },
      validationSchema: createUserSchema,
      onSubmit: createUser,
   })

   const { values } = formik

   function handlePhoneNumber(value: string, country: string) {
      formik.setFieldValue('phoneNumber', value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function createUser() {
      setIsLoading(true)
      if (!phoneNumberError) {
         addUserService({
            ...values,
            locationUuids: allowedLocations,
         })
            .then((res) => {
               if (res.status === 200) {
                  addNotification('successSave', 'success')
                  queryClient.invalidateQueries('locations')
                  handleRefetch()
                  handleClose()
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   useEffect(() => {
      if (allowedLocations.length === 0) {
         setLocationError(true)
      } else {
         setLocationError(false)
      }
   }, [allowedLocations])

   // useEffect(() => {
   //    if (isLocationAdmin && data) {
   //       const allowedAdminLocalization = data.find((item) => item.adminID === user.uuid)
   //       if (allowedAdminLocalization) {
   //          handleLocationAllowedChange(allowedAdminLocalization.uuid, allowedLocations, setAllowedLocations)
   //       }
   //    }
   // }, [])

   return (
      <>
         <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
               {t('USERS_AND_SUBSCRIPTIONS:createUserLabel')}
            </Typography>
            <FormikProvider value={formik}>
               <Form>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                     <Box sx={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                        <InputField<CreateUserPayload>
                           variant="standard"
                           label={t('TRANSLATION:formFields.name')}
                           name="name"
                           required
                        />
                        <InputField<CreateUserPayload>
                           variant="standard"
                           label={t('TRANSLATION:formFields.surname')}
                           name="surname"
                           required
                        />
                     </Box>
                     <Box sx={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                        <InputField<CreateUserPayload>
                           variant="standard"
                           label={t('TRANSLATION:formFields.email')}
                           name="email"
                           required
                        />
                        <MuiPhone
                           variant="standard"
                           value={formik.values.phoneNumber}
                           onChange={(value, country) => handlePhoneNumber(value, country)}
                        />
                     </Box>
                     <Box>
                        <Typography variant="body1" color="grayText">
                           {t('USERS_AND_SUBSCRIPTIONS:locationsAccess')}
                        </Typography>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              maxWidth: 'fit-content',
                              margin: '0 auto',
                           }}
                        >
                           {data &&
                              data.locations.map((location) => (
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          checked={allowedLocations.includes(location.locationSettings.uuid)}
                                          disabled={
                                             user.roles.includes(UserRoles.LOCALIZATION_ADMIN) &&
                                             !user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) &&
                                             location.locationSettings.adminUuid !== user.uuid
                                          }
                                          onChange={() =>
                                             handleLocationAllowedChange(
                                                location.locationSettings.uuid,
                                                allowedLocations,
                                                setAllowedLocations
                                             )
                                          }
                                          name={location.locationSettings.name}
                                       />
                                    }
                                    label={location.locationSettings.name}
                                 />
                              ))}
                        </Box>
                        {locationsError && (
                           <Typography sx={{ textAlign: 'center' }} variant="body2" color="error">
                              {t('USERS_AND_SUBSCRIPTIONS:locationsAccessWarning')}
                           </Typography>
                        )}
                     </Box>
                  </Box>
               </Form>
            </FormikProvider>
         </DialogContent>
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton
               disabled={isLoading || locationsError || phoneNumberError || !values.phoneNumber}
               variant="contained"
               onClick={createUser}
               type="submit"
            >
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
