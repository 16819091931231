import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import ListItem from '@mui/material/ListItem'
import { useMediaQuery } from '@mui/material'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'
import List from '@mui/material/List'
import Box from '@mui/material/Box'

import { AuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'
import swapUserLocationService from 'shared/services/swapUserLocation'
import { useNotificationContext } from 'shared/context/Notifications'
import { getRefreshToken } from 'shared/utils/helpers/refreshToken'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useClientPrices } from 'shared/hooks/ClientPriceHook'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import SearchProduct from 'shared/components/SearchProduct'
import { useProfileQuery } from 'shared/store/useProfile'
import smallLogo from 'assets/images/logo-small.png'
import BadgeIcon from 'shared/components/BadgeIcon'
import logo from 'assets/images/logo-large.png'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { AppBar, StyledIconButton, StyledLink, StyledLogo, StyledSmallLogo } from './styles'
import ChangePasswordModal from '../ChangePasswordModal'

interface Props {
   openSidebar?: boolean
}

export default function Header({ openSidebar }: Props) {
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
   const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
   const { t } = useTranslation('AUTHORIZED')
   const navigate = useNavigate()
   const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false)
   const { data: useProfileData } = useProfileQuery()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('md'))
   const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
   const queryClient = useQueryClient()
   const { user, logout } = useLogin()
   const { addNotification } = useNotificationContext()
   const location = useLocation()
   const { clientPricesOn, setPrices } = useClientPrices()

   const MENU_OPTIONS = [
      {
         label: t('header.menuItems.homePage'),
         linkTo: '/dashboard',
         icon: <HomeOutlinedIcon color="primary" />,
      },
   ]

   function handleLocationSwap(locationUuid: string) {
      swapUserLocationService(locationUuid)
         .then((res) => {
            if (res.status === 200) {
               getRefreshToken()
               addNotification(t('header.successLocationSwap'), 'success')
               queryClient.invalidateQueries('userProfile')
               queryClient.invalidateQueries('userStats')
               invalidateCarts(queryClient)
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(
                  error.response?.data?.errors[0].code || t('AUTHORIZED:header.failedLocationSwap'),
                  'error'
               )
            } else {
               addNotification(t('AUTHORIZED:header.failedLocationSwap'), 'error')
            }
         })
   }

   function renderButtons() {
      if (isMobile || isTablet) {
         return (
            <>
               <RouterLink
                  style={{
                     fontWeight: 'bold',
                     color: location.pathname.includes('main-cart') ? theme.palette.primary.main : 'gray',
                  }}
                  to="main-cart"
               >
                  <IconButton
                     sx={{
                        color: location.pathname.includes('main-cart') ? theme.palette.primary.main : 'gray',
                     }}
                  >
                     <BadgeIcon badgeType="CART_HEADER" />
                  </IconButton>
               </RouterLink>
               <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <Badge
                     badgeContent={(useProfileData?.unreadMessages || 0) + (useProfileData?.unreadNotifications || 0)}
                     color="error"
                     sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                     max={99}
                  >
                     <MenuIcon sx={{ fontSize: 27 }} />
                  </Badge>
               </IconButton>
               <Popover
                  anchorEl={menuAnchorEl}
                  open={!!menuAnchorEl}
                  onClose={() => setMenuAnchorEl(null)}
                  sx={{ mt: 4 }}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
               >
                  {useProfileData && !user.roles.includes(UserRoles.DEMO) && (
                     <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                           <Select
                              sx={{ mr: 1 }}
                              disabled={useProfileData.availableLocations?.length === 0}
                              onChange={(e) => handleLocationSwap(e.target.value)}
                              variant="standard"
                              value={useProfileData?.locationUuid}
                           >
                              {useProfileData.availableLocations?.map((item) => (
                                 <MenuItem key={item.uuid} value={item.uuid}>
                                    {item.name}
                                 </MenuItem>
                              ))}
                           </Select>
                        </Box>
                        <Divider />
                     </>
                  )}
                  <List>
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemButton onClick={() => navigate('notifications')}>
                           <ListItemIcon>
                              <StyledIconButton disabled unreadexist={useProfileData?.unreadNotifications !== 0}>
                                 <Badge
                                    badgeContent={useProfileData?.unreadNotifications}
                                    color="error"
                                    sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                                    max={99}
                                 >
                                    <NotificationsIcon
                                       sx={{
                                          color: location.pathname.includes('notifications')
                                             ? theme.palette.primary.main
                                             : 'gray',
                                       }}
                                    />
                                 </Badge>
                              </StyledIconButton>
                           </ListItemIcon>
                           <ListItemText>{t('AUTHORIZED:routes.notifications')}</ListItemText>
                        </ListItemButton>
                     </ListItem>
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemButton onClick={() => navigate('messages')}>
                           <ListItemIcon>
                              <StyledIconButton
                                 sx={{
                                    color: location.pathname.includes('messages') ? theme.palette.primary.main : 'gray',
                                 }}
                                 unreadexist={!!useProfileData?.unreadMessages}
                              >
                                 <Badge
                                    badgeContent={useProfileData?.unreadMessages}
                                    color="error"
                                    sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                                    max={99}
                                 >
                                    <EmailOutlinedIcon />
                                 </Badge>
                              </StyledIconButton>
                           </ListItemIcon>
                           <ListItemText>{t('AUTHORIZED:routes.messages')}</ListItemText>
                        </ListItemButton>
                     </ListItem>
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemButton onClick={() => setPrices(!clientPricesOn)}>
                           <ListItemIcon>
                              <IconButton>{clientPricesOn ? <Visibility /> : <VisibilityOff />}</IconButton>
                           </ListItemIcon>
                           <ListItemText>{t('AUTHORIZED:header.clientPrices')}</ListItemText>
                        </ListItemButton>
                     </ListItem>
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemButton
                           onClick={() => {
                              setOpenChangePasswordModal(true)
                              setAnchorEl(null)
                           }}
                        >
                           <ListItemIcon>
                              <IconButton>
                                 <LockIcon />
                              </IconButton>
                           </ListItemIcon>
                           <ListItemText>{t('TRANSLATION:changePassword')}</ListItemText>
                        </ListItemButton>
                     </ListItem>
                     <Divider />
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemButton onClick={() => logout('/')}>
                           <ListItemIcon>
                              <IconButton>
                                 <LogoutIcon color="primary" />
                              </IconButton>
                           </ListItemIcon>
                           <ListItemText>{t('header.menuItems.logout')}</ListItemText>
                        </ListItemButton>
                     </ListItem>
                  </List>
               </Popover>
            </>
         )
      }
      return (
         <>
            {(user.roles.includes(UserRoles.SUBSCRIPTION) || user.roles.includes(UserRoles.DEMO)) && (
               <RouterLink
                  style={{
                     fontWeight: 'bold',
                     color: location.pathname.includes('main-cart') ? theme.palette.primary.main : 'gray',
                  }}
                  to="main-cart"
               >
                  <IconButton
                     sx={{
                        color: location.pathname.includes('main-cart') ? theme.palette.primary.main : 'gray',
                     }}
                  >
                     <BadgeIcon badgeType="CART_HEADER" />
                  </IconButton>
               </RouterLink>
            )}
            <RouterLink to="notifications">
               <StyledIconButton
                  sx={{ color: location.pathname.includes('notifications') ? theme.palette.primary.main : 'gray' }}
                  unreadexist={!!useProfileData?.unreadNotifications}
               >
                  <Badge
                     badgeContent={useProfileData?.unreadNotifications}
                     color="error"
                     sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                     max={99}
                  >
                     <NotificationsIcon sx={{ fontSize: 27 }} />
                  </Badge>
               </StyledIconButton>
            </RouterLink>
            <RouterLink to="messages">
               <StyledIconButton
                  sx={{ color: location.pathname.includes('messages') ? theme.palette.primary.main : 'gray' }}
                  unreadexist={!!useProfileData?.unreadMessages}
               >
                  <Badge
                     badgeContent={useProfileData?.unreadMessages}
                     color="error"
                     sx={{ '& .MuiBadge-badge': { color: 'white', fontWeight: 'normal' } }}
                     max={99}
                  >
                     <EmailOutlinedIcon sx={{ fontSize: 27 }} />
                  </Badge>
               </StyledIconButton>
            </RouterLink>

            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
               <AccountCircleOutlinedIcon sx={{ fontSize: 27 }} color={anchorEl ? 'primary' : 'inherit'} />
            </IconButton>
            <Popover
               anchorEl={anchorEl}
               open={!!anchorEl}
               onClose={() => setAnchorEl(null)}
               sx={{ mt: 4 }}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
            >
               <Box sx={{ my: 2, px: 2.5 }}>
                  <Stack direction="row" spacing={1}>
                     <Typography variant="body1" noWrap>
                        {useProfileData?.userName}
                     </Typography>
                     <Tooltip title={t('header.menuItems.pointsTooltip') || ''}>
                        <StyledLink href="https://nagrody.motorro.pl" target="_blank">
                           {useProfileData && useProfileData?.loyaltyPoints <= 0
                              ? 0
                              : useProfileData?.loyaltyPoints.toFixed(0)}
                        </StyledLink>
                     </Tooltip>
                  </Stack>
               </Box>
               <Divider sx={{ my: 1 }} />
               {MENU_OPTIONS.map((option) => (
                  <MenuItem
                     key={option.label}
                     to={option.linkTo}
                     component={RouterLink}
                     onClick={() => setAnchorEl(null)}
                     sx={{ typography: 'body2' }}
                  >
                     {option.icon}
                     <Box
                        sx={{
                           width: 13,
                           height: 20,
                        }}
                     />

                     {option.label}
                  </MenuItem>
               ))}
               <MenuItem
                  onClick={() => {
                     setOpenChangePasswordModal(true)
                     setAnchorEl(null)
                  }}
                  sx={{ typography: 'body2' }}
               >
                  <LockIcon color="primary" />
                  <Box
                     sx={{
                        width: 13,
                        height: 20,
                     }}
                  />

                  {t('TRANSLATION:changePassword')}
               </MenuItem>
               <Divider />
               <MenuItem sx={{ typography: 'body2' }} onClick={() => logout('/')}>
                  <LogoutIcon color="primary" />
                  <Box
                     sx={{
                        width: 13,
                        height: 20,
                     }}
                  />
                  {t('header.menuItems.logout')}
               </MenuItem>
            </Popover>
            <LanguagePopover />
         </>
      )
   }

   useEffect(() => {
      setMenuAnchorEl(null)
   }, [location.pathname])

   return (
      <AppBar
         sx={{
            ...(openSidebar && {
               zIndex: theme.zIndex.drawer - 1,
            }),
         }}
      >
         <Stack direction="row" alignItems="center" width="100%" sx={{ px: { lg: 2, md: 2, xs: 1 } }}>
            <RouterLink
               style={{
                  marginRight: 1,
                  textDecoration: 'none',
               }}
               to="/dashboard"
            >
               <StyledLogo src={logo} alt="logo" />
            </RouterLink>
            <RouterLink
               style={{
                  marginRight: 1,
                  textDecoration: 'none',
               }}
               to="/dashboard"
            >
               <StyledSmallLogo src={smallLogo} alt="logo" />
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />
            <SearchProduct />
            <Box sx={{ flexGrow: 1 }} />
            {!isMobile && !isTablet && useProfileData && !user.roles.includes(UserRoles.DEMO) && (
               <Select
                  sx={{ mr: 1 }}
                  disabled={useProfileData.availableLocations?.length === 1}
                  onChange={(e) => handleLocationSwap(e.target.value)}
                  variant="standard"
                  value={useProfileData?.locationUuid}
               >
                  {useProfileData.availableLocations?.map((item) => (
                     <MenuItem key={item.uuid} value={item.uuid}>
                        {item.name}
                     </MenuItem>
                  ))}
               </Select>
            )}
            {!isMobile && (
               <Box>
                  {!clientPricesOn ? (
                     <Tooltip title={t('header.turnOnClientPrices')}>
                        <IconButton onClick={() => setPrices(true)}>
                           <VisibilityOff sx={{ fontSize: 25 }} />
                        </IconButton>
                     </Tooltip>
                  ) : (
                     <Tooltip title={t('header.turnOffClientPrices')}>
                        <IconButton onClick={() => setPrices(false)}>
                           <Visibility sx={{ fontSize: 25 }} />
                        </IconButton>
                     </Tooltip>
                  )}
               </Box>
            )}
            <Stack direction="row" justifyContent="center" spacing={{ xs: 0.5, sm: 1.5 }}>
               {renderButtons()}
            </Stack>
         </Stack>
         {user.roles.includes(UserRoles.DEMO) && (
            <Box
               sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: 'white',
                  width: '100%',
                  maxHeight: '40px',
                  fontSize: 15,
                  mt: 8,
                  position: 'fixed',
                  backgroundColor: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '7.5px',
               }}
            >
               {t('TRANSLATION:demoAccount')}
               <a style={{ color: 'white' }} href="https://motorro.pl/cennik/" target="_blank" rel="noreferrer">
                  <Box
                     sx={{
                        fontSize: 15,
                        '&:hover': { color: theme.palette.primary.main, cursor: 'pointer' },
                     }}
                  >
                     {t('TRANSLATION:buySubscription')}
                  </Box>
               </a>
            </Box>
         )}
         <ChangePasswordModal open={openChangePasswordModal} handleClose={() => setOpenChangePasswordModal(false)} />
      </AppBar>
   )
}
