import React from 'react'
import { useTranslation } from 'react-i18next'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useMediaQuery, useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import { DateTime } from 'luxon'

import { StyledFooter, StyledContactLink, StyledIconLink } from './styles'

export default function Footer() {
   const { t } = useTranslation('TRANSLATION')
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   return (
      <StyledFooter>
         <Container maxWidth="xl">
            <Grid container justifyContent={{ xs: 'center', md: 'space-between' }}>
               {!isMobile && (
                  <Grid item xs={12} md={3}>
                     <Stack direction="row" spacing={1} mt={1} alignItems="center" justifyContent="center">
                        <StyledIconLink
                           href="https://www.facebook.com/motorromarketplace"
                           target="_blank"
                           rel="noreferrer"
                        >
                           <FacebookIcon />
                        </StyledIconLink>
                        <StyledIconLink
                           href="https://www.linkedin.com/company/motorro/?originalSubdomain=pl"
                           target="_blank"
                           rel="noreferrer"
                        >
                           <LinkedInIcon />
                        </StyledIconLink>
                        <StyledIconLink
                           href="https://www.instagram.com/motorro_parts/"
                           target="_blank"
                           rel="noreferrer"
                        >
                           <InstagramIcon />
                        </StyledIconLink>
                        <StyledIconLink
                           href="https://www.youtube.com/@motorromarketplace"
                           target="_blank"
                           rel="noreferrer"
                        >
                           <YouTubeIcon />
                        </StyledIconLink>
                     </Stack>
                  </Grid>
               )}
               {!isMobile && (
                  <Grid item xs={12} md={3}>
                     <Stack direction={{ xs: 'column' }} mb={1} mt={1} textAlign={{ xs: 'center' }}>
                        <StyledContactLink href="mailto:kontakt@motorro.pl">
                           Email: kontakt@motorro.pl
                        </StyledContactLink>
                        <StyledContactLink href="tel:+48507425105">Tel. 507 425 105</StyledContactLink>
                     </Stack>
                  </Grid>
               )}
               <Grid item xs={12} md={3}>
                  <Stack direction={{ xs: 'column' }} mb={1} mt={1} textAlign={{ xs: 'center' }}>
                     <StyledContactLink href="https://motorro.pl/polityka-prywatnosci/" target="_blank">
                        {t('TRANSLATION:privacyPolicy')}
                     </StyledContactLink>
                     <StyledContactLink href="https://motorro.pl/regulamin/" target="_blank">
                        {t('TRANSLATION:termsOfUse')}
                     </StyledContactLink>
                  </Stack>
               </Grid>
               {!isMobile && (
                  <Grid item xs={12} md={3}>
                     <Typography
                        variant="body2"
                        mt={{ xs: 1, sm: 1 }}
                        sx={{
                           color: '#cfd0d4',
                           fontSize: 13,
                           fontWeight: 'bold',
                           textAlign: 'center',
                        }}
                     >
                        {`${t('footer.lastUpdatedDate')}: ${DateTime.fromMillis(
                           Number(process.env.VERSION_TIMESTAMP) * 1000
                        ).toLocaleString(DateTime.DATETIME_MED)}`}
                     </Typography>
                  </Grid>
               )}
               <Grid item xs={12} md={12}>
                  <Typography
                     variant="body2"
                     sx={{
                        color: '#cfd0d4',
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                     }}
                  >
                     {t('footer.rights')}
                  </Typography>
               </Grid>
            </Grid>
         </Container>
      </StyledFooter>
   )
}
