import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import React from 'react'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import CustomDialog from 'shared/components/CustomDialog'

interface Props {
   openModal: boolean
   handleClose: () => void
   handleReject: () => void
}

export default function ConfirmRejectModal({ openModal, handleClose, handleReject }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'COOPERATION_REFLINK'])

   return (
      <CustomDialog
         onClose={handleClose}
         isLoading={!openModal}
         open={openModal}
         content={
            <>
               <DialogTitle sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" color="primary">
                     {t('COOPERATION_REFLINK:confirmReject')}
                  </Typography>
               </DialogTitle>
               <DialogActions sx={{ justifyContent: 'center' }}>
                  <ContainedButton variant="contained" onClick={handleClose}>
                     {t('TRANSLATION:no')}
                  </ContainedButton>
                  <ContainedButton variant="contained" onClick={handleReject}>
                     {t('TRANSLATION:yes')}
                  </ContainedButton>
               </DialogActions>
            </>
         }
      />
   )
}
