import { Card, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Snackbar from '@mui/material/Snackbar'
import { useQueryClient } from 'react-query'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'

import { createProductsAvailabilityMap } from 'shared/utils/helpers/createProductAvailabilityMap'
import { reportMissingProductService } from 'shared/services/reportMissingProduct'
import DepartmentAvailability from 'shared/components/DepartmentAvailability'
import { useNotificationContext } from 'shared/context/Notifications'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { useCartSummaryQuery } from 'shared/store/useCartSummary'
import PriceComponent from 'shared/components/PriceComponent'
import { useClientPrices } from 'shared/hooks/ClientPriceHook'
import { addToCartService } from 'shared/services/addToCart'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import DeliveryTime from 'shared/components/DeliveryTime'
import CustomTable from 'shared/components/CustomTable'
import SalerLogo from 'shared/components/SalerLogo'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { BuyNowPayload, EditModal, Modal, ProductDetails, RemoveModal } from '../../types'
import RemoveWholesalerModal from '../RemoveWholesalerModal'
import EditWholesalerModal from '../EditWholesalerModal'
import { buyNowService } from '../../services'
import ModalComponent from './Modal'
import Actions from './Actions'

interface Props {
   data: CooperatingWholesaler[]
   details?: ProductDetails
   productID: Nullable<string>
}

export default function MainTable({ data, details, productID }: Props) {
   const [wholesalerEditModal, setWholesalerEditModal] = useState<EditModal | undefined>()
   const [wholesalerRemoveModal, setWholesalerRemoveModal] = useState<RemoveModal | undefined>()
   const [modal, setModal] = useState<Modal | undefined>()
   const [isLoading, setIsLoading] = useState(false)
   const { data: userData } = useProfileQuery()
   const { data: cartSummary } = useCartSummaryQuery()
   const { clientPricesOn } = useClientPrices()
   const { user } = useLogin()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const [orderSummaryData, setOrderSummaryData] = useState<BuyNowSummaryType>()
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const { addNotification } = useNotificationContext()
   const navigate = useNavigate()
   const queryClient = useQueryClient()

   async function onBuyNow(payload: BuyNowPayload) {
      if (productID) {
         setIsLoading(true)
         await buyNowService({
            cartName: payload.cartName,
            product: {
               ...payload,
               productsAvailabilityMap: createProductsAvailabilityMap(data),
               productUuid: productID,
               searchSession: '',
               uuid: '',
            },
         })
            .then(({ status, data: buyNowResponse }) => {
               setIsLoading(false)
               if (status === 200) {
                  queryClient.invalidateQueries('ordersHistory')
                  queryClient.invalidateQueries('minimums')
                  queryClient.invalidateQueries('mainCart')
                  setOrderSummaryData(buyNowResponse)
                  setModal(undefined)
                  if (buyNowResponse.product.find((item) => item.status !== 'AVAILABLE')) {
                     setTimeout(() => navigate('/dashboard/order-summary', { state: buyNowResponse }), 4000)
                  }
               }
            })
            .catch((error) => {
               setIsLoading(false)
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
      }
   }
   async function onAddToCart(payload: AddToCartPayload): Promise<{ status: number }> {
      return new Promise((resolve) => {
         if (productID && cartSummary) {
            setIsLoading(true)
            addToCartService(
               {
                  ...payload,
                  productsAvailabilityMap: createProductsAvailabilityMap(data),
                  productUuid: productID,
                  searchSession: '',
                  uuid: '',
               },
               cartSummary.cartDetailsDTO.cartUuid
            )
               .then(({ status }) => {
                  if (status === 200) {
                     addNotification('productAddedToCart', 'success', 'BOTTOM')
                  }
                  resolve({ status })
               })
               .catch((error) => {
                  if (isAxiosError<ErrorType>(error)) {
                     addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
                  } else {
                     addNotification('failedSave', 'error')
                  }
               })
               .finally(() => {
                  invalidateCarts(queryClient)
                  setIsLoading(false)
               })
         }
      })
   }

   function getTotalAvailability(row: CooperatingWholesaler) {
      if (row.totalAvailability && row.totalAvailability > row.wholesaleInfiniteAvailabilityThreshold) {
         return `> ${row.wholesaleInfiniteAvailabilityThreshold}`
      }
      if (row.totalAvailability && row.totalAvailability > 0) {
         return row.totalAvailability
      }
      if (row.totalAvailability && row.totalAvailability < 0) {
         return 0
      }
      return 0
   }

   async function onReportMissingProduct(payload: MissingProductsService) {
      if (productID) {
         setIsLoading(true)
         await reportMissingProductService({
            productUuid: payload.productUuid,
            wholesalerUuid: payload.wholesalerUuid,
         })
            .then(({ status }) => {
               if (status === 201) {
                  addNotification('productReported', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   useEffect(() => {
      setModal(undefined)
   }, [userData?.locationUuid, clientPricesOn])

   return data.length ? (
      <Card>
         <CustomTable
            rows={data}
            dataSize={data.length}
            disablePagination
            defaultSortBy="netPrice"
            defaultSortDirection="asc"
            excludedFromSorting={(row: CooperatingWholesaler) =>
               row.status !== 'OK_ONLINE_AVAILABILITY' ||
               !row.netPrice ||
               !row.totalAvailability ||
               row.totalAvailability === 0
            }
            defaultSortType="number"
            customRowStyles={(row: CooperatingWholesaler) => ({
               '& > td:not(:first-of-type):not(:last-of-type)': {
                  filter: `${row.status !== 'OK_ONLINE_AVAILABILITY' || !row.netPrice ? 'blur(4px)' : 'none'}`,
               },
               ' & > td ': {
                  filter: `${
                     row.status === 'OK_ONLINE_AVAILABILITY' &&
                     row.netPrice &&
                     (row.totalAvailability === 0 || row.totalAvailability === null)
                        ? 'grayScale(1)'
                        : 'none'
                  }`,
               },
            })}
            columns={[
               {
                  name: isMobile ? 'wholesaleAndAvailabilities' : 'wholesaleName',
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) =>
                     isMobile ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                           <SalerLogo value={row.wholesaleName} row={row} />
                           <Box sx={{ display: 'flex', gap: '2px', justifyContent: 'center' }}>
                              <DepartmentAvailability
                                 value={row.firstDepartmentAvailability}
                                 row={row}
                                 departmentName={row.firstDepartmentName}
                              />
                              <span> | </span>
                              <DepartmentAvailability
                                 value={row.secondDepartmentAvailability}
                                 row={row}
                                 departmentName={row.secondDepartmentName}
                              />
                              <span> | </span>
                              <DepartmentAvailability
                                 value={row.thirdDepartmentAvailability}
                                 row={row}
                                 departmentName={row.thirdDepartmentName}
                              />
                              <span> | </span>
                              {getTotalAvailability(row)}
                           </Box>
                        </Box>
                     ) : (
                        <SalerLogo value={row.wholesaleName} row={row} />
                     ),
               },
               {
                  name: 'netPrice',
                  sortable: true,
                  sortLabel: 'netPrice',
                  sortType: 'number',

                  renderCell: (row: CooperatingWholesaler) => (
                     <PriceComponent
                        value={clientPricesOn ? row.formattedClientNetPrice : row.formattedNetPrice}
                        data={data}
                        row={row}
                        priceType="NET"
                     />
                  ),
               },
               {
                  name: 'formattedConvertedNetPrice',
                  sortable: true,
                  hidden: !data.find((row) => row.convertedNetPrice),
                  sortLabel: 'convertedNetPrice',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => row.formattedConvertedNetPrice,
               },
               {
                  name: 'grossPrice',
                  sortable: true,
                  mainOnMobile: true,
                  sortLabel: 'grossPrice',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <PriceComponent
                        value={clientPricesOn ? row.formattedClientGrossPrice : row.formattedGrossPrice}
                        data={data}
                        row={row}
                        priceType="GROSS"
                     />
                  ),
               },
               {
                  name: 'firstDepartmentAvailability',
                  sortable: true,
                  sortLabel: 'firstDepartmentAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <DepartmentAvailability
                        value={row.firstDepartmentAvailability}
                        row={row}
                        departmentName={row.firstDepartmentName}
                     />
                  ),
               },
               {
                  name: 'secondDepartmentAvailability',
                  sortable: true,
                  sortLabel: 'secondDepartmentAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <DepartmentAvailability
                        value={row.secondDepartmentAvailability}
                        row={row}
                        departmentName={row.secondDepartmentName}
                     />
                  ),
               },
               {
                  name: 'thirdDepartmentAvailability',
                  sortable: true,
                  sortLabel: 'thirdDepartmentAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <DepartmentAvailability
                        value={row.thirdDepartmentAvailability}
                        row={row}
                        departmentName={row.thirdDepartmentName}
                     />
                  ),
               },
               {
                  name: 'totalAvailability',
                  // mainOnMobile: true,
                  sortable: true,
                  sortLabel: 'totalAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => getTotalAvailability(row),
               },
               {
                  name: 'minutesToDeliveryStart',
                  sortable: true,
                  sortLabel: 'minutesToDeliveryStart',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <Box>
                        {row.totalAvailability === null || row.totalAvailability === 0
                           ? null
                           : DeliveryTime(row.minutesToDeliveryStart)}
                     </Box>
                  ),
               },
               {
                  name: '',
                  preventOnClick: true,
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) => (
                     <Actions
                        status={row.status}
                        totalAvailability={row.totalAvailability}
                        wholesaleName={row.wholesaleName}
                        isLoading={isLoading}
                        wholesaleIndex={row.wholesaleIndex}
                        netPrice={row.netPrice}
                        errorMessage={row.errorMessage}
                        onBuy={() => setModal({ type: 'BUY', data: row })}
                        onAddToCart={() => setModal({ type: 'ORDER', data: row })}
                        onEditWholesaler={() =>
                           setWholesalerEditModal({
                              data: row,
                              productUuid: productID || '',
                           })
                        }
                        onRemoveWholesaler={() =>
                           setWholesalerRemoveModal({
                              data: row,
                              productUuid: productID || '',
                           })
                        }
                        onReportMissingProduct={() =>
                           onReportMissingProduct({
                              wholesalerUuid: row.wholesaleUuid,
                              productUuid: row.productUuid,
                           })
                        }
                     />
                  ),
               },
            ]}
         />
         {!!modal && (
            <ModalComponent
               onClose={() => setModal(undefined)}
               modalData={modal}
               producer={details?.producer}
               partIndex={details?.index}
               onBuy={onBuyNow}
               onAddToCart={onAddToCart}
               isLoading={isLoading}
            />
         )}
         {user.roles.includes(UserRoles.DATA_MANAGER) && (
            <EditWholesalerModal
               modalInfo={wholesalerEditModal}
               handleClose={() => {
                  setWholesalerEditModal(undefined)
               }}
            />
         )}
         {user.roles.includes(UserRoles.DATA_MANAGER) && (
            <RemoveWholesalerModal
               modalInfo={wholesalerRemoveModal}
               handleClose={() => setWholesalerRemoveModal(undefined)}
            />
         )}
         <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={!!orderSummaryData}
            autoHideDuration={10000}
            onClose={() => setOrderSummaryData(undefined)}
         >
            <Alert
               sx={{
                  fontWeight: 'bold',
                  pt: 0,
                  '&:hover': {
                     cursor: 'pointer',
                     userSelect: 'none',
                  },
               }}
               severity={orderSummaryData?.product.find((item) => item.status !== 'AVAILABLE') ? 'warning' : 'success'}
               onClick={() => navigate('/dashboard/order-summary', { state: orderSummaryData })}
            >
               {orderSummaryData?.product.find((item) => item.status !== 'AVAILABLE')
                  ? t('PRODUCT_DETAILS:tables.buyNowProblem')
                  : t('PRODUCT_DETAILS:tables.buyNowSuccess')}
               <IconButton
                  onClick={(event) => {
                     event.stopPropagation()
                     setOrderSummaryData(undefined)
                  }}
               >
                  <CloseIcon sx={{ fontSize: 20 }} />
               </IconButton>
            </Alert>
         </Snackbar>
      </Card>
   ) : null
}
