import { styled } from '@mui/material'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'

export const RootStyle = styled(Card)(({ theme }) => ({
   maxWidth: 480,
   margin: 'auto',
   display: 'flex',
   position: 'relative',
   alignItems: 'center',
   flexDirection: 'column',
   padding: theme.spacing(2),
   backgroundColor: 'rgba(255,255,255,0.7)',
   [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
   },
   [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
   },
}))

export const RootStyleMain = styled('div')(({ theme }) => ({
   [theme.breakpoints.up('md')]: {
      display: 'flex',
   },
}))

export const StyledButton = styled(Button)({
   height: '100%',
   padding: 10,
   minWidth: 150,
})
