import React, { useEffect, useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import DialogContent from '@mui/material/DialogContent'
import { useMediaQuery, useTheme } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useDeleteServiceMutation, useUpdateServiceMutation } from 'shared/store/useCartSummary'
import { EditServicePayload, FetchCartSummaryResult } from 'shared/store/useCartSummary/types'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import CustomDialog from 'shared/components/CustomDialog'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import PricingPrintModal from './components/PricingPrintModal'
import { ModalInfoType } from '../../types'
import AddServiceModal from './components/AddServiceModal'
import AfterUpdateModal from './components/AfterUpdateDiffsModal'
import SwapWholesaleModal from './components/SwapWholesaleModal'
import ReflinkModal from './components/ReflinkModal'

interface Props {
   modalInfo?: ModalInfoType
   data?: FetchCartSummaryResult
   handleClose: () => void
   handleRemoveProductFromCart: (uuid: string, removeFromUnavailable?: boolean) => void
   handleProductUpdate: (
      uuid: string,
      quantity?: number,
      includeProductInOrder?: boolean,
      netClientPrice?: number
   ) => void
}

export default function Modal({
   modalInfo,
   handleClose,
   handleProductUpdate,
   handleRemoveProductFromCart,
   data,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const product = data?.products.find((item) => item.uuid === modalInfo?.uuid)
   const [quantity, setQuantity] = useState(1)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const queryClient = useQueryClient()
   const { mutate } = useUpdateServiceMutation()
   const { mutate: deleteServiceMutate } = useDeleteServiceMutation()

   function handleDeleteProduct() {
      if (modalInfo?.uuid) handleRemoveProductFromCart(modalInfo.uuid, modalInfo?.removeFromUnavailable)
   }

   function changeServiceQuantity() {
      if (modalInfo?.uuid && modalInfo?.serviceUuid) {
         const payload: EditServicePayload = {
            quantity,
         }
         mutate(
            { payload, cartUuid: modalInfo.uuid, serviceUuid: modalInfo.serviceUuid },
            {
               onSuccess: () => {
                  invalidateCarts(queryClient)
                  handleClose()
               },
            }
         )
      }
   }

   function handleDeleteService() {
      if (modalInfo?.uuid && modalInfo?.serviceUuid) {
         deleteServiceMutate(
            { cartUuid: modalInfo.uuid, serviceUuid: modalInfo.serviceUuid },
            {
               onSuccess: () => {
                  invalidateCarts(queryClient)
                  handleClose()
               },
            }
         )
      }
   }

   function getModalContent() {
      switch (modalInfo?.modalType) {
         case 'AFTER_UPDATE':
            return <AfterUpdateModal handleClose={handleClose} updatePricesDiff={modalInfo.updatePricesDiffs || []} />
         case 'SWAP':
            return (
               <>
                  <DialogContent>
                     <SwapWholesaleModal
                        oldClientPrice={modalInfo.oldClientPrice}
                        defaultQuantity={modalInfo.defaultQuantity || 1}
                        productToRemoveUuid={modalInfo.productToRemoveUuid || ''}
                        removeFromUnavailable={modalInfo.removeFromUnavailable || false}
                        productUuid={modalInfo.uuid || ''}
                        handleClose={handleClose}
                     />
                  </DialogContent>
                  <DialogActions>
                     <ContainedButton
                        sx={{ mt: 'auto', alignSelf: 'center' }}
                        variant="contained"
                        onClick={handleClose}
                     >
                        {t('cancel')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         case 'SERVICES':
            return <AddServiceModal cartUuid={modalInfo.uuid || ''} handleClose={handleClose} />
         case 'DELETE':
            return (
               <>
                  <DialogContent>
                     <Typography variant="body1" sx={{ color: 'grayText' }}>
                        {t('MAIN_CART:sureDelete', { index: modalInfo?.index })}
                     </Typography>
                  </DialogContent>
                  <DialogActions>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:no')}
                     </OutlinedButton>
                     <ContainedButton variant="contained" onClick={handleDeleteProduct}>
                        {t('TRANSLATION:yes')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         case 'DELETE_SERVICE':
            return (
               <>
                  <DialogContent>
                     <Typography variant="body1" sx={{ color: 'grayText' }}>
                        {t('MAIN_CART:sureDeleteService', { name: modalInfo?.index })}
                     </Typography>
                  </DialogContent>
                  <DialogActions>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:no')}
                     </OutlinedButton>
                     <ContainedButton variant="contained" onClick={handleDeleteService}>
                        {t('TRANSLATION:yes')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         case 'QUANTITY':
            return (
               <>
                  <DialogContent
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           if (modalInfo.changeServiceQuantity) {
                              changeServiceQuantity()
                           } else if (product) {
                              handleProductUpdate(product.uuid, quantity)
                           }
                        }
                     }}
                  >
                     <TextField
                        sx={{ minWidth: 200, mt: 2 }}
                        variant="standard"
                        fullWidth={isMobile}
                        type="Number"
                        defaultValue={
                           modalInfo?.quantity && product && modalInfo?.quantity < product?.quantityInSet
                              ? product?.quantityInSet
                              : modalInfo?.quantity
                        }
                        label={t('MAIN_CART:pickQuantity')}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        inputProps={{
                           min: product?.quantityInSet || 1,
                           max: MAX_PRODUCT_QUANTITY,
                           step: !modalInfo.changeServiceQuantity ? product?.quantityInSet || 1 : null,
                        }}
                     />
                  </DialogContent>
                  <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton
                        variant="contained"
                        disabled={quantity > MAX_PRODUCT_QUANTITY}
                        onClick={() => {
                           if (modalInfo.changeServiceQuantity) {
                              changeServiceQuantity()
                           } else if (product) {
                              handleProductUpdate(product.uuid, quantity)
                           }
                        }}
                     >
                        {t('TRANSLATION:save')}
                     </ContainedButton>
                  </DialogActions>
               </>
            )
         case 'PRINT':
            return (
               data && (
                  <PricingPrintModal
                     data={data}
                     handleClose={handleClose}
                     variant={modalInfo?.pricingVariant || 'CLIENT'}
                  />
               )
            )
         case 'REFLINK':
            return <ReflinkModal modalInfo={modalInfo} handleClose={handleClose} />
         default:
            return <div />
      }
   }

   useEffect(() => {
      setQuantity(1)
   }, [modalInfo])

   return (
      <CustomDialog
         maxWidth="xl"
         fullScreen={!!isMobile}
         fullWidth={modalInfo?.modalType === 'PRINT'}
         open={!!modalInfo?.modalType}
         onClose={modalInfo?.modalType !== 'AFTER_UPDATE' ? handleClose : () => null}
         isLoading={!modalInfo}
         content={
            <>
               {modalInfo?.modalType !== 'PRINT' && modalInfo?.modalType !== 'SWAP' && modalInfo && (
                  <DialogTitle
                     sx={{ fontSize: 20, textAlign: modalInfo?.modalType === 'AFTER_UPDATE' ? 'center' : 'left' }}
                     color="primary"
                  >
                     {t(`MAIN_CART:modals.${modalInfo?.modalType}`)}
                  </DialogTitle>
               )}
               {getModalContent()}
            </>
         }
      />
   )
}
