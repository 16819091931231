import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

import { reportMissingProductService } from 'shared/services/reportMissingProduct'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import GrayButton from 'shared/components/Buttons/GrayButton'
import CustomTable from 'shared/components/CustomTable'
import SalerLogo from 'shared/components/SalerLogo'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { ButtonContainer } from '../../styles'
import AddWholesalerModal from '../AddWholesalerModal'
import { AddModal } from '../../types'

interface Props {
   data: CooperatingWholesaler[]
   productID: Nullable<string>
}

export default function NoProductTable({ data, productID }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'PRODUCT_DETAILS'])
   const [isLoading, setIsLoading] = useState(false)
   const { addNotification } = useNotificationContext()
   const [modal, setModal] = useState<AddModal>()
   const { user } = useLogin()

   async function onReportMissingProduct(payload: MissingProductsService) {
      if (productID) {
         setIsLoading(true)
         await reportMissingProductService({
            productUuid: payload.productUuid,
            wholesalerUuid: payload.wholesalerUuid,
         })
            .then(({ status }) => {
               if (status === 201) {
                  addNotification('productReported', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   return data.length ? (
      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: '8px' }}>
         <Typography variant="body1" sx={{ ml: 1, opacity: 0.5 }}>
            {t('PRODUCT_DETAILS:tables.noProductHeading')}
         </Typography>
         <CustomTable
            disablePagination
            rows={data}
            customTableStyles={() => ({
               width: { lg: '50%', md: '50%', xs: '100%' },
               ml: 0,
            })}
            dataSize={data.length}
            columns={[
               {
                  name: 'wholesaleName',
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) => <SalerLogo value={row.wholesaleName} row={row} />,
               },
               {
                  name: '',
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) => (
                     <ButtonContainer>
                        <Tooltip placement="right" title={t('PRODUCT_DETAILS:tables.sendReportTooltip') || ''}>
                           <GrayButton
                              sx={{ marginX: 4, wordBreak: 'break-word' }}
                              fullWidth
                              variant="outlined"
                              disabled={isLoading}
                              type="button"
                              onClick={() =>
                                 onReportMissingProduct({
                                    wholesalerUuid: row.wholesaleUuid,
                                    productUuid: row.productUuid,
                                 })
                              }
                           >
                              {t('PRODUCT_DETAILS:tables.reportButton')}
                           </GrayButton>
                        </Tooltip>
                        {user.roles.includes(UserRoles.DATA_MANAGER) && (
                           <IconButton onClick={() => setModal({ data: row, productUuid: productID || '' })}>
                              <AddCircleIcon />
                           </IconButton>
                        )}
                     </ButtonContainer>
                  ),
               },
            ]}
         />
         {user.roles.includes(UserRoles.DATA_MANAGER) && (
            <AddWholesalerModal modalInfo={modal} handleClose={() => setModal(undefined)} />
         )}
      </Box>
   ) : null
}
