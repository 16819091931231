import React from 'react'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import Card from '@mui/material/Card'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { FetchMinimumsResult } from 'shared/store/useLogisticMinimums/types'
import CustomDialog from 'shared/components/CustomDialog'

interface Props {
   open: boolean
   data: FetchMinimumsResult
   handleClose: () => void
}

export default function LogisticsMinimumDialog({ open, data, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'AUTHORIZED'])

   return (
      <CustomDialog
         isLoading={!data || !open}
         open={open}
         onClose={handleClose}
         maxWidth="xl"
         content={
            <>
               <DialogTitle sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: 20 }} color="primary">
                     {t('AUTHORIZED:logisticMinimumsDialog')}
                  </Typography>
               </DialogTitle>
               <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {data.logisticMinimums.map((item) => (
                     <Card
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           margin: '0 auto',
                           width: '80%',
                           padding: 2,
                        }}
                        key={item.wholesaleUuid}
                     >
                        <HiddenWholesalerLogo wholesalerName={item.wholesaleName} imgHeight="25px" imgWidth="auto" />
                        <Typography sx={{ fontSize: 16 }} color="text.secondary">
                           {item.remainingAmount} zł
                        </Typography>
                     </Card>
                  ))}
               </DialogContent>
               <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                  <ContainedButton onClick={handleClose} variant="contained">
                     {t('TRANSLATION:understand')}
                  </ContainedButton>
               </Box>
            </>
         }
      />
   )
}
