/* eslint-disable no-nested-ternary */
import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, useMediaQuery, useTheme } from '@mui/material'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import GrayButton from 'shared/components/Buttons/GrayButton'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { ButtonContainer } from './styles'

interface Props {
   onBuy: () => void
   onAddToCart: () => void
   status: CooperatingWholesalerStatus
   totalAvailability: Nullable<number>
   errorMessage: Nullable<string>
   isLoading: boolean
   wholesaleName: string
   wholesaleIndex: string
   netPrice: Nullable<number>
   onReportMissingProduct: () => void
   onEditWholesaler: () => void
   onRemoveWholesaler: () => void
}

export default function Actions({
   onAddToCart,
   onBuy,
   onEditWholesaler,
   onReportMissingProduct,
   onRemoveWholesaler,
   status,
   netPrice,
   errorMessage,
   wholesaleName,
   wholesaleIndex,
   totalAvailability,
   isLoading,
}: Props) {
   const { t } = useTranslation('PRODUCT_DETAILS')
   const { user } = useLogin()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function renderEditButton() {
      if (user.roles.includes(UserRoles.DATA_MANAGER)) {
         return (
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: { lg: 'row', md: 'row', xs: 'column' },
               }}
            >
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                  }}
               >
                  <IconButton onClick={onEditWholesaler}>
                     <EditIcon />
                  </IconButton>
                  <IconButton onClick={onRemoveWholesaler}>
                     <DeleteIcon color="error" />
                  </IconButton>
               </Box>
               {wholesaleIndex}
            </Box>
         )
      }
      return null
   }

   function renderConnectionProblemContent() {
      if (errorMessage && !errorMessage.includes('NOT_HANDLED')) {
         return (
            <>
               <Typography
                  sx={{
                     fontWeight: 'bold',
                     color: 'red',
                     border: '1px solid red',
                     p: 1,
                     maxWidth: isMobile ? '100px' : 'none',
                     borderRadius: '5px',
                  }}
                  variant="body1"
               >
                  {errorMessage}
               </Typography>
               {renderEditButton()}
            </>
         )
      }
      return (
         <>
            <Typography
               sx={{
                  fontWeight: 'bold',
                  color: 'red',
                  border: '1px solid red',
                  p: 1,
                  borderRadius: '5px',
                  maxWidth: isMobile ? '100px' : 'none',
               }}
               variant="body1"
            >
               {t('tables.wholesaleReconnectFailed')}
            </Typography>
            {renderEditButton()}
         </>
      )
   }

   if (
      status === 'ONLINE_CONNECTION_PROBLEM' ||
      status === 'PRODUCT_NOT_EXIST_IN_WHOLESALE' ||
      (status === 'OK_ONLINE_AVAILABILITY' && !netPrice)
   ) {
      return (
         <ButtonContainer sx={{ alignItems: 'center', justifyContent: 'center' }}>
            {renderConnectionProblemContent()}
         </ButtonContainer>
      )
   }
   if (!totalAvailability || totalAvailability < 0) {
      return (
         <ButtonContainer sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Tooltip
               placement="right"
               disableHoverListener={status !== 'OK_ONLINE_AVAILABILITY'}
               title={t('tables.sendReportTooltip') || ''}
            >
               <span>
                  <GrayButton
                     sx={{
                        maxWidth: isMobile ? '100px' : 'none',
                     }}
                     fullWidth={isMobile}
                     variant="outlined"
                     type="button"
                     onClick={onReportMissingProduct}
                     disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
                  >
                     {t('tables.reportButton')}
                  </GrayButton>
               </span>
            </Tooltip>
            {renderEditButton()}
         </ButtonContainer>
      )
   }
   return (
      <ButtonContainer
         sx={{
            maxWidth: user.roles.includes(UserRoles.DATA_MANAGER) ? '400px' : 'none',
            ml: user.roles.includes(UserRoles.DATA_MANAGER) ? 'auto' : '0',
            mr: user.roles.includes(UserRoles.DATA_MANAGER) ? 'auto' : '0',
            justifyContent: user.roles.includes(UserRoles.DATA_MANAGER) ? 'normal' : 'center',
            alignItems: 'center',
         }}
      >
         {wholesaleName !== 'Motorro' && (
            <ContainedButton
               sx={{
                  maxWidth: isMobile ? '100px' : 'none',
               }}
               fullWidth={isMobile}
               variant="contained"
               onClick={onBuy}
               disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading || user?.roles.includes(UserRoles.SALES_REP)}
            >
               {t('tables.buyNowButton')}
            </ContainedButton>
         )}
         <OutlinedButton
            sx={{
               maxWidth: isMobile ? '100px' : 'none',
            }}
            fullWidth={isMobile}
            variant="outlined"
            onClick={onAddToCart}
            disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
         >
            {isMobile ? t('tables.addToCartButtonShort') : t('tables.addToCartButton')}
         </OutlinedButton>
         {renderEditButton()}
      </ButtonContainer>
   )
}
