import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { useNotificationContext } from 'shared/context/Notifications'

export default function CustomSnackbar() {
   const { alert, removeNotification } = useNotificationContext()
   const [open, handleOpen] = useState(false)
   const durationInterval = useRef<null | NodeJS.Timeout>(null)

   const { t } = useTranslation('TRANSLATION')

   function handleClose() {
      handleOpen(false)
      removeNotification()
   }

   useEffect(() => {
      if (alert) {
         durationInterval.current = setInterval(() => handleOpen(false), 6000)
         if (!open) {
            handleOpen(true)
         }
      }

      return () => {
         if (durationInterval.current !== null) {
            clearInterval(durationInterval.current as NodeJS.Timeout)
         }
      }
   }, [alert])

   return (
      <Snackbar
         sx={{ marginTop: { lg: 5, md: 5, xs: 1 } }}
         anchorOrigin={{
            vertical: alert?.alignment === 'BOTTOM' ? 'bottom' : 'top',
            horizontal: alert?.alignment === 'BOTTOM' ? 'right' : 'center',
         }}
         open={open}
      >
         <Alert onClose={handleClose} severity={alert?.status} sx={{ width: '100%' }}>
            {alert?.message ? t(alert.message) : null}
         </Alert>
      </Snackbar>
   )
}
