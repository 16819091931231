import DialogTitle from '@mui/material/DialogTitle'
import React, { useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useFormik, Form, FormikProvider } from 'formik'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import useNonApiWholesalesQuery from 'shared/store/useNonApiWholesales'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import CustomDialog from 'shared/components/CustomDialog'
import InputField from 'shared/components/InputField'
import { pythonHttpClient } from 'shared/lib'

import { AddWholesalerProductType } from './types'
import { EditModal } from '../../types'
import { schema } from './schema'

interface Props {
   modalInfo: EditModal | undefined
   handleClose: () => void
}

export default function AddWholesalerModal({ modalInfo, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [multipleIndexes, setMultipleIndex] = useState<string[]>()
   const { data } = useNonApiWholesalesQuery()

   const formik = useFormik<AddWholesalerProductType>({
      initialValues: {
         depositIndex: '',
         depositPrice: null,
         wholesalerIndex: '',
         catalogPrice: null,
         quantity: null,
         taxRate: 23,
         times: 1,
         priceVariants: null,
         departmentsAvailability: null,
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, isValid, setFieldError } = formik

   function handleSubmit() {
      if (modalInfo) {
         pythonHttpClient
            .post(`products/${modalInfo.productUuid}/wholesales/${modalInfo.data.wholesaleUuid}`, {
               wholesalerIndex: values.wholesalerIndex,
               depositIndex: values.depositIndex || null,
               depositPrice: values.depositPrice ? values.depositPrice.toString() : null,
               catalogPrice: null,
               quantity: null,
               taxRate: values.taxRate.toString(),
               times: values.times,
               priceVariants: values.priceVariants || null,
               departmentsAvailability: values.departmentsAvailability || null,
            })
            .then((res) => {
               if (res.status === 204) {
                  addNotification('successSave', 'success')
                  handleClose()
               }
            })
            .catch((error) => {
               if (error.response.status === 424) {
                  setFieldError('wholesaler_index', 'Taki indeks już istnieje')
                  pythonHttpClient
                     .post(`products/wholesales/${modalInfo.data.wholesaleUuid}`, {
                        wholesaler_index: values.wholesalerIndex,
                     })
                     .then((res) => {
                        setMultipleIndex(res.data.products_uuids)
                     })
               } else {
                  addNotification('failedSave', 'error')
               }
            })
      }
   }

   function handleWindowOpen(mainProductUuid?: string) {
      let params = ''
      if (multipleIndexes) {
         multipleIndexes.forEach((item) => {
            params = params.concat(`&productUuid=${item}`)
         })
      }
      if (mainProductUuid && params) {
         window.open(`${window.location.origin}/dashboard/internal-search?mainProductUuid=${mainProductUuid}${params}`)
      }
   }

   return (
      <CustomDialog
         isLoading={!modalInfo}
         open={!!modalInfo}
         onClose={handleClose}
         content={
            <>
               <DialogTitle color="primary">Dodaj produkt do {modalInfo?.data.wholesaleName}</DialogTitle>
               <FormikProvider value={formik}>
                  <Form>
                     <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 3, py: 1 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                           <InputField name="wholesalerIndex" label="Index" />
                           <InputField
                              inputProps={{
                                 min: 1,
                              }}
                              type="Number"
                              label="Krotność"
                              name="times"
                           />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                           <InputField name="depositIndex" label="Indeks kaucji produktu" />
                           <InputField type="number" name="depositPrice" label="Kwota kaucji produktu" />
                        </Box>
                        <InputField type="number" inputProps={{ min: 1 }} name="taxRate" label="VAT" />
                        {data?.find((item) => item.uuid === modalInfo?.data.wholesaleUuid) && (
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <Typography variant="body1" color="primary.dark">
                                 Dostępność:
                              </Typography>
                              {data!
                                 .find((item) => item.uuid === modalInfo?.data.wholesaleUuid)
                                 ?.departmentsAvailability!.map((key) => (
                                    <InputField
                                       key={key}
                                       InputLabelProps={{ shrink: true }}
                                       label={key}
                                       name={`departmentsAvailability[${key}]`}
                                    />
                                 ))}
                           </Box>
                        )}
                        {data?.find((item) => item.uuid === modalInfo?.data.wholesaleUuid) && (
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <Typography variant="body1" color="primary.dark">
                                 Warianty cenowe:
                              </Typography>
                              {data!.find((item) => item.uuid === modalInfo?.data.wholesaleUuid)?.priceVariants &&
                                 Object.entries(
                                    data!.find((item) => item.uuid === modalInfo?.data.wholesaleUuid)!.priceVariants
                                 ).map(([key, value]) => (
                                    <InputField
                                       key={key}
                                       InputLabelProps={{ shrink: true }}
                                       label={value}
                                       name={`priceVariants[${key}]`}
                                    />
                                 ))}
                           </Box>
                        )}
                        {multipleIndexes && (
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                              <Button
                                 color="error"
                                 variant="contained"
                                 onClick={() => handleWindowOpen(modalInfo?.productUuid)}
                              >
                                 Pokaż listę produktów
                              </Button>
                           </Box>
                        )}
                     </DialogContent>
                     <DialogActions sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                        <OutlinedButton type="button" variant="outlined" onClick={handleClose}>
                           Zamknij
                        </OutlinedButton>
                        <ContainedButton disabled={!isValid} type="submit" variant="contained">
                           Zapisz
                        </ContainedButton>
                     </DialogActions>
                  </Form>
               </FormikProvider>
            </>
         }
      />
   )
}
