import React, { useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import DialogContent from '@mui/material/DialogContent'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { Base64 } from 'js-base64'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'

import { DialogActions } from '@mui/material'
import { ChangePasswordInitialValues, ChangePasswordPayload } from '../../../../types'
import { changeUserPasswordService } from '../../../../services'
import { changePasswordSchema } from '../../../../schema'

interface Props {
   handleClose: () => void
   uuid: string
}

export default function ChangePasswordModal({ handleClose, uuid }: Props) {
   const { t } = useTranslation('TRANSLATION')
   const { addNotification } = useNotificationContext()
   const [isLoading, setIsLoading] = useState(false)

   const formik = useFormik<ChangePasswordInitialValues>({
      initialValues: {
         password: '',
         confirmPassword: '',
      },
      validationSchema: changePasswordSchema,
      onSubmit: handlePasswordChange,
   })

   const { values } = formik

   function handlePasswordChange() {
      setIsLoading(true)
      const payload: ChangePasswordPayload = {
         password: Base64.encode(values.password),
         repassword: Base64.encode(values.confirmPassword),
      }
      changeUserPasswordService(payload, uuid)
         .then((res) => {
            if (res.status === 200) {
               addNotification('successSave', 'success')
               handleClose()
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => setIsLoading(false))
   }

   return (
      <>
         <DialogContent>
            <FormikProvider value={formik}>
               <Form>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
                     <InputField<ChangePasswordInitialValues>
                        name="password"
                        variant="standard"
                        type="password"
                        label={t('formFields.password')}
                        required
                     />
                     <InputField<ChangePasswordInitialValues>
                        name="confirmPassword"
                        variant="standard"
                        required
                        type="password"
                        label={t('formFields.confirmPassword')}
                     />
                  </Box>
               </Form>
            </FormikProvider>
         </DialogContent>
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('cancel')}
            </OutlinedButton>
            <ContainedButton disabled={isLoading} variant="contained" onClick={handlePasswordChange}>
               {t('save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
