import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { useDeleteReflinkMutation } from 'shared/store/useClientReflinks'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { DialogContent } from '@mui/material'

interface Props {
   reflinkUuid: string
   handleClose: () => void
}

export default function DeleteReflinkModalContent({ reflinkUuid, handleClose }: Props) {
   const { mutate } = useDeleteReflinkMutation()
   const { t } = useTranslation(['TRANSLATION', 'CLIENTS_CARTS'])

   function handleDelete() {
      mutate(reflinkUuid, {
         onSuccess: handleClose,
      })
   }

   return (
      <>
         <DialogContent />
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:no')}
            </OutlinedButton>
            <ContainedButton onClick={handleDelete} variant="contained">
               {t('TRANSLATION:yes')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
