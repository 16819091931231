/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableBody from '@mui/material/TableBody'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import { ChevronLeft } from '@mui/icons-material'
import { Box, Collapse, Table, Typography, useMediaQuery, useTheme } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { sortAlphabetical, sortByDate, sortNumber } from 'shared/utils/helpers/sorters'
import reverseDateRepresentation from 'shared/utils/helpers/reverseDateRepresentation'
import {
   StyledCollapseTableCell,
   StyledTable,
   StyledTableBody,
   StyledTableCell,
   StyledTableHead,
   StyledTableHeadCell,
   StyledTableRow,
} from '../StyledTableComponents'
import HeadLabel from '../TableHeadCell'

interface Props<T> {
   onRowClick?: (row: T) => void
   customRowStyles?: (row: T) => { [key: string]: any }
   customTableStyles?: () => { [key: string]: any }
   excludedFromSorting?: (row: T) => boolean
   customNoResultsText?: string
   disablePagination?: boolean
   customFooter?: (columnsCount: number) => ReactNode
   customChangePage?: (page: number, rowsPerPage: number) => void
   onChangeRowsPerPage?: (rowsPerPage: number) => void
   customChangeRowsPerPage?: (page: number, rowsPerPage: number) => void
   pagingVariants?: number[]
   customHeadRowStyles?: () => { [key: string]: any }
   onMouseDown?: (e: any, row: T) => void
   dataSize: number
   defaultSortBy?: string
   defaultSortDirection?: string
   defaultSortType?: 'date' | 'string' | 'number'
   activePage?: number
   rowsPerPage?: number
   rows: T[]
   columns: TableColumn<T>[]
}

type TableColumn<T> = {
   name: string
   renderCell: (row: T) => ReactNode
   sortable?: boolean
   preventOnClick?: boolean
   sortType?: 'number' | 'string' | 'date'
   sortLabel?: string
   hidden?: boolean
   showPriceType?: boolean
   showSortOption?: boolean
   customCellStyles?: (row: T) => { [key: string]: any }
   mainOnMobile?: boolean
   onlyOnMobile?: boolean
}

export default function CustomTable({
   onRowClick,
   customFooter,
   excludedFromSorting,
   customNoResultsText,
   onMouseDown,
   rows,
   defaultSortBy,
   defaultSortType,
   defaultSortDirection,
   customTableStyles,
   customHeadRowStyles,
   disablePagination,
   columns,
   activePage,
   onChangeRowsPerPage,
   rowsPerPage,
   customRowStyles,
   customChangePage,
   dataSize,
   customChangeRowsPerPage,
   pagingVariants,
}: Props<any>) {
   const { t } = useTranslation('TRANSLATION')
   const [itemsPerPage, setItemsPerPage] = useState<number>(rowsPerPage ?? (pagingVariants && pagingVariants[0]) ?? 15)
   const [page, setPage] = useState(activePage ?? 0)
   const [collapsed, setCollapsed] = useState<number[]>([])
   const theme = useTheme()
   const [sortBy, setSortBy] = useState(defaultSortBy)
   const [sortedData, setSortedData] = useState<any[]>([])
   const [sortType, setSortType] = useState<'date' | 'string' | 'number'>(defaultSortType || 'string')
   const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(
      (defaultSortDirection as 'asc' | 'desc') || null
   )
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   function getRows() {
      if (customChangePage && customChangeRowsPerPage) {
         return sortedData
      }
      if (disablePagination) {
         return sortedData
      }

      return sortedData.slice(itemsPerPage * page, itemsPerPage * page + itemsPerPage)
   }

   useEffect(() => {
      if (excludedFromSorting) {
         setSortedData([
            ...rows.filter((row) => !excludedFromSorting(row)),
            ...rows.filter((row) => excludedFromSorting(row)),
         ])
      } else {
         setSortedData([...rows])
      }
   }, [])

   useEffect(() => {
      setPage(0)
   }, [rows])

   useEffect(() => {
      let sortedRows = [...rows]
      if (sortBy) {
         if (sortType === 'string') {
            sortedRows.sort((a, b) =>
               sortDirection === 'asc'
                  ? sortAlphabetical(a[sortBy], b[sortBy])
                  : -sortAlphabetical(a[sortBy], b[sortBy])
            )
         }
         if (sortType === 'date') {
            sortedRows.sort((a, b) =>
               sortDirection === 'asc'
                  ? sortByDate(
                       reverseDateRepresentation(a[sortBy] ?? '') || '',
                       reverseDateRepresentation(b[sortBy]) || ''
                    )
                  : -sortByDate(reverseDateRepresentation(a[sortBy]) || '', reverseDateRepresentation(b[sortBy]) || '')
            )
         }
         if (sortType === 'number') {
            sortedRows.sort((a, b) =>
               sortDirection === 'asc' ? sortNumber(a[sortBy], b[sortBy]) : -sortNumber(a[sortBy], b[sortBy])
            )
         }
      }
      if (excludedFromSorting) {
         sortedRows = [
            ...sortedRows.filter((row) => !excludedFromSorting(row)),
            ...rows.filter((row) => excludedFromSorting(row)),
         ]
      }
      setSortedData(sortedRows)
   }, [sortBy, sortDirection, sortType, rows, excludedFromSorting])

   function updateDirection(rowIndex: number) {
      setSortBy(columns.filter((column) => !column.hidden)[rowIndex].sortLabel)
      setSortType(columns.filter((column) => !column.hidden)[rowIndex].sortType ?? 'string')
      if (sortDirection === 'desc') {
         setSortDirection('asc')
      }
      if (sortDirection === 'asc') {
         setSortDirection(null)
         setSortedData([...rows])
         setSortBy(undefined)
         setSortBy(undefined)
      }
      if (sortDirection === null) {
         setSortDirection('desc')
      }
   }

   function handleCollapse(rowIndex: number) {
      if (collapsed.includes(rowIndex)) {
         setCollapsed((prevState) => [...prevState.filter((item) => item !== rowIndex)])
      } else {
         const newCollapsed = [...collapsed]
         newCollapsed.push(rowIndex)
         setCollapsed([...newCollapsed])
      }
   }

   function renderRows(rowsToRender: any[]) {
      if (isMobile) {
         return rowsToRender.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
               <StyledTableRow
                  sx={
                     customRowStyles
                        ? {
                             ...customRowStyles(row),
                             ...(collapsed.includes(rowIndex) && {
                                borderBottom: '2px solid rgba(0,0,0,0.1)',
                             }),
                          }
                        : {
                             ...(collapsed.includes(rowIndex) && {
                                borderBottom: '2px solid rgba(0,0,0,0.1)',
                             }),
                          }
                  }
                  onClick={(e: any) => {
                     if (row.preventOnClick) {
                        e.stopPropagation()
                        e.preventDefault()
                     } else if (columns.filter((column) => !column.mainOnMobile && !column.hidden).length > 0) {
                        handleCollapse(rowIndex)
                     } else if (onRowClick) {
                        onRowClick(row)
                     }
                  }}
               >
                  {columns
                     .filter((column) => column.mainOnMobile && !column.hidden)
                     .map((column) => (
                        <StyledTableCell
                           onClick={(e: any) => {
                              if (column.preventOnClick) {
                                 e.stopPropagation()
                                 e.preventDefault()
                              }
                           }}
                           key={column.name}
                        >
                           {column.renderCell(row)}
                        </StyledTableCell>
                     ))}
               </StyledTableRow>
               {columns.filter((column) => !column.mainOnMobile && !column.hidden).length > 0 && (
                  <TableRow>
                     <TableCell style={{ padding: 0 }} colSpan={columns.filter((column) => column.mainOnMobile).length}>
                        <Collapse
                           style={{ overflowY: 'auto', maxHeight: '300px' }}
                           in={collapsed.includes(rowIndex)}
                           timeout="auto"
                        >
                           <Table>
                              <TableBody>
                                 {columns
                                    .filter((column) => !column.mainOnMobile && !column.hidden)
                                    .map((column) => (
                                       <TableRow key={column.name}>
                                          {!!column.name && (
                                             <StyledCollapseTableCell>
                                                {t(`TRANSLATION:columnNames:${column.name}`)}
                                             </StyledCollapseTableCell>
                                          )}
                                          {!!column.name && (
                                             <StyledCollapseTableCell
                                                colSpan={
                                                   columns.filter((innerColumn) => innerColumn.mainOnMobile).length - 2
                                                }
                                             />
                                          )}
                                          <StyledCollapseTableCell
                                             colSpan={
                                                !column.name
                                                   ? columns.filter((innerColumn) => innerColumn.mainOnMobile).length +
                                                     1
                                                   : 1
                                             }
                                             sx={{ color: 'black' }}
                                          >
                                             <Box
                                                sx={{
                                                   height: '40px',
                                                   maxHeight: '340x',
                                                   overflow: 'hidden',
                                                   fontSize: '11px',
                                                   display: 'flex',
                                                   alignItems: 'center',
                                                   justifyContent: 'center',
                                                   '>*': {
                                                      fontSize: '11px',
                                                   },
                                                }}
                                             >
                                                {column.renderCell(row)}
                                             </Box>
                                          </StyledCollapseTableCell>
                                       </TableRow>
                                    ))}
                              </TableBody>
                           </Table>
                        </Collapse>
                     </TableCell>
                  </TableRow>
               )}
            </React.Fragment>
         ))
      }
      return rowsToRender.map((row, index) => (
         <StyledTableRow
            key={index}
            sx={customRowStyles && customRowStyles(row)}
            onClick={(e: any) => {
               if (onRowClick) {
                  onRowClick(row)
               }
            }}
            onMouseDown={(e: any) => {
               if (onMouseDown) {
                  onMouseDown(e, row)
               }
            }}
         >
            {columns
               .filter((column) => !column.onlyOnMobile && !column.hidden)
               .map((column) => (
                  <StyledTableCell
                     onClick={(e: any) => {
                        if (column.preventOnClick) {
                           e.stopPropagation()
                           e.preventDefault()
                        }
                     }}
                     sx={column.customCellStyles && column.customCellStyles(row)}
                     key={column.name}
                  >
                     {column.renderCell(row)}
                  </StyledTableCell>
               ))}
         </StyledTableRow>
      ))
   }

   return (
      <StyledTable sx={customTableStyles && { ...customTableStyles() }}>
         <StyledTableHead sx={customHeadRowStyles && { ...customHeadRowStyles() }}>
            {isMobile
               ? columns
                    .filter((column) => column.mainOnMobile && !column.hidden)
                    .map((column) => (
                       <StyledTableHeadCell key={column.name}>
                          {t(`TRANSLATION:columnNames.${column.name}`)}
                       </StyledTableHeadCell>
                    ))
               : columns
                    .filter((column) => !column.onlyOnMobile && !column.hidden)
                    .map((column, index) =>
                       column.sortable ? (
                          <StyledTableHeadCell key={column.name}>
                             <HeadLabel
                                label={t(`TRANSLATION:columnNames:${column.name}`)}
                                value={column.sortLabel ?? ''}
                                shouldShowPriceType={column.showPriceType}
                                index={index}
                                showSorting={column.showSortOption}
                                updateDirection={updateDirection}
                                sortDirection={sortDirection}
                                sortName={sortBy || ''}
                             />
                          </StyledTableHeadCell>
                       ) : (
                          <StyledTableHeadCell key={column.name}>
                             {t(`TRANSLATION:columnNames.${column.name}`)}
                          </StyledTableHeadCell>
                       )
                    )}
         </StyledTableHead>
         <StyledTableBody noFooter={disablePagination && !customFooter}>
            {getRows().length > 0 ? (
               renderRows(getRows())
            ) : (
               <StyledTableRow>
                  <StyledTableCell sx={{ borderRadius: '8px' }} colSpan={columns.length}>
                     <Typography variant="h6" color="gray" sx={{ opacity: 0.7 }} fontWeight="bold">
                        {customNoResultsText ?? t('TRANSLATION:noResults')}
                     </Typography>
                  </StyledTableCell>
               </StyledTableRow>
            )}
         </StyledTableBody>
         {customFooter && customFooter(columns.filter((column) => !column.hidden).length)}
         {!disablePagination && (
            <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
               <TableRow>
                  <StyledTableCell
                     sx={{
                        textAlign: 'right',
                        paddingRight: { lg: 7, md: 7, xs: 2 },
                        fontSize: 12,
                        justifyContent: { lg: 'normal', md: 'normal', xs: 'space-between' },
                     }}
                     colSpan={columns.length}
                  >
                     {!isMobile && t('TRANSLATION:itemsPerPage')}
                     <Select
                        sx={{
                           mx: 1,
                           color: 'grayText',

                           fontSize: 12,
                        }}
                        variant="standard"
                        disabled={rows.length === 0}
                        value={itemsPerPage}
                        onChange={(e) => {
                           if (customChangeRowsPerPage) {
                              customChangeRowsPerPage(page, Number(e.target.value))
                           }
                           if (onChangeRowsPerPage) {
                              onChangeRowsPerPage(Number(e.target.value))
                           }
                           setItemsPerPage(Number(e.target.value))
                        }}
                     >
                        {pagingVariants!.map((item) => (
                           <MenuItem key={item} value={item}>
                              {item}
                           </MenuItem>
                        ))}
                     </Select>
                     {!isMobile
                        ? t('TRANSLATION:displayed', {
                             displayed: `${itemsPerPage * page + 1} - ${
                                itemsPerPage * page + itemsPerPage > dataSize
                                   ? dataSize
                                   : itemsPerPage * page + itemsPerPage
                             }`,
                             all: dataSize,
                          })
                        : `${itemsPerPage * page + 1} - ${
                             itemsPerPage * page + itemsPerPage > dataSize
                                ? dataSize
                                : itemsPerPage * page + itemsPerPage
                          }`}
                     <IconButton
                        disabled={dataSize === 0 || page === 0}
                        onClick={() => {
                           if (customChangePage) {
                              customChangePage(page - 1, itemsPerPage)
                           }
                           setPage((currentPage) => currentPage - 1)
                        }}
                     >
                        <ChevronLeft />
                     </IconButton>
                     <IconButton
                        disabled={dataSize === 0 || page === Math.ceil(dataSize / itemsPerPage) - 1}
                        onClick={() => {
                           if (customChangePage) {
                              customChangePage(page + 1, itemsPerPage)
                           }
                           setPage((currentPage) => currentPage + 1)
                        }}
                     >
                        <ChevronRight />
                     </IconButton>
                  </StyledTableCell>
               </TableRow>
            </TableFooter>
         )}
      </StyledTable>
   )
}
