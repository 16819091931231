import React, { useState, useEffect, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Client, IMessage } from '@stomp/stompjs'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import SockJS from 'sockjs-client'

import { fetchCooperatingWholesalersData } from 'shared/services/fetchCooperationWholesalers'
import mergeObjects from 'shared/utils/helpers/mergeObjects'
import { useProfileQuery } from 'shared/store/useProfile'
import { httpClient } from 'shared/lib'

import MainTable from '../../../WholesaleAvailabilityTable'

const SOCKET_URL = `${process.env.REACT_APP_API_URL}/websockets`

interface Props {
   productUuid: string
   productToRemoveUuid: string
   removeFromUnavailable: boolean
   oldClientPrice?: string
   defaultQuantity: number
   handleClose: () => void
}

export default function SwapWholesaleModal({
   productUuid,
   oldClientPrice,
   productToRemoveUuid,
   removeFromUnavailable,
   defaultQuantity,
   handleClose,
}: Props) {
   const [isData, setIsData] = useState(false)

   const [data, setData] = useState<FetchCooperationDataResult>({
      availableWholesalers: [],
      unAvailableWholesalers: [],
   })
   const { data: userProfileData } = useProfileQuery()
   const websocket = useRef<Client>()

   const navigate = useNavigate()

   function publishWithPromise(destination: string, headers: Record<string, string>, body: string): Promise<void> {
      return new Promise((resolve) => {
         if (websocket.current) {
            websocket.current.publish({ destination, headers, body })
         }
         resolve()
      })
   }

   async function handleWebSocketMessage(msg: IMessage): Promise<void> {
      setData((prevData) => {
         const newData = {
            ...prevData,
            availableWholesalers: prevData.availableWholesalers.map((item) => {
               if (msg.body) {
                  const jsonBody = JSON.parse(msg.body) as CooperatingWholesaler
                  if (item.wholesaleUuid === jsonBody.wholesaleUuid) {
                     const updatedItem = mergeObjects(item, jsonBody) as CooperatingWholesaler
                     return updatedItem
                  }
               }
               return item
            }),
         }
         return newData
      })
   }

   async function onConnected(): Promise<void> {
      if (websocket.current) {
         await publishWithPromise(
            `/core/api/ws/product/availability-in-cooperating/${userProfileData?.uuid}${productUuid}`,
            {
               'X-Authorization': `Bearer ${window.localStorage.getItem('ACCESS_TOKEN')}`,
            },
            JSON.stringify({ productUuid })
         )

         await new Promise<void>((resolve) => {
            if (websocket.current) {
               websocket.current.subscribe(
                  `/product/availability-in-cooperating/${userProfileData?.uuid}${productUuid}`,
                  (msg) => {
                     handleWebSocketMessage(msg)
                     resolve()
                  }
               )
            }
         })
      }
   }

   async function init(productId: string) {
      await fetchCooperatingWholesalersData(productId).then((res) => {
         setData(res)
         setIsData(true)
      })
   }

   useEffect(() => {
      if (!productUuid) {
         navigate('/dashboard/search')
         return
      }
      if (userProfileData) {
         init(productUuid)
      }
   }, [productUuid, userProfileData, httpClient])

   useEffect(() => {
      if (isData) {
         websocket.current = new Client({
            webSocketFactory: () => new SockJS(SOCKET_URL),
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: onConnected,
            connectHeaders: {
               'X-Authorization': `Bearer ${window.localStorage.getItem('ACCESS_TOKEN')}`,
            },
         })
         websocket.current.activate()
      }
   }, [isData])

   useEffect(() => {
      return () => {
         if (websocket.current) {
            websocket.current.deactivate()
         }
      }
   }, [])

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { lg: 'center', md: 'center', xs: 'normal' },
            px: { lg: 10, md: 10, xs: 0 },
            width: '100%',
         }}
      >
         {isData ? (
            <MainTable
               data={data.availableWholesalers}
               oldClientPrice={oldClientPrice}
               defaultQuantity={defaultQuantity}
               handleModalClose={handleClose}
               removeFromUnavailable={removeFromUnavailable || false}
               productToRemoveUuid={productToRemoveUuid}
               productID={productUuid}
            />
         ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   )
}
