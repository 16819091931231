/* eslint-disable no-nested-ternary */
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import Select from '@mui/material/Select'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import CircularProgress from '@mui/material/CircularProgress'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import {
   Badge,
   Card,
   FormControl,
   FormControlLabel,
   InputAdornment,
   InputLabel,
   Switch,
   TextField,
   useMediaQuery,
   useTheme,
} from '@mui/material'
import { sortAlphabetical } from 'shared/utils/helpers/sorters'
import { useClientPrices } from 'shared/hooks/ClientPriceHook'
import CustomTable from 'shared/components/CustomTable'
import ImageComponent from 'shared/components/ImageComponent'
import { StyledSpan, ButtonWrapper, StyledBox, PayBox, StyledAvailabilityDot } from './styles'
import {
   DuplicatedProduct,
   FetchProductsResult,
   sortDirectionType,
   Product,
   SendDuplicatesPayload,
   FetchProductsPayload,
   VoteType,
} from './types'
import { pl, en, de } from './locales'
import { fetchProducts, getSubstitutesService, sendDuplicates, sendSubstituteVote } from './services'
import MergeProductsModal from './components/MergeProductsModal'
import DeleteModal from './components/DeleteModal'
import ScoreDetailsModal from './components/ScoreDetailsModal'

export default function ProductsList() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'PRODUCTS_LIST'])
   i18n.addResourceBundle('pl-PL', 'PRODUCTS_LIST', pl)
   i18n.addResourceBundle('en-EN', 'PRODUCTS_LIST', en)
   i18n.addResourceBundle('de-DE', 'PRODUCTS_LIST', de)
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   const getSearchType = searchParams.get('searchType') as PropType<FetchProductsPayload, 'searchType'>
   const { addNotification } = useNotificationContext()
   const { user } = useLogin()
   const [limitExceeded, setLimitExceeded] = useState(false)
   const location = useLocation()
   const [duplicatedProducts, setDuplicatedProducts] = useState<DuplicatedProduct[]>([])
   const [sortDirection] = useState<sortDirectionType>('asc')
   const [isSendingDuplicates, setIsSendingDuplicates] = useState(false)
   const [searchError, setSearchError] = useState(false)
   const [deleteModal, setDeleteModal] = useState<string>()
   const [data, setData] = useState<FetchProductsResult>()
   const [sortedProducts, setSortedProducts] = useState<PropType<FetchProductsResult, 'products'>>([])
   const [sortBy, setSortBy] = useState<Exclude<keyof Product, 'wholesaleName' | 'scoreComponents' | 'voteType'>>()
   const [openScoreDetails, setOpenScoreDetails] = useState<PropType<Product, 'scoreComponents'>>()
   const [itemsPerPage, setItemsPerPage] = useState(25)
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
   const [isLoading, setIsLoading] = useState(false)
   const [votingLoading, setVotingLoading] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [search, setSearch] = useState('')
   const [activePage, setActivePage] = useState(0)
   const [producers, setProducers] = useState<string[]>([])
   const [producersFilter, setProducersFilter] = useState<string[]>([])
   const [onlyAvailable, setOnlyAvailable] = useState(false)
   const { clientPricesOn } = useClientPrices()
   const [shouldRedirect, setShouldRedirect] = useState(true)

   const searchValue = searchParams.get('searchType') !== 'SUBSTITUTES' ? searchParams.get('value') || '' : ''

   function renderVotingCell(voteType: PropType<Product, 'voteType'>, productUuid: string) {
      if (getSearchType === 'SUBSTITUTES') {
         if (voteType !== 'MAIN') {
            return (
               <Box
                  onClick={(e) => {
                     e.preventDefault()
                     e.stopPropagation()
                  }}
               >
                  {renderVotes(voteType, productUuid)}
               </Box>
            )
         }
         return <Box />
      }
      return null
   }

   function handleVoting(replacementProductUuid: string, vote: Exclude<VoteType, 'MAIN'>) {
      setVotingLoading(true)
      sendSubstituteVote(searchParams.get('value') || '', replacementProductUuid, vote)
         .then(() => {
            addNotification('successSave', 'success')
            getSubstitutesData(true)
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
   }

   function renderVotes(voteType: VoteType, replacementProductUuid: string) {
      switch (voteType) {
         case null:
            return (
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title={t('PRODUCTS_LIST:voteUpTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'UP')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbUpOffAltOutlinedIcon color={!votingLoading ? 'success' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title={t('PRODUCTS_LIST:voteDownTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'DOWN')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbDownOutlinedIcon color={!votingLoading ? 'error' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <IconButton disabled>
                     <DoDisturbIcon />
                  </IconButton>
               </Box>
            )
         case 'DOWN':
            return (
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title={t('PRODUCTS_LIST:voteUpTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'UP')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbUpOffAltOutlinedIcon color={!votingLoading ? 'success' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <IconButton disabled>
                     <ThumbDownAltIcon color={!votingLoading ? 'error' : 'disabled'} />
                  </IconButton>
                  <Tooltip title={t('PRODUCTS_LIST:voteCancelTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, null)
                        }}
                        disabled={votingLoading}
                     >
                        <DoDisturbIcon />
                     </IconButton>
                  </Tooltip>
               </Box>
            )
         case 'UP':
            return (
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton disabled>
                     <ThumbUpAltIcon color={!votingLoading ? 'success' : 'disabled'} />
                  </IconButton>
                  <Tooltip title={t('PRODUCTS_LIST:voteDownTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, 'DOWN')
                        }}
                        disabled={votingLoading}
                     >
                        <ThumbDownOutlinedIcon color={!votingLoading ? 'error' : 'disabled'} />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title={t('PRODUCTS_LIST:voteCancelTooltip')}>
                     <IconButton
                        onClick={(e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           handleVoting(replacementProductUuid, null)
                        }}
                        disabled={votingLoading}
                     >
                        <DoDisturbIcon />
                     </IconButton>
                  </Tooltip>
               </Box>
            )
         default:
            return null
      }
   }

   function returnSearchPhrase() {
      if (searchParams.get('searchType')) {
         switch (searchParams.get('searchType')) {
            case 'OIL':
               return `${searchParams.get('producerName') || ''} ${searchParams.get('sae') || ''} ${
                  searchParams.get('capacity') || ''
               }${searchParams.get('standard') || ''}`
            case 'INDEX':
            case 'OE':
            case 'EXTENSION':
            case 'WHOSALE_INDEX':
               return searchParams.get('value')
            case 'SUBSTITUTES':
               return `${searchParams.get('index')} - ${t('PRODUCTS_LIST:substitutes')}`
            default:
               return ''
         }
      }
      return ''
   }

   function handleOnClick(productUuid: string) {
      navigate({
         pathname: '/dashboard/product',
         search: createSearchParams({
            uuid: productUuid,
         }).toString(),
      })
   }

   function handleScrollClick(productUuid: string) {
      const urlStart = window.location.href.split('/')[0]
      const url = `${urlStart}/dashboard/product?uuid=${productUuid}`
      return window.open(url, '_blank')
   }

   function handleDuplicates(productUuid: string, productIndex: string, producerName: string, wholesaleCount: number) {
      if (duplicatedProducts.some((item) => item.uuid === productUuid)) {
         const newDuplicatedProducts: DuplicatedProduct[] = duplicatedProducts.filter(
            (item) => item.uuid !== productUuid
         )
         setDuplicatedProducts(newDuplicatedProducts)
      } else {
         const newDuplicatedProducts: DuplicatedProduct[] = [
            ...duplicatedProducts,
            { index: productIndex, uuid: productUuid, producer: producerName, wholesaleCount },
         ]

         setDuplicatedProducts(newDuplicatedProducts)
      }
   }

   function handleDuplicatesSend() {
      setIsSendingDuplicates(true)
      const payload: SendDuplicatesPayload = {
         products: duplicatedProducts,
      }
      sendDuplicates(payload)
         .then(() => {
            addNotification('duplicatesSend', 'success')
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => {
            setIsSendingDuplicates(false)
            setDuplicatedProducts([])
         })
   }

   function renderLoadingOrError() {
      if (searchError) {
         return (
            <Typography variant="h4" color="red" sx={{ margin: '0 auto' }}>
               {t('PRODUCTS_LIST:searchError')}
            </Typography>
         )
      }
      if (limitExceeded) {
         return (
            <Typography variant="h4" color="red" sx={{ margin: '0 auto' }}>
               {t('TRANSLATION:errors.searches.limit-exceeded')}
            </Typography>
         )
      }
      return <CircularProgress color="primary" />
   }

   function returnProductName(productName: string, productIndex: string) {
      if (productName) {
         if (productName.length > 100) {
            return `${productName.slice(0, 100)}...`
         }
         return productName
      }
      return productIndex
   }

   function returnAvailabilityDot(availability: number) {
      switch (true) {
         case availability === 0:
            return <StyledAvailabilityDot color="red" />
         case availability === 1:
            return <StyledAvailabilityDot color="orange" />
         case availability === -1:
            return <StyledAvailabilityDot color="gray" />
         case availability > 1:
            return <StyledAvailabilityDot color="green" />
         default:
            return null
      }
   }

   function getSubstitutesData(showUnverified?: boolean) {
      if (showUnverified) {
         getSubstitutesService(searchParams.get('value') || '', showUnverified)
            .then((res) => {
               setData(res)
               setSearchError(false)
            })
            .catch(() => {
               setSearchError(true)
               if (user.roles.includes(UserRoles.INTERNAL)) {
                  setData({ resultsCount: 0, hasMoreResults: false, products: [], searchSession: '' })
               }
            })
            .finally(() => {
               setVotingLoading(false)
               setIsLoading(false)
               setShouldRedirect(false)
            })
      } else {
         getSubstitutesService(searchParams.get('value') || '')
            .then((res) => {
               setData(res)
               setSearchError(false)
            })
            .catch(() => {
               setSearchError(true)
               if (user.roles.includes(UserRoles.INTERNAL)) {
                  setData({ resultsCount: 0, hasMoreResults: false, products: [], searchSession: '' })
               }
            })
            .finally(() => {
               setIsLoading(false)
               setVotingLoading(false)
               setShouldRedirect(false)
            })
      }
   }

   function fetchProductsData() {
      const searchPhrase = searchParams.get('value') || null
      const filters: PropType<FetchProductsPayload, 'filters'> = {}
      const sourceParams: PropType<FetchProductsPayload, 'sourceParams'> = {}
      setSearchError(false)

      searchParams.forEach((value: string, key: string) => {
         if ((key === 'sae' || key === 'standard' || key === 'capacity') && value !== '') {
            filters[key] = value
         }
      })

      searchParams.forEach((value: string, key: string) => {
         if ((key === 'vin' || key === 'category' || key === 'model' || key === 'carBrand') && value !== '') {
            sourceParams[key] = value
         }
      })

      const getSearchPhrase = () => {
         return searchPhrase ? searchPhrase.replace(/\(.*?\)/g, '') : searchPhrase
      }

      fetchProducts({
         searchType: getSearchType || 'INDEX',
         searchPhrase: getSearchPhrase(),
         producerUuids: searchParams.get('producer') ? [`${searchParams.get('producer')}`] : [],
         source: searchParams.get('catalogName') || null,
         sourceParams,
         filters,
      })
         .then((res) => {
            setData(res)
            setIsLoading(false)
            if (res.products.length === 1) {
               if (location.state) {
                  if (location.state.indexes) {
                     navigate(
                        {
                           pathname: '/dashboard/product',
                           search: createSearchParams({
                              uuid: res.products[0].uuid,
                           }).toString(),
                        },
                        {
                           state: {
                              indexes: location.state.indexes,
                              activeIndex: location.state.activeIndex || 0,
                           },
                        }
                     )
                  }
               } else {
                  navigate({
                     pathname: '/dashboard/product',
                     search: createSearchParams({
                        uuid: res.products[0].uuid,
                     }).toString(),
                  })
               }
            } else {
               setShouldRedirect(false)
            }
         })
         .catch((err) => {
            if (isAxiosError<ErrorType>(err)) {
               if (err.response?.status === 429) {
                  setLimitExceeded(true)
                  setData(undefined)
               }
            } else {
               setSearchError(true)
               setData(undefined)
            }
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   useEffect(() => {
      setIsLoading(true)
      setSortBy(undefined)
      setDuplicatedProducts([])

      switch (getSearchType) {
         case 'OIL':
         case 'EXTENSION':
         case 'WHOLESALE_INDEX':
         case 'INDEX':
         case 'OE':
            fetchProductsData()
            break
         case 'SUBSTITUTES':
            getSubstitutesData()
            break
         default:
            break
      }

      setActivePage(0)
      if (window.localStorage.getItem('itemsPerPage')) {
         setItemsPerPage(Number(window.localStorage.getItem('itemsPerPage')))
      } else {
         setItemsPerPage(25)
      }
   }, [searchValue, searchParams, navigate])

   useEffect(() => {
      if (data) {
         if (producersFilter.length > 0) {
            if (!onlyAvailable) {
               const tempSortedProducts = [...data.products].filter((product) =>
                  producersFilter.includes(product.producer)
               )

               setSortedProducts([...tempSortedProducts])
            } else {
               const tempSortedProducts = [...data.products]
                  .filter((product) => producersFilter.includes(product.producer))
                  .filter((product) => product.availability > 0)

               setSortedProducts([...tempSortedProducts])
            }
         }
         if (producersFilter.length === 0) {
            if (!onlyAvailable) {
               const tempSortedProducts = [...data.products]

               setSortedProducts([...tempSortedProducts])
            } else {
               const tempSortedProducts = [...data.products].filter((product) => product.availability > 0)

               setSortedProducts([...tempSortedProducts])
            }
         }
      }
   }, [sortBy, sortDirection, itemsPerPage, activePage, producersFilter, onlyAvailable])

   useEffect(() => {
      if (data) {
         setOnlyAvailable(false)
         setProducersFilter([])
         setSortedProducts([...data.products])
         const tempProducers: string[] = []
         data.products.forEach((product) => {
            if (!tempProducers.includes(product.producer) && product.producer !== null) {
               tempProducers.push(product.producer)
            }
         })

         setProducers(tempProducers.sort((a, b) => sortAlphabetical(a, b)))
      }
   }, [data])

   useEffect(() => {
      const tempProducers: string[] = []
      if (data) {
         if (!onlyAvailable) {
            data.products.forEach((product) => {
               if (!tempProducers.includes(product.producer)) {
                  tempProducers.push(product.producer)
               }
            })
         } else {
            data.products
               .filter((product) => product.availability > 0)
               .forEach((product) => {
                  if (!tempProducers.includes(product.producer)) {
                     tempProducers.push(product.producer)
                  }
               })
         }
      }
      setProducers(tempProducers.sort((a, b) => sortAlphabetical(a, b)))
   }, [sortedProducts, onlyAvailable])

   return (
      <StyledBox>
         {data && sortedProducts && !isLoading && !shouldRedirect ? (
            user.roles.includes(UserRoles.SUBSCRIPTION) || user.roles.includes(UserRoles.DEMO) ? (
               <>
                  <Card sx={{ overflowX: 'auto' }}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: { md: 'row', xs: 'column' },
                           justifyContent: 'space-between',
                           gap: { lg: 0, md: 0, xs: '12px' },
                           mb: { md: 3 },
                           padding: { lg: '24px 24px 0px 24px', md: '24px 24px 0px 24px', xs: '12px 0px 12px 0px' },
                           alignItems: 'center',
                           width: '100%',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              gap: 1,
                           }}
                        >
                           <Typography variant="body1" fontWeight="bold">
                              {t('PRODUCTS_LIST:searchedPhrase')}
                              <StyledSpan>{returnSearchPhrase()}</StyledSpan>
                           </Typography>
                           {data.resultsCount < 250 && (
                              <Typography variant="body1" fontWeight="bold">
                                 {t('PRODUCTS_LIST:found')} <StyledSpan> {data ? data.resultsCount : 0} </StyledSpan>
                              </Typography>
                           )}
                           {/* <Typography variant="body1" fontWeight="bold">
                               {t('PRODUCTS_LIST:foundMoreThan')}
                               <StyledSpan> {1000} </StyledSpan>
                            </Typography> */}
                        </Box>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: { lg: 4, md: 4, xs: 0 },
                              alignItems: 'center',
                              flexDirection: { lg: 'row', md: 'row', xs: 'column' },
                           }}
                        >
                           <Box
                              sx={{
                                 display: 'flex',
                                 gap: { lg: 4, md: 4, xs: '40px' },
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                              }}
                           >
                              <Badge color="error" badgeContent={t('PRODUCTS_LIST:news')}>
                                 <Typography sx={{ p: 1 }} variant="body1" fontWeight="bold">
                                    {t('PRODUCTS_LIST:filterByProducer')}:
                                 </Typography>
                              </Badge>
                              <FormControl variant="outlined" component="fieldset">
                                 <InputLabel>{t('TRANSLATION:columnNames.producer')}</InputLabel>
                                 <Select
                                    disabled={data.resultsCount === 0}
                                    value={producersFilter}
                                    onChange={(e) => {
                                       setActivePage(0)
                                       setProducersFilter(e.target.value as string[])
                                    }}
                                    sx={{ width: { lg: 200, md: 200, xs: 150 } }}
                                    variant="outlined"
                                    label={t('TRANSLATION:columnNames.producer')}
                                    multiple
                                    startAdornment={
                                       <InputAdornment position="start">
                                          <IconButton
                                             disabled={data.resultsCount === 0}
                                             onClick={() => setProducersFilter([])}
                                          >
                                             <CloseIcon />
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                 >
                                    <Box
                                       onClick={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                       }}
                                       sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                       <TextField
                                          onKeyDown={(e) => e.stopPropagation()}
                                          onClick={(e) => {
                                             e.stopPropagation()
                                             e.preventDefault()
                                          }}
                                          onFocus={(e) => e.target.select()}
                                          variant="standard"
                                          value={search}
                                          onChange={(e) => setSearch(e.target.value)}
                                          label={t('PRODUCTS_LIST:search')}
                                       />
                                    </Box>
                                    {producers
                                       .filter((producer) => producer !== null)
                                       .filter((producer) => producer.toLowerCase().includes(search.toLowerCase()))
                                       .map((producer) => (
                                          <MenuItem value={producer}>{producer}</MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>
                           </Box>
                           <FormControlLabel
                              sx={{ fontWeight: 'bold' }}
                              label={
                                 <Typography variant="body1" fontWeight="bold">
                                    {t('PRODUCTS_LIST:onlyAvailable')}
                                 </Typography>
                              }
                              control={
                                 <Switch
                                    disabled={data.resultsCount === 0}
                                    checked={onlyAvailable}
                                    onChange={(e) => {
                                       setActivePage(0)
                                       setProducersFilter([])
                                       setOnlyAvailable(e.target.checked)
                                    }}
                                 />
                              }
                           />
                        </Box>
                     </Box>

                     <CustomTable
                        pagingVariants={[10, 25, 50]}
                        rows={sortedProducts}
                        onRowClick={(row: Product) => handleOnClick(row.uuid)}
                        dataSize={sortedProducts.length}
                        onChangeRowsPerPage={(rowsPerPage) =>
                           window.localStorage.setItem('itemsPerPage', rowsPerPage.toString())
                        }
                        onMouseDown={(e, row: Product) => {
                           if (e.button === 1) handleScrollClick(row.uuid)
                        }}
                        rowsPerPage={
                           window.localStorage.getItem('itemsPerPage')
                              ? Number(window.localStorage.getItem('itemsPerPage'))
                              : 25
                        }
                        columns={[
                           {
                              name: isMobile ? 'indexAndName' : 'partIndex',
                              sortable: true,
                              mainOnMobile: true,
                              sortLabel: 'index',
                              sortType: 'string',
                              renderCell: (row: Product) =>
                                 isMobile ? (
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          maxWidth: '150px',
                                       }}
                                    >
                                       <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                          <span style={{ fontWeight: 'bold' }}>
                                             {row.index.length > 30 ? `${row.index.slice(0, 30)}...` : row.index}
                                          </span>
                                          {row.wholesaleCount < 2 ? (
                                             <Tooltip
                                                sx={{ color: 'red' }}
                                                title={t('PRODUCTS_LIST:unmappedTooltip') || ''}
                                             >
                                                <PriorityHighRoundedIcon style={{ marginBottom: -5, fontSize: 20 }} />
                                             </Tooltip>
                                          ) : null}
                                       </Box>
                                       <span>{returnProductName(row.name, row.index)}</span>
                                    </Box>
                                 ) : (
                                    <Box>
                                       {row.index.length > 30 ? `${row.index.slice(0, 30)}...` : row.index}
                                       {row.wholesaleCount < 2 ? (
                                          <Tooltip
                                             sx={{ color: 'red' }}
                                             title={t('PRODUCTS_LIST:unmappedTooltip') || ''}
                                          >
                                             <PriorityHighRoundedIcon style={{ marginBottom: -5, fontSize: 20 }} />
                                          </Tooltip>
                                       ) : null}
                                    </Box>
                                 ),
                           },
                           {
                              name: 'productName',
                              sortable: true,
                              hidden: isMobile,
                              sortLabel: 'name',
                              sortType: 'string',
                              renderCell: (row: Product) => returnProductName(row.name, row.index),
                           },
                           {
                              name: 'producer',
                              sortable: true,
                              hidden: isMobile,
                              sortLabel: 'producer',
                              sortType: 'string',
                              renderCell: (row: Product) => (
                                 <Tooltip title={row.producer}>
                                    <ImageComponent
                                       filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                                       producerName={row.producer}
                                    />
                                 </Tooltip>
                              ),
                           },
                           {
                              name: 'meanPrice',
                              sortable: true,
                              mainOnMobile: true,
                              sortLabel: 'meanPrice',
                              sortType: 'number',
                              renderCell: (row: Product) =>
                                 isMobile ? (
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          gap: '4px',
                                       }}
                                    >
                                       <Tooltip title={row.producer}>
                                          <ImageComponent
                                             height={20}
                                             width={isMobile ? '100px' : 'auto'}
                                             filename={row.producerNameWithoutSpecialChars.toLowerCase()}
                                             producerName={row.producer}
                                          />
                                       </Tooltip>
                                       {row.meanPrice !== 0 && row.meanPrice !== null
                                          ? clientPricesOn
                                             ? row.formattedClientMeanPrice
                                             : row.formattedMeanPrice
                                          : '-'}
                                       {returnAvailabilityDot(row.availability)}
                                    </Box>
                                 ) : (
                                    <Box>
                                       {row.meanPrice !== 0 && row.meanPrice !== null
                                          ? clientPricesOn
                                             ? row.formattedClientMeanPrice
                                             : row.formattedMeanPrice
                                          : '-'}
                                    </Box>
                                 ),
                           },
                           {
                              name: 'availability',
                              sortable: true,
                              hidden: isMobile,
                              sortLabel: 'availability',
                              sortType: 'number',
                              renderCell: (row: Product) => returnAvailabilityDot(row.availability),
                           },
                           {
                              name: 'duplicatedProducts',
                              preventOnClick: true,
                              hidden: isMobile,
                              renderCell: (row: Product) => (
                                 <Checkbox
                                    color="primary"
                                    checked={!!duplicatedProducts.find((item) => item.uuid === row.uuid)}
                                    onClick={() =>
                                       handleDuplicates(row.uuid, row.index, row.producer, row.wholesaleCount)
                                    }
                                 />
                              ),
                           },
                           {
                              name: '',
                              hidden: !user.roles.includes(UserRoles.DATA_MANAGER) || isMobile,
                              preventOnClick: true,
                              renderCell: (row: Product) => (
                                 <Button variant="outlined" color="error" onClick={() => setDeleteModal(row.uuid)}>
                                    Usuń
                                 </Button>
                              ),
                           },
                           {
                              name: 'connectedWholesales',
                              hidden: !user.roles.includes(UserRoles.DATA_MANAGER) || isMobile,
                              renderCell: (row: Product) => (
                                 <Typography variant="body2" fontWeight="bold">
                                    {row.wholesaleCount <= 1 && (row.wholesaleName || 'BRAK')}
                                 </Typography>
                              ),
                           },
                           {
                              name: 'score',
                              hidden:
                                 !(
                                    user.roles.includes(UserRoles.INTERNAL) ||
                                    user.roles.includes(UserRoles.DATA_MANAGER)
                                 ) || isMobile,
                              renderCell: (row: Product) => (
                                 <Typography
                                    onClick={(e) => {
                                       e.stopPropagation()
                                       e.preventDefault()
                                       setOpenScoreDetails(row.scoreComponents)
                                    }}
                                    variant="body2"
                                    fontWeight="bold"
                                 >
                                    {row.customScore}
                                 </Typography>
                              ),
                           },
                           {
                              name: 'voteCount',
                              hidden: !user.roles.includes(UserRoles.DATA_MANAGER) || isMobile,
                              renderCell: (row: Product) => (
                                 <>
                                    <Typography variant="body2" fontWeight="bold">
                                       {row.voteCount}
                                    </Typography>
                                    {renderVotingCell(row.voteType, row.uuid)}
                                 </>
                              ),
                           },
                           // {
                           //    name: '',
                           //    onlyOnMobile: true,
                           //    renderCell: (row: Product) => (
                           //       <ContainedButton variant="contained" onClick={() => handleOnClick(row.uuid)}>
                           //          {t('TRANSLATION:showButton')}
                           //       </ContainedButton>
                           //    ),
                           // },
                        ]}
                     />
                  </Card>
                  {sortedProducts.length > 0 && data && !isLoading && (
                     <ButtonWrapper>
                        {user.roles.includes(UserRoles.DATA_MANAGER) ? (
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <Button
                                 variant="contained"
                                 color="error"
                                 sx={{ borderRadius: 0.5 }}
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={() => setOpenModal(true)}
                              >
                                 Połącz produkty
                              </Button>
                              <ContainedButton
                                 variant="contained"
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={handleDuplicatesSend}
                              >
                                 {t('PRODUCTS_LIST:sendDuplicates')}
                              </ContainedButton>
                              {data.hasMoreResults && (
                                 <ContainedButton onClick={() => getSubstitutesData(true)} variant="contained">
                                    {t('PRODUCTS_LIST:showAll')}
                                 </ContainedButton>
                              )}
                           </Box>
                        ) : (
                           <Box sx={{ display: 'flex', gap: 1 }}>
                              <ContainedButton
                                 variant="contained"
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={handleDuplicatesSend}
                              >
                                 {t('PRODUCTS_LIST:sendDuplicates')}
                              </ContainedButton>
                              {data.hasMoreResults && (
                                 <ContainedButton onClick={() => getSubstitutesData(true)} variant="contained">
                                    {t('PRODUCTS_LIST:showAll')}
                                 </ContainedButton>
                              )}
                           </Box>
                        )}
                     </ButtonWrapper>
                  )}
               </>
            ) : (
               <PayBox>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 20 }}>
                     <Typography variant="h6" sx={{ mt: 1, color: 'red', textTransform: 'uppercase' }}>
                        {t('PRODUCTS_LIST:buySubscription')}
                     </Typography>
                     <ContainedButton onClick={() => navigate('/dashboard/payments')} variant="contained">
                        {t('PRODUCTS_LIST:payments')}
                     </ContainedButton>
                  </div>
               </PayBox>
            )
         ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{renderLoadingOrError()}</div>
         )}
         <MergeProductsModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            duplicatedProducts={duplicatedProducts}
         />
         <DeleteModal productUuid={deleteModal || ''} handleClose={() => setDeleteModal(undefined)} />
         {openScoreDetails && (
            <ScoreDetailsModal scoreComponents={openScoreDetails} handleClose={() => setOpenScoreDetails(undefined)} />
         )}
      </StyledBox>
   )
}
