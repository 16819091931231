import { useNavigate, useSearchParams } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormikProvider, useFormik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import KeyIcon from '@mui/icons-material/Key'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { initFacebookPixelPageView } from 'shared/lib/FacebookPixel'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import { initGoogleTag } from 'shared/lib/GoogleTag'
import MuiPhone from 'shared/components/PhoneInput'
import { httpClient } from 'shared/lib'

import ProducersSlider from '../Homepage/components/ProducersSlider'
import { StyledContactLink } from '../Homepage/styles'
import Informations from './components/Informations'
import { schema } from './schema'

const SectionStyle = styled(Card)({
   maxWidth: 664,
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
})

export default function DemoRegister() {
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const [searchParams] = useSearchParams()
   const { addNotification } = useNotificationContext()
   const navigate = useNavigate()
   const theme = useTheme()
   const [pixelInitialized, setPixelInitialized] = useState(false)
   const { t } = useTranslation(['TRANSLATION', 'UNAUTHORIZED'])

   type DemoRegisterValues = {
      name: string
      surname: string
      phoneNumber: string
      email: string
      acceptRegulations: boolean
   }
   function handleSubmit() {
      setIsLoading(true)
      const url = searchParams.get('r') ? `demo/user?remarkText=${searchParams.get('r')}` : 'demo/user'
      httpClient
         .post(url, {
            ...formik.values,
         })
         .then((res) => {
            if (res.status === 201) {
               setIsLoading(true)
               setTimeout(() => navigate('/demo-register-completed'), 1000)
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         })
   }

   const formik = useFormik<DemoRegisterValues>({
      initialValues: {
         name: '',
         surname: '',
         acceptRegulations: false,
         phoneNumber: '',
         email: '',
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, setFieldValue } = formik

   function handlePhoneNumber(value: string, country: string) {
      formik.setFieldValue('phoneNumber', value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   useEffect(() => {
      if (!pixelInitialized) {
         initFacebookPixelPageView()
         setPixelInitialized(true)
         initGoogleTag('registerPageDemo')
      }
   }, [pixelInitialized])

   return (
      <>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               gap: 2,
               mb: { xs: 2 },
               alignItems: 'center',
               justifyContent: 'center',
               minHeight: 'calc(100vh - 309px)',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  gap: 0.5,
                  ml: 3,
                  marginBottom: 1,
               }}
            >
               <KeyIcon
                  sx={{
                     fontSize: 24,
                     mt: 0.5,
                     color: theme.palette.primary.main,
                     transform: 'scaleX(-1) rotate(45deg)',
                  }}
               />
               <Typography variant="h4" fontWeight="bold">
                  {t('UNAUTHORIZED:demo.demoRegisterTitle')}
               </Typography>
            </Box>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: { xl: 'row', lg: 'column', md: 'column', sm: 'column', xs: 'column' },
                  gap: 2,
               }}
            >
               <Card sx={{ paddingX: { md: 5, sm: 2, xs: 1 }, paddingY: 2, maxWidth: 664 }}>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                     }}
                  >
                     <FormikProvider value={formik}>
                        <Form>
                           <Stack
                              gap={2}
                              sx={{
                                 display: 'flex',
                                 flexDirection: { xl: 'column', lg: 'column', xs: 'column', sm: 'column' },
                              }}
                           >
                              <Stack direction="column" gap={2}>
                                 <InputField name="name" fullWidth label={t('TRANSLATION:formFields.name')} />
                                 <InputField name="surname" fullWidth label={t('TRANSLATION:formFields.surname')} />
                              </Stack>
                              <Stack direction="column" gap={2}>
                                 <InputField name="email" label="E-mail" />
                                 <MuiPhone
                                    variant="outlined"
                                    value={formik.values.phoneNumber}
                                    onChange={(value, country) => handlePhoneNumber(value, country)}
                                 />
                              </Stack>
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       value={values.acceptRegulations}
                                       onChange={() => setFieldValue('acceptRegulations', !values.acceptRegulations)}
                                    />
                                 }
                                 label={
                                    <Typography textAlign="center" sx={{ my: 2 }} variant="body1">
                                       {t('UNAUTHORIZED:regulations')}
                                       <StyledContactLink href="https://motorro.pl/regulamin/" target="_blank">
                                          {t('UNAUTHORIZED:termsOfUseRegister')}
                                       </StyledContactLink>
                                       {t('UNAUTHORIZED:andRegister')}
                                       <StyledContactLink
                                          href="https://motorro.pl/polityka-prywatnosci/"
                                          target="_blank"
                                       >
                                          {t('UNAUTHORIZED:privacyPolicyRegister')}
                                       </StyledContactLink>
                                       {t('UNAUTHORIZED:accept')}
                                    </Typography>
                                 }
                              />
                           </Stack>
                           <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                              <ContainedButton
                                 type="submit"
                                 disabled={phoneNumberError || !formik.isValid || isLoading}
                                 variant="contained"
                              >
                                 {t('TRANSLATION:register')}
                              </ContainedButton>
                           </Box>
                        </Form>
                     </FormikProvider>
                  </Box>
               </Card>
               <SectionStyle>
                  <Informations />
               </SectionStyle>
            </Box>
         </Box>
         <ProducersSlider />
      </>
   )
}
