import { useMediaQuery, useTheme } from '@mui/material'
import { useQueryClient } from 'react-query'
import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { createProductsAvailabilityMap } from 'shared/utils/helpers/createProductAvailabilityMap'
import { useCartSummaryQuery, useRemoveProductMutation } from 'shared/store/useCartSummary'
import { reportMissingProductService } from 'shared/services/reportMissingProduct'
import DepartmentAvailability from 'shared/components/DepartmentAvailability'
import { RemoveProductPayload } from 'shared/store/useCartSummary/types'
import { useNotificationContext } from 'shared/context/Notifications'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import PriceComponent from 'shared/components/PriceComponent'
import { addToCartService } from 'shared/services/addToCart'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import DeliveryTime from 'shared/components/DeliveryTime'
import CustomTable from 'shared/components/CustomTable'
import SalerLogo from 'shared/components/SalerLogo'

import ModalComponent from './Modal'
import { Modal } from '../../types'
import Actions from './Actions'

interface Props {
   data: CooperatingWholesaler[]
   productToRemoveUuid: string
   defaultQuantity: number
   oldClientPrice?: string
   removeFromUnavailable: boolean
   productID: Nullable<string>
   handleModalClose: () => void
}

export default function MainTable({
   data,
   productID,
   productToRemoveUuid,
   oldClientPrice,
   removeFromUnavailable,
   defaultQuantity,
   handleModalClose,
}: Props) {
   const [isLoading, setIsLoading] = useState(false)
   const [modal, setModal] = useState<Modal | undefined>()
   const { data: cartSummary } = useCartSummaryQuery()
   const { mutate } = useRemoveProductMutation()
   const { addNotification } = useNotificationContext()
   const queryClient = useQueryClient()
   const theme = useTheme()
   const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

   async function onSwapProduct(payload: AddToCartPayload): Promise<{ status: number }> {
      return new Promise((resolve) => {
         if (productID && cartSummary) {
            setIsLoading(true)
            if (productToRemoveUuid) {
               const removePayload: RemoveProductPayload = {
                  uuid: productToRemoveUuid,
                  cartUuid: cartSummary.cartDetailsDTO.cartUuid,
                  removeFromUnavailable: removeFromUnavailable || false,
               }
               mutate(removePayload, {
                  onSuccess: () => {
                     addToCartService(
                        {
                           ...payload,
                           productsAvailabilityMap: createProductsAvailabilityMap(data),
                           productUuid: productID,
                           searchSession: '',
                           uuid: '',
                        },
                        cartSummary.cartDetailsDTO.cartUuid
                     )
                        .then(({ status }) => {
                           if (status === 200) {
                              invalidateCarts(queryClient)
                              handleModalClose()
                              resolve({ status })
                           }
                        })
                        .catch((error) => {
                           if (isAxiosError<ErrorType>(error)) {
                              addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
                           } else {
                              addNotification('failedSave', 'error')
                           }
                        })
                        .finally(() => {
                           setIsLoading(false)
                        })
                  },
               })
            }
         }
      })
   }

   async function onReportMissingProduct(payload: MissingProductsService) {
      if (productID) {
         setIsLoading(true)
         await reportMissingProductService({
            productUuid: payload.productUuid,
            wholesalerUuid: payload.wholesalerUuid,
         })
            .then(({ status }) => {
               if (status === 201) {
                  addNotification('productReported', 'success')
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   function getTotalAvailability(row: CooperatingWholesaler) {
      if (row.totalAvailability && row.totalAvailability > row.wholesaleInfiniteAvailabilityThreshold) {
         return `> ${row.wholesaleInfiniteAvailabilityThreshold}`
      }
      if (row.totalAvailability && row.totalAvailability > 0) {
         return row.totalAvailability
      }
      if (row.totalAvailability && row.totalAvailability < 0) {
         return 0
      }
      return 0
   }

   return data.length ? (
      <>
         <CustomTable
            rows={data}
            dataSize={data.length}
            disablePagination
            defaultSortBy="netPrice"
            defaultSortDirection="asc"
            excludedFromSorting={(row: CooperatingWholesaler) =>
               row.status !== 'OK_ONLINE_AVAILABILITY' ||
               !row.netPrice ||
               !row.totalAvailability ||
               row.totalAvailability === 0
            }
            defaultSortType="number"
            customRowStyles={(row: CooperatingWholesaler) => ({
               '& > td:not(:first-of-type):not(:last-of-type)': {
                  filter: `${row.status !== 'OK_ONLINE_AVAILABILITY' || !row.netPrice ? 'blur(4px)' : 'none'}`,
               },
               ' & > td ': {
                  filter: `${
                     row.status === 'OK_ONLINE_AVAILABILITY' &&
                     row.netPrice &&
                     (row.totalAvailability === 0 || row.totalAvailability === null)
                        ? 'grayScale(1)'
                        : 'none'
                  }`,
               },
            })}
            columns={[
               {
                  name: isMobile ? 'wholesaleAndAvailabilities' : 'wholesaleName',
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) =>
                     isMobile ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                           <SalerLogo value={row.wholesaleName} row={row} />
                           <Box sx={{ display: 'flex', gap: '2px', justifyContent: 'center' }}>
                              <DepartmentAvailability
                                 value={row.firstDepartmentAvailability}
                                 row={row}
                                 departmentName={row.firstDepartmentName}
                              />
                              <span> | </span>
                              <DepartmentAvailability
                                 value={row.secondDepartmentAvailability}
                                 row={row}
                                 departmentName={row.secondDepartmentName}
                              />
                              <span> | </span>
                              <DepartmentAvailability
                                 value={row.thirdDepartmentAvailability}
                                 row={row}
                                 departmentName={row.thirdDepartmentName}
                              />
                              <span> | </span>
                              {getTotalAvailability(row)}
                           </Box>
                        </Box>
                     ) : (
                        <SalerLogo value={row.wholesaleName} row={row} />
                     ),
               },
               {
                  name: 'netPrice',
                  sortable: true,
                  sortLabel: 'netPrice',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <PriceComponent value={row.formattedNetPrice} data={data} row={row} priceType="NET" />
                  ),
               },
               {
                  name: 'convertedNetPrice',
                  sortable: true,
                  hidden: !data.find((row) => row.convertedNetPrice),
                  sortLabel: 'convertedNetPrice',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => row.formattedConvertedNetPrice,
               },
               {
                  name: 'grossPrice',
                  sortable: true,
                  sortLabel: 'grossPrice',
                  sortType: 'number',
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) => (
                     <PriceComponent value={row.formattedGrossPrice} data={data} row={row} priceType="GROSS" />
                  ),
               },
               {
                  name: 'firstDepartmentAvailability',
                  sortable: true,
                  sortLabel: 'firstDepartmentAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <DepartmentAvailability
                        value={row.firstDepartmentAvailability}
                        row={row}
                        departmentName={row.firstDepartmentName}
                     />
                  ),
               },
               {
                  name: 'secondDepartmentAvailability',
                  sortable: true,
                  sortLabel: 'secondDepartmentAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <DepartmentAvailability
                        value={row.secondDepartmentAvailability}
                        row={row}
                        departmentName={row.secondDepartmentName}
                     />
                  ),
               },
               {
                  name: 'thirdDepartmentAvailability',
                  sortable: true,
                  sortLabel: 'thirdDepartmentAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <DepartmentAvailability
                        value={row.thirdDepartmentAvailability}
                        row={row}
                        departmentName={row.thirdDepartmentName}
                     />
                  ),
               },
               {
                  name: 'totalAvailability',
                  sortable: true,
                  sortLabel: 'totalAvailability',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => getTotalAvailability(row),
               },
               {
                  name: 'minutesToDeliveryStart',
                  sortable: true,
                  sortLabel: 'minutesToDeliveryStart',
                  sortType: 'number',
                  renderCell: (row: CooperatingWholesaler) => (
                     <Box>
                        {row.totalAvailability === null || row.totalAvailability === 0
                           ? null
                           : DeliveryTime(row.minutesToDeliveryStart)}
                     </Box>
                  ),
               },
               {
                  name: '',
                  mainOnMobile: true,
                  renderCell: (row: CooperatingWholesaler) => (
                     <Actions
                        status={row.status}
                        totalAvailability={row.totalAvailability}
                        isLoading={isLoading}
                        netPrice={row.netPrice}
                        errorMessage={row.errorMessage}
                        onReportMissingProduct={() =>
                           onReportMissingProduct({
                              wholesalerUuid: row.wholesaleUuid,
                              productUuid: row.productUuid,
                           })
                        }
                        onSwapProduct={() => setModal({ data: row, defaultQuantity, oldClientPrice })}
                     />
                  ),
               },
            ]}
         />
         {modal && (
            <ModalComponent
               onClose={() => setModal(undefined)}
               modalData={modal}
               onSwapProduct={onSwapProduct}
               isLoading={isLoading}
            />
         )}
      </>
   ) : null
}
